import Scripts from '../../Scripts/asurion-script.json';
import { CloseButton } from '@chakra-ui/react'
import translationsKeys from '../../Scripts/translationsKeys.json'
import { ResumeFlowDataKey, processIncidentKey } from '../../modules/config/constants';
import { cancelCleaningServices, cancelSRApi, getOnlineSession, logGTMEvent } from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { clearSessionData, getGTMData, getRedirectUrl, isChineseDefaultLanguage, saveError, showErrorPage } from '../../utils/utills';
import { AccountInfo, TextScript, getCarrierByDomain, getTypeByDomain, useAccount, useSessionStorage } from '@MGPD/myasurion-shared';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Flex,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { hideCancelReasons } from '../../utils/featuresUtills';
import CNScripts from '../../Scripts/asurion-script-chinese.json';
import { GTM_EVENT } from '../../utils/constant';
import { useMutation, useQuery } from '@tanstack/react-query';
import { findAgreementByMdn } from '../../../services/api';
import { loadingMessages, loadingWaitingMessages } from '../../../modules/config/constants';
// import Geocode from 'react-geocode';
interface popupProps {
  onYesClick?: () => void;
  openCancelPopup: boolean;
  closeCancelPopup: (args: boolean) => void;
  isVAS?: boolean;
}

const CancelSrPopup: React.FC<PropsWithChildren<popupProps>> = ({
  onYesClick,
  openCancelPopup,
  closeCancelPopup,
  isVAS = false
}) => {
  const cancelRef = React.useRef<any>();
  let { isOpen } = useDisclosure();

  const CancelPopUpKeys = translationsKeys.CancelSR;
  const GlobalTranslations = translationsKeys.Global;

  const globalState = useCentralStore();
  const navigate = useNavigate();

  const getServiceRequestId = (data: any) => {
    if (data?.CustomerCaseStatus?.toLowerCase() == 'reopen') return data?.ServiceRequests?.[1]?.ServiceRequestId || data?.ServiceRequest?.ServiceRequestId
    else return data?.ServiceRequest?.ServiceRequestId || data?.ServiceRequests?.[0]?.ServiceRequestId
  }


  const isTOM = globalState?.state?.sessionResponse?.InitializeResponse?.ClientName === "Asurion_TechCare"
  const interactionData = globalState?.state?.sessionResponse?.Interaction;
  const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;
  const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);
  const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
  const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];

  const [processIncidentResponse] = useSessionStorage<any>(processIncidentKey, {});
  const { ServiceRequest } = processIncidentResponse;

  const ServiceRequestId = ServiceRequest?.ServiceRequestId || getServiceRequestId(ResumeFlowData);
  const agreementData: any = globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  // const MDN = agreementData?.Assets?.Asset?.filter(
  //   (a: any) => a?.AssetInstance === 'ENROLLED'
  // )[0];

  let MDN =  agreementData?.Assets?.Asset?.filter(
    (asset: any) => asset?.AssetInstance === 'ENROLLED'
  )?.[0];
    if(MDN === undefined) {
      MDN = agreementData?.Assets?.Asset?.filter(
        (asset: any) => asset?.AssetInstance === 'REPLACED'
      )?.[0];
    }

  const R_MDN = R_ServiceRequests?.EnrolledDevice?.MDN 
  isOpen = openCancelPopup;
  const _CustomerCaseNumber = globalState?.state?.sessionResponse?.CustomerCaseResults?.CustomerCases[0]?.CustomerCaseNumber
  const R_CustomerCaseNumber = ResumeFlowData?.CustomerCaseNumber;
  const AgreementId = ResumeFlowData?.AgreementId || agreementData?.AgreementId;
  const [showReasons, setShowReasons] = useState(false);
  const [disable, setDisable] = useState(true);
  const [selectedValues, setSelectedValues] = useState<Array<string>>([]);

  
  const [, setLoadingMessage] = useState(
      loadingMessages[Math.floor(Math.random() * loadingMessages.length)]
  );
  const disableApplLoad = getCarrierByDomain()?.toLowerCase() === 'starhub'//useFeatureIsOn('disableApplLoad');
  const [account, ] = useAccount<AccountInfo>({ profileId: '', userId: '' });
  const [sessionData, ] = useSessionStorage<any>('data', '');

  let hideReasons:any;
  if(agreementData?.ClientOffer?.ClientOfferName){
   hideReasons = hideCancelReasons(agreementData?.ClientOffer?.ClientOfferName)? true: false
  }
  const isChinese = isChineseDefaultLanguage();
  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };

  const displayReasons = () => {
    setShowReasons(true);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    let values: Array<string> = selectedValues;

    if (e.target.checked) {
      values.push(value);
    } else {
      values = selectedValues.filter((e) => e !== value);
    }
    
    setDisable(values?.length > 0 ? false : true);

    setSelectedValues(values);
  };

  const onClose = () => {
    closeCancelPopup(false);
    setShowReasons(false);
  };

  const onSubmit = () => {
    // first close the popup
    onClose();
    globalState?.dispatch({ type: ActionTypes.SET_PAYMENT_MESSAGE, payload: null });
    if(isVAS){
      callCleaningCancelClaimApi();
    }else{
      const reasons = hideReasons? 'Customer Requested':selectedValues.join(', ');
      callCancelClaimApi(reasons);
    }
  };

  const { mutate: fetchAgreementByMdn } = useMutation({
    mutationFn: findAgreementByMdn,
    retry: 5,
    retryDelay: (failureCount) => {
        if (failureCount === 3) {
            setLoadingMessage(
                loadingWaitingMessages[
                    Math.floor(Math.random() * loadingWaitingMessages.length)
                ]
            );
        }
        return Math.min(1000 * 2 ** failureCount, 30000);
    },
    onSuccess: (data) => {
        if(disableApplLoad) {
            // let deviceList: any = [];
            // deviceList = data?.deviceData?.filter((a: any) => a?.type?.toLowerCase() !== 'byod' && a?.assetInstance?.toUpperCase() === 'REPLACED')
      
            // if (!deviceList || deviceList === undefined || deviceList.length === 0) {
            // deviceList = data?.deviceData?.filter((a: any) => a?.type?.toLowerCase() !== 'byod' && a?.assetInstance?.toUpperCase() === 'ENROLLED')
            // }
            
            // deviceList = [...new Map(deviceList?.map((item: any) =>
            //   [item['mdn'], item])).values()]

            let deviceList1 = new Map()
                data?.deviceData?.forEach((v: any) => {
                    let prevValue = deviceList1.get(v.mdn)
                    if(!prevValue || prevValue.type === "ENROLLED"){
                        deviceList1.set(v.mdn, v)
                    } 
                })
             let deviceList = [...deviceList1.values()]

            globalState?.dispatch({
                type: ActionTypes.SET_DEVICES_LIST,
                payload: deviceList || data?.deviceData,
            });

            globalState?.dispatch({
                type: ActionTypes.SET_FINDAGREEMENT_BY_MDN_DATA,
                payload: data,
            });
            globalState?.dispatch({
              type: ActionTypes.SET_FINDAGREEMENT_SESSION_DATA,
              payload: data?.data,
            });
        }
    },
    onSettled: () => {
        fetchOnlineSession();
    },
    onError: (error) => {
        console.error('Findagreement failed', (error as { message: string })?.message);
        // doLogout('findAgreementByMdn');
    },
});

const { refetch: fetchOnlineSession } = useQuery(
  ['sessionData'],
  async () => {
      return await getOnlineSession();
  },
  {
      retry: 5,
      retryDelay: (failureCount, error) => {
        console.error('error', error)
          if (failureCount === 3) {
              setLoadingMessage(
                  loadingWaitingMessages[
                      Math.floor(Math.random() * loadingWaitingMessages.length)
                  ]
              );
          }
          // Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Error', {
          //     userId: account.userId,
          //     error: error,
          // });
          return Math.min(1000 * 2 ** failureCount, 30000);
      },
      onSuccess: (data) => {
          // Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Success', {
          //     userId: account.userId,
          // });
  
            globalState?.dispatch({
              type: ActionTypes.SET_SESSION_RESPONSE,
              payload: data,
            });
            const isMaxis = data.InitializeResponse?.ClientName?.toUpperCase() === "MAXIS"
            globalState?.dispatch({
              type: ActionTypes.SET_IS_MAXIS_FLAG,
              payload: isMaxis,
            });
            
            const btMethods = data?.InitializeResponse?.isBraintree?.method
            
            globalState?.dispatch({
              type: ActionTypes.SET_BRAINTREE_METHODS,
              payload: btMethods,
            });
            
      
            // const agreementData = data?.FindAgreementsResults?.Agreements?.Agreement?.[0]
            // const address1 = agreementData?.Address?.Address1;
            // const address2 = agreementData?.Address?.Address2;
            // const city = agreementData?.Address?.City;
            // const _postalCode = agreementData?.Address?.PostalCode;
            // const addressjoin = { address1, address2, city, _postalCode };
            // const fulladdress = Object.values(addressjoin).join(', ');
      
            // Geocode.setApiKey(import.meta.env.VITE_GOOGLE_API_KEY);    
            // Geocode.setLanguage('en');
            // Geocode.setRegion('SGP');
            // Geocode.setLocationType('ROOFTOP');
      
            // Geocode.fromAddress(fulladdress).then(
            //   (response) => {
            //     const { lat, lng } = response?.results?.[0]?.geometry?.location;
            //     globalState?.dispatch({
            //       type: ActionTypes.SET_LOCATION_COORDINATES,
            //       payload: { lat, lng },
            //     });
            //   },
            //   (error) => {
            //     console.error(error);
            //   }
            // );
          // setAgreementData(data?.FindAgreementsResults?.Agreements?.Agreement?.[0])
      },
      onError: () => {
          // console.error(error);
          // Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Error', {
          //     userId: account.userId,
          //     error,
          // });
          // setPageLoading(true);
          // setIsFailedApiInit(true);
          // doLogout('getOnlineSession');
      },
      enabled: false,
  }
);

  const callCancelClaimApi = (reasons: string) => {
    let CustomerCaseNumber = R_CustomerCaseNumber || serviceRequestData?.CustomerCaseNumber || _CustomerCaseNumber;
    const CancelServiceRequestParams = {
      CancelServiceRequestParams: {
        ServiceRequestId: ServiceRequestId,
        InteractionLineId: interactionLineId,
        NoteText: reasons,
        Operation: 'Cancel',
        Reason: reasons,
        SubReason: reasons,
        CancelEntities: 'ALL',
        RequestedBy: 'Customer',
        ReturnEntity: true,
        AgreementId: (isTOM /*|| globalState?.state?.isMaxis*/) ? AgreementId : undefined,
        CustomerCaseNumber: isTOM ? CustomerCaseNumber : globalState?.state?.isMaxis ? String(CustomerCaseNumber) : undefined,
        MobileDeviceNumber: isTOM ? R_MDN || MDN?.MobileDeviceNumber : undefined
      },
    };
    setLoading(true);
    cancelSRApi(ServiceRequestId, CancelServiceRequestParams, interactionLineId, globalState?.state?.findAgreementSessionData)
      .then(() => {
        handleCancelSRFlow();
        const GTMData = getGTMData(GTM_EVENT.CANCEL_CLAIM_EVENT, globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0], 
          getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)
          logGTMEvent(GTMData)
          globalState?.dispatch({ type: ActionTypes.SET_SERVICE_ORDER_DATA, payload: undefined });
          if(disableApplLoad) {
            fetchAgreementByMdn({
              mdn: globalState?.state?.selectedEnrolledDevice?.mdn || account?.enrolledMdn || '',
              email: globalState?.state?.selectedEnrolledDevice?.emailAddress ?? account?.email ?? '',
              data: sessionData,
              imei: '',
              idx: 0,
              assetId: '',
              isDeviceData: true
          })
          }
      })
      .catch((error) => {
        setLoading(false);
        setSelectedValues([]);
        saveError(error, globalState);
        showErrorPage(navigate)
      });
  };

  const callCleaningCancelClaimApi = () => {
    const CancelCleaningServiceRequest = {
      CancelCleaningServiceRequest: {
        AgreementId: AgreementId,
      },
    };

    setLoading(true);
    cancelCleaningServices(CancelCleaningServiceRequest, interactionLineId, globalState?.state?.findAgreementSessionData)
      .then(() => {
        handleCancelSRFlow();
      })
      .catch((error) => {
        setLoading(false);
        setSelectedValues([]);
        saveError(error, globalState);
        showErrorPage(navigate)
      });
  };

  const handleCancelSRFlow = () => {
    setLoading(false);
    setSelectedValues([]);
    clearSessionData();

    if (onYesClick) {
      onYesClick();
    } else {
      // redirect to landing page
      // window.open(getRedirectUrl(getTypeByDomain()), '_self');
      navigate(getRedirectUrl(getTypeByDomain()));
    }
  }

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => onClose()}
        isOpen={isOpen}
        isCentered
        autoFocus={false}
      >
        <AlertDialogOverlay />
        <AlertDialogContent margin={'12px'}>
          <AlertDialogBody paddingX={'12px'} paddingTop={'12px'} as={'b'} fontSize={'18px'}>
           {showReasons && <Flex justifyContent={'flex-end'}><CloseButton onClick={() => onClose()} /></Flex>}
            <TextScript
              text={showReasons  ? Scripts.CancelSR.cancelReasonTitle : (isChinese? CNScripts.CancelSR.cancelSRInfo :Scripts.CancelSR.cancelSRInfo)}
              translationKey={`${CancelPopUpKeys.PageName}/${showReasons ? CancelPopUpKeys.KeyCancelReasonTitle : CancelPopUpKeys.KeyCancelSRInfo }`}
            />
          </AlertDialogBody>
          <Box display={showReasons  ? 'none' : 'block'} p={'12px 12px'}>
            <Flex gap="2" justifyContent={'space-between'}>
              <Button
                colorScheme="primary"
                variant="outline"
                width={'50%'}
                onClick={() => closeCancelPopup(false)}
              >
                <TextScript
                  text={hideReasons ? isChinese? CNScripts.CancelSR.CancelNo : Scripts.CancelSR.CancelNo :Scripts.Global.No}
                  translationKey={`${hideReasons}`?`${CancelPopUpKeys.PageName}/${CancelPopUpKeys.KeyCancelNo}`:`${GlobalTranslations.PageName}/${GlobalTranslations.No}`}
                />
              </Button>
              <Button
                onClick={() => hideReasons? onSubmit() :displayReasons()}
                width={'50%'}
                colorScheme="primary"
                variant="outline"
              >
                <TextScript
                  text={hideReasons ? isChinese?CNScripts.CancelSR.CancelYes:Scripts.CancelSR.CancelYes :Scripts.Global.Yes}
                  translationKey={`${hideReasons}`?`${CancelPopUpKeys.PageName}/${CancelPopUpKeys.KeyCancelYes}`:`${GlobalTranslations.PageName}/${GlobalTranslations.No}`}
                />
              </Button>
            </Flex>
          </Box>
          <Box display={showReasons && !hideReasons ? 'flex' : 'none'} flexDirection={'column'} p={'12px 12px'}>
            {Scripts.CancelSR.cancelReasons.map((val, index) => (
              <Stack direction="row">
                <Checkbox
                  value={val}
                  paddingY={'0.25rem'}
                  size="lg"
                  colorScheme="primary"
                  key={index}
                  fontSize={'16px'}
                  onChange={(e) => handleCheckboxChange(e, val)}
                >
                  <TextScript
                    text={val}
                    translationKey={`${CancelPopUpKeys.PageName}/${CancelPopUpKeys.KeyCancelReasons}${index}`}
                  />
                </Checkbox>
              </Stack>
            ))}

            <Button
              onClick={onSubmit}
              isDisabled={disable}
              width={'full'}
              marginTop={'24px'}
              colorScheme="primary"
              variant="outline"
            >
              <TextScript
                text={Scripts.Global.Submit}
                translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Submit}`}
              />
            </Button>
          </Box>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CancelSrPopup;
