/* eslint-disable no-console */
import { getCarrierByDomain, getUrlByTypeAndEnv } from '@MGPD/myasurion-shared';
import { Flex } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { getAuthToken } from '../services/api';
import Loader from '../ClaimComponents/utils/loader';

function SSORedirect() {
    useEffect(() => {
        const keysToMove = ['at', 'account', 'al'];

        keysToMove.forEach((key) => {
            const value = localStorage.getItem(key);
            if (value) {
                sessionStorage.setItem(key, value);
                localStorage.removeItem(key);
            }
        });
        refetchSSOToken();
    }, []);

    const { refetch: refetchSSOToken } = useQuery(
        ['SSOToken'],
        async () => {
            console.log('getting auth token');
            const dataValue = JSON.parse(sessionStorage.getItem('data') ?? '');
            if (!dataValue) {
                console.log('data is missing from session!');
                const carrier = getCarrierByDomain();
                const repairPortalUrl: string | null =
                    getUrlByTypeAndEnv(carrier, 'repair', import.meta.env.VITE_ENV) ?? null;
                //switch when testing locally
                // window.location.href = '/login';
                window.location.href = repairPortalUrl + 'login';
                return;
            }
            return await getAuthToken(dataValue);
        },
        {
            retry: 5,
            onSuccess: (data) => {
                console.log('getting auth token success!', data);
                const carrier = getCarrierByDomain();
                const repairPortalUrl: string | null =
                    getUrlByTypeAndEnv(carrier, 'repair', import.meta.env.VITE_ENV) ?? null;
                const token = data?.c ?? null;

                if (token && repairPortalUrl && repairPortalUrl !== '') {
                    console.log('redirecting user to ssoconsumer');
                    //switch when testing locally
                    // window.location.href = '/ssoconsumer/?c=' + token;
                    window.location.href = repairPortalUrl + '/portal/ssoconsumer/?c=' + token;
                }
            },
            onError: (error) => {
                console.error(error);
            },
            enabled: false,
        }
    );
    return (
        <Flex
            w={'full'}
            color={'#000'}
            mt={'60px'}
            p={'16px 8px'}
            maxW={{ base: 'initial', lg: '768px', xl: '992px', '2xl': '1280px' }}
            mx={{ base: '0px', lg: 'auto' }}
            flex={1}
            style={{ height: 'calc(100vh - 56px)' }}
        >
            <Flex h="full" w="full" justifyContent="center" alignItems="center">
                {/* <Spinner /> */}
                <Loader withoutOverlay={true}/>
            </Flex>
        </Flex>
    );
}

export default SSORedirect;
