import { currentAgreementKey, processIncidentKey } from '../modules/config/constants';
import {
  getMIMEType,
  getSignedRequestForUpload,
  getSignedURLForDownload,
  getVideoFileExt,
} from '../utils/upload-utils';
import { VideoDeclaration, useSessionStorage } from '@MGPD/myasurion-shared';
import { Box } from '@chakra-ui/layout';
import { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useNavigate } from 'react-router-dom';

import { uploadToS3BucketBySignedURL, uploadVideoDocument } from '../services/api';
import './carousel.css';
import { useCentralStore } from '../store/appContext';
import { saveError, showErrorPage } from '../utils/utills';
import { getMappedClientNameVal } from '../utils/helper';

const VideoDeclarationPage = () => {
  const navigate = useNavigate();
  const [uploadStatus, setUploadStatus] = useState<string>('NONE');

  const [find_agreement] = useSessionStorage<any>(currentAgreementKey, null);
  const [ProcessIncidentData] = useSessionStorage<any>(processIncidentKey, null);
  const globalState = useCentralStore();
  const interactionData = globalState?.state?.sessionResponse?.Interaction

  let programmeName = find_agreement && find_agreement?.ClientProductSkuNbr;
  let clientName = find_agreement && find_agreement?.ClientOffer?.ClientOfferName;

  const carouselContent = [
    {
      headerText: 'Keep your face within the frame and read the text below:',
      bodyText: `My Registered Device is no longer in my possession and the device will become the property of ${clientName} upon acceptance of my replacement request`,
    },
    {
      headerText: 'Keep your face within the frame and read the text below:',
      bodyText: `${clientName} or ${programmeName} has the right to register the registered device’s IMEI to the Singapore Police Force`,
    },
    {
      headerText: 'Keep your face within the frame and read the text below:',
      bodyText:
        'or any other relevant local authorities and take any other action that is deemed necessary',
    },
    {
      headerText: 'Keep your face within the frame and read the text below:',
      bodyText:
        'I understand that any unauthorised sale, transfer or use of the registered device may be seen as dealing in stolen property.',
    },
  ];

  const handleNextClick = () => {
    // navigate('/claimconfirmation');
    // navigate('/lostmode');
    navigate('../reviewhold', { replace: true });
  };
  const onSubmitVideo = (blob: Blob) => {
    setUploadStatus('INPROGRESS');
    uploadVideo(blob);
  };

  const uploadVideo = async (blob: Blob) => {
    let AgreementId = find_agreement?.AgreementId;
    let CustomerCaseNumber = ProcessIncidentData?.CustomerCaseNumber;
    const signedUrl: any = await getSignedRequestForUpload(
      AgreementId,
      interactionData?.InteractionLine.InteractionLineId,
      'video-declaration',
      getMappedClientNameVal(globalState?.state?.sessionResponse?.InitializeResponse?.ClientName?.toLowerCase()),
      globalState?.state?.findAgreementSessionData
    ).catch((error) => {
      saveError(error, globalState);
      showErrorPage(navigate);
      return;
    });

    const formData = new FormData();
    const fileExt = getVideoFileExt();
    const videoFileName = `${CustomerCaseNumber}_video_declaration.${fileExt}`;
    var file = new File([blob], videoFileName);
    formData.append('blob', blob, videoFileName);

    // uploadToS3(signedUrl, file, formData)

    const uploadedFile = await uploadToS3BucketBySignedURL(
      signedUrl, 
      getMIMEType(), 
      file
      ).catch((error) => {
        saveError(error, globalState);
        showErrorPage(navigate);
        return;
      });

    const getVideoHoldId = (holds: any) => {
      const videoHold = holds?.find(
        (hold: any) => hold?.HoldType === 'VIDEO' && hold?.HoldStatus === 'OPEN'
      );
      return videoHold?.HoldId || holds?.[0]?.HoldId;
    };

    const callUpdateReferenceDocApi = async (document: object) => {
      const ReferenceDocumentParameters = {
        ReferenceDocumentParameters: {
          ClientAccountId: find_agreement?.ClientAccount?.ClientAccountId,
          ClientChannelId: find_agreement?.ClientAccount?.ClientChannelId,
          AgreementId: AgreementId,
          ReferenceId: getVideoHoldId(ProcessIncidentData?.Holds),
          ReferenceNumber: CustomerCaseNumber?.toString(),
          ClientAccountDocCategory: 'Video Hold',
          CreatedBy: '',
          ReferenceType: 'HLD',
          Documents: [document],
          ServiceRequestId: ProcessIncidentData?.ServiceRequest?.ServiceRequestId,
        },
      };
      await uploadVideoDocument(ReferenceDocumentParameters, interactionData?.InteractionLine.InteractionLineId, globalState?.state?.findAgreementSessionData)
        .then(() => {
          setUploadStatus('COMPLETED');
        })
        .catch((error) => {
          console.log('uploadVideoDocument:', error);
          saveError(error, globalState);
          showErrorPage(navigate)
        });
    };

    if (uploadedFile && uploadedFile.status === 200 && uploadedFile.statusText === 'OK') {
      const fileName = signedUrl.split('?')?.[0]?.split('/')?.pop();
      const extension = file?.name?.split('.').pop();

      const params = {
        FileDownloadRequest: {
          AgreementId: AgreementId,
          FileName: `${fileName}.${extension}`,
          FolderName: "video-declaration"
        },
      };

      const data: any = await getSignedURLForDownload(params, interactionData?.InteractionLine.InteractionLineId, globalState?.state?.findAgreementSessionData);
      const { bucketName } = data;

      const documents = {
        BucketName: bucketName,
        Path: `${fileName}.${extension}`,
        Type: getMIMEType(),
        Status: 'uploaded',
      };

      callUpdateReferenceDocApi(documents);
    }
  };

  return (
    <Box w={{ base: 'full', lg: '80%' }} margin='auto'>
      <Box>
        <VideoDeclaration
          CarouselbtnLable="Stop Recording"
          carouselContent={carouselContent}
          handleNextClick={handleNextClick}
          handleSubmit={onSubmitVideo}
          uploadStatus={uploadStatus}
        />
      </Box>
    </Box>
  );
};

export default VideoDeclarationPage;
