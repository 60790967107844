import PropTypes from "prop-types";
import { Viewer, Worker, SpecialZoomLevel } from "@react-pdf-viewer/core";

import { WORKER_URL_PDF } from '../utils/constant';

const base64toBlob = (data: any) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data?.substr(
    "data:application/pdf;base64,".length
  );

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};

const PDF = ({ pdfClasses, base64String }: any) => {
  if (!base64String) return <div />;

  const blob = base64toBlob(base64String);
  const url = URL.createObjectURL(blob);

  return (
    <div className={pdfClasses}>
      <Worker workerUrl={WORKER_URL_PDF}>
        <Viewer fileUrl={url} defaultScale={SpecialZoomLevel.PageWidth}
        />
      </Worker>
    </div>
  );
};

PDF.propTypes = {
  base64String: PropTypes.string,
  pdfClasses: PropTypes.string,
};

PDF.defaultProps = {
  base64String: null,
  pdfClasses: "mt-2 h-80",
};

export default PDF;
