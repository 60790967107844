import { hasAddressFieldsSequence } from '../utils/featuresUtills';
import { Box, BoxProps, Flex, Text } from '@chakra-ui/layout';
import { FormControl, FormErrorMessage, Select } from '@chakra-ui/react';
import TextInput from './component/TextInput';
import React, { ChangeEvent } from 'react';
import { AddressError, ChangeAddressFieldConfig } from '../types/types';

interface ChangeAddressProps {
  newAddressQuestion: string;
  fields: ChangeAddressFieldConfig[];
  handleInputChange: (fieldName: ChangeAddressFieldConfig, value: string) => void;
  provience?: Array<string>;
  district?: Array<string>;
  selectedDistrict?: string;
  selectedProvince?: string;
  handleSelectChange?: (e: ChangeEvent<HTMLSelectElement>, value: string) => void;
  addressError?: AddressError;
  programmeName: string;
  getByCityFeature?:boolean
}

export const ChangeAddress: React.FC<ChangeAddressProps & BoxProps> = ({
  newAddressQuestion,
  fields,
  handleInputChange,
  provience,
  district,
  selectedDistrict,
  selectedProvince,
  handleSelectChange,
  addressError,
  programmeName
}) => {
  const isDistrict = (field: any) => {
    return field?.name === 'District';
  };

  return (
    <>
      <Box mx={4} mt={4} fontFamily={'Apercu Pro'}>
        {addressError && addressError?.showError &&
        <Box>
          <Text fontWeight={'bold'} color={'red'} pb={'8px'} fontSize={'md'}>
            {/* not adding translation here as error message is coming from API */}
            {addressError?.errorMessage}
          </Text>
        </Box>}
        <Text fontSize="md" fontWeight={700} mb={4}>
          {newAddressQuestion}
        </Text>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          wrap="wrap"
          justifyContent={{ base: 'start', lg: 'space-between' }}
        >
          {fields.map((field) => (
            <>
              {field?.type === 'Input' && (
                <>
                  {field.label && (
                    <Text fontWeight={'bold'} pb={2}>
                      {field.label}
                    </Text>
                  )}
                  <FormControl
                    key={field.name}
                    isRequired={field.isRequired}
                    isInvalid={field.isInvalid}
                    w={{ base: 'full', lg: '48%' }}
                    mb={4}
                  >
                    <TextInput
                      cursor={(field?.defaultDisable)? 'not-allowed': ''}
                      type="text"
                      showIcon={false}
                      borderColor={'#A5AAAF'}
                      placeholder={field.placeholder}
                      value={field.value}
                      maxLength={40}
                      onChange={(e: any) => {
                        handleInputChange(field, e.value);
                      }}
                      isDisabled={field?.defaultDisable}
                    />
                    {field.isInvalid && <FormErrorMessage>{field.errorMessage}</FormErrorMessage>}
                  </FormControl>
                </>
              )}
              {field?.type === 'Select' && hasAddressFieldsSequence(programmeName) && (
                <Box mb={4} w={{ base: 'full', lg: '48%' }}>
                  <Select
                    // placeholder={placeholders?.District}
                    placeholder={field.placeholder}
                    size="lg"
                    h={'60px'}
                    borderColor={'#A5AAAF'}
                    value={isDistrict(field) ? selectedDistrict : selectedProvince}
                    onChange={(e) =>
                      isDistrict(field)
                        ? handleSelectChange && handleSelectChange(e, 'District')
                        : handleSelectChange && handleSelectChange(e, 'Province')
                    }
                  >
                    {isDistrict(field) &&
                      Array.isArray(district) &&
                      [...new Set(district)]?.map((val: any) => {
                        return (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        );
                      })}

                    {!isDistrict(field) &&
                      Array.isArray(provience) &&
                      [...new Set(provience)]?.map((val: any) => {
                        return (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        );
                      })}
                  </Select>
                </Box>
              )}
            </>
          ))}
        </Flex>
        { !hasAddressFieldsSequence(programmeName) && (
          <Box>
            {fields.map((field) => (
              <>
                {field?.type === 'Select' && (
                  <Box mb={4}>
                    <Select
                      // placeholder={placeholders?.District}
                      placeholder={field.placeholder}
                      size="lg"
                      h={'60px'}
                      borderColor={'#A5AAAF'}
                      value={isDistrict(field) ? selectedDistrict : selectedProvince}
                      onChange={(e) =>
                        isDistrict(field)
                          ? handleSelectChange && handleSelectChange(e, 'District')
                          : handleSelectChange && handleSelectChange(e, 'Province')
                      }
                    >
                      {isDistrict(field) &&
                        Array.isArray(district) &&
                        [...new Set(district)]?.map((val: any) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        })}

                      {!isDistrict(field) &&
                        Array.isArray(provience) &&
                        [...new Set(provience)]?.map((val: any) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        })}
                    </Select>
                  </Box>
                )}
              </>
            ))}
          </Box> 
        )}
      </Box>
    </>
  );
};

export default ChangeAddress;
