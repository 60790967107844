import { ContentfulImage } from '../../types/types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { createClient } from 'contentful';
import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';
import { ReactNode } from 'react';

/* eslint-disable no-console */
export const printVersion = () => {
  console.log(`%c ${APP_NAME} v${APP_VERSION}  `, 'background: #9747DD; color: #FFF');
};

export const stringToSlug = (str: string): string => {
  return str
    .toLowerCase() // Convert the string to lowercase
    .replace(/\s+/g, '-') // Replace spaces with dashes
    .replace(/[^a-z0-9-]/g, '') // Remove non-alphanumeric characters and dashes
    .replace(/-{2,}/g, '-') // Replace consecutive dashes with a single dash
    .replace(/^-+|-+$/g, ''); // Remove dashes from the beginning or end of the slug
};

async function getFirebaseConfig() {
  const firebaseConfig = {
    apiKey: import.meta.env.VITE_REMOTE_CONFIG_API_KEY,
    authDomain: import.meta.env.VITE_REMOTE_CONFIG_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_REMOTE_CONFIG_PROJECT_ID,
    storageBucket: import.meta.env.VITE_REMOTE_CONFIG_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_REMOTE_CONFIG_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_REMOTE_CONFIG_APP_ID,
    measurementId: import.meta.env.VITE_REMOTE_CONFIG_MEASUREMENT_ID,
  };

  const app = initializeApp(firebaseConfig);
  const remoteConfig = getRemoteConfig(app);
  await fetchAndActivate(remoteConfig);

  return remoteConfig;
}

export async function getContentfulImage(
  slug: string
): Promise<any | null> {
  try {
    const remoteConfig = await getFirebaseConfig();
    const spaceId = getValue(
      remoteConfig,
      import.meta.env.VITE_REMOTE_CONFIG_CONTENTFUL_SPACE_ID_REF
    ).asString();

    const accessToken = getValue(
      remoteConfig,
      import.meta.env.VITE_REMOTE_CONFIG_CONTENTFUL_ACCESS_TOKEN_REF
    ).asString();

    const environment = getValue(
      remoteConfig,
      import.meta.env.VITE_REMOTE_CONFIG_CONTENTFUL_ENVIRONMENT_REF
    ).asString();

    const client = createClient({
      space: spaceId,
      accessToken: accessToken,
      environment: environment,
    });

    const response: any = await client.getEntries<any>({
      content_type: 'genericIdImageDescriptionSlug',
      'fields.slug': slug,
    });

    // let entry;

    // if (type === 'video') {
      const entry = response?.includes?.Asset?.[0];
    // } else {
      // entry = response?.items[0]?.fields?.image;
    // }

    const entryImage: ContentfulImage = entry;

    return entryImage.fields?.file.url || null;
  } catch (error) {
    console.error('Error fetching entry:', error);
    throw error;
  }
}

export async function getContentfulEntry(
  searchParams: Record<string, any>,
  contentType: string
): Promise<Record<string, any> | null> {
  try {
    const remoteConfig = await getFirebaseConfig();
    const spaceId = getValue(
      remoteConfig,
      import.meta.env.VITE_REMOTE_CONFIG_CONTENTFUL_SPACE_ID_REF
    ).asString();

    const accessToken = getValue(
      remoteConfig,
      import.meta.env.VITE_REMOTE_CONFIG_CONTENTFUL_ACCESS_TOKEN_REF
    ).asString();

    const environment = getValue(
      remoteConfig,
      import.meta.env.VITE_REMOTE_CONFIG_CONTENTFUL_ENVIRONMENT_REF
    ).asString();

    const client = createClient({
      space: spaceId,
      accessToken: accessToken,
      environment: environment,
    });

    const response = await client.getEntries<any>({
      content_type: contentType,
      ...searchParams,
    });

    console.log('ENTRY!', response.items[0].fields);
    const entry = response.items[0].fields;

    return entry || null;
  } catch (error) {
    console.error('Error fetching entry:', error);
    throw error;
  }
}

export const processContentfulImageToProgressiveJpg = (url: string) => {
  return `${url}?fm=jpg&fl=progressive`;
};

export const processContentfulImageToWebp = (url: string, quality: string | null = null) => {
  return `${url}?fm=webp${quality ? '&q=' + quality : ''}`;
};

export const richTextOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: ReactNode) => <span id={node}>{children}</span>,
    [BLOCKS.HEADING_1]: (node: any, children: ReactNode) => <h1 id={node}>{children}</h1>,
    [BLOCKS.HEADING_2]: (node: any, children: ReactNode) => <h2 id={node}>{children}</h2>,
    [BLOCKS.HEADING_3]: (node: any, children: ReactNode) => <h3 id={node}>{children}</h3>,
    [BLOCKS.HEADING_4]: (node: any, children: ReactNode) => <h4 id={node}>{children}</h4>,
    [BLOCKS.HEADING_5]: (node: any, children: ReactNode) => <h5 id={node}>{children}</h5>,
    [BLOCKS.HEADING_6]: (node: any, children: ReactNode) => <h6 id={node}>{children}</h6>,
    [BLOCKS.UL_LIST]: (node: any, children: ReactNode) => <ul id={node}>{children}</ul>,
    [BLOCKS.OL_LIST]: (node: any, children: ReactNode) => <ol id={node}>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (node: any, children: ReactNode) => <li id={node}>{children}</li>,
    [BLOCKS.QUOTE]: (node: any, children: ReactNode) => (
      <blockquote id={node}>{children}</blockquote>
    ),
    [BLOCKS.HR]: () => <hr />,
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const { description, file } = node.data.target.fields;
      const url = file.url;
      const type = file.contentType;
      const mimeType = type ? type.split('/')[0] : 'image';

      if (mimeType === 'image') {
        const { width, height } = file.details.image;
        return (
          <figure>
            <img src={url} alt={description} width={width} height={height} />
          </figure>
        );
      }

      if (mimeType === 'video') {
        return (
          <video src={url} controls>
            {description && (
              <track kind="subtitles" label="English" srcLang="en" src={description} />
            )}
          </video>
        );
      }

      return null;
    },
    [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
      <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ),
  },
  renderText: (text: string) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};
