/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-console */
import { assetDataKey, ConfirmDeviceKey, ResumeFlowDataKey, deliveryTypeKey, nightDeliveryFeeKey, processIncidentKey, serviceFeeKey, returnAddressKey, deliveryAddressKey } from '../modules/config/constants';
import ActionTypes from '../store/actionTypes';
import { useCentralStore } from '../store/appContext';
import { getDeviceImageByModel } from '../utils/get-device-images';
import { Regex, camelize, getCountryCode, getPickupSlot, getReturnSlot, isClaimWithinCutoff } from '../utils/helper';
import { DeliveryDetails, LogisticsAndFulfilment, SubHeader, TextScript, useSessionStorage, getTextScript, useConfig, PickupSchedule, useTextScript, getCarrierByDomain, getTypeByDomain } from '@MGPD/myasurion-shared';
import { Box, Button, Flex, Input, Link } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { useNavigate } from 'react-router-dom';
import RefreshLogisticsScripts from '../Scripts/asurion-script.json';
import WalkingDetails from './WalkingDetails';
import CancelSrPopup from './myclaims/CancelSrPopup';
import {CheckIcon, SmallCloseIcon} from '@chakra-ui/icons'
import { saveError, showErrorPage, timeConvert24to12Hr, getDateAndDay, isPUR, isDelivery as isDeliveryType, getTime, isInWarrantyOEM, isEWSClaim, getGTMData } from '../utils/utills';
import { TCATAddressParse, addressStandardize, getCitiesByPostalCode, getCityListOnLoad, getStatePostalCodeOnCity, logGTMEvent, shippingmethodsv2Api } from '../services/api';
import RefreshLogisticsKeys from '../Scripts/translationsKeys.json';
import { getCurrencySymbol, getGlobalDateFormat } from '../utils/localization-helper';
import { GTM_EVENT, LOGISTICS_TYPES } from '../utils/constant';
import { hasTCAT, getAddressFields, getAlternateMobileLength, getPostalCodeLength, hasQAS, hasMultiDeviceSupport, getStateByCityFeature, getInlineChangeAddressFlag } from '../utils/featuresUtills';
import ChangeAddress from './ChangeAddress';
import { getDeliveryOptions } from '../utils/featuresUtills.tsx';
import features from '../utils/features.json';
import { ChangeAddressFieldConfig, AddressError } from '../types/types.ts';

interface ShippingMethod {
  pickUpDate: string;
  deliveryDate: string;
}

const RefreshLogisticsAndFulfilmentContainer = () => {
  const navigate = useNavigate();
  const globalState = useCentralStore();

  const { isTranslationsLoading, language } = useTextScript();

  const RefreshLogisticFormtranslationKey = RefreshLogisticsKeys.RefreshLogisticForm
  const refreshLogisticsScripts = RefreshLogisticsScripts?.RefreshLogisticsScripts;
  // Session Storage Chnage //
  const [servicefeeList] = useSessionStorage<any>(serviceFeeKey, null);
  const [confirmedDevice] = useSessionStorage<any>(ConfirmDeviceKey, null);
  const [assetData] = useSessionStorage<any>(assetDataKey, null);

  const shippingMethodRes: ShippingMethod[]  = globalState?.state?.shippingMethodRes;
  const find_agreement = globalState?.state?.currentAgreementData;
  const InitializeResponse = globalState?.state?.sessionResponse?.InitializeResponse;
  const agreementData1: any =
  globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  const nightDeliveryFee =
    servicefeeList &&
    servicefeeList?.filter((item: any) => item?.IncidentType === 'NIGHT DELIVERY FEE')[0].Fees
      .TotalAmount;

  const [, setNighDeliverytFees] = useSessionStorage<any>(nightDeliveryFeeKey, null);
  const [sessionUpdatedPickupAddress, setSessionUpdatedPickupAddress] = useSessionStorage<any>(deliveryAddressKey, null);
  const [sessionUpdatedReturnAddress, setSessionUpdatedReturnAddress] = useSessionStorage<any>(returnAddressKey, null);
  const [, setSessionUpdatedDeliveryDate] = useSessionStorage<any>('deliveryDate', null);
  const [, setSessionUpdatedDeliveryTime] = useSessionStorage<any>('deliveryTime', null);
  const [, setSessionUpdatedDeliveryTimeCode] = useSessionStorage<any>('deliveryTimeCode', null);

  const [, setSessionUpdatedReturnDate] = useSessionStorage<any>('ReturnDate', null);
  const [, setSessionUpdatedReturnTime] = useSessionStorage<any>('ReturnTime', null);
  const [, setSessionUpdatedReturnTimeCode] = useSessionStorage<any>('ReturnTimeCode', null);
  const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);

  const [srDeliveryType] = useSessionStorage<any>(deliveryTypeKey, null);
  const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);

  const isPickupAndReturn = isPUR(srDeliveryType);
  const isDelivery = isDeliveryType(srDeliveryType);

  //localization color theme changes
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { primary, accent } = colors;

  const [isNewAddress, setIsNewAddress] = useState(false);
  const [TCATAddressError, setTCATAddressError] = useState<AddressError>({
    showError: false,
    errorMessage: ''
  });

  const address1 = find_agreement?.Address?.Address1;
  const address2 = find_agreement?.Address?.Address2;
  // const address3 = find_agreement?.Address?.Address3;
  const city = find_agreement?.Address?.City;
  const _postalCode = find_agreement?.Address?.PostalCode;
  const addressjoin = { address1, address2, city, _postalCode };
  const fulladdress = Object.values(addressjoin).filter(Boolean).join(', ');
  // const [citiesData, setCitiesData] = useSessionStorage<any>(GetCitiesKey, null);
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('$')
  const [dateFormat, setDateFormat] = useState<string>('');

  const [preferredWalkingStoreLocation, setPreferredWalkingStoreLocation] = useState('');
  const [preferredWalkingTimeSlot, setPreferredWalkingTimeSlot] = useState('');
  const [preferredWalkingDate, setPreferredWalkingDate] = useState('');

  const [isAddressConfirm, setIsAddressConfirm] = useState(false);

  const [isDateTimeslotConfirm, setIsDateTimeslotConfirm] = useState(false);
  const [changeDateTimeFlag, setChangeDateTimeFlag] = useState(false);

  const [changeAddressFlag, setChangesAddressFlag] = useState(false);
  const [changeReturnAddressFlag, setChangesReturnAddressFlag] = useState(false);

  const [isReturnDateTime, setReturnDateTime] = useState(false);
  const [isReturnDateTimeConfirm, setReturnDateTimeConfirm] = useState(false);

  // Pick-up
  const [updatedPickupAddress, setUpdatedPickupAddress] = useState(fulladdress);
  const [updatedPickupDate, setUpdatedPickupDate] = useState(
    globalState?.state?.shippingMethodDatav2?.[0]?.EstimatedDeliveryDate
  );
  const [updatedPickupTime, setUpdatedPickupTime] = useState(
    globalState?.state?.shippingMethodDatav2?.[0]?.DeliverySlots?.[0]
  );

  // Return
  const [shppingMethodV2Return, setShippingMethodV2Return] = useState<any>()
  const [updatedReturnAddress, setUpdatedReturnAddress] = useState('');
  const [updatedReturnDate, setUpdatedReturnDate] = useState("");
  const [updatedReturnTime, setUpdatedReturnTime] = useState("");
 
  const [EnterNewReturnAddressText, setEnterNewReturnAddressText] = useState('');
  const [EnterNewPickupAddressText, setEnterNewPickupAddressText] = useState('');
  const [confirmReturnAddressText, setconfirmReturnAddressText] = useState('');
  const [SelectNewReturnScheduleText, setSelectNewReturnScheduleText] = useState('');
  const [PleaseReviewConfirmDetailsText, setPleaseReviewConfirmDetailsText] = useState('');
  const [SelectNewPickupScheduleText, setSelectNewPickupScheduleText] = useState('');
  const [PleaseConfirmYourPickupAddressText, setPleaseConfirmYourPickupAddressText] = useState('');
  const [SelectPreferredStoreLBLText, setSelectPreferredStoreLBLText] = useState('');
  const [showAddUpdateError, setShowAddUpdateError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [cities, setCities] = useState([]);
  const [district, setDistrict] = useState([]);
  const [provience, setProvience] = useState<string[]>([]);
  const [statesData, setStatesData] = useState<string[]>([]);
  const [showAlternateContactField, setShowAlternateContactField] = useState(false);
  const [alternateContactNo, setAlternateContactNo] = useState('');
  const [alternateContactNoPlaceholder, setAlternateContactNoPlaceholder] = useState('');
  const [invalidMobile, setInvalidMobile] = useState(false);

  const [fields, setFields] = useState(getAddressFields(agreementData1?.ClientOffer?.ClientOfferName))
  const getStateByCityFeatureFlag :any= getStateByCityFeature(agreementData1?.ClientOffer?.ClientOfferName)

  const handleSelectChange = (event: any, fieldName: any) => {
    const selectedValue = event.target.value;
    if (getStateByCityFeatureFlag) {
      if (fieldName === 'District') {
        if (selectedValue !== '') {
          let field = getFieldByFieldName(fieldName);
          updateFields(field, selectedValue, field?.isInvalid);
          const language = (sessionStorage.getItem('al') || 'en-US')?.replace(/["']/g, '');
          getPostalCodeStateOnCity(selectedValue, language);
          const PostalCodeField = getFieldByFieldName('PostalCode');
          updateFields(PostalCodeField, '', PostalCodeField?.isInvalid);
        }
      } else if (fieldName === 'Province') {
        if (selectedValue !== '') {
          const language = (sessionStorage.getItem('al') || 'en-US')?.replace(/["']/g, '');
          const languageSuffix = language === 'en-US' ? '' : 'TL';
          let field = getFieldByFieldName(fieldName);
          updateFields(field, selectedValue, field?.isInvalid); //set State/Province
          let selectedProvinceObj: any = statesData?.find((s: any) => {
            return s[`State${languageSuffix}`] === selectedValue;
          });
          const PostalCodeField = getFieldByFieldName('PostalCode');
          updateFields(PostalCodeField, selectedProvinceObj.PostalCode, PostalCodeField?.isInvalid); //set PostalCode
        }
      }
    } else {
      const selectedValue = event.target.value;

      let field = getFieldByFieldName(fieldName);
      updateFields(field, selectedValue, field?.isInvalid);
    }
  };

  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };

  const R_ServiceRequests = ResumeFlowData?.ServiceRequests?.[0];
  const R_ServiceRequestId = R_ServiceRequests?.ServiceRequestId;

  const processIncData = globalState?.state?.processIncData || serviceRequestData;
  const interactionData = globalState?.state?.sessionResponse?.Interaction;
  const isTOM = InitializeResponse?.ClientName === "Asurion_TechCare";

  useEffect(() => {
    let GTMData = getGTMData(GTM_EVENT.LOGISTIC_SCREEN_EVENT, globalState?.state?.currentAgreementData,
      getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)
      logGTMEvent(GTMData);
  }, [])
  useEffect(() => {
    setUpdatedPickupDate(globalState?.state?.shippingMethodDatav2?.[0]?.EstimatedDeliveryDate);
    setUpdatedPickupTime(globalState?.state?.shippingMethodDatav2?.[0]?.DeliverySlots?.[0]);
    globalState?.dispatch({
      type: ActionTypes.SET_PICKUP_DATE,
      payload: globalState?.state?.shippingMethodDatav2?.[0]?.EstimatedDeliveryDate,
    });
    globalState?.dispatch({
      type: ActionTypes.SET_PICKUP_TIME,
      payload: globalState?.state?.shippingMethodDatav2?.[0]?.DeliverySlots?.[0],
    });

    setSessionUpdatedDeliveryDate(
      globalState?.state?.shippingMethodDatav2?.[0]?.EstimatedDeliveryDate
    );

    setSessionUpdatedDeliveryTime(
      globalState?.state?.shippingMethodDatav2?.[0]?.DeliverySlots?.[0]
    );
    setSessionUpdatedDeliveryTimeCode(
      globalState?.state?.shippingMethodDatav2?.[0]?.DeliverySlots?.[0]
    );

  }, [globalState?.state?.shippingMethodDatav2]);

  useEffect (() => {
    setUpdatedReturnDate(shppingMethodV2Return?.[0]?.EstimatedDeliveryDate);
    setUpdatedReturnTime(shppingMethodV2Return?.[0]?.DeliverySlots?.[0]);

    globalState?.dispatch({
      type: ActionTypes.SET_RETURN_DATE,
      payload: shppingMethodV2Return?.[0]?.EstimatedDeliveryDate,
    });

    globalState?.dispatch({
      type: ActionTypes.SET_RETURN_TIME,
      payload: shppingMethodV2Return?.[0]?.DeliverySlots?.[0],
    });

    setSessionUpdatedReturnDate(
      shppingMethodV2Return?.[0]?.EstimatedDeliveryDate
    );

    setSessionUpdatedReturnTime(shppingMethodV2Return?.[0]?.DeliverySlots?.[0]);
    setSessionUpdatedReturnTimeCode(
      shppingMethodV2Return?.[0]?.DeliverySlots?.[0]
    );

  },[shppingMethodV2Return])

  const [isNightFeesApplicable, setIsNightFeesApplicable] = useState(false);

  const R_IncidentType = R_ServiceRequests?.IncidentType;
  const incType = camelize(globalState?.state?.processIncData?.ServiceRequest?.IncidentType || R_IncidentType);

  const isIW_OEM = isInWarrantyOEM(processIncData);
  const isEWS = isEWSClaim(processIncData, incType);
  
  // const deviceName = find_agreement?.Assets?.Asset.filter(
  //   (asset: any) => asset?.AssetInstance === 'ENROLLED'
  // )?.[0];

  let deviceName =  find_agreement?.Assets?.Asset?.filter(
    (asset: any) => asset?.AssetInstance === 'ENROLLED'
  )?.[0];
    if(deviceName === undefined) {
      deviceName = find_agreement?.Assets?.Asset?.filter(
        (asset: any) => asset?.AssetInstance === 'REPLACED'
      )?.[0];
    }

  const R_ReplacementAsset = ResumeFlowData?.ReplacementAsset;

  const AssetFamily =
    (confirmedDevice && confirmedDevice?.familyName?.replace(/iphone/gi, 'iPhone')) ||
    (confirmedDevice &&
      camelize(confirmedDevice[0].HorizonItemInformation.AssetFamily.toLowerCase())?.replace(
        /iphone/gi,
        'iPhone'
      ) +
        ' ' +
        (confirmedDevice[0].HorizonItemInformation.AssetCapacity == '1'
          ? `${confirmedDevice[0].HorizonItemInformation.AssetCapacity}TB`
          : `${confirmedDevice[0].HorizonItemInformation.AssetCapacity}GB`) +
        ' ' +
        confirmedDevice[0].SCMItemInformation.Color) ||
    (R_ReplacementAsset &&
      camelize(R_ReplacementAsset?.Make?.toLowerCase()) +
        ' ' +
        camelize(R_ReplacementAsset?.AssetCatalogName.split(' ')?.slice(1)?.join(' '))?.replace(
          /iphone/gi,
          'iPhone'
        )) ||
    camelize(deviceName?.Make?.Name?.toLowerCase()) +
      ' ' +
      camelize(deviceName?.AssetCatalog?.AssetCatalogName.split(' ')?.slice(1)?.join(' '))?.replace(
        /iphone/gi,
        'iPhone'
      )?.replace('gb', 'GB');

  const image =
    (confirmedDevice && confirmedDevice?.img) ||
    (confirmedDevice &&
      getDeviceImageByModel(
        globalState?.state?.deviceImages,
        confirmedDevice[0].HorizonItemInformation.AssetFamily,
        '',
        100
      )) ||
    getDeviceImageByModel(
      globalState?.state?.deviceImages,
      AssetFamily?.split(' ')?.slice(0, -2)?.join(' '),
      '',
      100
    );

  const enablePickupDateTimeSlotButton = updatedPickupDate !== '' && updatedPickupTime !== '';
  const enableReturnDateTimeSlotButton = updatedReturnDate !== '' && updatedReturnTime !== '';
  const enableWalkingNextButton =
    preferredWalkingStoreLocation && preferredWalkingDate && preferredWalkingTimeSlot;
  
  const currencyCode = globalState?.state?.sessionResponse?.InitializeResponse?.CurrencyCode;

  const postalCodeLength = getPostalCodeLength(agreementData1?.ClientOffer?.ClientOfferName);
  
    function checkIfEmpty(strEntry: any) {
    const dontrun = false;
    if (dontrun) {
      setUpdatedPickupAddress('');
      setUpdatedPickupDate('');
      setUpdatedPickupTime('');
      setUpdatedReturnAddress('');
      setUpdatedReturnDate('');
      setUpdatedReturnTime('');
    }

    if (strEntry) {
      return false;
    } else {
      return true;
    }
  }

  const onDateSelect = (date: string) => {
    if (!isReturnDateTime) {
      setUpdatedPickupDate(date);
      setSessionUpdatedDeliveryDate(date);
      globalState?.dispatch({ type: ActionTypes.SET_PICKUP_DATE, payload: date });
    } else {
      setUpdatedReturnDate(date);
      setSessionUpdatedReturnDate(date);
      globalState?.dispatch({ type: ActionTypes.SET_RETURN_DATE, payload: date });
    }
  };

  const onTimeSlotSelect = (timeSlot: string) => {
    if (!isReturnDateTime) {
      setUpdatedPickupTime(timeSlot);
      setSessionUpdatedDeliveryTime(timeSlot?.split(':')?.[0]);
      setSessionUpdatedDeliveryTimeCode(timeSlot?.split(':')?.[1]);
      globalState?.dispatch({ type: ActionTypes.SET_PICKUP_TIME, payload: timeSlot });
    } else {
      setUpdatedReturnTime(timeSlot);
      setSessionUpdatedReturnTime(timeSlot?.split(':')?.[0]);
      setSessionUpdatedReturnTimeCode(timeSlot?.split(':')?.[1]);
      globalState?.dispatch({ type: ActionTypes.SET_RETURN_TIME, payload: timeSlot });
    }
  };

  const clearValidations = () => {
    if(!getStateByCityFeatureFlag){
      setDistrict([]);
    }
    setFields(getAddressFields(agreementData1?.ClientOffer?.ClientOfferName));
    setProvience([]);
  }

  const onConfirmOrNextClick = () => {
    navigate('/representativeform', { replace: true });
  };

  const handleWalkingDetails = () => {
    navigate('/representativeform', { replace: true });
  };

  useEffect(() => {
    if(!isTranslationsLoading) {
      getDateFormat();
      getCurrency();
      getPleaseConfirmYourReturnAddress();
      getSelectNewReturnSchedule();
      getPleaseReviewConfirmDetails();
      getSelectNewPickupSchedule();
      getPleaseConfirmYourPickupAddress();
      getSelectPreferredStoreLBL();
      loadPlaceholderScripts();
      getAlternateContactNumberPlaceHolder();
      getEnterNewPickupAddress();
      getEnterNewReturnAddress();

      if(globalState?.state?.isMaxis){
        // show change address page default for maxis
        handleChageDeliveryAddressClick();
      }
    }
    
  }, [language, isTranslationsLoading])

  const getDateFormat = async () => {
    const format = await getGlobalDateFormat();
    setDateFormat(format);
  }

  const getCurrency = async () => {
    const currency = await getCurrencySymbol();
    setCurrencySymbol(currency);
  }

  const getEnterNewReturnAddress = async () => {
    const EnterNewDeliveryAddressText = await getTextScript(
      `${RefreshLogisticsKeys.AddressLinePopup.PageName}/${RefreshLogisticsKeys.AddressLinePopup.EnterNewReturnAddress}`,
      refreshLogisticsScripts?.EnterNewReturnAddress,
      language,
      []
    );
    setEnterNewReturnAddressText(EnterNewDeliveryAddressText);
  };

  const getEnterNewPickupAddress = async () => {
    const EnterNewPickupAddressText = await getTextScript(
      `${RefreshLogisticsKeys.AddressLinePopup.PageName}/${RefreshLogisticsKeys.AddressLinePopup.EnterNewPickUpAddress}`,
      refreshLogisticsScripts?.EnterNewPickUpAddress,
      language,
      []
    );
    setEnterNewPickupAddressText(EnterNewPickupAddressText);
  };

  const getPleaseConfirmYourReturnAddress = async () => {
    const confirmReturnAddress = await getTextScript(
      `${RefreshLogisticFormtranslationKey.PageName}/${RefreshLogisticFormtranslationKey.PleaseConfirmYourReturnAddress}`,
      `${refreshLogisticsScripts?.PleaseConfirmYourReturnAddress}`,
      language,
      []
     );
    setconfirmReturnAddressText(confirmReturnAddress);
  }
  const getSelectNewReturnSchedule = async () => {
    const SelectNewReturnSchedule = await getTextScript(
      `${RefreshLogisticFormtranslationKey.PageName}/${RefreshLogisticFormtranslationKey.SelectNewReturnSchedule}`,
      `${refreshLogisticsScripts?.SelectNewReturnSchedule}`,
      language,
      []
     );
    setSelectNewReturnScheduleText(SelectNewReturnSchedule);
  }
  const getPleaseReviewConfirmDetails = async () => {
    const PleaseReviewConfirmDetails = await getTextScript(
      `${RefreshLogisticFormtranslationKey.PageName}/${RefreshLogisticFormtranslationKey.PleaseReviewConfirmDetailsText}`,
      `${refreshLogisticsScripts?.PleaseReviewConfirmDetails}`,
      language,
      []
      );
    setPleaseReviewConfirmDetailsText(PleaseReviewConfirmDetails);
  }
  const getSelectNewPickupSchedule = async () => {
    const SelectNewPickupSchedule = await getTextScript(
      `${RefreshLogisticFormtranslationKey.PageName}/${RefreshLogisticFormtranslationKey.SelectNewPickupScheduleText}`,
      `${refreshLogisticsScripts?.SelectNewPickupSchedule}`,
      language,
      []
     );
    setSelectNewPickupScheduleText(SelectNewPickupSchedule);
  }

  const getPleaseConfirmYourPickupAddress = async () => {
    const PleaseConfirmYourPickupAddress = await getTextScript(
        `${RefreshLogisticFormtranslationKey.PageName}/${RefreshLogisticFormtranslationKey.PleaseConfirmYourPickupAddress}`,
        `${refreshLogisticsScripts?.PleaseConfirmYourPickupAddress}`,
        language,
        []
    );
    setPleaseConfirmYourPickupAddressText(PleaseConfirmYourPickupAddress);
  }
 
  const WalkinDetailstranslationKey = RefreshLogisticsKeys.WalkinDetails


  const getSelectPreferredStoreLBL = async () => {
    const SelectPreferredStoreLBL = await getTextScript( 
      `${WalkinDetailstranslationKey.PageName}/${WalkinDetailstranslationKey.SelectPreferredStoreLBL}`,
      `${RefreshLogisticsScripts?.LogisticsScripts?.SelectPreferredStoreLBL}`,
      language,
      []
    ) 
    setSelectPreferredStoreLBLText(SelectPreferredStoreLBL);
  } 
  const isWalkin = [LOGISTICS_TYPES.WALKIN, 'Walk-in to your preferred location'].includes(srDeliveryType)
  const pickUpQuestion =
    isDateTimeslotConfirm && !isReturnDateTime && !isReturnDateTimeConfirm
      ? `${confirmReturnAddressText}`
      : isReturnDateTime
      ? `${SelectNewReturnScheduleText}`
      : isReturnDateTimeConfirm
      ? `${PleaseReviewConfirmDetailsText}`
      :isWalkin ?
      `${SelectPreferredStoreLBLText}`
      : changeDateTimeFlag
      ? `${SelectNewPickupScheduleText}`
      : `${PleaseConfirmYourPickupAddressText}`;

  useEffect(() => {
    checkIfNightFeeApplicable();
  }, [updatedPickupTime]);

    useEffect(() => {
      if (getStateByCityFeatureFlag && sessionStorage) {
        const serviceRequestId = processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId;
        const language = (sessionStorage.getItem('al') || 'en-US')?.replace(/["']/g, '');
        getCitiesOnLoad(serviceRequestId, language);
      }
    }, []);

  const checkIfNightFeeApplicable = () => {
    if (['7pm - 10pm:19-22'].includes(updatedPickupTime)) {
      setNighDeliverytFees(nightDeliveryFee);
      globalState?.dispatch({
        type: ActionTypes.SET_DELIVERY_NIGHT_FEE,
        payload: nightDeliveryFee,
      });
      setIsNightFeesApplicable(true);
      // return true;
    } else {
      setIsNightFeesApplicable(false);
      setNighDeliverytFees(null);
      // return false;
    }
  };

  const callAddressStandardizeApi = (payload: any) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      addressStandardize(payload, interactionData?.InteractionLine?.InteractionLineId, globalState?.state?.findAgreementSessionData)
        .then((data) => {
          setLoading(false);
          if (data) {
            resolve(data);
          } else {
            reject('No data received from API');
          }
        })
        .catch((error) => {
          setShowAddUpdateError(true);
          setLoading(false);
          reject(error);
        });
    });
  };

  const getAddress1 = () => {
    const addressLine1 = fields.filter((item: any) => {
      return item.mapTo === 'Address1';
    });

    return addressLine1.map((address: { value: any; }) => address.value).join(' ')
  }

  const getAddress2 = () => {
    const addressLine2 = fields.filter((item: any) => {
      return item.mapTo === 'Address2';
    });

    return addressLine2.map((address: { value: any; }) => address.value).join(' ')
  }

  const getAddress3 = () => {
    const addressLine3 = fields.filter((item: any) => {
      return item.mapTo === 'Address3';
    });

    return addressLine3.map((address: { value: any; }) => address.value).join(' ')
  }

  const getCity = () => {
    return getFieldByFieldName('District')?.value
  }

  const getStateProvinceCode = () => {
    return getFieldByFieldName('Province')?.value
  }

  const getPostalCode = () => {
    return getFieldByFieldName('PostalCode')?.value
  }
  

  const addNewPickupAddress = () => {
    if(!hasQAS(agreementData1?.ClientOffer?.ClientOfferName)){
      
      let obj = {
        Address1: getAddress1() ?? '',
        Address2: getAddress2() ?? '',
        Address3: getAddress3() ?? '',
        City: getCity(),
        StateProvinceCode: getStateProvinceCode(),
        CountryCode: getCountryCode(currencyCode),
        PostalCode: getPostalCode()
      }

      let newAddress = `${obj?.Address1} ${obj?.Address2} ${obj?.Address3} ${obj?.City} ${obj?.StateProvinceCode} ${obj?.PostalCode}`
      
      setUpdatedPickupAddress(newAddress);
      globalState?.dispatch({
        type: ActionTypes.SET_PICKUP_ADDRESS,
        payload: newAddress,
      });
      setSessionUpdatedPickupAddress(obj);

      if(isTOM){
        setIsAddressConfirm(false);
        setChangeDateTimeFlag(false);
        setChangesAddressFlag(false);
        setIsNewAddress(true);
      } else {
        //for maxis call shipping method v2 API here to get pickup date time-slots 
       callPickUpShippingMethodV2Api(obj);
      }
    } else {
      const payloadParams = getQASParams();
    
    callAddressStandardizeApi(payloadParams)
      .then((data: any) => {
        if (data?.StandardizeAddressResponse) {
          const correctedAdd = data?.StandardizeAddressResponse?.AddressResult?.CorrectedAddress;
          if (correctedAdd) {
            const obj = getStandardizeAddress(correctedAdd);
            let newAddress = `${obj?.Address1} ${obj?.Address3} ${obj?.Address2} ${obj?.PostalCode}`;
            setIsAddressConfirm(false);
            setChangeDateTimeFlag(false);
            setChangesAddressFlag(false);
            setUpdatedPickupAddress(newAddress);
            globalState?.dispatch({
              type: ActionTypes.SET_PICKUP_ADDRESS,
              payload: newAddress,
            });
            setIsNewAddress(true);
            setSessionUpdatedPickupAddress(obj);
             clearValidations()
            Geocode.setApiKey(import.meta.env.VITE_GOOGLE_API_KEY);
            Geocode.setLanguage('en');
            Geocode.setRegion('SGP');
            Geocode.setLocationType('ROOFTOP');

            Geocode.fromAddress(newAddress).then(
              (response) => {
                const { lat, lng } = response?.results?.[0]?.geometry?.location;
                globalState?.dispatch({
                  type: ActionTypes.SET_LOCATION_COORDINATES,
                  payload: { lat, lng },
                });
              },
              (error) => {
                console.error(error);
              }
            ).catch(error => {
              saveError(error, globalState);
              // showErrorPage(navigate)
            });
          } else {
            setAddressError(true);
          }
        } else {
          setShowAddUpdateError(true);
        }
      })
      .catch(() => {
        setShowAddUpdateError(true);
      });
    }
  };

  const addNewReturnAddress = () => {
    if(!hasQAS(agreementData1?.ClientOffer?.ClientOfferName)){

      let obj = {
        Address1: getAddress1() ?? '',
        Address2: getAddress2() ?? '',
        Address3: getAddress3() ?? '',
        City: getCity(),
        StateProvinceCode: getStateProvinceCode(),
        CountryCode: getCountryCode(currencyCode),
        PostalCode: getPostalCode()
      }
      
      let newAddress = `${obj?.Address1} ${obj?.Address2} ${obj?.Address3} ${obj?.City} ${obj?.StateProvinceCode} ${obj?.PostalCode}`
      setChangesReturnAddressFlag(false);
      setReturnDateTime(false);
      setUpdatedReturnAddress(newAddress);
      setIsNewAddress(true);
      setSessionUpdatedReturnAddress(obj);
      globalState?.dispatch({
        type: ActionTypes.SET_RETURN_ADDRESS,
        payload: newAddress
      });
    } else {
    const payloadParams = getQASParams();

    callAddressStandardizeApi(payloadParams)
      .then((data: any) => {
        if (data?.StandardizeAddressResponse) {
          const correctedAdd = data?.StandardizeAddressResponse?.AddressResult?.CorrectedAddress;
          if (correctedAdd) {
            const obj = getStandardizeAddress(correctedAdd);
            let newAddress = `${obj?.Address1} ${obj?.Address3} ${obj?.Address2} ${obj?.PostalCode}`;
            setChangesReturnAddressFlag(false);
            setReturnDateTime(false);
            setUpdatedReturnAddress(newAddress);
            setIsNewAddress(true);
            setSessionUpdatedReturnAddress(obj);
            globalState?.dispatch({
              type: ActionTypes.SET_RETURN_ADDRESS,
              payload: newAddress
            });
          } else {
            setAddressError(true);
          }
        } else {
          setShowAddUpdateError(true);
          clearValidations()
        }
      })
      .catch(() => {
        setShowAddUpdateError(true);
      });
    }
  }

  const getQASParams = () => {
    const payloadParams = {
      QASParams: {
        ClientId: InitializeResponse?.ClientId,
        AgreementId: agreementData1?.AgreementId,
        Address: {
          Address1: getAddress1() ?? '',
          Address2: getAddress2() ?? '',
          Address3: getAddress3() ?? '',
          City: 'Singapore',
          StateProvinceCode: 'Singapore',
          CountryCode: getCountryCode(currencyCode),
          PostalCode: getPostalCode(),
        },
      },
    };

    return payloadParams;
  }

  const getStandardizeAddress = (correctedAdd: any) => {
    const obj = {
      Address1: correctedAdd?.AddressLine1 ?? '',
      Address2: correctedAdd?.AddressLine2 ?? '',
      Address3: correctedAdd?.AddressLine3 ?? '',
      City: correctedAdd?.CityName || 'Singapore',
      StateProvinceCode: correctedAdd?.StateProvinceCode ?? '',
      CountryCode: correctedAdd?.CountryCode ?? '',
      PostalCode: correctedAdd?.PostalCode ?? '',
    };

    return obj;
  }

  const onCancelClick = () => {
    setOpenCancelPopup(!openCancelPopup);
  }

  const onBackClick = () => {
    navigate('/deliveryoptions', { replace: true });
  }

  const callshippingmethodV2Api = () => {
    if (isTOM /*|| globalState?.state?.isMaxis*/) {
      const givenPickupDate = new Date(updatedPickupDate);
      const slots = getReturnSlot();
      const returnSlots = shippingMethodRes
        ?.filter((item) => new Date(item.pickUpDate) >= givenPickupDate)
        ?.map((item: ShippingMethod | undefined) => ({
          EstimatedDeliveryDate: item?.deliveryDate,
          DeliverySlots: slots,
        }));
      setShippingMethodV2Return(returnSlots);
    } else {
    setLoading(true);
    shippingmethodsv2Api({
      LookupShippingMethodsParameters: {
        ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
        ReceiverAddress: {
          // Address1: sessionUpdatedReturnAddress?.Address1,
          // Address2: sessionUpdatedReturnAddress?.Address2,
          // Address3: sessionUpdatedReturnAddress?.Address3,
          // City: sessionUpdatedReturnAddress?.City,
          // StateProvinceCode: sessionUpdatedReturnAddress?.StateProvinceCode,
          // CountryCode: sessionUpdatedReturnAddress?.CountryCode,
          // PostalCode: sessionUpdatedReturnAddress?.PostalCode,
          ...sessionUpdatedReturnAddress
        },
        Mode:'delivery',
        PickUpDate: updatedPickupDate,
        PickUpSlot: updatedPickupTime,
        getDeliverySlots: true,
      },
    }, interactionData?.InteractionLine?.InteractionLineId, globalState?.state?.findAgreementSessionData)
      .then((data) => {
        setLoading(false);
        if (data) {
          const pickUpShippingMethodResults = data?.ShippingMethodResults;
          const shipingMethods = pickUpShippingMethodResults?.map((item: any) => ({
            EstimatedDeliveryDate: item?.deliveryDate,
            DeliverySlots: item?.deliverySlot
          }));
          let _shipingMethods = shipingMethods?.filter((d: any) => d?.DeliverySlots?.length > 0)
          setShippingMethodV2Return(_shipingMethods)
        }
      })
      .catch((error) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate)
      });
    }
  };

  const callPickUpShippingMethodV2Api = (pickUpAddress: any) => {
    setLoading(true);
    shippingmethodsv2Api(
      {
        LookupShippingMethodsParameters: {
          ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
          ReceiverAddress: {
            LocationType: 'Residential',
            Simulation: true,
            ...pickUpAddress
          },
          Mode:'pickup',
          getDeliverySlots: true,
        },
      },
      interactionData?.InteractionLine?.InteractionLineId,
      globalState?.state?.findAgreementSessionData
    )
      .then((data) => {
        setLoading(false);
        setIsAddressConfirm(false);
        setChangeDateTimeFlag(false);
        setChangesAddressFlag(false);
        setIsNewAddress(true);
        if (data) {
          const pickUpShippingMethodResults = data?.ShippingMethodResults;
          const slots = getPickupSlot();
          const shipingMethods = pickUpShippingMethodResults.map((item: any, index: number) => {
            const claimWithinCutoff = isClaimWithinCutoff(InitializeResponse?.TimeZone);
            const estimatedDeliveryDate = item?.pickUpDate;
          
            let deliverySlots;
            if (index === 0) {
              // For first date,if claim filed before 2 PM, then `slots`, else `slots[1]`
              deliverySlots = globalState?.state?.isMaxis && claimWithinCutoff ? slots : [slots[1]];
            } else {
              deliverySlots = slots;
            }
          
            return {
              EstimatedDeliveryDate: estimatedDeliveryDate,
              DeliverySlots: item?.pickUpSlot || deliverySlots,
            };
          });
          // setShippingMethod(shipingMethods);
          let _shipingMethods = shipingMethods?.filter((d: any) => d?.DeliverySlots?.length > 0)
          setShippingMethodV2Return(_shipingMethods)
          globalState?.dispatch({
            type: ActionTypes.SET_SHIPPING_METHOD_V2,
            payload: _shipingMethods,
          });
          globalState?.dispatch({
            type: ActionTypes.SET_RAW_SHPPINGMETHOD_RES,
            payload: pickUpShippingMethodResults,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  };
  
  const loadPlaceholderScripts = async () => {
   
    let updatedFields = [];

    for (const field of fields) {

      const defaultPlaceholder = field?.placeholder;
      const defaultError = field?.errorMessage;

      const returnedText = await getTextScript(
        `${RefreshLogisticsKeys.Global.PageName}/${field?.name}`,
        defaultPlaceholder,
        language,
        []
      );

      const returnedTextError = await getTextScript(
        `${RefreshLogisticsKeys.Global.PageName}/${field?.name}Error`,
        defaultError,
        language,
        []
      );

      field.placeholder = returnedText;
      field.errorMessage = returnedTextError;

      updatedFields.push(field)
    }

    setFields(updatedFields);
  };

  const handleInputChange = (field: ChangeAddressFieldConfig, value: string) => {
    let isInvalid = false;
    const hasQASFeature = hasQAS(agreementData1?.ClientOffer?.ClientOfferName);

    if (field?.name === 'PostalCode' && !getStateByCityFeatureFlag) {
      if (!checkIfEmpty(value) && value?.length !== postalCodeLength) {
        isInvalid = true;
      } else if (checkIfEmpty(value)) {
        isInvalid = true;

        // clear city and state drop down if pincode empty
        if(!hasQASFeature){
          setDistrict([]);
          setProvience([]);
        }
      }

      if (!hasQASFeature && value?.length === postalCodeLength) {
        const language = (sessionStorage.getItem('al') || 'en-US')?.replace(/["']/g, '');
        getCities(value, language);
      }

    } else if (!field?.isRequired) {
      if (!Regex.test(value)) {
        isInvalid = true;
      }
    } else {
      isInvalid = checkIfEmpty(value) || !Regex.test(value);
    }

    updateFields(field, value, isInvalid);
  };

  const getFieldByFieldName = (fieldName: string) => {
   
    return fields.find((item:any) => {
      return item.name === fieldName;
    });
    
  }

  const updateFields = (field: any, value: string, isInvalid: boolean) => {
    setFields((prevFields: any) =>
      prevFields.map((item: any) =>
        item.name === field.name
          ? {
              ...item,
              value: value,
              isInvalid: isInvalid,
            }
          : item
      )
    );
  }

  const isAnyRequiredFieldInvalid = (fields:any) => {
    for (const field of fields) {
      if (field.isRequired && field.isInvalid) {
        return true;
      }
      if (field.isRequired && (field.value === null || field.value === "")) {
        return true;
      }
      // if(formFields.District === '' || formFields.Province === ''){
      //   return true;
      // }
    }
    return false;
  }

  useEffect(() => {
    const language = (sessionStorage.getItem('al') || 'en-US')?.replace(/["']/g, '');
    const postalCodeField = getFieldByFieldName("PostalCode");
    const districtField = getFieldByFieldName("District");
    const languageSuffix = language === 'en-US' ? '' : 'TL';

    if (
      postalCodeField?.value?.length === postalCodeLength &&
      districtField?.value &&
      !getStateByCityFeatureFlag
    ) {
      const filterCondition = (val: any) => val.PostalCode === postalCodeField?.value;

      const filteredStates = cities
        ?.filter(filterCondition)
        .filter((val: any) => val[`City${languageSuffix}`] === districtField?.value)
        .map((val: any) => val[`State${languageSuffix}`]);

      if(!filteredStates?.length){
        const field = getFieldByFieldName('PostalCode')
        updateFields(field, "", true)
      } else {
        if (filteredStates?.length) {
          const field = getFieldByFieldName('Province')
          updateFields(field, filteredStates[0], field?.isInvalid)
        }
      }
      setProvience(filteredStates);
    }
  }, [district]);

  const getCities = (postalCode: string, language: string) => {

    setLoading(true);

    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;
    const clientID = globalState?.state?.sessionResponse?.InitializeResponse?.ClientId;

    getCitiesByPostalCode(interactionLineId, postalCode, language, clientID, globalState?.state?.findAgreementSessionData).then((data)=> {
      setLoading(false);
      const citiesData = data?.GetCityStatebyZipCodeResponse;

      setCities(citiesData);

      const languageSuffix = language === 'en-US' ? '' : 'TL';
      // const filterCondition = (val: any) => val.PostalCode === formFields.PostalCode;
      const filterCondition = (val: any) => val.PostalCode === postalCode;

      const filteredCities = citiesData?.filter(filterCondition).map((v: any) => v[`City${languageSuffix}`]);

      if(!filteredCities?.length){
        const field = getFieldByFieldName('PostalCode')
        updateFields(field, "", true)
      } else {
        if (filteredCities.length) {
          const postalCodeField = getFieldByFieldName("District");
          updateFields(postalCodeField, filteredCities[0], postalCodeField?.isInvalid);
        }
      }

      setDistrict(filteredCities);
      setProvience([]);
      
    }).catch((error) => {
      setLoading(false);
      saveError(error, globalState);
      showErrorPage(navigate)
    })
  }

  //Approach 2 --> get Cities on Load of Page
  const getCitiesOnLoad = (serviceRequestId: string, language: string) => {
    setLoading(true);
    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;
    const clientID = globalState?.state?.sessionResponse?.InitializeResponse?.ClientId;

    getCityListOnLoad(interactionLineId, serviceRequestId, language, clientID, globalState?.state?.findAgreementSessionData)
      .then((data) => {
        setLoading(false);
        const citiesData = data?.GetCityStatebyZipCodeResponse;
        setCities(citiesData);
        const languageSuffix = language === 'en-US' ? '' : 'TL';
        const filteredCities: any = citiesData?.map((v: any) => v[`City${languageSuffix}`]);
        setDistrict(filteredCities);
      })
      .catch((error: any) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  };

  //Approach 2 ---> get State and Postal Code on City Slected.

  const getPostalCodeStateOnCity = (city: string, language: string) => {

    setLoading(true);
    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;
    const clientID = globalState?.state?.sessionResponse?.InitializeResponse?.ClientId;
    const serviceRequestId = processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId;

    getStatePostalCodeOnCity(interactionLineId, city, language, clientID, serviceRequestId, globalState?.state?.findAgreementSessionData).then((data)=> {
      setLoading(false);
      const StateData = data?.GetCityStatebyZipCodeResponse;
      const languageSuffix = language === 'en-US' ? '' : 'TL';
      const filteredStates = StateData?.map((val: any) => val[`State${languageSuffix}`]);
        setProvience(filteredStates);
        setStatesData(StateData)
      }
      ).catch((error :any) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate)
    })
  }

  const handleChangeReturnAddress = () => {
    setChangesReturnAddressFlag(true);
    // clearValidations();
  }

  const getDeliverySlot = (time: any) => {  
    if(isTOM){
      return timeConvert24to12Hr(time)
    } else{
      // if(type === 'return'){
      //   return timeConvert24to12Hr(formatReturnTime(time))
      // }else{
        return time;
      // }      
    } 
  } 

  const handleChageDeliveryAddressClick = () => {
    setChangesAddressFlag(true);
   // clearValidations();
  }

  const confirmAlternateContactNumber = () => {
    if (alternateContactNo && !invalidMobile && globalState) {
      globalState?.dispatch({ type: ActionTypes.SET_ALTERNATE_CONTACT_NUMBER, payload: alternateContactNo });
      setShowAlternateContactField(false);
    }
  }

  let validLength = getAlternateMobileLength(find_agreement?.ClientOffer?.ClientOfferName);

  const handleAlternateContactChange = (e:any) => {
    const { value } = e.target;
    setAlternateContactNo(value);

    let reg = new RegExp('^[\\d]{'+validLength+'}$', 'g');
    if (reg.test(value)) {
      setInvalidMobile(false);
    } else if (value.trim() === '') {
      setInvalidMobile(false);
    } else {
      setInvalidMobile(true);
    }
  };

  const handleCancel = () => {
    setShowAlternateContactField(false);
    setInvalidMobile(false);
  }

  const getAlternateContactNumberPlaceHolder = async() => {
    const returnedTextError = await getTextScript(
      `${RefreshLogisticsKeys.Global.PageName}/${RefreshLogisticsKeys.Global.AlternateContactNumber}`,
      RefreshLogisticsScripts.Global.AlternateContactNumber,
      language,
      []
    );
    setAlternateContactNoPlaceholder(returnedTextError)
  }

  const showBackButton = () => {
    const deliveryOptions =  getDeliveryOptions(incType, find_agreement?.ClientOffer?.ClientOfferName, features);
    let optionsCount : any =  Object.values(deliveryOptions).reduce((a:any, item:any) => a + item, 0);
    return optionsCount > 1;
  }

  const handlePickupAddressConfirm = () => {
    setIsAddressConfirm(true);
    setChangeDateTimeFlag(true);
    if (!isNewAddress) {
      setSessionUpdatedPickupAddress(find_agreement?.Address);
      globalState?.dispatch({
        type: ActionTypes.SET_PICKUP_ADDRESS,
        payload: fulladdress,
      });
    }
  }

  const callTCATAddressParseAPI = () => {
    setLoading(true);

    const payload = {
      ParseAddressRequest: {
        ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
        CustomerCaseId: processIncData?.CustomerCaseId || R_ServiceRequests?.CustomerCaseId,
        PostalCode: sessionUpdatedPickupAddress?.PostalCode,// "115 南港區",
        State: sessionUpdatedPickupAddress?.StateProvinceCode,// "大安區",
        City: sessionUpdatedPickupAddress?.City,//"台北市",
        AddressLine1: sessionUpdatedPickupAddress?.Address1,// "南港路三段50巷6號3樓"
      }
    }

    TCATAddressParse(payload, interactionData?.InteractionLine?.InteractionLineId, agreementData1?.AgreementId, globalState?.state?.findAgreementSessionData)
    .then((data) => {
      setLoading(false);
      if (data && data.IsOK?.toUpperCase() === "Y") {
        handlePickupAddressConfirm();
        TCATAddressError.showError = false;
        TCATAddressError.errorMessage = data?.Message
        setTCATAddressError(TCATAddressError)
      } else {
        TCATAddressError.showError = true;
        TCATAddressError.errorMessage = data?.Message || 'address error'
        setTCATAddressError(TCATAddressError)

        handleChageDeliveryAddressClick();
      }
    })
    .catch((error) => {
      setShowAddUpdateError(true);
      setLoading(false);
      saveError(error, globalState);
      showErrorPage(navigate);
    });
  }

  return (
    <Box>
      <Box id={'myasurion-srswap-logisticandfulfillment'} w='full' m="0 auto">
        <Box mt={16} w={{ base: 'full', lg: '80%' }}  mx="auto">
          {/* <BackButton>
            <Text>{refreshLogisticsScripts?.DeliveryOptions}</Text>
          </BackButton> */}
          <SubHeader
            onClick={onBackClick}
            backText={'Back'}
            allowBackButtonClick={false}
            showCancelButton={true}
            showBackButton={!showBackButton() ? false : true}
            cancelText={RefreshLogisticsScripts.CancelSR.cancelBtnText}
            onCancelClick={onCancelClick}>
          </SubHeader>
          <Box w={{ base: 'full', lg: '60%' }}  mx="auto" pb={{ base: 0, lg: '100px'}}>
            <LogisticsAndFulfilment imageEl={ hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName) ? <></> : image} question={pickUpQuestion} deviceName={AssetFamily}>
              {(isPickupAndReturn || isDelivery) && (
                <Box p={4}>
                  {!changeAddressFlag &&
                    !changeDateTimeFlag &&
                    !isReturnDateTime &&
                    !changeReturnAddressFlag &&
                    dateFormat && 
                    dateFormat?.length > 0 && (
                      <DeliveryDetails
                        deliveryAddress={''}
                        deliveryDate={''}
                        deliveryTime={''}
                        pickupAddress={isPickupAndReturn ? (updatedPickupAddress || '-') : ''}
                        pickupDate={isDateTimeslotConfirm && isPickupAndReturn ? getDateAndDay(updatedPickupDate, dateFormat) : ''}
                        pickupTime={isDateTimeslotConfirm && isPickupAndReturn ? getTime(getDeliverySlot(updatedPickupTime)) : ''}
                        returnAddress={updatedReturnAddress !== '' ? updatedReturnAddress : ''}
                        returnDate={
                          isReturnDateTimeConfirm && updatedReturnDate !== '' ? getDateAndDay(updatedReturnDate, dateFormat) : ''
                        }
                        returnTime={
                          isReturnDateTimeConfirm && updatedReturnTime !== '' ? getTime(getDeliverySlot(updatedReturnTime)) : ''
                        }
                        nightDeliveryFee={nightDeliveryFee}
                        showDeliveryDetails={true}
                        showPickupDeatils={true}
                        showReturnDetails={(!isIW_OEM && !isEWS)}
                        isNightFeesApplicable={isNightFeesApplicable}
                        currencySymbol={currencySymbol}
                        MDN={`${assetData?.MobileDeviceNumber || deviceName?.MobileDeviceNumber}`}
                        isAddressConfirm={getInlineChangeAddressFlag(find_agreement?.ClientOffer?.ClientOfferName) ? isAddressConfirm : true}
                        changeAddressFlag={getInlineChangeAddressFlag(find_agreement?.ClientOffer?.ClientOfferName) ? changeAddressFlag : true}
                        handleChageDeliveryAddressClick={()=>handleChageDeliveryAddressClick()}
                        isDateTimeSlotConfirm={isDateTimeslotConfirm}
                        isReturnDateTime={isReturnDateTime}
                        changeReturnAddressFlag={changeReturnAddressFlag}
                        isReturnDateTimeConfirm={isReturnDateTimeConfirm}
                        InlineFlag={getInlineChangeAddressFlag(find_agreement?.ClientOffer?.ClientOfferName)}
                        handleChangeReturnAddressClick={()=>handleChangeReturnAddress()}
                      />
                    )}

                  {(changeAddressFlag || changeReturnAddressFlag) && (
                    <>
                      <ChangeAddress
                        newAddressQuestion={changeAddressFlag
                          ? (EnterNewPickupAddressText || refreshLogisticsScripts?.EnterNewPickUpAddress)
                          : (EnterNewReturnAddressText || refreshLogisticsScripts?.EnterNewReturnAddress)}
                        fields={fields}
                        handleInputChange={handleInputChange}
                        selectedProvince={getFieldByFieldName('Province')?.value}
                        selectedDistrict={getFieldByFieldName('District')?.value}
                        provience={provience}
                        district={district}
                        handleSelectChange={handleSelectChange}
                        addressError={TCATAddressError}
                        programmeName={agreementData1?.ClientOffer?.ClientOfferName}
                      />
                    </>
                  )}
                  {changeDateTimeFlag && (
                    <PickupSchedule
                      dateLabel={refreshLogisticsScripts?.PickupDate}
                      timeSlotLabel={refreshLogisticsScripts?.PickupTime}
                      shipingMethods={shppingMethodV2Return || globalState?.state?.shippingMethodDatav2}
                      onDateSelect={onDateSelect}
                      onTimeSlotSelect={onTimeSlotSelect}
                      selectedDate={updatedPickupDate}
                      selectedTime={updatedPickupTime}
                      isWalking={true}
                      isPUR={true}
                      isTOM={isTOM}
                      applyDateFormat={getDateAndDay}
                      dateFormat={dateFormat || RefreshLogisticsScripts.Global.DateFormat}
                      applyTimeTranslation={getTime}
                      isMaxis={globalState?.state?.isMaxis}
                    />
                  )}
                  {isReturnDateTime && shppingMethodV2Return && (
                    <PickupSchedule
                      dateLabel={refreshLogisticsScripts?.ReturnDate}
                      timeSlotLabel={refreshLogisticsScripts?.ReturnTime}
                      shipingMethods={shppingMethodV2Return}
                      onDateSelect={onDateSelect}
                      onTimeSlotSelect={onTimeSlotSelect}
                      selectedDate={updatedReturnDate}
                      selectedTime={updatedReturnTime}
                      isWalking={true}
                      isPUR={true}
                      isTOM={isTOM}
                      applyDateFormat={getDateAndDay}
                      dateFormat={dateFormat || RefreshLogisticsScripts.Global.DateFormat}
                      applyTimeTranslation={getTime}
                    />
                  )}
                </Box>
              )}
              {[LOGISTICS_TYPES.WALKIN, 'Walk-in to your preferred location'].includes(srDeliveryType) && (
                <Box p={4}>
                  <WalkingDetails
                    preferredWalkingStoreLocation={preferredWalkingStoreLocation}
                    preferredWalkingTimeSlot={preferredWalkingTimeSlot}
                    preferredWalkingDate={preferredWalkingDate}
                    setPreferredWalkingStoreLocation={setPreferredWalkingStoreLocation}
                    setPreferredWalkingTimeSlot={setPreferredWalkingTimeSlot}
                    setPreferredWalkingDate={setPreferredWalkingDate}
                  />
                </Box>
              )}
              {!isAddressConfirm && !changeAddressFlag && (isPickupAndReturn || isDelivery) && <Box px={4} pb={2}>
                <Flex justifyContent={'space-between'} pb={2}>
                  <Box fontWeight={'bold'}>
                    <TextScript
                      text={'Alternate Contact Number'}
                      translationKey={`${RefreshLogisticsKeys.RefreshLogisticsScripts.PageName}/alternateContactNoText1`}
                    />
                  </Box>
                  {!showAlternateContactField && (
                    <Box onClick={() => setShowAlternateContactField(true)}>
                      <Link textDecorationLine={'underline'}>
                        <TextScript
                          text={RefreshLogisticsScripts.Global.Edit}
                          translationKey={`${RefreshLogisticsKeys.Global.PageName}/${RefreshLogisticsKeys.Global.Edit}`}
                        />
                      </Link>
                    </Box>
                  )}
                  {showAlternateContactField && (
                    <Flex justifyContent={'end'}>
                      <Box
                        onClick={confirmAlternateContactNumber}
                        borderWidth="1px"
                        borderRadius="md"
                        borderColor="#6E767D"
                        px={1}
                        mr={3}
                        cursor={'pointer'}
                      >
                        <CheckIcon boxSize={4} focusable={true} />
                      </Box>
                      <Box
                        onClick={handleCancel}
                        borderWidth="1px"
                        borderRadius="md"
                        borderColor="#6E767D"
                        px={1}
                        cursor={'pointer'}
                      >
                        <SmallCloseIcon boxSize={4} focusable={true} />
                      </Box>
                    </Flex>
                  )}
                </Flex>
                {showAlternateContactField && (
                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    borderColor="#6E767D"
                    maxW={{ base: '80%', lg: '50%' }}
                  >
                    <Input
                      border={'none'}
                      maxLength={validLength}
                      readOnly={false}
                      disabled={false}
                      placeholder={alternateContactNoPlaceholder}
                      _focus={{ border: 'none', boxShadow: 'none' }}
                      onChange={(e) => handleAlternateContactChange(e)}
                    />
                  </Box>
                )}
                {showAlternateContactField && invalidMobile && <Box color={'red'} fontSize={'14px'}>
                  <TextScript
                    text={'* Invalid mobile number'}
                    translationKey={`${RefreshLogisticsKeys.RefreshLogisticsScripts.PageName}/alternateContactNoErrorMsg`}
                  />
                </Box>}
                {!showAlternateContactField && <Box>{globalState?.state?.alternateContactNumber || '-'}</Box>}
              </Box>}
            </LogisticsAndFulfilment>
          </Box>
        </Box>
        <CancelSrPopup
          closeCancelPopup={setOpenCancelPopup}
          openCancelPopup={openCancelPopup}/>
      </Box>
      <Box>
      {(isPickupAndReturn || isDelivery) ? (
            <Flex direction={{ base: 'column', lg: 'row-reverse' }} justifyContent={{ base: 'start', lg: 'space-evenly' }} mt={6} p={4} w='full' marginX={'auto'} position={{ base: 'relative', lg: 'fixed' }} bottom='0' bgColor={{ base: '', lg: '#faf9f5' }}>
                <>
                  {!isAddressConfirm && !changeAddressFlag && (
                    <Button
                      py={{ base: 3, lg: 0 }}
                      px={{ base: 5, lg: 0 }}
                      id={'addressConfirm'}
                      isDisabled={(!globalState?.state?.pickUpAddress && (!find_agreement?.Address || !find_agreement?.Address?.Address1)) || showAlternateContactField}
                      bgColor={primary}
                      color="white"
                      w={{ base: 'full', lg: '12%'}}
                      onClick={() => {
                          if(hasTCAT(agreementData1?.ClientOffer?.ClientOfferName)){
                            callTCATAddressParseAPI()
                          } else {
                            handlePickupAddressConfirm()
                          }
                        }
                      }
                    >
                      <TextScript 
                        text={RefreshLogisticsScripts.Global.Confirm}
                        translationKey={`${RefreshLogisticsKeys.Global.PageName}/${RefreshLogisticsKeys.Global.Confirm}`}
                      />
                    </Button>
                  )}
                  {!getInlineChangeAddressFlag(find_agreement?.ClientOffer?.ClientOfferName) && !isAddressConfirm && !changeAddressFlag && (
                    <Flex justify={{ base: 'center', lg: 'start'}}>
                        <Button
                          colorScheme={'black'}
                          color={accent} //Link Color
                          variant="link"
                          height="48px"
                          fontWeight={700}
                          as={'u'}
                          w={{ base: "full", lg: 'auto' }}
                          cursor={'pointer'}
                          onClick={() => handleChageDeliveryAddressClick()}
                        >
                          <TextScript 
                            text={refreshLogisticsScripts?.ChangeAddress}
                            translationKey={`${RefreshLogisticsKeys.RefreshLogisticsScripts.PageName}/${RefreshLogisticsKeys.RefreshLogisticsScripts.btnChangeAddress}`}
                          />
                        </Button>
                    </Flex>
                  )}
                </>
                <>
                {!isAddressConfirm && changeAddressFlag && (
                  <Flex w='full' direction={{ base: 'column', lg: 'row-reverse' }} justifyContent={{ base: 'start', lg: 'space-evenly' }}>
                  {(showAddUpdateError || addressError) && (
                    <Box fontSize={'sm'} px={4} pb={1} textColor={'red'}>
                      {showAddUpdateError && (
                        <TextScript
                          text={'*Error in updating the address'}
                          translationKey={`${RefreshLogisticsKeys.RefreshLogisticsScripts.PageName}/updateAddressErrorMsg`}
                        />
                      )}

                      {addressError && (
                        <TextScript
                          text={'*Please enter valid address'}
                          translationKey={`${RefreshLogisticsKeys.RefreshLogisticsScripts.PageName}/invalidAddressErrorMsg`}
                        />
                      )}
                    </Box>
                  )}
                    <Button
                      py={3}
                      px={5}
                      id={'addressConfirm'}
                      bgColor={primary}
                      color="white"
                      isDisabled={ isAnyRequiredFieldInvalid(fields) }
                      w={{ base: "full", lg: '15%' }}
                      onClick={() => addNewPickupAddress()}
                    >
                      <TextScript 
                        text={refreshLogisticsScripts?.AddNewAddress}
                        translationKey={`${RefreshLogisticsKeys.RefreshLogisticsScripts.PageName}/${RefreshLogisticsKeys.RefreshLogisticsScripts.btnAddNewAddress}`}
                      />
                    </Button>
                    <Flex justify={{ base: 'center', lg: 'start'}}>
                      <Button
                        colorScheme={'black'}
                        color={accent}
                        display={globalState?.state?.isMaxis ? 'none' : 'block'}
                        variant="link"
                        height="48px"
                        as={'u'}
                        pt={'1rem'}
                        textAlign={'center'}
                        cursor={'pointer'}
                        fontWeight={700}
                        w={{ base: "full", lg: 'auto' }}
                        onClick={() => {
                          setChangesAddressFlag(false);
                          setShowAddUpdateError(false);
                          setAddressError(false);
                          clearValidations();
                        }}
                      >
                        <TextScript 
                          text={RefreshLogisticsScripts?.Global?.Cancel}
                          translationKey={`${RefreshLogisticsKeys.Global.PageName}/${RefreshLogisticsKeys.Global.Cancel}`}
                        />
                      </Button>
                    </Flex>
                  </Flex>
                )}
                </>
                {isAddressConfirm &&
                      isDateTimeslotConfirm &&
                      !isReturnDateTime &&
                      !isReturnDateTimeConfirm &&
                      !changeReturnAddressFlag &&
                      <Box  w='full'>
                         <Box display={{ base: 'block', lg: 'none' }}>
                          {isAddressConfirm &&
                                isDateTimeslotConfirm &&
                                !isReturnDateTime &&
                                !isReturnDateTimeConfirm &&
                                !changeReturnAddressFlag && (
                                  <Button
                                    py={3}
                                    px={5}
                                    id={'dateAndTimeSlotConfirm'}
                                    bgColor={primary}
                                    color="white"
                                    w={{ base: "full", lg: '10%' }}
                                    onClick={() => {
                                      callshippingmethodV2Api();
                                      setReturnDateTime(true);
                                      setChangeDateTimeFlag(false);
                                      // setSessionUpdatedReturnAddress(find_agreement?.address)
                                      return;
                                    }}
                                  >
                                    <TextScript 
                                      text={RefreshLogisticsScripts.Global.Confirm}
                                      translationKey={`${RefreshLogisticsKeys.Global.PageName}/${RefreshLogisticsKeys.Global.Confirm}`}              
                                    />
                                  </Button>
                                )}
                                {isAddressConfirm &&
                                  isDateTimeslotConfirm &&
                                  !isReturnDateTime &&
                                  !isReturnDateTimeConfirm &&
                                  !changeReturnAddressFlag && !getInlineChangeAddressFlag(find_agreement?.ClientOffer?.ClientOfferName) && (
                                    <Button
                                      colorScheme={'black'}
                                      color={accent} //Link Color
                                      variant="link"
                                      as={'u'}
                                      fontWeight={700}
                                      height="48px"
                                      w={{ base: "full", lg: '10%'}}
                                      onClick={handleChangeReturnAddress}
                                    >
                                      <TextScript 
                                        text={refreshLogisticsScripts?.ChangeReturnAddress}
                                        translationKey={`${RefreshLogisticsKeys.RefreshLogisticsScripts.PageName}/${RefreshLogisticsKeys.RefreshLogisticsScripts.btnChangeReturnAddress}`}
                                      />
                                    </Button>
                                  )}
                                  {isAddressConfirm &&
                                    isDateTimeslotConfirm &&
                                    !changeDateTimeFlag &&
                                    !isReturnDateTime &&
                                    !changeReturnAddressFlag &&
                                    !isReturnDateTimeConfirm && (
                                      <Button
                                        colorScheme={'black'}
                                        color={accent} //Link Color
                                        variant="link"
                                        as={'u'}
                                        fontWeight={700}
                                        height="48px"
                                        w={{ base: "full", lg: '20%' }}
                                        onClick={() => {
                                          isDateTimeslotConfirm
                                            ? setIsDateTimeslotConfirm(false)
                                            : setReturnDateTimeConfirm(false);
                                          setChangeDateTimeFlag(true);
                                        }}
                                      >
                                        <TextScript 
                                          text={refreshLogisticsScripts?.ChangePickupDateTime}
                                          translationKey={`${RefreshLogisticsKeys.RefreshLogisticsScripts.PageName}/${RefreshLogisticsKeys.RefreshLogisticsScripts.btnChangePickupDateTime}`}
                                        />
                                      </Button>
                                    )}
                          </Box> 
                          <Flex w='48%' m='auto' direction={'row-reverse'} justifyContent={'space-between'} display={{ base: 'none', lg: 'flex' }} justify={'center'}>
                          {isAddressConfirm &&
                                isDateTimeslotConfirm &&
                                !isReturnDateTime &&
                                !isReturnDateTimeConfirm &&
                                !changeReturnAddressFlag && (
                                  <Button
                                    py={3}
                                    px={5}
                                    id={'dateAndTimeSlotConfirm'}
                                    bgColor={primary}
                                    color="white"
                                    w={{ base: "full", lg: '20%' }}
                                    onClick={() => {
                                      callshippingmethodV2Api();
                                      setReturnDateTime(true);
                                      setChangeDateTimeFlag(false);
                                      // setSessionUpdatedReturnAddress(find_agreement?.address)
                                      return;
                                    }}
                                  >
                                    <TextScript 
                                      text={RefreshLogisticsScripts.Global.Confirm}
                                      translationKey={`${RefreshLogisticsKeys.Global.PageName}/${RefreshLogisticsKeys.Global.Confirm}`}              
                                    />
                                  </Button>
                                )}
                                <Flex direction={'column'} justify={'start'}>
                                  {isAddressConfirm &&
                                    isDateTimeslotConfirm &&
                                    !isReturnDateTime &&
                                    !isReturnDateTimeConfirm &&
                                    !changeReturnAddressFlag &&
                                    !getInlineChangeAddressFlag(find_agreement?.ClientOffer?.ClientOfferName) && (
                                      <Button
                                        colorScheme={'black'}
                                        color={accent} //Link Color
                                        variant="link"
                                        w={'auto'}
                                        mb='20px'
                                        mx='20px'
                                        as={'u'}
                                        fontWeight={700}
                                        onClick={handleChangeReturnAddress}
                                      >
                                        {/* {refreshLogisticsScripts?.ChangeReturnAddress} */}
                                        <TextScript 
                                          text={refreshLogisticsScripts?.ChangeReturnAddress}
                                          translationKey={`${RefreshLogisticsKeys.RefreshLogisticsScripts.PageName}/${RefreshLogisticsKeys.RefreshLogisticsScripts.btnChangeReturnAddress}`}
                                        />
                                      </Button>
                                    )}
                                    {isAddressConfirm &&
                                      isDateTimeslotConfirm &&
                                      !changeDateTimeFlag &&
                                      !isReturnDateTime &&
                                      !changeReturnAddressFlag &&
                                      !isReturnDateTimeConfirm && (
                                        <Button
                                          colorScheme={'black'}
                                          color={accent} //Link Color
                                          variant="link"
                                          as={'u'}
                                          fontWeight={700}
                                          w={{ base: "full", lg: 'auto' }}
                                          onClick={() => {
                                            isDateTimeslotConfirm
                                              ? setIsDateTimeslotConfirm(false)
                                              : setReturnDateTimeConfirm(false);
                                            setChangeDateTimeFlag(true);
                                          }}
                                        >
                                          <TextScript 
                                            text={refreshLogisticsScripts?.ChangePickupDateTime}
                                            translationKey={`${RefreshLogisticsKeys.RefreshLogisticsScripts.PageName}/${RefreshLogisticsKeys.RefreshLogisticsScripts.btnChangePickupDateTime}`}
                                          />
                                        </Button>
                                      )}
                                </Flex>
                          </Flex>   
                      </Box>
                }
                {isAddressConfirm && !isDateTimeslotConfirm && !isReturnDateTime && (
                  <Button
                    py={3}
                    px={5}
                    id={'timeSlotConfirm'}
                    bgColor={primary}
                    color="white"
                    w={{ base: 'full', lg: '10%'}}
                    isDisabled={!enablePickupDateTimeSlotButton}
                    onClick={() => {
                      setIsDateTimeslotConfirm(true);
                      // for OEM IW and EWS flow we are not showing return address and return slots
                      if(isIW_OEM || isEWS){
                        setReturnDateTimeConfirm(true);
                      } else {
                        setUpdatedReturnAddress(
                          updatedReturnAddress !== '' ? updatedReturnAddress : globalState?.state?.isMaxis ? updatedPickupAddress : fulladdress
                        );
                        setSessionUpdatedReturnAddress(
                          updatedReturnAddress !== '' ? updatedReturnAddress : globalState?.state?.isMaxis ? sessionUpdatedPickupAddress : find_agreement?.address
                        );
                        globalState?.dispatch({
                          type: ActionTypes.SET_RETURN_ADDRESS,
                          payload: updatedReturnAddress !== '' ? updatedReturnAddress : globalState?.state?.isMaxis ? updatedPickupAddress : fulladdress,
                        });
                      }
                      if (isAddressConfirm) {
                        setChangeDateTimeFlag(false);
                      }
                    }}
                  >
                    <TextScript 
                      text={RefreshLogisticsScripts.Global.Confirm}
                      translationKey={`${RefreshLogisticsKeys.Global.PageName}/${RefreshLogisticsKeys.Global.Confirm}`}
                    />
                  </Button>
                )}
                {changeReturnAddressFlag && (
                  <>
                {(showAddUpdateError || addressError) && (
                  <Box fontSize={'sm'} px={4} pb={1} textColor={'red'}>
                    {showAddUpdateError && (
                      <TextScript
                        text={'*Error in updating the address'}
                        translationKey={`${RefreshLogisticsKeys.RefreshLogisticsScripts.PageName}/updateAddressErrorMsg`}
                      />
                    )}

                    {addressError && (
                      <TextScript
                        text={'*Please enter valid address'}
                        translationKey={`${RefreshLogisticsKeys.RefreshLogisticsScripts.PageName}/invalidAddressErrorMsg`}
                      />
                    )}
                  </Box>
                )}
                    <Button
                      py={3}
                      px={5}
                      id={'addressConfirm'}
                      bgColor={primary}
                      color="white"
                      isDisabled={ isAnyRequiredFieldInvalid(fields) }
                      w={{ base: 'full', lg: '15%'}}
                      onClick={addNewReturnAddress}
                    >
                      <TextScript 
                        text={refreshLogisticsScripts?.AddNewReturnAddress}
                        translationKey={`${RefreshLogisticsKeys.RefreshLogisticsScripts.PageName}/${RefreshLogisticsKeys.RefreshLogisticsScripts.btnAddNewReturnAddress}`}
                      />
                    </Button>
                    <Button
                      color={accent}
                      variant="link"
                      height="48px"
                      textDecorationLine={'underline'}
                      w={{ base: "full", lg: 'auto' }}
                      m={'10px 0px'}
                      textAlign={'center'}
                      cursor={'pointer'}
                      onClick={() => {
                        setChangesAddressFlag(false);
                        setChangesReturnAddressFlag(false);
                        setShowAddUpdateError(false);
                        setAddressError(false);
                        clearValidations();
                      }}
                    >
                      <TextScript 
                        text={RefreshLogisticsScripts?.Global?.Cancel}
                        translationKey={`${RefreshLogisticsKeys.Global.PageName}/${RefreshLogisticsKeys.Global.Cancel}`}
                      />
                    </Button>
                  </>
                )}
                
                
              {isAddressConfirm && isReturnDateTime && !isReturnDateTimeConfirm && (
                <Button
                  py={3}
                  px={5}
                  id={'dateAndTimeSlotConfirm'}
                  bgColor={primary}
                  color="white"
                  w={{ base: 'full', lg: '10%'}}
                  isDisabled={!enableReturnDateTimeSlotButton}
                  onClick={() => {
                    setReturnDateTime(false);
                    setReturnDateTimeConfirm(true);
                    return;
                  }}
                >
                  <TextScript 
                    text={RefreshLogisticsScripts.Global.Confirm}
                    translationKey={`${RefreshLogisticsKeys.Global.PageName}/${RefreshLogisticsKeys.Global.Confirm}`}
                  />
                </Button>
              )}
              {isAddressConfirm && isReturnDateTimeConfirm && !isReturnDateTime && !changeDateTimeFlag && (
                <Button
                  py={3}
                  px={5}
                  id={'dateAndTimeSlotConfirm'}
                  bgColor={primary}
                  color="white"
                  w={{ base: 'full', lg: '10%'}}
                  onClick={() => {
                    onConfirmOrNextClick();
                    return;
                  }}
                >
                  <TextScript 
                    text={RefreshLogisticsScripts.Global.Confirm}
                    translationKey={`${RefreshLogisticsKeys.Global.PageName}/${RefreshLogisticsKeys.Global.Confirm}`}
                  />
                </Button>
              )}
                {isAddressConfirm && !isReturnDateTime && isReturnDateTimeConfirm && !isIW_OEM && !isEWS && (
                  <Button
                    colorScheme={'black'}
                    color={accent} //Link Color
                    variant="link"
                    height="48px"
                    as={'u'}
                    fontWeight={700}
                    w={{ base: "full", lg: 'auto' }}
                    onClick={() => {
                      setReturnDateTimeConfirm(false);
                      setReturnDateTime(true);
                    }}
                  >
                    <TextScript 
                      text={refreshLogisticsScripts?.ChangeReturnDateTime}
                      translationKey={`${RefreshLogisticsKeys.RefreshLogisticsScripts.PageName}/${RefreshLogisticsKeys.RefreshLogisticsScripts.btnChangeReturnDateTime}`}
                    />
                  </Button>
                )}

                {isAddressConfirm && !isReturnDateTime && !changeDateTimeFlag && isReturnDateTimeConfirm && (isIW_OEM || isEWS) && (
                  <Button
                    colorScheme={'black'}
                    color={accent} //Link Color
                    variant="link"
                    height="48px"
                    as={'u'}
                    fontWeight={700}
                    w={{ base: "full", lg: 'auto' }}
                    onClick={() => {
                      setIsDateTimeslotConfirm(false)
                      setReturnDateTimeConfirm(false);

                      setChangeDateTimeFlag(true);
                    }}
                  >
                    <TextScript 
                      text={refreshLogisticsScripts?.ChangePickupDateTime}
                      translationKey={`${RefreshLogisticsKeys.RefreshLogisticsScripts.PageName}/${RefreshLogisticsKeys.RefreshLogisticsScripts.btnChangePickupDateTime}`}
                    />
                  </Button>
                )}
            </Flex>
          ) : (
            <Flex justify='center' mt={6} p={4} w='full' position={{ base: 'relative', lg: 'fixed' }} bottom='0' bgColor={{ base: '', lg: '#faf9f5' }}>
              <Button
                py={3}
                px={5}
                id={'walkingStoreDetails'}
                bgColor={primary}
                isDisabled={!enableWalkingNextButton}
                color="white"
                w={{ base: "full", lg: '20%' }}
                onClick={() => {
                  handleWalkingDetails();
                }}
              >
                <TextScript 
                  text={RefreshLogisticsScripts.Global.Next}
                  translationKey={`${RefreshLogisticsKeys.Global.PageName}/${RefreshLogisticsKeys.Global.Next}`}
                />
              </Button>
            </Flex>
          )}
      </Box>
    </Box>
  );
};

export default RefreshLogisticsAndFulfilmentContainer;