import { boxShadow } from '../../../App';
import { Box, BoxProps, Text } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

const MainCaption: React.FC<PropsWithChildren & BoxProps> = ({ children, ...props }) => {
  return (
    <Box p={'16px'} w={'full'} bg={'#fff'} boxShadow={boxShadow} {...props}>
      <Text fontSize={'18px'} fontWeight={400} lineHeight={'124%'}>
        {children}
      </Text>
    </Box>
  );
};
export default MainCaption;
