import Scripts from '../Scripts/asurion-script.json';
import MainStackLayout from '../layout/MainStackLayout';
import { assetDataKey } from '../modules/config/constants';
import { useCentralStore } from '../store/appContext';
import { getProgrammeName } from '../utils/localization-helper';
import { getRedirectUrl } from '../utils/utills';
import {
  DeviceCard,
  TextScript,
  getTypeByDomain,
  useConfig,
  useSessionStorage,
  useTextScript,
} from '@MGPD/myasurion-shared';
import { Box, Button, Center, Link, Skeleton, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import translationsKeys from '../Scripts/translationsKeys.json';

function ClaimInEligible() {
  const globalState = useCentralStore();
  const { isTranslationsLoading, language } = useTextScript();

  const [assetData] = useSessionStorage<any>(assetDataKey, null);
  const MobileDeviceNumber = assetData && assetData?.MobileDeviceNumber?.split('_')?.[0];

  const ClaimInEligible = translationsKeys.ClaimInEligible;

  const [programmeNameLBL, setProgrammeNameLBL] = useState('');

  const agreementData1: any =
    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { primary } = colors;

  useEffect(() => {
    if (agreementData1 && !isTranslationsLoading) {
      // get programme name
      getProgrammeNameLbl();
    }
  }, [agreementData1, language, isTranslationsLoading]);

  // const onContactUsClick = () => {
  //   window.open(assetData?.SPWebsiteUrl, '_blank');
  // };

  const getProgrammeNameLbl = async () => {
    const programmeName = await getProgrammeName(agreementData1?.ClientOffer?.ClientOfferName);
    setProgrammeNameLBL(programmeName);
  };

  return (
    <Center>
      <Box w={{ base: 'full', lg: '45%' }} id={'myasurion-profile-dashboard'} paddingTop={'54px'}>
        <MainStackLayout gap={0} spacing={0}>
          <Box
            id={'myasurion-profile-avatar'}
            mt={4}
            mx="auto"
            h={'auto'}
            p={'4'}
          >
            <Center>
              {assetData ? (
                <DeviceCard
                  width={'100%'}
                  type=""
                  status=""
                  assetId={assetData && assetData?.AssetId.toString()}
                  plan={programmeNameLBL || agreementData1?.ClientOffer?.ClientOfferName}
                  mdn={`(${
                    assetData?.CountryCallingCode?.split('+')?.[1] ||
                    agreementData1?.ContactPoints?.ContactPoint?.[0]?.CountryCallingCode
                  }) ${MobileDeviceNumber}`}
                  model={`${assetData?.AssetCatalog.AssetCatalogName}`}
                  imei={assetData?.IMEI}
                  imageEl={<></>}
                ></DeviceCard>
              ) : (
                <Skeleton minW={'335px'} minH={'150px'} w={'full'} borderRadius={'8px'} />
              )}
            </Center>
            <Text mt={'6'} fontSize={'16px'}>
              <TextScript
                text={Scripts.ClaimInEligible.HeaderText}
                translationKey={`${ClaimInEligible.PageName}/${ClaimInEligible.HeaderText}`}
              />
            </Text>

            {/* {assetData?.SPWebsiteUrl && (
              <Box width={{ base: 'full', lg: '50%' }} marginX={'auto'}>
                <Button
                  mt={'5'}
                  width={'full'}
                  textAlign={'center'}
                  colorScheme="primary"
                  bg={colors.primary}
                  cursor={'pointer'}
                  onClick={onContactUsClick}
                >
                  <TextScript
                    text={Scripts.ClaimInEligible.LblContactUs}
                    translationKey={`${ClaimInEligible.PageName}/${ClaimInEligible.LblContactUs}`}
                  />
                </Button>
              </Box>
            )}

            <Box display="flex" justifyContent="center" mt={'1.5rem'}>
              <Link
                href={getRedirectUrl(getTypeByDomain())}
                color={accent}
                fontSize={'1rem'}
                fontWeight={700}
                py={'12px'}
                textDecoration="underline"
              >
                <TextScript
                  text={Scripts.Global.Close}
                  translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Close}`}
                />
              </Link>
            </Box> */}

            <Box mt={'6'}>
              <Text>
                <TextScript
                  text={Scripts.ClaimInEligible.LblOEMWebsite}
                  translationKey={`${translationsKeys.ClaimInEligible.PageName}/${translationsKeys.ClaimInEligible.LblOEMWebsite}`}
                />
              </Text>
              <Link
                href={assetData?.SPWebsiteUrl}
                color={primary}
                fontSize={'1rem'}
                fontWeight={700}
                py={'12px'}
                textDecoration="underline"
                isExternal
              >
                {assetData?.SPWebsiteUrl}
              </Link>
            </Box>
            <Box mt={'6'}>
              <Text>
                <TextScript
                  text={Scripts.ClaimInEligible.LblServiceHotline}
                  translationKey={`${translationsKeys.ClaimInEligible.PageName}/${translationsKeys.ClaimInEligible.LblServiceHotline}`}
                />
              </Text>
              <Link
                href={`tel:${assetData?.ContactPersonNumber}`}
                color={primary}
                fontSize={'1rem'}
                fontWeight={700}
                py={'12px'}
                textDecoration="underline"
              >
                {assetData?.ContactPersonNumber}
              </Link>
            </Box>
            <Box width={{ base: '90%', lg: '50%' }} textAlign={'center'} marginX={'auto'}>
              <Button
                mt={'6'}
                width={'full'}
                py={3}
                px={5}
                colorScheme="primary"
                onClick={() => window.open(getRedirectUrl(getTypeByDomain()), '_self')}
              >
                <TextScript
                  text={Scripts.Global.GoBack}
                  translationKey={`${translationsKeys.ClaimCard.PageName}/${translationsKeys.ClaimCard.Gobackbtnlbl}`}
                />
              </Button>
            </Box>
          </Box>
        </MainStackLayout>
      </Box>
    </Center>
  );
}

export default ClaimInEligible;
