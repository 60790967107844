import { noop } from '@MGPD/myasurion-shared';
import { Box, Select as ChakraSelect, Text, useToken } from '@chakra-ui/react';
import * as React from 'react';
import { MySelectProps } from '../../types/types';

const { useState, useEffect } = React;

export const Select = ({
  placeholder,
  value: _value = '',
  floatingLabel,
  floatingPlaceholder,
  onChange = noop,
  onBlur = noop,
  ...restProps
}: MySelectProps) => {
  const [value, setValue] = useState<string>(_value as string);
  const [isActive, setIsActive] = useState(false);

  const leftPadding = useToken('components.Select.baseStyle.field.paddingX', '16px');
  const placeholderColor = useToken(
    'components.Select.baseStyle.field._placeholder.color',
    'brand.neutral.deeper'
  );

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
    onChange?.(e);
  };

  useEffect(() => {
    setIsActive(value !== '');
  }, [value]);

  useEffect(() => {
  if(floatingLabel){
    setValue(floatingLabel);
  }
  },[]);

  return (
    <Box pos="relative">
      <ChakraSelect
        {...restProps}
        placeholder={placeholder}
        isSearchable
       // onFocus={() => setIsActive(false)}
        //value={_value}
        onChange={handleChange}
      />
      <Text
        top={isActive ? '30%' : '50%'}
        left={leftPadding}
        transform={isActive ? 'translate(-13%,-50%) scale(0.7)' : 'translate(-20%,-50%) scale(1)'}
        p="0 12px"
        color={isActive ? '#6E767D' : placeholderColor}
        transformOrigin="top left"
        transition="all .2s ease-out"
        pointerEvents="none"
        pos="absolute"
        w="fit-content"
        h="fit-content"
        zIndex="5"
      >
        {isActive ? (floatingPlaceholder || placeholder) : ''}
      </Text>
    </Box>
  );
};

export default Select;
