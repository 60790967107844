import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Text
} from '@chakra-ui/react';
import React from 'react';
import { PropsWithChildren } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
// import Geocode from 'react-geocode';
interface popupProps {
  cancelDeliveryPopup: boolean;
  closeCancelPopup: (args: boolean) => void;
  callInquiryForTrackSR: (args: any) => void;
  setCancelDeliverySuccessMsg: (args: any) => void;
  cancelDeliverySuccessMsg: any
}

const CancelDeliveryPopup: React.FC<PropsWithChildren<popupProps>> = ({
  cancelDeliveryPopup,
  closeCancelPopup,
  callInquiryForTrackSR,
  // setCancelDeliverySuccessMsg,
  cancelDeliverySuccessMsg
}) => {
  const cancelRef = React.useRef<any>();

  const onClose = () => {
    closeCancelPopup(false);
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => onClose()}
        isOpen={cancelDeliveryPopup}
        isCentered
        autoFocus={false}
      >
        <AlertDialogOverlay />
        <AlertDialogContent margin={'12px'} p='4px 20px'>
            <Box
                onClick={() => closeCancelPopup(false)}
                p={3}
                cursor={'pointer'}
                position={'absolute'}
                right={'0'}
            >
                <CloseIcon boxSize={4} focusable={true} />
            </Box>
          <AlertDialogBody paddingX={'12px'} paddingTop={'12px'} as={'b'} fontSize={'18px'} mt='5'>
           <Text>{cancelDeliverySuccessMsg ? 'Your delivery cancel request has been submitted for internal approval, you will be contacted once it is approved. Thanks for contacting SmartSupport!' : 'Do you want to proceed with cancelling your delivery request?'}</Text>
          </AlertDialogBody>
          {!cancelDeliverySuccessMsg && <Box p={'12px 12px'}>
            <Flex justifyContent={'center'}>
              <Button
                colorScheme="primary"
                // variant="outline"
                width={'50%'}
                onClick={() => callInquiryForTrackSR('cancelDelivery')}
              >
                Yes, confirm
              </Button>
            </Flex>
          </Box>
        }
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CancelDeliveryPopup;
