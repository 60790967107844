import MainStackLayout from '../../layout/MainStackLayout';
import { CSAT } from '@MGPD/myasurion-shared';
import {
  Box,
  Center,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function Csat() {
    const navigate = useNavigate();
return (
    <Center>
      <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'} w="full">
        <MainStackLayout gap={0} spacing={0}>
          <Box p={'16px'} w={{ base: 'full', lg: '80%' }} marginX={{ base: '', lg: 'auto' }} mb={{ base: '0', lg: '20px' }}>
        <CSAT
        question={'How would you rate your overall satisfaction with the [goods/service] you received?'}
        onSubmit={()=>{navigate('/thanksmessage')}}
        ></CSAT>
          </Box>
        </MainStackLayout>
      </Box>
    </Center>
  );
}

export default Csat;
