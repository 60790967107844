/* eslint-disable no-console */
import {
    AvatarInitials,
    SignOutLink,
    SignOutLinkProps,
    TextScript,
    generateUrl,
    getCarrierByDomain,
    useConfig,
} from '@MGPD/myasurion-shared';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    BoxProps,
    Button,
    ButtonProps,
    HStack,
    Link,
    Spacer,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export interface MenuListProps {
    name: string;
    code: string;
    url: string;
}

export interface ServiceRequestButtonProps {
    enabled?: boolean;
    label?: string;
    styleProps?: ButtonProps;
    onClick?: () => void;
}

export interface MenuDisplayProps {
    firstname: string;
    lastname: string;
    accountType?: string;
    skipUrlClick?: boolean;
    signOutProps?: SignOutLinkProps;
    serviceRequestButtonProps?: ServiceRequestButtonProps;
    toggleMenu?: (flag: boolean) => void;
}

export const MenuDisplay: React.FC<MenuDisplayProps & BoxProps> = ({
    firstname,
    lastname,
    accountType = 'main account',
    signOutProps,
    serviceRequestButtonProps,
    skipUrlClick,
    toggleMenu,
    ...props
}) => {
    const { data } = useConfig();
    const { urls, mainMenu } = data;
    const { theme }: { theme?: any } = data;
    const { serviceRequestBtn } = theme?.components || {};
    const carrier = getCarrierByDomain();
    const navigate = useNavigate();

    const navigateOnClick = (path?: string) => {
        toggleMenu && toggleMenu(false);
        if (skipUrlClick && path) {
            navigate(`/${path}`, {replace: true});
        }
    };

    const extractLastPathSegment = (path: string): string | undefined => {
        const segments = path.split('/');
        return segments.pop();
    };

    return (
        <Box
            id={'myasurion-menu-display'}
            zIndex={999998}
            pos={'fixed'}
            top={0}
            left={0}
            bg={'#fff'}
            w={'full'}
            h={'100dvh'}
            m={0}
            p={0}
            border={'none'}
            {...props}
        >
            <VStack
                gap={0}
                spacing={0}
                w={'full'}
                alignItems={'baseline'}
                id={'myasurion-menu-display-stack'}
            >
                <Box w={'full'} px={'16px'} py={'24px'} borderBottom={`1px solid #F0F0F5`}>
                    <HStack gap={6}>
                        <Box>
                            <AvatarInitials
                                firstname={firstname}
                                lastname={lastname}
                                carrier={carrier}
                            />
                        </Box>
                        <Box>
                            <VStack alignItems={'baseline'}>
                                <Text
                                    fontSize={'xs'}
                                    fontWeight={900}
                                    lineHeight={'136%'}
                                    textTransform={'uppercase'}
                                >
                                    <TextScript
                                        text={accountType}
                                        translationKey={`MenuDisplay/${accountType}`}
                                    />
                                </Text>
                                <Link
                                    textTransform={'capitalize'}
                                    fontSize={'2xl'}
                                    lineHeight={'132%'}
                                    href={
                                        !skipUrlClick ? `${generateUrl(urls?.profile || '')}` : '#'
                                    }
                                    onClick={(event) => {
                                        if (skipUrlClick) {
                                            event.preventDefault(); // Prevent default anchor redirection
                                            navigateOnClick(urls?.profile);
                                        }
                                    }}
                                    _hover={{ textDecoration: 'none', cursor: 'pointer' }}
                                >
                                    {carrier === 'cht_home'
                                        ? `${lastname} ${firstname}`
                                        : `${firstname} ${lastname}`}
                                </Link>
                            </VStack>
                        </Box>
                        <Spacer />
                        <Box>
                            <Link
                                href={!skipUrlClick ? `${generateUrl(urls?.profile || '')}` : '#'}
                                onClick={(event) => {
                                    if (skipUrlClick) {
                                        event.preventDefault();
                                        navigateOnClick(urls?.profile);
                                    }
                                }}
                                _hover={{ textDecoration: 'none', cursor: 'pointer' }}
                            >
                                <ChevronRightIcon w={'24px'} h={'24px'} color={'#000'} />
                            </Link>
                        </Box>
                    </HStack>
                </Box>
                {mainMenu &&
                    mainMenu.map((menu: MenuListProps, i) => {
                        const url = generateUrl(menu.url);
                        return (
                            <Box w={'full'} px={'16px'} py={'24px'} key={`menu-${menu.code}-${i}`}>
                                <HStack>
                                    <Link
                                        fontSize={'2xl'}
                                        fontWeight={300}
                                        lineHeight={'132%'}
                                        href={!skipUrlClick ? url : '#'}
                                        onClick={(event) => {
                                            if (skipUrlClick) {
                                                event.preventDefault();
                                                menu?.url?.includes('/portal/plan') ? (toggleMenu && toggleMenu(false), navigate('/portal/plan')) : navigateOnClick(extractLastPathSegment(url));
                                            }
                                        }}
                                        w={'100%'}
                                        _hover={{ textDecoration: 'none', cursor: 'pointer' }}
                                    >
                                        <Stack direction={'row'} gap={0}>
                                            <Box>{menu.name}</Box>
                                            <Spacer />
                                            <Box>
                                                <ChevronRightIcon
                                                    w={'24px'}
                                                    h={'24px'}
                                                    color={'#000'}
                                                />
                                            </Box>
                                        </Stack>
                                    </Link>
                                </HStack>
                            </Box>
                        );
                    })}
            </VStack>
            <Box
                id={'header-signout-section'}
                position={'fixed'}
                bottom={0}
                w={'full'}
                bg={'#FFFFFF'}
                boxShadow={' 0px -4px 8px 0px rgba(0, 0, 0, 0.04)'}
            >
                <VStack alignItems={'baseline'} w={'full'} gap={6} p={'24px 16px'}>
                    {serviceRequestButtonProps && serviceRequestButtonProps.enabled && (
                        <Button
                            {...serviceRequestBtn}
                            {...serviceRequestButtonProps?.styleProps}
                            onClick={() => {
                                serviceRequestButtonProps?.onClick &&
                                    serviceRequestButtonProps.onClick();
                            }}
                        >
                            {serviceRequestButtonProps?.label || 'File a service request'}
                        </Button>
                    )}
                    <SignOutLink
                        label={signOutProps?.label && signOutProps.label}
                        onSignOut={() => {
                            signOutProps?.onSignOut && signOutProps.onSignOut();
                        }}
                        margin={'0 auto'}
                        {...signOutProps?.styleProps}
                    />
                </VStack>
            </Box>
        </Box>
    );
};
