/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-console */
import Scripts from '../../Scripts/asurion-script.json';
import TranslationKeys from '../../Scripts/translationsKeys.json';
import { TextScript } from '@MGPD/myasurion-shared';
import { Box, Flex, Link, Text } from '@chakra-ui/react';

interface ServiceField {
  name: string;
  value: string;
  text: string;
  pageName: string;
  translationKey: string;
  showEditButton: boolean;
  onEditClick?: (args: string) => void;
}

const ServiceField: React.FC<ServiceField> = ({
  name,
  value,
  text,
  pageName,
  translationKey,
  showEditButton,
  onEditClick,
}) => {
  return (
    <Box w="full" pb="2">
      <Flex mb={1} justifyContent={'space-between'}>
        <Box fontWeight={'bold'}>
          <TextScript text={text} translationKey={`${pageName}/${translationKey}`} />
        </Box>
        {showEditButton && (
          <Box onClick={() => onEditClick && onEditClick(name)}>
            <Link textDecorationLine={'underline'}>
              <TextScript
                text={Scripts.Global.Edit}
                translationKey={`${TranslationKeys.Global.PageName}/${TranslationKeys.Global.Edit}`}
              />
            </Link>
          </Box>
        )}
      </Flex>
      <Text>{value}</Text>
    </Box>
  );
};

export default ServiceField;
