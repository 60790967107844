import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import { TextScript } from '@MGPD/myasurion-shared';
import {  Image, Text } from '@chakra-ui/react';
import RemoveIcon from '../../icons/Remove.svg';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { PropsWithChildren } from 'react';

import Tip4 from '../../icons/Tip4.svg';
import brightness from '../../icons/brightness.svg';
import camera from '../../icons/camera.svg';
import focus from '../../icons/focus.svg';

interface popupProps {
  openCancelPopup: boolean;
  closeCancelPopup: (args: boolean) => void;
}

const FhotoToolTip: React.FC<PropsWithChildren<popupProps>> = ({
  openCancelPopup,
  closeCancelPopup,
}) => {
  const cancelRef = React.useRef<any>();
  let { isOpen } = useDisclosure();
  isOpen = openCancelPopup;
  const FhotoToolTipKeys = translationsKeys.FhotoToolTip;

  const onClose = () => {
    closeCancelPopup(false);
  };

  const ToolTipText = [
    {
      script: Scripts.FhotoToolTip.Tip1,
      key: 'Tip1',
      localimage: brightness,
    },
    {
      script: Scripts.FhotoToolTip.Tip2,
      key: 'Tip2',
      localimage: camera,
    },
    {
      script: Scripts.FhotoToolTip.Tip3,
      key: 'Tip3',
      localimage: focus,
    },
    {
      script: Scripts.FhotoToolTip.Tip4,
      key: 'Tip4',
      localimage: Tip4,
    },
  ];

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => onClose()}
        isOpen={isOpen}
        isCentered
        autoFocus={false}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogBody>
            <Flex justifyContent={'space-between'}>
              <Text
                fontSize={'24px'}
                fontFamily={'Apercu Pro'}
                fontWeight="400"
                lineHeight="38px"
                paddingTop={'12px'}
              >
                <TextScript
                  text={Scripts.FhotoToolTip.HeaderText}
                  translationKey={`${FhotoToolTipKeys.PageName}/${FhotoToolTipKeys.HeaderText1}`}
                />
              </Text>
              {
                <Flex justifyContent={'flex-end'}>
                  <Image  src={RemoveIcon} onClick={() => onClose()} />
                </Flex>
              }
            </Flex>
            <Box paddingTop={'16px'}>
              <Box paddingLeft={'32px'} paddingRight={'32px'}>
                {ToolTipText?.map((val: any) => {
                  return (
                    <Flex direction={'row'}>
                      <Image src={val?.localimage} marginBottom={'auto'} />
                      <Text
                        marginLeft={'16px'}
                        marginBottom={'20px'}
                        fontFamily={'Apercu Pro'}
                        fontWeight="400"
                      >
                        <TextScript
                          text={val?.script}
                          translationKey={`${FhotoToolTipKeys.PageName}/${FhotoToolTipKeys}${val?.key}`}
                        />
                      </Text>
                    </Flex>
                  );
                })}
              </Box>
            </Box>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FhotoToolTip;
