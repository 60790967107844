import { getDownloadUrlFromS3, getSignedUrlFromS3 } from '../services/api';


export async function getSignedRequestForUpload(agreementId: string, InteractionLineId: string, folderName: string, clientName: string, findAgreementSessionData: string) {
  return new Promise((resolve, reject) => {
    const payload = {
      FileUploadRequest: {
        AgreementId: agreementId,
        FolderName: folderName, 
        ClientName: clientName
      },
    };
    getSignedUrlFromS3(payload, InteractionLineId, findAgreementSessionData)
      .then((data) => {
        resolve(data.url);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function getSignedURLForDownload(payload: any, InteractionLineId: string, findAgreementSessionData: string) {

  return new Promise((resolve, reject) => {
    getDownloadUrlFromS3(payload, InteractionLineId, findAgreementSessionData)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Safari 3.0+ "[object HTMLElementConstructor]"
const isSafari: boolean =
  /constructor/i.test(window.HTMLElement as any) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(
    !window['safari' as any] ||
    (typeof 'safari' !== 'undefined' && (window['safari' as any] as any).pushNotification)
  );

// Internet Explorer 6-11
const isIE: boolean = /*@cc_on!@*/ false || !!(document as any).documentMode;

// Edge 20+
const isEdge: boolean = !isIE && !!(window as any).StyleMedia;

// Chrome 1 - 79
const isChrome: boolean =
  !!(window as any).chrome &&
  (!!(window as any).chrome.webstore || !!(window as any).chrome.runtime);

// Edge (based on chromium) detection
const isEdgeChromium: boolean = isChrome && navigator.userAgent.indexOf('Edg') != -1;
const browser = {
  isSafari,
  isIE,
  isEdge,
  isChrome,
  isEdgeChromium,
};

const getOS = (): string => {
  let userAgent = (window.navigator as Navigator).userAgent,
    platform = window.navigator.platform || (navigator as any).userAgentData.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = '';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'MACOS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'IOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'WINDOWS';
  } else if (/Android/.test(userAgent)) {
    os = 'ANDROID';
  } else if (!os && /Linux/.test(platform)) {
    os = 'LINUX';
  }

  return os;
};

export const getMIMEType = (): string => {
  const OS: string | null = getOS();
  console.log('OS:', OS);
  if (['MACOS'].includes(OS) && !browser.isSafari) {
    return 'video/webm';
  } else if (['MACOS', 'IOS'].includes(OS)) {
    return 'video/mp4';
  } else {
    return 'video/webm';
  }
};

export const getVideoFileExt = (): string => {
  const OS: string | null = getOS();
  console.log('OS:', OS);
  if (['MACOS'].includes(OS) && !browser.isSafari) {
    return 'webm';
  } else if (['MACOS', 'IOS'].includes(OS)) {
    return 'mp4';
  } else {
    return 'webm';
  }
};

export const validateFileType = (file: any) => {
  let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
  return allowedExtensions.exec(file);
};

export const base64ToURL = (base64Data: string, mimeType: string) => {
  const binaryData = atob(base64Data);
  const byteArray = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    byteArray[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([byteArray], { type: mimeType });
  const url = URL.createObjectURL(blob);
  return url;
};
export function dataURItoBlob(dataURI: any, contentType = null, shouldConvertToBin = false) {
  var binary;
  if (shouldConvertToBin) binary = atob(dataURI.split(',')[1]);
  else binary = atob(dataURI);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  if (contentType) return new Blob([new Uint8Array(array)], { type: contentType });
  return new Blob([new Uint8Array(array)]);
}

export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      const reader: any = fileReader.result;
      const readerfinal = reader?.split('base64,');
      resolve(readerfinal[1]);
    };

    fileReader.onerror = (err) => {
      reject(err);
    };
  });
};

export const getBase64ForPDF = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      const reader: any = fileReader.result;
      resolve(reader);
    };

    fileReader.onerror = (err) => {
      reject(err);
    };
  });
};
