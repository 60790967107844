import { TextScript } from '@MGPD/myasurion-shared';

import {
  Box,
  CSSReset,
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import ReplacementKeys from '../Scripts/translationsKeys.json'

// const theme = extendTheme({
//   components: {
//     Slider: {
//       baseStyle: {
//         track: {
//           bg: 'gray',
//           height: '2px',
//           borderRadius: '4px',
//         },
//         thumb: {
//           borderColor: 'black',
//           boxSize: '4',
//         },
//         filledTrack: {
//           bg: 'black',
//         },
//       },
//     },
//   },
// });

interface DualRangeSliderProps {
  selectedSliderValues: [number, number];
  handleSliderChange: (values: [number, number]) => void;
  handleSliderCancel: () => void;
  showPriceRange: boolean;
  defaultSliderValues: [number, number];
}

const DualRangeSlider: React.FC<DualRangeSliderProps> = ({
  selectedSliderValues,
  handleSliderChange,
  defaultSliderValues,
}) => {
  return (
    <Box px={4}>
      {/* <ChakraProvider theme={theme}> */}
        <CSSReset />
        <>
          <Box fontWeight={'bold'} fontSize={'18px'} marginBottom={'12px'}>
            <TextScript
              text='Service fee range'
              translationKey={`${ReplacementKeys.Replacement.PageName}/${ReplacementKeys.Replacement.KeySrf}`}
            />
          </Box>
          {/* {showPriceRange && (
            <Box display="flex" flexWrap="wrap" justifyContent="center" mt={4} mb={4}>
              <Box
                display="flex"
                p={3}
                fontSize="xs"
                h={10}
                color="#A5AAAF"
                bg="#E6E6EB"
                roundedLeft="full"
                alignItems="center"
                border={'1px solid #A5AAAF'}
              >
                SGD&nbsp;{selectedSliderValues[0]} - SGD&nbsp;{selectedSliderValues[1]}
              </Box>
              <Button
                type="button"
                bg="gray.400"
                size={'md'}
                h={10}
                minH={10}
                roundedRight="full"
                onClick={() => handleSliderCancel()}
              >
                <CloseIcon color={'black'} display={'inline'}/>
              </Button>
            </Box>
          )} */}
          <Flex justifyContent="space-between">
            <Box
              rounded="lg"
              bg="#E6E6EB"
              // px={3}
              border={'1px solid #A5AAAF'}
              fontSize="xs"
              // py={{ base: 3.5, xl: 0 }}
              display="flex"
              alignItems="center"
              width="60px"
              height="56px"
            >
             <Text color={'#A5AAAF'} p={'12px'}>{selectedSliderValues[0]}</Text> 
            </Box>
            <Box
              rounded="lg"
              bg="#E6E6EB"
              border={'1px solid #A5AAAF'}
              // px={3}
              fontSize="xs"
              // py={{ base: 3.5, xl: 0 }}
              display="flex"
              alignItems="center"
              width="60px"
              height="56px"
              color={'#A5AAAF'}
            >
             <Text color={'#A5AAAF'} p={'12px'}>{selectedSliderValues[1]}</Text>
            </Box>
          </Flex>
          <Flex justifyContent={'center'} pb={2}>
            <RangeSlider
              aria-label={['min', 'max']}
              min={defaultSliderValues[0]}
              max={defaultSliderValues[1]}
              value={selectedSliderValues}
              onChange={handleSliderChange}
              marginTop={'12px'}
              marginBottom={'16px'}
              width="calc(100% - 16px)"
              colorScheme={'blackAlpha'}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} borderColor={'black'} boxSize= {'4'} />
              <RangeSliderThumb index={1} borderColor={'black'} boxSize= {'4'}/>
            </RangeSlider>
          </Flex>
        </>
      {/* </ChakraProvider> */}
    </Box>
  );
};

export default DualRangeSlider;
