/* eslint-disable no-console */
import { SubHeader, TextScript, useConfig, useTextScript } from '@MGPD/myasurion-shared';
import { Box, Button, Card, CardBody, Flex, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import Translationkeys from '../../Scripts/translationsKeys.json';
import airconCeilingMituImg from '../../icons/aircon_ceiling_mitusubhishi.jpg';
import airconCeilingMituHeavyImg from '../../icons/aircon_ceiling_mitusubhishi_heavy.jpg';
import washingMachineUpRight from '../../icons/washing_machine_upright.jpg';
import airconIndoorImg from '../../icons/aircon_indoor.jpg';
import airconIndoorDaikinImg from '../../icons/aircon_indoor_daikin.jpg';
import airconIndoorFujImg from '../../icons/aircon_indoor_fujitsu.png';
import airconIndoorFujNorImg from '../../icons/aircon_indoor_fujitsu_nocria.png';
import airconIndoorMituImg from '../../icons/aircon_indoor_mitusubhishi.jpg';
import airconIndoorTransImg from '../../icons/aircon_indoor_transformer.jpg';
import washingMachineImg from '../../icons/washing_machine.jpg';
import washingMachineDrum from '../../icons/washing_machine_drum.jpg';
import { useCentralStore } from '../../store/appContext';
import { CleaningDeviceModel } from '../../types/types';
import { getCurrencySymbol } from '../../utils/localization-helper';
import MainStackLayout from '../../layout/MainStackLayout';
import ActionTypes from '../../store/actionTypes';

function SelectDeviceModel() {
  const globalState = useCentralStore();
  const navigate = useNavigate();

  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;

  const { isTranslationsLoading, language } = useTextScript();

  const [currencySymbol, setCurrencySymbol] = useState('');

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [deviceModelList, setDeviceModelList] = useState<Array<CleaningDeviceModel>>();
  const [selectedModel, setSelectedModel] = useState<CleaningDeviceModel>();

  const SelectDeviceModelScripts = Scripts.CleaningService.SelectDeviceModel;
  const SelectDeviceModelTranslationkeys = Translationkeys.CleaningService.SelectDeviceModel;
  const pageName = SelectDeviceModelTranslationkeys.PageName;

  const modelImages: { [key: string]: string } = {
    'Separate air-conditioning indoor unit cleaning': airconIndoorImg,
    'Separate air-conditioning indoor unit cleaning (Transformers)': airconIndoorTransImg,
    'Separate air-conditioning indoor unit cleaning (Mitsubishi Heavy Industries)':
      airconIndoorMituImg,
    'Separate air-conditioning indoor unit cleaning (Fujitsu)': airconIndoorFujImg,
    'Separate air-conditioning indoor unit cleaning (Fujitsu nocria z series)':
      airconIndoorFujNorImg,
    'Ceiling-type air conditioner indoor unit cleaning (Daikin)': airconIndoorDaikinImg,
    'Ceiling type air conditioner indoor unit cleaning (Mitsubishi)': airconCeilingMituImg,
    'Ceiling-type air conditioner indoor unit cleaning (Mitsubishi Heavy Industries)':
      airconCeilingMituHeavyImg,
    'Window air conditioner': airconCeilingMituHeavyImg,
    'Upright washing machine cleaning': washingMachineImg,
    'Drum type washing machine cleaning': washingMachineDrum,
    'American upright washing machine cleaning': washingMachineUpRight,
  };

  useEffect(() => {
    if (globalState?.state?.selectedDeviceCategory) {
      // get device model based on category

      const categoryName = globalState?.state?.selectedDeviceCategory?.categoryName;

      const modelList: Array<CleaningDeviceModel> =
        globalState?.state?.deviceCategories?.GetCleaningServiceDeviceDetails[categoryName];
      const deviceModelList: Array<CleaningDeviceModel> = [];

      for (const model of modelList) {
        const deviceModel: CleaningDeviceModel = {
          DeviceCategoryDisplay: model?.DeviceCategory,
          DeviceCategory: model?.DeviceCategory,
          DeviceCategoryCN: model?.DeviceCategoryCN,
          image: '',
          localimage: getLocalImage(model?.AssetModelNbr),
          selected: isModelSelected(model?.DeviceTypeModel),
          DeviceTypeModel: model?.DeviceTypeModel,
          DeviceTypeModelPrice: model?.DeviceTypeModelPrice,
          PricewithDiscount: model?.PricewithDiscount,
          DiscountPrice: model?.DiscountPrice,
          key: model?.AssetModelNbr,
          CleaningServiceDevicesId: model?.CleaningServiceDevicesId,
          AssetCatalogId: model?.AssetCatalogId,
          AssetModelNbr: model?.AssetModelNbr,
          AssetCatalogDesc: model?.AssetCatalogDesc
        };

        deviceModelList.push(deviceModel);
      }

      setDeviceModelList(deviceModelList);
    }
  }, [globalState?.state?.selectedDeviceCategory]);

  useEffect(() => {
    if (deviceModelList?.length) setModel(deviceModelList);
  }, [deviceModelList]);

  useEffect(() => {
    getCurrency();
  }, [language, isTranslationsLoading]);

  const isModelSelected = (DeviceTypeModel: string) => {
    // pre select category if it is there in state
    if (DeviceTypeModel === globalState?.state?.selectedDeviceModel?.DeviceTypeModel) return true;

    return false;
  };

  const getCurrency = async () => {
    const currency = await getCurrencySymbol();
    setCurrencySymbol(currency);
  };

  const getLocalImage = (AssetModelNbr: string): string => {
    return modelImages[AssetModelNbr];
  };

  const onSubmit = () => {
    navigate('/setDeviceDetails', { replace: true });
  };

  const onCancelClick = () => {
    window.history.back();
  };

  const onBackClick = () => {
    navigate('/selectDeviceCateogry', { replace: true });
  };

  const onDeviceModelClick = (category: any) => {
    const selectedOptions = deviceModelList?.map((item: any) => {
      if (category === item.DeviceTypeModel) {
        item.selected = !item.selected;
      } else {
        item.selected = false;
      }
      return item;
    });

    setDeviceModelList(selectedOptions);

    setModel(selectedOptions);
  };

  const setModel = (selectedOptions?: Array<CleaningDeviceModel>) => {
    // get selected cateogry
    const selectedModel = selectedOptions?.find((a) => {
      return a.selected == true;
    });

    setSelectedModel(selectedModel);

    // set selected category state
    globalState?.dispatch({
      type: ActionTypes.SET_CLEANING_DEVICE_MODEL,
      payload: selectedModel,
    });

    // set button disable state
    setIsDisabled(selectedModel ? false : true);
  };

  return (
    <Box paddingTop={'54px'} w="full">
      <MainStackLayout gap={0} spacing={0}>
        <Flex w={{ base: 'full', lg: '80%' }} h={'100%'} margin="0 auto" direction={'column'}>
          <SubHeader
            backText={'Back'}
            allowBackButtonClick={false}
            showCancelButton={false}
            showBackButton={true}
            onClick={onBackClick}
            cancelText={Scripts.CancelSR.cancelBtnText}
            onCancelClick={onCancelClick}
          ></SubHeader>
          <Box px={4} mx={'auto'} w={{ base: 'full', lg: '60%' }}>
            <Text mt={'5'} fontSize={{ base: '2xl', lg: '3xl' }}>
              <TextScript
                text={SelectDeviceModelScripts.HeaderText}
                translationKey={`${pageName}/${SelectDeviceModelTranslationkeys.HeaderText}`}
              />
            </Text>

            <Text mt={'2'}>
              <TextScript
                text={SelectDeviceModelScripts.ModelTitle}
                translationKey={`${pageName}/${SelectDeviceModelTranslationkeys.ModelTitle}`}
              />
            </Text>

            <SimpleGrid
              mt={'4'}
              mb={{ base: '45%', lg: '25%' }}
              spacing={{ base: 2, md: 5, lg: 5 }}
              columns={{ base: 2, md: 3, lg: 3 }}
            >
              {deviceModelList?.map((val, index) => {
                return (
                  <center key={index}>
                    {/* <AspectRatio ratio={{ base: 2 / 3.5, md: 3/4, lg: 3 / 4 }}> */}
                      <Card
                        size={'sm'}
                        borderWidth={val?.selected ? '1px' : '1px'}
                        borderColor={val?.selected ? colors.bodyTextColor : '#A5AAAF'}
                        borderRadius={'8px'}
                        minHeight={{ base: '320px', lg: '300px' }}
                        onClick={() => {
                          onDeviceModelClick(val?.DeviceTypeModel);
                        }}
                        cursor={'pointer'}
                        _hover={{ bg: '#A5AAAF' }}
                      >
                        <CardBody py={'4'}>
                          <Image
                            my={'2'}
                            marginX={'auto'}
                            height={'100px'}
                            src={val?.image || val?.localimage}
                            alt="your model"
                          />
                          <Text>
                            <TextScript
                              text={val?.DeviceTypeModel}
                              translationKey={`${pageName}/${SelectDeviceModelTranslationkeys.Model}-${val?.key}`}
                            />
                          </Text>

                          <Text fontSize="xs" mt={'2'} color={'#808080'}>
                            <TextScript
                              text={val?.AssetCatalogDesc}
                              translationKey={`${pageName}/${SelectDeviceModelTranslationkeys.ModelDesc}-${val?.key}`}
                            />
                          </Text>
                        </CardBody>
                      </Card>
                    {/* </AspectRatio> */}
                  </center>
                );
              })}
            </SimpleGrid>
          </Box>
          <Box
            w={'full'}
            p={'16px'}
            bg={'white'}
            position={'fixed'}
            bottom={0}
            right={0}
            left={0}
            pb={{ base: '25%', lg: '2rem' }}
            zIndex={'3'}
            style={{ boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.08)' }}
          >
            <Flex
              w={{ base: 'full', lg: '80%' }}
              direction={'row'}
              justifyContent={'space-between'}
              mx={'auto'}
            >
              <Box>
                <Text fontSize={'14px'}>
                  <TextScript
                    text={SelectDeviceModelScripts.CleaingFeeText}
                    translationKey={`${pageName}/${SelectDeviceModelTranslationkeys.CleaingFeeText}`}
                  />
                </Text>
                {selectedModel && (
                  <Text lineHeight={'tall'} fontSize={'xl'} as={'b'}>
                    {`${currencySymbol}${selectedModel?.PricewithDiscount}`}
                  </Text>
                )}
              </Box>

              <Button
                width={'auto'}
                textAlign={'center'}
                colorScheme="primary"
                color={'white'}
                isDisabled={isDisabled}
                onClick={() => onSubmit()}
              >
                <TextScript
                  text={Scripts.Global.Continue}
                  translationKey={`${Translationkeys.Global.PageName}/${Translationkeys.Global.Continue}`}
                />
              </Button>
            </Flex>
          </Box>
        </Flex>
      </MainStackLayout>
    </Box>
  );
}

export default SelectDeviceModel;
