import Scripts from '../../Scripts/asurion-script.json';
import { CloseButton, Text } from '@chakra-ui/react'
import translationsKeys from '../../Scripts/translationsKeys.json'
import { TextScript } from '@MGPD/myasurion-shared';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogOverlay,
    Flex,
    useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { PropsWithChildren } from 'react';


interface popupProps {
    openPopup: boolean;
    closePopup: (args: boolean) => void;
}

const ExistingCategoryPopup: React.FC<PropsWithChildren<popupProps>> = ({
    openPopup,
    closePopup,
}) => {
    const cancelRef = React.useRef<any>();
    let { isOpen } = useDisclosure();

    isOpen = openPopup;
    const ExistinCategoryKeys = translationsKeys.ExistinCategory;
    
    const onClose = () => {
        closePopup(false);
    };

    return (
        <>
            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={() => onClose()}
                isOpen={isOpen}
                isCentered
                autoFocus={false}
            >
                <AlertDialogOverlay />
                <AlertDialogContent margin={'12px'}>
                    <AlertDialogBody paddingX={'12px'} paddingTop={'12px'} as={'b'} fontSize={'18px'}>
                        <Flex justifyContent={'flex-end'}><CloseButton onClick={() => onClose()} /></Flex>
                        <Text fontWeight={'700'}>
                            <TextScript
                                text={Scripts.ExistingCategory.HeaderText}
                                translationKey={`${ExistinCategoryKeys.PageName}/${ExistinCategoryKeys.HeaderText}`}
                            />
                        </Text>
                        <Text fontWeight={'normal'}>
                            <TextScript
                                text={Scripts.ExistingCategory.Info}
                                translationKey={`${ExistinCategoryKeys.PageName}/${ExistinCategoryKeys.Info}`}
                            />
                        </Text>
                    </AlertDialogBody>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};

export default ExistingCategoryPopup;
