import {
  currentAgreementKey,
  nightDeliveryFeeKey,
  serviceFeeKey,
  getaspstorelistResponseKey,
  InWarrantyDataKey,
  shippingMethodResponseKey
} from '../../modules/config/constants';
import {
  getappointmentslotsApi,
  getscheduledavailabilityTOMApi,
  getaspstorelistTOMAPi,
  getaspstorelistAPi,
  shippingmethodsv1Api,
  shippingmethodsv2Api,
  shippingmethodsv3Api,
  TCATAddressParse,
} from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { getPickupSlot, getReturnSlot, isClaimWithinCutoff } from '../../utils/helper';
import { getAddress, getDateAndDay, getTime, isAddressEmpty, isAfter4PMSlot, isBatteryReplacement, isDelivery, isDeviceRefresh, isDeviceRepair, isMalfunction, isPUR, isReplacement, isScreenRepair, isScreenRepairBatReplacement, isScreenRepairWarranty, isSwap, isWalkIn, saveError, showErrorPage } from '../../utils/utills';
import { PickupSchedule, TextScript, useSessionStorage } from '@MGPD/myasurion-shared';
import { Input } from '@chakra-ui/input';
import { Box, Text } from '@chakra-ui/layout';
import moment from 'moment';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import AddressLinePopup from './AddressLinePopup';
import Scripts from '../../Scripts/asurion-script.json'
import translationKeys from '../../Scripts/translationsKeys.json'
import { getGlobalDateFormat } from '../../utils/localization-helper';
import { FULLFILLMENT_TYPES, WARRANTY_TYPE } from '../../utils/constant';
import { callShippingMethodv1, getAlternateMobileLength, getDeliverySlot, hasTCAT } from '../../utils/featuresUtills';
import { AddressLinePopupProps } from '../../types/types';

export interface shippingMethodContent {
  EstimatedDeliveryDate: string;
  DeliverySlots: string[];
}

interface ShippingMethod {
  pickUpDate: string;
  deliveryDate: string;
}

interface EditDetailsProps {
  deliveryAddress: string;
  deliveryDate: string;
  deliveryTime: string;
  pickupAddress: any;
  pickupDate: string;
  pickupTime: any;
  returnAddress: any;
  returnDate: string;
  returnTime: string;
  showDeliveryDeatils: boolean;
  showCollectionDetails: boolean;
  showAppointmentDetails: boolean;
  showPickupDeatils: boolean;
  showReturnDetails: boolean;
  isWalking: boolean;
  claimData: any;
  isPickupEditable: boolean,
  isReturnEditable: boolean,
  alternateNumber: string,
  isAlternateNumberEditable: boolean;
}

const EditDetails: React.FC<PropsWithChildren<EditDetailsProps>> = ({
  pickupAddress,
  pickupDate,
  pickupTime,
  deliveryAddress,
  deliveryDate,
  deliveryTime,
  returnAddress,
  returnDate,
  returnTime,
  showDeliveryDeatils,
  showCollectionDetails,
  showAppointmentDetails,
  showPickupDeatils,
  showReturnDetails,
  isWalking,
  claimData,
  isPickupEditable,
  isReturnEditable,
  alternateNumber,
  isAlternateNumberEditable
}) => {
  // Delivery and Walk-In
  const [updatedDeliveryDate, setUpdatedDeliveryDate] = useState('');
  const [updatedDeliveryTime, setUpdatedDeliveryTime] = useState('');
  const [deliveryAdd, setDeliveryAdd] = useState(deliveryAddress);
  const [, setNighDeliverytFees] = useSessionStorage<any>(nightDeliveryFeeKey, null);
  const [servicefeeList] = useSessionStorage<any>(serviceFeeKey, null);
  const [, setgetaspstorelistAPiResponse] = useSessionStorage<any>(
    getaspstorelistResponseKey,
    null
  );
  const [InWarrantyData] = useSessionStorage<any>(InWarrantyDataKey, null);
  const [, setStoreList] = useState([]);

  const [updatedAlternateContactNumber, setAlternateContactNumber] = useState(alternateNumber);
  const [invalidMobile, setInvalidMobile] = useState(false);

  // Pick-up
  const [updatedPickupDate, setUpdatedPickupDate] = useState('');
  const [updatedPickupTime, setUpdatedPickupTime] = useState('');
  const [pickupAdd, setPickupAdd] = useState(getAddress(pickupAddress));

  // Return
  const [updatedReturnDate, setUpdatedReturnDate] = useState('');
  const [updatedReturnTime, setUpdatedReturnTime] = useState('');
  const [returnAdd, setReturnAdd] = useState(!isAddressEmpty(returnAddress) ? getAddress(returnAddress) : '');

  const [shippingMethodResponse, setShippingMethodRes] = useState<any>();
  const [, setShippingMethod] = useSessionStorage<any>(shippingMethodResponseKey, null);
  const [openAddressPopup, setOpenAddressPopup] = useState<AddressLinePopupProps>();
  const [find_agreement] = useSessionStorage<any>(currentAgreementKey, null);
  const [isPickup, setIsPickup] = useState<boolean>(false);
  const [shppingMethodV2Return, setShippingMethodV2Return] = useState<any>();
  const [dateFormat, setDateFormat] = useState<string>('');

  const globalState: any = useCentralStore();
  const navigate = useNavigate();
  const interactionData = globalState?.state?.sessionResponse?.Interaction;
  const _locationCoordinates = globalState?.state?.locationCoordinates;
  // const assetData1 = find_agreement?.Assets?.Asset?.filter(
  //   (a: any) => a?.AssetInstance === 'ENROLLED'
  // )?.[0];

  let assetData1 =  find_agreement?.Assets?.Asset?.filter(
    (asset: any) => asset?.AssetInstance === 'ENROLLED'
  )?.[0];
    if(assetData1 === undefined) {
      assetData1 = find_agreement?.Assets?.Asset?.filter(
        (asset: any) => asset?.AssetInstance === 'REPLACED'
      )?.[0];
    }

  const Make = assetData1?.Make?.Name?.toUpperCase();
  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };
 
  const initializeData = globalState?.state?.sessionResponse?.InitializeResponse;
  const updateddeliveryaddress = claimData?.OutboundShippingOrder?.Address;
  const isTOM = initializeData?.ClientName === 'Asurion_TechCare';

  const serviceRequest = claimData?.ServiceRequests?.[0];
  const incidentType = claimData?.IncidentType;
  const hasDTAddOnAcquired = claimData?.IsDataTransfer;
  const isIW_OEM = claimData?.WarrantyType === WARRANTY_TYPE.IW_OEM;
  const isEWSClaim = isMalfunction(incidentType) && claimData?.WarrantyType === WARRANTY_TYPE.HI;

  const isRepairRefresh = (isDeviceRefresh(incidentType) ||
  isDeviceRepair(incidentType) ||
  isBatteryReplacement(incidentType) ||
  isScreenRepair(incidentType) ||
  isScreenRepairBatReplacement(incidentType) ||
  isScreenRepairWarranty(incidentType) ||
  (isMalfunction(incidentType) && isIW_OEM)) ||
  isEWSClaim;

  let validLength = getAlternateMobileLength(find_agreement?.ClientOffer?.ClientOfferName);

  const updateWalkinDateTime = (date: string, time: string) => {
    let selectedTime = time === '8am - 4pm:8-16' && getDeliverySlot(find_agreement?.ClientOffer?.ClientOfferName) ? time = 'In 2 Hours' : time;
    const formattedTime = selectedTime.includes(':')? selectedTime.split(':')[0]: selectedTime;
    setUpdatedDeliveryDate(date);
    setUpdatedDeliveryTime(formattedTime);

    globalState?.dispatch({ type: ActionTypes.SET_DELIVERY_DATE, payload: date });
  };

  const srDeliveryType = () => {

    if(isRepairRefresh)
      return serviceRequest?.FulfillmentOption;
    else
      return claimData?.ISSGWalkin === false ? FULLFILLMENT_TYPES.DELIVERY : FULLFILLMENT_TYPES.WALKIN;

    /*if (
      (isSwap(incidentType) || 
        isReplacement(incidentType) ||
        isMalfunction(incidentType)) &&
      claimData?.ISSGWalkin === false
    ) {
      return 'Delivery';
    } else if (
      (isDeviceRefresh(incidentType) ||
        isDeviceRepair(incidentType) ||
        isBatteryReplacement(incidentType)) &&
      serviceRequest?.FulfillmentOption?.toUpperCase() === 'PUR'
    ) {
      return 'PUR';
    } else {
      return 'Walk-in';
    }*/
  };

  const getFullfillmentMode = () => {
    if (isDeviceRepair(incidentType)) {
      if (serviceRequest?.FulfillmentOption?.toUpperCase() === 'PUR') {
        return 'pickup';
      } else {
        return 'delivery';
      }
    } else {
      return 'pickup';
    }
  };

  useEffect(() => {
    getDateFormat();
    if (
      (isSwap(incidentType) || 
        isReplacement(incidentType) ||
        (isMalfunction(incidentType) && !isIW_OEM && !isEWSClaim))  &&
      claimData?.ISSGWalkin === false
    ) {
      callshippingmethodapi();
    }else if(isSwap(incidentType) && globalState?.state?.isMaxis && isWalking ){
      callgetaspStorelistApi()
    } else if ( isRepairRefresh &&
      serviceRequest?.FulfillmentOption?.toUpperCase() === 'PUR'
    ) {
      // if (isTOM /*|| globalState?.state?.isMaxis*/) {
        if(showPickupDeatils && isPickupEditable)
          callshippingmethodV2Api('');
        else if(showReturnDetails && isReturnEditable 
          && hasTCAT(find_agreement?.ClientOffer?.ClientOfferName)) {

          if(!isAddressEmpty(returnAddress)){
            // if return address is there then
            // call address parse API
            callTCATAddressParseAPI(returnAddress)
          } else {
            // open Address Line PopUp
             setOpenAddressPopup({open: true, addressFieldName: 'returnAdd'});
          }
        }

      // } else {
      //   callshippingmethodV2Api();
      //   callshippingmethodV2ApiForReturn(
      //     updatedPickupDate || moment(pickupDate, 'DD MMMM YYYY, dddd')?.format('YYYY-MM-DD'),
      //     updatedPickupTime || pickupTime
      //   );
      // }
    } else if (isWalking) {
      callgetschedule(serviceRequest?.RepairRequest?.SERVICE_PROVIDER_ID);
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    if(alternateNumber && isAlternateNumberEditable){
      globalState?.dispatch({ type: ActionTypes.SET_ALTERNATE_CONTACT_NUMBER, payload: alternateNumber });
    }
  }, [alternateNumber])
  
  const callshippingmethodV2Api = (__addr: any) => {
    setLoading(true);

    const isMaxis = globalState?.state?.isMaxis;
    const pickUpAddress = globalState?.state?.pickUpAddress;

    shippingmethodsv2Api(
      {
        LookupShippingMethodsParameters: {
          ServiceRequestId: serviceRequest.ServiceRequestId,
          ReceiverAddress: {
            Address1:  isMaxis ? pickUpAddress?.Address1 : find_agreement?.Address?.Address1,
            Address2: isMaxis ? pickUpAddress?.Address2 : find_agreement?.Address?.Address2,
            Address3: isMaxis ? pickUpAddress?.Address3 : find_agreement?.Address?.Address3,
            City: isMaxis ? pickUpAddress?.City : find_agreement?.Address?.City,
            StateProvinceCode: isMaxis ? pickUpAddress?.StateProvinceCode : find_agreement?.Address?.StateProvinceCode,
            CountryCode: isMaxis ? pickUpAddress?.CountryCode : find_agreement?.Address?.CountryCode,
            PostalCode: isMaxis ? pickUpAddress?.PostalCode : find_agreement?.Address?.PostalCode,
            LocationType: (isTOM || globalState?.state?.isMaxis) ? 'Residential' : undefined,
            Simulation: (isTOM || globalState?.state?.isMaxis) ? true : undefined,
          },
          Mode: getFullfillmentMode(),
          getDeliverySlots: true,
        },
      },
      interactionData?.InteractionLine?.InteractionLineId,
      globalState?.state?.findAgreementSessionData
    )
      .then((data) => {
        setLoading(false);
        const pickUpShippingMethodResults = data?.ShippingMethodResults;
        const slots = getPickupSlot();
        const shipingMethods = pickUpShippingMethodResults.map((item: any, index: number) => {
          const claimWithinCutoff = isClaimWithinCutoff(initializeData?.TimeZone);
          const estimatedDeliveryDate = item?.pickUpDate;

          let deliverySlots;
          if (index === 0) {
            // For first date,if claim filed before 2 PM, then `slots`, else `slots[1]`
            deliverySlots = (isTOM || globalState?.state?.isMaxis) && claimWithinCutoff ? slots : [slots[1]];
          } else {
            deliverySlots = slots;
          }

          return {
            EstimatedDeliveryDate: estimatedDeliveryDate,
            DeliverySlots: item?.pickUpSlot || deliverySlots,
          };
        });

        globalState?.dispatch({
          type: ActionTypes.SET_RAW_SHPPINGMETHOD_RES,
          payload: pickUpShippingMethodResults,
        });
        const _shipingMethods = shipingMethods?.filter((d: any) => d?.DeliverySlots?.length > 0);
        setShippingMethodRes(_shipingMethods);
        setUpdatedPickupDate(moment(pickupDate, 'DD MMMM YYYY, dddd')?.format('YYYY-MM-DD') || _shipingMethods?.[0]?.EstimatedDeliveryDate)
        // setUpdatedPickupTime(shipingMethods?.[0]?.DeliverySlots?.[0])
        if (isTOM /*|| globalState?.state?.isMaxis*/) {
          let formatDate = moment(pickupDate, 'DD MMMM YYYY, dddd')?.format('YYYY-MM-DD');
          const givenPickupDate = new Date(formatDate);
          const R_slots = getReturnSlot();
          const returnSlots = pickUpShippingMethodResults
            ?.filter((item: any) => new Date(item?.pickUpDate) >= givenPickupDate)
            .map((item: ShippingMethod | undefined) => ({
              EstimatedDeliveryDate: item?.deliveryDate,
              DeliverySlots: R_slots,
            }));
          setShippingMethodV2Return(returnSlots);
        } /*else {
          callshippingmethodV2ApiForReturn(
            updatedPickupDate || moment(pickupDate, 'DD MMMM YYYY, dddd')?.format('YYYY-MM-DD'),
            updatedPickupTime || pickupTime,
            __addr
          );
        }*/
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const callshippingmethodV2ApiForReturn = ( __addr: any, date?: string, time?: string) => {
    // return new Promise((resolve, reject) => {
      setLoading(true);
      shippingmethodsv2Api(
        {
          LookupShippingMethodsParameters: {
            ServiceRequestId: serviceRequest.ServiceRequestId,
            ReceiverAddress: {
              Address1: __addr?.Address1 || find_agreement?.Address?.Address1,
              Address2: __addr?.Address2 || find_agreement?.Address?.Address2,
              Address3: __addr?.Address3 || find_agreement?.Address?.Address3,
              City: __addr?.City || find_agreement?.Address?.City,
              StateProvinceCode: __addr?.StateProvinceCode || find_agreement?.Address?.StateProvinceCode,
              CountryCode: __addr?.CountryCode || find_agreement?.Address?.CountryCode,
              PostalCode: __addr?.PostalCode || find_agreement?.Address?.PostalCode,
            },
            Mode: 'delivery',
            PickUpDate: date,
            PickUpSlot: time,
            getDeliverySlots: true,
          },
        },
        interactionData?.InteractionLine?.InteractionLineId,
        globalState?.state?.findAgreementSessionData
      )
        .then((data) => {
          setLoading(false);
          if (data) {
            const pickUpShippingMethodResults = data?.ShippingMethodResults;
            const shipingMethods = pickUpShippingMethodResults?.map((item: any) => ({
              EstimatedDeliveryDate: item?.deliveryDate,
              DeliverySlots: item?.deliverySlot,
            }))?.filter((d: any) => d?.DeliverySlots?.length > 0);
            setShippingMethodV2Return(shipingMethods);

            const date = shipingMethods?.[0]?.EstimatedDeliveryDate;
            const timeSlot = shipingMethods?.[0]?.DeliverySlots?.[0];

            const parsedReturnDate = moment(returnDate, 'DD MMMM YYYY, dddd')?.format('YYYY-MM-DD');
            const isExistingReturnDateInList = allowDatePreselect(shipingMethods, parsedReturnDate);

            setUpdatedReturnDate((returnDate && returnDate?.length > 0 && isExistingReturnDateInList) ? parsedReturnDate : date);
            setUpdatedReturnTime((returnTime && returnTime?.length > 0 && isExistingReturnDateInList) ? returnTime : timeSlot);
            // resolve(shipingMethods);

            globalState?.dispatch({ type: ActionTypes.SET_RETURN_DATE, payload: date });
            globalState?.dispatch({ type: ActionTypes.SET_RETURN_TIME, payload: timeSlot });

          } else {
            // reject('No data received from API');
          }
        })
        .catch((error) => {
          setLoading(false);
          saveError(error, globalState);
          showErrorPage(navigate);
          // reject(error);
        });
    // });
  };

  const applyShippingMethodFilters = (response: any) => {
    if ((isTOM) && response?.length) {
      const claimWithinCutoff = isClaimWithinCutoff(initializeData?.TimeZone);
      response
        .filter((d: any) => !d.ShippingMethodType?.includes('SUR'))
        .map((r: any) => {
          r.DeliverySlots = [r.ShippingMethodType !== 'Same Day' ? '19' : claimWithinCutoff ? '20' : '17'];
          return r;
        });
    }else if(globalState?.state?.isMaxis &&  response.length){
      return response
      .filter((d: any) => !d.ShippingMethodType?.includes('SUR'))
      .map((r: any) => {
        r.DeliverySlots = [r.ShippingMethodType !== 'Same Day' ? '18'  : '6'];
        return r;
      });
    }
    return response?.filter((d: any) => d?.DeliverySlots?.length > 0);
  }

  const callshippingmethodapi = (addr?: any) => {
    const isShippingMethodV1 = callShippingMethodv1(incidentType, find_agreement?.ClientOffer?.ClientOfferName)
    setLoading(true);
    // if (isTOM || globalState?.state?.isMaxis) {
      if(isShippingMethodV1) {
      const address = addr ? addr : updateddeliveryaddress;
      shippingmethodsv1Api(
        {
          LookupShippingMethodsParameters: {
            ServiceRequestId: serviceRequest.ServiceRequestId,
            ReceiverAddress: {
              Address1: address?.Address1 || find_agreement?.Address?.Address1,
              Address2: address?.Address2 || find_agreement?.Address?.Address2,
              Address3: address?.Address3 || find_agreement?.Address?.Address3,
              City: address?.City || find_agreement?.Address?.City,
              StateProvinceCode:
                address?.StateProvinceCode || find_agreement?.Address?.StateProvinceCode,
              CountryCode: find_agreement?.Address?.CountryCode,
              PostalCode: address?.PostalCode || find_agreement?.Address?.PostalCode,
              LocationType: 'Residential',
            },
            CustomerType: 'RETAIL',
            Warehouse: 'BKK',
            Simulation: true,
            getDeliverySlots: getDeliverySlot(find_agreement?.ClientOffer?.ClientOfferName)? true: false,
            AlternateDays: 7,
          },
          ServiceOrderId: serviceRequest?.ServiceOrder?.ServiceOrderId,
          InteractionLineId: interactionData?.InteractionLine?.InteractionLineId
        },
        serviceRequest?.ServiceOrder?.ServiceOrderId,
        interactionData?.InteractionLine?.InteractionLineId,
        globalState?.state?.findAgreementSessionData
      )
        .then((data) => {
          setLoading(false);
          // let ShippingMethodResults = data?.ShippingMethod;
          // const claimWithinCutoff = isClaimWithinCutoff(initializeData?.TimeZone);
          // ShippingMethodResults = ShippingMethodResults?.filter(
          //   (d: any) => !d.ShippingMethodType.includes('SUR')
          // )?.map((r: any) => {
          //   r.DeliverySlots = [
          //     r.ShippingMethodType !== 'Same Day' ? '19' : claimWithinCutoff ? '20' : '17',
          //   ];
          //   return r;
          // });
          // setShippingMethodRes(ShippingMethodResults);
          // setShippingMethod(ShippingMethodResults);
          // updateWalkinDateTime(
          //   ShippingMethodResults?.[0]?.EstimatedDeliveryDate,
          //   ShippingMethodResults?.[0]?.DeliverySlots?.[0]
          // );
          let ShippingMethodResults = data?.ShippingMethod;
          let _shippingMethodResponse = applyShippingMethodFilters(ShippingMethodResults)

          setShippingMethodRes(_shippingMethodResponse);
          setShippingMethod(_shippingMethodResponse);
          updateWalkinDateTime(
            _shippingMethodResponse?.[0]?.EstimatedDeliveryDate,
            _shippingMethodResponse?.[0]?.DeliverySlots?.[0]
          );

        })
        .catch((error) => {
          setLoading(false);
          console.error('error', error);
          saveError(error, globalState);
          showErrorPage(navigate);
        });
    } else {
      // for Swap and replaacement call V3
      shippingmethodsv3Api(
        {
          LookupShippingMethodsParameters: {
            ServiceRequestId: serviceRequest.ServiceRequestId,
            getDeliverySlots: true,
            AlternateDays: 7,
            ServiceOrderId: serviceRequest?.ServiceOrder?.ServiceOrderId,
            InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
          },
        },
        interactionData?.InteractionLine?.InteractionLineId,
        globalState?.state?.findAgreementSessionData
      )
        .then((data: any) => {
          setLoading(false);
          if (claimData?.WarrantyType?.toUpperCase() === WARRANTY_TYPE.IW) {
            let NightDeliverySlot = data?.ShippingMethod?.map((a: any) => {
              return a.DeliverySlots.pop('7pm - 10pm:19-22');
            });
            console.log(NightDeliverySlot);
            let ShippingMethodResults = data?.ShippingMethod;
            setShippingMethodRes(ShippingMethodResults);
          } else {
            let ShippingMethodResults = data?.ShippingMethod;
            setShippingMethodRes(ShippingMethodResults);
          }
        })
        .catch((error: any) => {
          setLoading(false);
          console.error('error', error);
        });
    }
  };

  const {refetch: callgetaspStorelistApi} = useQuery(
    ['getaspstorelistData'],
    async () => {
      setLoading(true);
      if(isTOM || globalState?.state?.isMaxis){
        return await getaspstorelistTOMAPi(
          _locationCoordinates?.lat,
          _locationCoordinates?.lng,
          Make,
           serviceRequest.ServiceRequestId,
          interactionData?.InteractionLine?.InteractionLineId,
          globalState?.state?.findAgreementSessionData
        ); 
      } else {
      return await getaspstorelistAPi(
        serviceRequest.ServiceRequestId,
        incidentType,
        interactionData?.InteractionLine?.InteractionLineId,
        globalState?.state?.findAgreementSessionData
      );
      }
    },
    {
      enabled: false,
      onSuccess: (data: any) => {
        setLoading(false);
        const resp = data?.GetASPListResponse;
        setgetaspstorelistAPiResponse(resp);
        handleStoreChange(resp);
      },
      onError: (error) => {
        console.error('error ala', error);
        saveError(error, globalState);
        showErrorPage(navigate)
      },
    }
  );

  const callgetschedule = (storeId: any) => {
    setLoading(true);
    if (isTOM || globalState?.state?.isMaxis) {
      getscheduledavailabilityTOMApi(
        {
          GetScheduledAvailabilityReq: {
            CustomerCaseId: claimData?.CustomerCaseId,
            Department: 'AOC - TLC',
            ServiceProviderId: storeId,
            ServiceRequestId: serviceRequest.ServiceRequestId,
          },
        },
        interactionData?.InteractionLine?.InteractionLineId,
        globalState?.state?.findAgreementSessionData
      )
        .then((data) => {
          setLoading(false);
          const res = data?.availabilityData;
          const shipingMethods: shippingMethodContent[] = res.map((item: any) => ({
            EstimatedDeliveryDate: item.date,
            DeliverySlots: item.startTime,
          }));
          shipingMethods?.forEach((item) => {
            return (item.DeliverySlots = item?.DeliverySlots.map((time) =>
              moment(time, 'HH:mm:ss').locale('en-US').format('hh:mm A')
            ));
          });
          if (hasDTAddOnAcquired) {
            shipingMethods.map((item: any) => {
              let slots = item?.DeliverySlots?.filter((slot: any) => {
                if (isAfter4PMSlot(slot)) return false;
                return true;
              });
              item.DeliverySlots = slots;
            });
          }
          setShippingMethodRes(shipingMethods);
        })
        .catch((error) => {
          setLoading(false);
          saveError(error, globalState);
          showErrorPage(navigate);
        });
    } else {
      getappointmentslotsApi(
        {
          AppointmentSlotsRequest: {
            ServiceRequestId: serviceRequest.ServiceRequestId,
            ServiceProviderId: storeId,
          },
        },
        interactionData?.InteractionLine?.InteractionLineId,
        globalState?.state?.findAgreementSessionData
      )
        .then((data) => {
          setLoading(false);
          const res = data?.availabilityData;
          const shipingMethods = res.map((item: any) => ({
            EstimatedDeliveryDate: item.date,
            DeliverySlots: item.startTime,
          }))?.filter((d: any) => d?.DeliverySlots?.length > 0);
          setShippingMethodRes(shipingMethods);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onAddressInputClick = (args: boolean, fieldName: any) => {
    setIsPickup(args);
    setOpenAddressPopup({open: true, addressFieldName: fieldName});
  };

  const allowDatePreselect = (shipingMethods: any, returnDate: string) => {

    let shipingMethod =  shipingMethods?.find((shipingMethod: any) => {
      return shipingMethod?.EstimatedDeliveryDate === returnDate;
    })

    return shipingMethod ? true : false;
  }

  useEffect(() => {
    if (updatedPickupDate || updatedPickupTime) {
      if (isTOM /*|| globalState?.state?.isMaxis*/) {
        const givenPickupDate = new Date(updatedPickupDate);
        const slots = getReturnSlot();
        const returnSlots = globalState?.state?.shippingMethodRes
          ?.filter((item: any) => new Date(item?.pickUpDate) >= givenPickupDate)
          .map((item: ShippingMethod | undefined) => ({
            EstimatedDeliveryDate: item?.deliveryDate,
            DeliverySlots: slots,
          }));
        setShippingMethodV2Return(returnSlots);
        const date = returnSlots?.[0]?.EstimatedDeliveryDate;
        const timeSlot = returnSlots?.[0]?.DeliverySlots?.[0];
        setUpdatedReturnDate(date);
        setUpdatedReturnTime(timeSlot);
        globalState?.dispatch({ type: ActionTypes.SET_RETURN_DATE, payload: date });
        globalState?.dispatch({ type: ActionTypes.SET_RETURN_TIME, payload: timeSlot });
      } else {
        if(showReturnDetails && isReturnEditable)
          callshippingmethodV2ApiForReturn(
            globalState?.state?.pickUpAddress,
            updatedPickupDate || moment(pickupDate, 'DD MMMM YYYY, dddd')?.format('YYYY-MM-DD'),
            updatedPickupTime || pickupTime
          )
            // .then((data: any) => {
            //   const date = data?.[0]?.EstimatedDeliveryDate;
            //   const timeSlot = data?.[0]?.DeliverySlots?.[0];
            //   setUpdatedReturnDate(moment(returnDate, 'DD MMMM YYYY, dddd')?.format('YYYY-MM-DD') || date);
            //   setUpdatedReturnTime(timeSlot);
            //   globalState?.dispatch({ type: ActionTypes.SET_RETURN_DATE, payload: date });
            //   globalState?.dispatch({ type: ActionTypes.SET_RETURN_TIME, payload: timeSlot });
            // })
            // .catch((error) => {
            //   console.log('shippingmethodV2error:', error);
            // });
      }
    } else {
      return;
    }
  }, [updatedPickupDate, updatedPickupTime]);

  useEffect(() => {
    if (isDelivery(srDeliveryType())) {
      const deliveryaddress = globalState?.state?.deliveryAddress;

      const concatDeliveryAdd =
        Object?.keys(deliveryaddress)?.length > 0 &&
        `${deliveryaddress?.Address1} ${deliveryaddress?.Address2} ${
          deliveryaddress?.Address3 || ''
        } ${deliveryaddress?.StateProvinceCode} ${deliveryaddress?.PostalCode}`;
      {
        concatDeliveryAdd && setDeliveryAdd(concatDeliveryAdd);
      }
    } else if (isPUR(srDeliveryType())) {
      const pickUpaddress = globalState?.state?.pickUpAddress;
      const returnAddress = globalState?.state?.returnAddress;

      const concatPickupAdd =
      Object?.keys(pickUpaddress)?.length > 0 && pickUpaddress.Address1 ?
      `${pickUpaddress?.Address1} ${pickUpaddress?.Address2} ${pickUpaddress?.Address3 || ''} ${pickUpaddress?.StateProvinceCode} ${
        pickUpaddress?.PostalCode
      }`: pickUpaddress;

      const concatReturnAdd =

        Object?.keys(returnAddress)?.length > 0 && returnAddress.Address1 ?
        `${returnAddress?.Address1} ${returnAddress?.Address2} ${returnAddress?.Address3 || ''} ${returnAddress?.StateProvinceCode || ''} ${
          returnAddress?.PostalCode
        }`: returnAddress;
      {
        concatPickupAdd && setPickupAdd(concatPickupAdd);
      }
      {
        concatReturnAdd && setReturnAdd(concatReturnAdd);
        concatReturnAdd && callshippingmethodV2ApiForReturn(
          returnAddress, 
          updatedPickupDate || moment(pickupDate, 'DD MMMM YYYY, dddd')?.format('YYYY-MM-DD'),
          updatedPickupTime || pickupTime)
      }
    } else if (isWalkIn(srDeliveryType())) {
      const deliveryaddress = globalState?.state?.deliveryAddress;
      const concatDeliveryAdd =
        Object?.keys(deliveryaddress)?.length > 0 &&
        `${deliveryaddress?.Address1} ${deliveryaddress?.PostalCode}`;
      {
        concatDeliveryAdd && setDeliveryAdd(concatDeliveryAdd);
      }
    }
  }, [globalState.state?.pickUpAddress, globalState.state?.deliveryAddress, globalState.state?.returnAddress]);

  const handleStoreChange = (reponse: any) => {
    if (InWarrantyData && InWarrantyData?.ServiceProviderName) {
      // for IW claim show only last claim store details here
      let storeList = reponse?.filter(
        (val: any) => val.RepairStoreName === InWarrantyData?.ServiceProviderName
      );
      setStoreList(storeList);
    } else {
      setStoreList(reponse);
    }
  };

  const onDateSelect = (date: string) => {
    // const parsedDate = moment(date, 'DD MMMM YYYY, dddd');
    // const formattedDate = parsedDate.format('YYYY-MM-DD');

    setUpdatedDeliveryDate(date);
    globalState?.dispatch({ type: ActionTypes.SET_DELIVERY_DATE, payload: date });
  };

  const onTimeSlotSelect = (timeSlot: string) => {
    let selectedTimeSlot;
    if ("8am - 4pm:8-16" === timeSlot && getDeliverySlot(find_agreement?.ClientOffer?.ClientOfferName)) {
      selectedTimeSlot = 'In 2 Hours:2H';
   }else{
    selectedTimeSlot = timeSlot;
   }
   const  formattedTime = selectedTimeSlot.includes(':')? selectedTimeSlot.split(':')[0]: selectedTimeSlot;
    setUpdatedDeliveryTime(formattedTime);
    globalState?.dispatch({ type: ActionTypes.SET_DELIVERY_TIME, payload:("8am - 4pm:8-16" === timeSlot && getDeliverySlot(find_agreement?.ClientOffer?.ClientOfferName))? selectedTimeSlot :timeSlot });
    globalState?.dispatch({
      type: ActionTypes.SET_DELIVERY_TIME_CODE,
      payload: ("8am - 4pm:8-16" === timeSlot && getDeliverySlot(find_agreement?.ClientOffer?.ClientOfferName))? selectedTimeSlot?.split(':')?.[1] : timeSlot?.split(':')?.[1],
    });
  };

  const onPickupDateSelect = (date: string) => {
    // callshippingmethodV2ApiForReturn(date ,(updatedPickupTime || pickupTime))
    setUpdatedPickupDate(date);
    globalState?.dispatch({ type: ActionTypes.SET_PICKUP_DATE, payload: date });
  };

  const onPickupTimeSlotSelect = (timeSlot: string) => {
    //  callshippingmethodV2ApiForReturn(updatedPickupDate || moment(pickupDate, 'DD MMMM YYYY, dddd')?.format('YYYY-MM-DD') ,timeSlot);
    setUpdatedPickupTime(timeSlot);
    globalState?.dispatch({ type: ActionTypes.SET_PICKUP_TIME, payload: timeSlot });
  };

  const onReturnDateSelect = (date: string) => {
    setUpdatedReturnDate(date);
    globalState?.dispatch({ type: ActionTypes.SET_RETURN_DATE, payload: date });
  };

  const onReturnTimeSlotSelect = (timeSlot: string) => {
    setUpdatedReturnTime(timeSlot);
    globalState?.dispatch({ type: ActionTypes.SET_RETURN_TIME, payload: timeSlot });
  };

  const handleAlternateContactChange = (e:any) => {
    const { value } = e.target;
    setAlternateContactNumber(value);

    let reg = new RegExp('^[\\d]{'+validLength+'}$', 'g');
    if (reg.test(value)) {
      setInvalidMobile(false);
      globalState?.dispatch({ type: ActionTypes.SET_ALTERNATE_CONTACT_NUMBER, payload: value });
    } else if (value.trim() === '') {
      setInvalidMobile(false);
      globalState?.dispatch({ type: ActionTypes.SET_ALTERNATE_CONTACT_NUMBER, payload: value });
    } else {
      setInvalidMobile(true);
      globalState?.dispatch({ type: ActionTypes.SET_ALTERNATE_CONTACT_NUMBER, payload: '' });
    }
  };

  const nightDeliveryFee = servicefeeList?.filter(
    (item: any) => item?.IncidentType === 'NIGHT DELIVERY FEE'
  )?.[0]?.Fees?.TotalAmount;

  useEffect(() => {
    checkIfNightFeeApplicable();
  }, [updatedDeliveryTime]);

  const checkIfNightFeeApplicable = () => {
    if (['7pm - 10pm:19-22'].includes(updatedDeliveryTime)) {
      setNighDeliverytFees(nightDeliveryFee);
      globalState?.dispatch({
        type: ActionTypes.SET_DELIVERY_NIGHT_FEE,
        payload: nightDeliveryFee,
      });
      globalState?.dispatch({
        type: ActionTypes.CHARGE_NIGHT_DELIVERY_FEE,
        payload: true,
      });
    } else {
      setNighDeliverytFees(null);
      globalState?.dispatch({
        type: ActionTypes.SET_DELIVERY_NIGHT_FEE,
        payload: null,
      });
      globalState?.dispatch({
        type: ActionTypes.CHARGE_NIGHT_DELIVERY_FEE,
        payload: false,
      });
    }
  };

  const getLabel = () => {
    if (showCollectionDetails) {
      return ['Collection Address', 'Collection Date', 'Collection Time'];
    } else if (showAppointmentDetails) {
      return ['Repair Centre Location', 'Appointment Date', 'Appointment Time'];
    } else {
      return ['Delivery Address', 'Delivery Date', 'Delivery Time'];
    }
  };

  const getDateFormat = async () => {
    const format = await getGlobalDateFormat();
    setDateFormat(format);
  }

  const callTCATAddressParseAPI = (address: any, handleAddNewAddressFlow?: any) => {
    setLoading(true);

    const payload = {
      ParseAddressRequest: {
        CustomerCaseId: claimData?.CustomerCaseId,
        ServiceRequestId: serviceRequest.ServiceRequestId,
        PostalCode: address?.PostalCode,// "115 南港區",
        State: address?.StateProvinceCode,// "大安區",
        City: address?.City,//"台北市",
        AddressLine1: address?.Address1,// "南港路三段50巷6號3樓"
      }
    }

    TCATAddressParse(payload, interactionData?.InteractionLine?.InteractionLineId, find_agreement?.AgreementId, globalState?.state?.findAgreementSessionData)
    .then((data) => {
      setLoading(false);
      if(handleAddNewAddressFlow){
        handleAddNewAddressFlow(data, address)
      } else if (data && data.IsOK?.toUpperCase() === "Y") {
        callshippingmethodV2ApiForReturn(
          address,
          updatedPickupDate || moment(pickupDate, 'DD MMMM YYYY, dddd')?.format('YYYY-MM-DD'),
          updatedPickupTime || pickupTime);
      } else {
        // open Address Line PopUp
        setOpenAddressPopup({open: true, addressFieldName: 'returnAdd'});
      }
    })
    .catch((error) => {
      setLoading(false);
      saveError(error, globalState);
      showErrorPage(navigate);
    });
  }

  return (
    <Box pb={4}>
      {(showDeliveryDeatils || showCollectionDetails || showAppointmentDetails) && (
        <>
          <Box pb={4}>
            <Box borderWidth="1px" borderRadius="lg" borderColor="#6E767D">
              <Box px={3} py={1} mb={'-10px'} fontSize={'sm'} fontWeight={400} color={'gray.500'}>
                {getLabel()?.[0]}
              </Box>
              <Input
                value={deliveryAdd}
                onClick={() => onAddressInputClick(false, 'delivery')}
                py="0.5rem"
                border={'none'}
                _focus={{ border: 'none', boxShadow: 'none' }}
              />
            </Box>
          </Box>

          {deliveryDate && deliveryTime && (
            <PickupSchedule
              shipingMethods={shippingMethodResponse}
              nightDeliveryFees="43"
              isWalking={isWalking}
              dateLabel={getLabel()?.[1]}
              timeSlotLabel={getLabel()?.[2]}
              selectedDate={
                updatedDeliveryDate ||
                moment(deliveryDate, 'DD MMMM YYYY, dddd')?.format('YYYY-MM-DD')
              }
              selectedTime={updatedDeliveryTime || deliveryTime}
              onDateSelect={onDateSelect}
              onTimeSlotSelect={onTimeSlotSelect}
              isTOM={!isWalking && isTOM}
              isMaxis={globalState?.state?.isMaxis}
              incidentType={incidentType?.toUpperCase()}
              applyDateFormat={getDateAndDay}
              dateFormat={dateFormat || Scripts?.Global?.DateFormat}
              applyTimeTranslation={getTime}
              getDeliverySlotFlag={getDeliverySlot(find_agreement?.ClientOffer?.ClientOfferName)}
            />
          )}
        </>
      )}
      { isAlternateNumberEditable && (isPickupEditable || isReturnEditable) && (
        <Box pb={4}>
          <Box borderWidth="1px" borderRadius="lg" borderColor="#6E767D">
            <Box px={3} py={1} mb={'-10px'} fontSize={'sm'} fontWeight={400} color={'gray.500'}>
              <Text fontWeight={'700'}>
                <TextScript 
                  text={'Alternate Contact Number'}
                  translationKey={`${translationKeys.Global.PageName}/AlternativeContactNumber`}
                />
              </Text>
            </Box>
            <Input
              pt={'0px'}
              height={'auto'}
              value={updatedAlternateContactNumber}
              py="0.5rem"
              border={'none'}
              _focus={{ border: 'none', boxShadow: 'none' }}
              maxLength={validLength}
              onChange={(e) => handleAlternateContactChange(e)}
            />
          </Box>
          { invalidMobile && ( 
            <Box color={'red'} fontSize={'14px'}>
              <TextScript
                text={'* Invalid mobile number'}
                translationKey={`${translationKeys.RefreshLogisticsScripts.PageName}/alternateContactNoErrorMsg`}
              />
            </Box>
          )}
        </Box>
      )}
      {showPickupDeatils && isPickupEditable && (
        <>
          <Box pb={4}>
            <Box borderWidth="1px" borderRadius="lg" borderColor="#6E767D">
              <Box px={3} py={1} mb={'-10px'} fontSize={'sm'} fontWeight={400} color={'gray.500'}>
                <Text>
                  <TextScript
                    text={Scripts.Global.PickUpAddress}
                    translationKey={`${translationKeys.Global.PageName}/${translationKeys.Global.PickUpAddress}`}
                  />
                </Text>
              </Box>
              <Input
                pt={'0px'}
                height={'auto'}
                value={pickupAdd}
                onClick={() => onAddressInputClick(true, 'pickUpAdd')}
                py="0.5rem"
                border={'none'}
                _focus={{ border: 'none', boxShadow: 'none' }}
              />
            </Box>
          </Box>
          {pickupDate && pickupTime && (
            <PickupSchedule
              shipingMethods={shippingMethodResponse}
              nightDeliveryFees="43"
              isWalking={true} //need to check
              dateLabel="Pick-up Date"
              timeSlotLabel="Pick-up Time"
              selectedDate={
                updatedPickupDate || moment(pickupDate, 'DD MMMM YYYY, dddd')?.format('YYYY-MM-DD')
              }
              selectedTime={updatedPickupTime || pickupTime}
              onDateSelect={onPickupDateSelect}
              onTimeSlotSelect={onPickupTimeSlotSelect}
              isTOM={isTOM}
              applyDateFormat={getDateAndDay}
              dateFormat={dateFormat || Scripts?.Global?.DateFormat}
              applyTimeTranslation={getTime}
            />
          )}
        </>
      )}
      {showReturnDetails && isReturnEditable && (
        <>
          <Box pb={4}>
            <Box borderWidth="1px" borderRadius="lg" borderColor="#6E767D">
              <Box px={3} py={1} mb={'-10px'} fontSize={'sm'} fontWeight={400} color={'gray.500'}>
                <Text>
                  <TextScript
                    text={Scripts.Global.ReturnAddress}
                    translationKey={`${translationKeys.Global.PageName}/${translationKeys.Global.ReturnAddress}`}
                  />
                </Text>
              </Box>
              <Input
                pt={'0px'}
                height={'auto'}
                value={returnAdd}
                onClick={() => onAddressInputClick(false, 'returnAdd')}
                py="0.5rem"
                border={'none'}
                _focus={{ border: 'none', boxShadow: 'none' }}
              />
            </Box>
          </Box>
          {/* {returnDate && returnTime && ( */}
            <PickupSchedule
              shipingMethods={shppingMethodV2Return}
              nightDeliveryFees="43"
              isWalking={true}
              dateLabel="Return Date"
              timeSlotLabel="Return Time"
              selectedDate={
                updatedReturnDate || (!isAddressEmpty(returnAddress) ? moment(returnDate, 'DD MMMM YYYY, dddd')?.format('YYYY-MM-DD') : '')
              }
              selectedTime={updatedReturnTime || (!isAddressEmpty(returnAddress) ? returnTime : '')}
              onDateSelect={onReturnDateSelect}
              onTimeSlotSelect={onReturnTimeSlotSelect}
              isPUR={true}
              isTOM={isTOM}
              applyDateFormat={getDateAndDay}
              dateFormat={dateFormat || Scripts?.Global?.DateFormat}
              applyTimeTranslation={getTime}
            />
          {/* )} */}
        </>
      )}
      {openAddressPopup?.open &&
        <AddressLinePopup
          setAddressPopup={setOpenAddressPopup}
          addressPopup={openAddressPopup}
          srDeliveryType={srDeliveryType}
          isPickup={isPickup}
          claimData={claimData}
          isWalkIn={isWalking}
          setShippingMethodRes={setShippingMethodRes}
          updateWalkinDateTime={updateWalkinDateTime}
          callshippingmethodapi={callshippingmethodapi}
          callshippingmethodV2Api={callshippingmethodV2Api}
          callTCATAddressParseAPI={callTCATAddressParseAPI}
        />
      }
    </Box>
  );
};

export default EditDetails;
