import { TextScript } from '@MGPD/myasurion-shared';
import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import { useCentralStore } from '../../store/appContext';

interface PaymentBreakdownProps {
  currencySymbol: string;
}

const PaymentBreakdown: React.FC<PaymentBreakdownProps> = ({ currencySymbol }) => {

  const globalState = useCentralStore();

  const commonScripts = Scripts?.ClaimConfirmation;

return (
    <Box>
        {/* Commented out code */}
        {/* <Box display={{ base: 'none', lg: 'block' }}>
            <hr style={{ marginTop: '8px' }} />
        </Box> */}
        <Box mt={'16px'} mb={'16px'}>
            <hr
                style={{
                    border: 'none',
                    borderTop: '2px solid #adaaaa',
                }}
            />
        </Box>
        <Box w={{ base: 'full', lg: '80%' }} mx={'auto'}>
            <Text my={3} fontWeight={700}>
                <TextScript
                    text={commonScripts?.paymentBreakdown}
                    translationKey={`${translationsKeys?.ConfirmationAfterSubmission.PageName}/${translationsKeys?.ConfirmationAfterSubmission.lblPaymentBreakdown}`}
                />
            </Text>

            <Flex mb={3} direction="row" justifyContent="space-between">
                <Text>
                    <TextScript
                        text={Scripts.CleaningService.Terms.SRF}
                        translationKey={`${translationsKeys.CleaningService.Terms.PageName}/${translationsKeys.CleaningService.Terms.SRF}`}
                    ></TextScript>
                </Text>
                {currencySymbol && (
                    <Text>
                        {`${currencySymbol}${globalState?.state?.selectedDeviceModel?.DeviceTypeModelPrice?.toString()}`}
                    </Text>
                )}
            </Flex>

            <hr
                style={{
                    border: 'none',
                    borderTop: '2px dotted #adaaaa',
                }}
            />

            <Flex my={2} direction="row" justifyContent="space-between">
                <Text>
                    <TextScript
                        text={Scripts.Global.Subtotal}
                        translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Subtotal}`}
                    />
                </Text>

                {currencySymbol && (
                    <Text>
                        {`${currencySymbol}${globalState?.state?.selectedDeviceModel?.DeviceTypeModelPrice?.toString()}`}
                    </Text>
                )}
            </Flex>
            <Flex mb={2} direction="row" justifyContent="space-between">
                <Text>
                    <TextScript
                        text={Scripts.Global.Discount}
                        translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Discount}`}
                    />
                </Text>
                {currencySymbol && (
                    <Text>
                        {`${currencySymbol}${globalState?.state?.selectedDeviceModel?.DiscountPrice?.toString()}`}
                    </Text>
                )}
            </Flex>
            <Flex mb={2} direction="row" justifyContent="space-between">
                <Text fontWeight={700}>
                    <TextScript
                        text={Scripts.Global.Total}
                        translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Total}`}
                    />
                </Text>
                {currencySymbol && (
                    <Text fontWeight={700}>
                        {`${currencySymbol}${globalState?.state?.selectedDeviceModel?.PricewithDiscount?.toString()}`}
                    </Text>
                )}
            </Flex>
        </Box>
        <Box mt={'16px'} mb={'16px'}>
            <hr
                style={{
                    border: 'none',
                    borderTop: '2px solid #adaaaa',
                }}
            />
        </Box>
    </Box>
);
};

export default PaymentBreakdown;
