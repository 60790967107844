import { Dispatch, createContext, useContext, useReducer } from 'react';
import React from 'react';

import reducer, { actionType, initialState, stateType } from './reducers';

export type ContextType = {
  state: stateType;
  dispatch: Dispatch<actionType>;
};
const AppContext = createContext<ContextType | undefined>(undefined);

const { Provider } = AppContext;

interface Props {
  children: React.ReactNode;
}

const ContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

// const ContextProvider = ({ children: React.ReactNode, initialState: stateType, reducer }) => {
//   return <Provider value={useReducer(reducer, initialState)}>{children}</Provider>;
// };

export const useCentralStore = () => useContext(AppContext);

export default ContextProvider;
