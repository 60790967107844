/* eslint-disable no-console */
import MainStackLayout from '../../layout/MainStackLayout';
import '../../services/api';
import { Box, Text, Button } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import moment from 'moment'
import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import { useCentralStore } from '../../store/appContext';
import { TextScript, getTypeByDomain, useConfig } from '@MGPD/myasurion-shared';
import { getTextScript } from '@MGPD/myasurion-shared/components/localization';
import { getRedirectUrl } from '../../utils/utills';

function RenewalReview() {

    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;

    const RenewalReviewScrips = Scripts.Renewal.RenewalReview
    const globalState = useCentralStore();
    const [renewedScript, setRenewedScript] = useState<any>(undefined)
    const agreementData1: any = globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];


    const getTitleText = async (value: string) => {
        const val = await getTextScript(
            `renewalReview/renewedTextScript2`,
            RenewalReviewScrips.renewedScript,
            'en-US',
            [value]);
        setRenewedScript(val)
    };

    useEffect(() => {
        if (agreementData1) getTitleText(`${(moment(agreementData1?.EndDate).format('DD MMMM YYYY'))?.toString()}, ${moment(agreementData1?.EndDate).format('dddd')}`)
    }, [agreementData1])


    return (
        <Box>
            <Box
                w={{ base: 'full', lg: '80%' }}
                margin="auto"
                // pb={{ base: 0, lg: '150px' }}
                id={'myasurion-profile-dashboard'}
                paddingTop={'54px'}
            >
                <MainStackLayout gap={0} spacing={0}>
                    <Box
                        id={'myasurion-profile-avatar'}
                        w={{ base: 'full', lg: '80%' }}
                        margin="0 auto"
                        h={'auto'}
                        p={'10px'}
                    >
                        <Text mt='8' mb="3" fontSize={'32px'}>
                            <TextScript
                                text={RenewalReviewScrips.thankyouText}
                                translationKey={`renewalReview/thankyouText`}
                            />
                        </Text>
                        <Text mb="3" fontSize={'24px'}>
                            <TextScript
                                text={RenewalReviewScrips.heading}
                                translationKey={`renewalReview/renewalReviewHeading`}
                            />
                        </Text>
                        <Text mb="3" fontSize={'16px'}>
                            <div dangerouslySetInnerHTML={{ __html: renewedScript }} />
                        </Text>
                        <Text mb="3" fontSize={'16px'}>
                            <TextScript
                                text={RenewalReviewScrips.noteScript}
                                translationKey={`renewalReview/noteScript`}
                            />
                        </Text>
                        <Box w={{ base: 'full', lg: '50%' }} m="20px auto">
                    <Button
                        w="full"
                        // marginLeft={{ base: '0', lg: '20px' }}
                        color="white"
                        colorScheme="primary"
                        bg={colors.primary}
                        cursor={'pointer'}
                        onClick={() => window.location.href = getRedirectUrl(getTypeByDomain())}
                    >
                                <TextScript
                                    text={Scripts.Global.Close}
                                    translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Close}`}
                                />
                    </Button>
                </Box>
                    </Box>
                </MainStackLayout>
            </Box>
        </Box>
    );
}

export default RenewalReview;
