/* eslint-disable no-console */

import { SubHeader, TextScript, getTypeByDomain, useConfig, useTextScript } from '@MGPD/myasurion-shared';
import { Box, Button, Flex, Image, Input, Text, Link } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import PlusIcon from '../../icons/PlusIcon.svg';
import RemoveIcon from '../../icons/Remove.svg';
import takePhoto from '../../icons/TakePhoto.svg';
import FhotoToolTip from './FhotoToolTip';
import { createInquiry, createInquiryV1, updateDeviceDetailsApi, uploadToS3BucketBySignedURL } from '../../services/api';
import { dataURItoBlob, 
  getBase64,
  getBase64ForPDF,
  getSignedRequestForUpload,
  getSignedURLForDownload, } from '../../utils/upload-utils';
import { useCentralStore } from '../../store/appContext';
import { getProgrammeName } from '../../utils/localization-helper';
import ActionTypes from '../../store/actionTypes';
import { getRedirectUrl, saveError, showErrorPage } from '../../utils/utills';
import { getMappedClientNameVal } from '../../utils/helper';
import MainStackLayout from '../../layout/MainStackLayout';
import PDF from '../../utils/preview-pdf';

function UploadPurchaseReceipt() {
  const navigate = useNavigate();
  const globalState = useCentralStore();
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;

  const [selectedFile, setFile] = useState<any>(undefined);
  const [selectedSecondFile, setSecondFile] = useState<any>(undefined);
  const [hideUploadBtn, setHideUploadBtn] = useState<boolean>(false);
  const [SecondImage, setSecondImage] = useState<boolean>(false);
  const [showSecondImageAsFirst, setShowSecondImageAsFirst] = useState<boolean>(false);
  const [moreThan10Mb, setMoreThan10Mb] = useState<boolean>(false);
  const [fileError, setFileError] = useState<boolean>(false);
  const [, setUploadStatus] = useState<string>('NONE');
  const [, setGeneratedFileName] = useState('');
  const [previewFile, setPreviewFile] = useState<any>();
  const [previewSecondFile, setPreviewSecondFile] = useState<any>();
  const [pdf, setPdf] = useState(false);
  const [secondpdf, setSecondPdf] = useState(false);
  const [base64String, setBase64String] = useState('');
  const [base64StringSecond, setBase64StringSecond] = useState('');
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [programmeNameLBL, setProgrammeNameLBL] = useState('')
  const { isTranslationsLoading, language } = useTextScript();

  const UploadPurchaseReceiptScripts = Scripts.AddDevice.UploadPurchaseReceipt;
  const UploadPurchaseReceiptKeys = translationsKeys.AddDevice.UploadPurchaseReceipt;

  const pageName = UploadPurchaseReceiptKeys.PageName;
  let documents: any = [];

  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };
 
  const isDisabled = (selectedFile || selectedSecondFile) && !fileError && !moreThan10Mb !== undefined ? false : true;
  const agreementData: any = globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  const enrolledAssetData = globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0]?.Assets?.Asset?.filter(
    (a: any) => { return a?.AssetInstance?.toUpperCase() === 'ENROLLED' || a?.AssetInstance?.toUpperCase() === 'CUSVERIFY'; }
  );
  const EmailAddress = agreementData?.ContactPoints?.ContactPoint?.filter((a: any) => a.EmailAddress)
  const MobileDeviceNumber = agreementData?.ContactPoints?.ContactPoint?.filter((a: any) => { return a.PhoneNumber })
  const selectedDevice = globalState?.state?.selectedDeviceDetails
  const intializeResponse = globalState?.state?.sessionResponse?.InitializeResponse;
  const interactionData = globalState?.state?.sessionResponse?.Interaction;
  const NewDeviceDetails = globalState?.state?.NewAddedDevices;
  

  const getProgrammeNameLbl = async () => {
    const programmeName = await getProgrammeName(agreementData?.ClientOffer?.ClientOfferName);
    setProgrammeNameLBL(programmeName);
  }

  useEffect(() => {
    if (agreementData && !isTranslationsLoading) {
      // get programme name
      getProgrammeNameLbl();
    }
  }, [agreementData, language, isTranslationsLoading])

  const onClickPopup = () => {
    setOpenCancelPopup(!openCancelPopup);
  };

  const onCancelClick = () => {
    window.location.href = getRedirectUrl(getTypeByDomain());
  };

  const getFilePDF = async (file: any) => {
    const extension: any = file?.name?.split('.').pop();
    let isPdf = extension?.toUpperCase() === 'PDF' ? true : false;

    if (isPdf) {
      setPdf(isPdf);
      const base: any = await getBase64ForPDF(file);
      setBase64String(base);
    }
    return file;
  };

  const getSecondFilePDF = async (file: any) => {
    const extension: any = file?.name?.split('.').pop();
    let isSecondPdf = extension?.toUpperCase() === 'PDF' ? true : false;

    if (isSecondPdf) {
      setSecondPdf(isSecondPdf);
      const base: any = await getBase64ForPDF(file);
      setBase64StringSecond(base);
    }
    return file;
  };

  const handleFileUpload = async (e: any) => {
    setFileError(false);
    const file = e.target.files[0];
    const extension: any = file?.name?.split('.').pop();

    let regex = /.*\.(jpg|png|jpe?g|pdf|bmp)$/i;
    if (regex.test(file.name)) {
      setFileError(false);
      setMoreThan10Mb(false);
      // actions.setImage(true);
      let fileSizeInKB = file.size / 1024;
      if (fileSizeInKB && fileSizeInKB <= 10000) {
        setMoreThan10Mb(false);
        setFile(file);
        if (extension === 'pdf') {
          setPreviewFile(getFilePDF(file));
        } else {
          setPreviewFile(file);
        }
        setHideUploadBtn(true);
        setShowSecondImageAsFirst(false);
      } else {
        setMoreThan10Mb(true);
        setFileError(true);
      }
    } else {
      setFileError(true);
    }
  };

  const handleFileUploadSecond = async (e: any) => {
    setFileError(false);
    const file = e.target.files[0];
    const extension: any = file?.name?.split('.').pop();

    let regex = /.*\.(jpg|png|jpe?g|pdf|bmp)$/i;
    if (regex.test(file.name)) {
      setFileError(false);
      setMoreThan10Mb(false);
      // actions.setImage(true);
      let fileSizeInKB = file.size / 1024;
      if (fileSizeInKB && fileSizeInKB <= 10000) {
        setMoreThan10Mb(false);
        setSecondFile(file);
        if (extension === 'pdf') {
          setPreviewSecondFile(getSecondFilePDF(file));
        } else {
          setPreviewSecondFile(file);
        }
        setHideUploadBtn(true);
        setSecondImage(true);
      } else {
        setMoreThan10Mb(true);
        setFileError(true);
      }
    } else {
      setFileError(true);
    }
  };

  const removeFile = () => {
    if (SecondImage) {
      setShowSecondImageAsFirst(true);
      setHideUploadBtn(true);
    } else {
      setShowSecondImageAsFirst(false);
      setHideUploadBtn(false);
    }
    setFile(undefined);
    setPdf(false);
  };

  const removeSecondFile = () => {
    setSecondImage(false);
    setSecondFile(undefined);
    setSecondPdf(false);
    setShowSecondImageAsFirst(false)
    if (selectedFile) {
      setHideUploadBtn(true);
    } else {
      setHideUploadBtn(false);
    }

  };

  const handleSubmit = (selectedFile: any, selectedSecondFile: any) => {
    if (globalState?.state?.selectedDeviceDetails?.AssetRank?.toUpperCase() === 'ANCHOR DEVICE') {
      callUpdateDeviceDetailsAPI()
    } else {
      setUploadStatus('INPROGRESS');
      if (selectedFile && selectedSecondFile) {
        UploadPurchaseReceipt(selectedFile)
        UploadPurchaseReceipt(selectedSecondFile)
      } else {
        UploadPurchaseReceipt(selectedFile);
      }
    }
  };

  const saveGeneratedFileName = (url: any, file: any) => {
    const fileName: any = url.split('?')?.[0]?.split('/')?.pop();
    const extension: any = file?.name?.split('.').pop();
    setGeneratedFileName(`${fileName}.${extension}`);
  };


  // Inquiry Api 
  const callInquiryRegisterDeviceApi = (document: object) => {
    setLoading(true);
    const CreateInquiryParams = {
      CreateInquiryParams: {
        MobileDeviceNumber: MobileDeviceNumber?.[0]?.PhoneNumber || '',
        FirstName: agreementData?.Customers?.Customer[0]?.FirstName || '',
        LastName: agreementData?.Customers?.Customer[0]?.LastName || '',
        ContactNumber: MobileDeviceNumber?.[0]?.PhoneNumber || '',
        ClientName: intializeResponse?.ClientName,
        InquiryChannel: 'ONLINE',
        InquiryType: selectedDevice?.AssetRank?.toUpperCase() === "ANCHOR DEVICE" ? 'Anchor Device Registration' : 'Device Registration',
        InquirySubType: selectedDevice?.AssetRank?.toUpperCase() === "ANCHOR DEVICE" ? 'Anchor Device Registration' : 'Device Registration',
        InquiryDescription: `ModelNo=${NewDeviceDetails?.AssetModel}|SerialNumber=${NewDeviceDetails?.SerialNumber}|Category=${NewDeviceDetails?.category}|Make=${NewDeviceDetails?.AssetMake}|AssetCatalogId =${NewDeviceDetails?.AssetCatalogId}|PurchaseDate=${NewDeviceDetails?.PurchaseDate}|PurchasePrice=${NewDeviceDetails?.ModelRRP}|StoreCode=${agreementData?.AgreementPurchase?.StoreCode}|CountryCallingCode=${agreementData?.ContactPoints?.ContactPoint[0]?.CountryCallingCode}| CurrencyCode = ${intializeResponse?.CurrencyCode}| ClientOfferName = ${programmeNameLBL||agreementData?.ClientOffer?.ClientOfferName}`,
        CallDriverCategory: 'Master Data Management',
        CallDriverSubCategory: 'Master Data Management',
        ClientId: intializeResponse?.ClientId, // "39EBF36EBC7A11EC953306C1D4AC7E74",
        Email: EmailAddress[0]?.EmailAddress,
        DepartmentType: 'Supply Chain',
        Priority: 'High',
        ProgramName: agreementData?.ClientOffer?.ClientOfferName,
        AgreementId: agreementData?.AgreementId,
        isPremiumPlan: true,
        ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
        ClientChannelId: agreementData?.ClientAccount?.ClientChannelId,
        CustomerId: agreementData?.Customers?.Customer[0]?.CustomerId,
        AssetId: enrolledAssetData?.[0]?.AssetId,
        Documents: document,
        DeviceDetails: {
          Category: NewDeviceDetails?.category,
          AssetMake: NewDeviceDetails?.AssetMake,
          AssetModel: NewDeviceDetails?.AssetModel,
          AssetAttributes: `ModelNo=${NewDeviceDetails?.AssetModel}|SerialNumber=${NewDeviceDetails?.SerialNumber}|Category=${NewDeviceDetails?.category}|Make=${NewDeviceDetails?.AssetMake}|AssetCatalogId =${NewDeviceDetails?.AssetCatalogId}|PurchaseDate=${NewDeviceDetails?.PurchaseDate}|PurchasePrice=${NewDeviceDetails?.ModelRRP}|StoreCode=${agreementData?.AgreementPurchase?.StoreCode}|CountryCallingCode=${agreementData?.ContactPoints?.ContactPoint[0]?.CountryCallingCode}| CurrencyCode = ${intializeResponse?.CurrencyCode}| ClientOfferName = ${agreementData?.ClientOffer?.ClientOfferName}`,
          PurchaseDate: NewDeviceDetails?.PurchaseDate,
          SerialNumber: NewDeviceDetails?.SerialNumber,
        },
        UpdateDeviceDetails: {
          AssetCatalogId: NewDeviceDetails?.AssetCatalogId,
          IMEI: NewDeviceDetails?.SerialNumber,
          ClientAssetSkuNumber: NewDeviceDetails?.AssetModel,
        },
      },
    };
    let inquiryApiVersion = CreateInquiryParams?.CreateInquiryParams?.InquiryType?.toUpperCase() === "ANCHOR DEVICE REGISTRATION" ? createInquiryV1 : createInquiry;

    inquiryApiVersion(
      CreateInquiryParams,
      interactionData?.InteractionLine.InteractionLineId, globalState?.state?.findAgreementSessionData
    )
      .then((resp) => {
        setLoading(false);
        globalState?.dispatch({ type: ActionTypes.SET_INQUIRY_RESPONSE, payload: resp });
        navigate('/confirmDeviceRegistration', { replace: true });
        setUploadStatus('COMPLETED');
      })
      .catch((error: any) => {
        setLoading(false);
        console.log('uploadPurchaseReceipt:', error);
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  };

  //Upload File Api

  const UploadPurchaseReceipt = async (fileToUpload: any) => {
    setLoading(true);
    let AgreementId = agreementData?.AgreementId;
    const signedUrl: any = await getSignedRequestForUpload(
      AgreementId,
      interactionData?.InteractionLine.InteractionLineId,
      'purchase-receipt',
      getMappedClientNameVal(globalState?.state?.sessionResponse?.InitializeResponse?.ClientName?.toLowerCase()),
      globalState?.state?.findAgreementSessionData
    ).catch((error) => {
      setLoading(false);
      saveError(error, globalState);
      showErrorPage(navigate);
      return;
    });

    const base64Obj = await getBase64(fileToUpload);
    saveGeneratedFileName(signedUrl, fileToUpload);
    const uploadedFile: any = await uploadToS3BucketBySignedURL(
      signedUrl,
      fileToUpload?.type,
      dataURItoBlob(base64Obj)
    ).catch((error) => {
      setLoading(false);
      saveError(error, globalState);
      showErrorPage(navigate);
      return;
    });

    if (uploadedFile && uploadedFile?.status === 200 && uploadedFile.statusText === 'OK') {
      const fileName = signedUrl.split('?')?.[0]?.split('/')?.pop();
      const extension = fileToUpload?.name?.split('.').pop();

      const params = {
        FileDownloadRequest: {
          AgreementId: AgreementId,
          FileName: `${fileName}.${extension}`,
          FolderName: "purchase-receipt"
        },
      };

      const data: any = await getSignedURLForDownload(
        params,
        interactionData?.InteractionLine.InteractionLineId,
        globalState?.state?.findAgreementSessionData
      );
      const { bucketName } = data;

      documents.push({
        BucketName: bucketName,
        Path: `${fileName}.${extension}`,
        Type: fileToUpload?.type,
        Status: 'uploaded',
      })
      if (SecondImage) {
        if (documents?.length > 1) {
          callInquiryRegisterDeviceApi(documents);
        }
      } else {
        callInquiryRegisterDeviceApi(documents);
      }
    }
  };

  //Update Device Details Api for Anchor Device Only 

  const getUpdateDeviceDetailsParams = () => {
    const UpdateDeviceParameters = {
      UpdateDeviceParameters: {
        MobileDeviceNumber: MobileDeviceNumber?.[0]?.PhoneNumber,
        AgreementId: agreementData?.AgreementId,
        SerialNumber: NewDeviceDetails?.SerialNumber,
        AssetId: globalState?.state?.selectedDeviceDetails?.AssetId,
        User: `${agreementData?.Customers?.Customer[0]?.FirstName || ''} ${agreementData?.Customers?.Customer[0]?.LastName || ''}`,
      },
    };
    return UpdateDeviceParameters;
  };

  const callUpdateDeviceDetailsAPI = () => {
    setLoading(true);
    updateDeviceDetailsApi(
      getUpdateDeviceDetailsParams(),
      interactionData?.InteractionLine?.InteractionLineId, globalState?.state?.findAgreementSessionData
    )
      .then((resp) => {

        setLoading(false);
        if (resp.message === 'Serial number is not unique or already in use') {
          navigate('/errorPage', { replace: true });
        } else {
          setUploadStatus('INPROGRESS');
          if (selectedFile && selectedSecondFile) {
            UploadPurchaseReceipt(selectedFile)
            UploadPurchaseReceipt(selectedSecondFile)
          } else {
            UploadPurchaseReceipt(selectedFile);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  };

  const onBackClick = () => {
    if(globalState?.state?.selectedDeviceDetails?.AssetRank?.toUpperCase() === 'ANCHOR DEVICE'){
      navigate('/updateAnchorDevice', { replace: true });
    }else{
      navigate('/deviceDetails', { replace: true });
    }
  }
  return (
    <Box>
      <Box w={{ base: 'full', lg: '80%' }} margin="auto" pt={'50px'}>
        <MainStackLayout gap={0} spacing={0}>
          <SubHeader
            onClick={onBackClick}
            backText={'Back'}
            allowBackButtonClick={false}
            showCancelButton={false}
            showBackButton={true}
            cancelText={Scripts.CancelSR.cancelBtnTextCancel}
            onCancelClick={onCancelClick}
          ></SubHeader>

          <Box
            id={'myasurion-profile-avatar'}
            w={{ base: 'full', lg: '80%' }}
            margin="0 auto"
            h={'auto'}
            p={'1rem'}
            alignContent={'center'}
          >
            {!hideUploadBtn && (
              <Box>
                <Text mt={'5'} fontSize={{ base: '2xl', lg: '3xl' }}>
                  <TextScript
                    text={UploadPurchaseReceiptScripts.header}
                    translationKey={`${pageName}/${UploadPurchaseReceiptKeys.header}`}
                  />
                </Text>

                <Text mt={'2'}>
                  <TextScript
                    text={UploadPurchaseReceiptScripts.subHeader}
                    translationKey={`${pageName}/${UploadPurchaseReceiptKeys.subHeader}`}
                  />
                </Text>
              </Box>
            )}
            {!hideUploadBtn &&
              <Flex flexDir={'row'} pt={2}>
                {/* <Image src={help} cursor={'pointer'}></Image> */}
                <Link
                  cursor={'pointer'}
                  ml={'2'}
                  textDecoration={'underline'}
                  onClick={() => {
                    onClickPopup();
                  }}
                >
                  <TextScript
                    text={UploadPurchaseReceiptScripts.Tooltip}
                    translationKey={`${pageName}/${UploadPurchaseReceiptKeys.FhotoToolTip}`}
                  />
                </Link>
              </Flex>
            }
            {(hideUploadBtn || showSecondImageAsFirst) && (
              <Box>
                <Text mt={'5'} fontSize={'3xl'}>
                  <TextScript
                    text={UploadPurchaseReceiptScripts.SecondImageHeader}
                    translationKey={`${pageName}/${UploadPurchaseReceiptKeys.SecondImageHeader}`}
                  />
                </Text>

                <Text fontSize={'3xl'}>
                  <TextScript
                    text={UploadPurchaseReceiptScripts.SecondImageHeader1}
                    translationKey={`${pageName}/${UploadPurchaseReceiptKeys.SecondImageHeader1}`}
                  />
                </Text>

                <Text mt={'2'} fontSize={{ base: '2xl', lg: '20px' }}>
                  <TextScript
                    text={UploadPurchaseReceiptScripts.SecondSubheader}
                    translationKey={`${pageName}/${UploadPurchaseReceiptKeys.SecondSubheader}`}
                  />
                </Text>
              </Box>
            )}

            {fileError && (
              <Text textAlign={'center'} marginX={'auto'} color={'red'}>
                <TextScript
                  text={UploadPurchaseReceiptScripts.FileUploadError}
                  translationKey={`${pageName}/${UploadPurchaseReceiptKeys.FileUploadError}`}
                />
              </Text>
            )}

            {!hideUploadBtn && (
              <Flex
                mt="8"
                direction={'column'}
                alignContent={'center'}
                border={'1px solid #D5D6DA'}
                p="4"
                position={'relative'}
                marginX={'auto'}
                width={{ base: '100%', lg: '50%' }}
              >
                <Box py={5}>
                  <Image my={3} marginX={'auto'} src={takePhoto} alt="your model" />

                  <Text width={'60%'} textAlign={'center'} marginX={'auto'}>
                    <TextScript
                      text={UploadPurchaseReceiptScripts.imageUploadInfo}
                      translationKey={`${pageName}/${UploadPurchaseReceiptKeys.imageUploadInfo}`}
                    />
                  </Text>

                  <Button mt={'3'} w="full" colorScheme="primary" cursor={'pointer'}>
                    <TextScript
                      text={UploadPurchaseReceiptScripts.btnPickImage}
                      translationKey={`${pageName}/${UploadPurchaseReceiptKeys.btnPickImage}`}
                    />
                  </Button>
                </Box>

                <Input
                  type="file"
                  display={'hidden'}
                  border={'none'}
                  height={'100%'}
                  width={'100%'}
                  cursor={'pointer'}
                  position={'absolute'}
                  accept=".png,.jpg,.jpeg,.bmp,.pdf"
                  //   placeholder={placeHolder}
                  onChange={(e) => handleFileUpload(e)}
                  style={{
                    opacity: 0,
                    overflow: 'hidden',
                    zIndex: 999,
                  }}
                />

              </Flex>
            )}

            {((selectedFile?.name && previewFile) || showSecondImageAsFirst) && (
              <Flex columnGap={'2'} mt={'30px'} justifyContent={'center'}>

                {selectedFile?.name && previewFile && (
                  <Box>
                    <Flex>
                      <Box
                        border={'1px solid #C8C8C8'}
                        borderRadius={'8px'}
                        width={'155px'}
                        height={'207px'}
                        overflow={'auto'}
                      >

                        <Image src={RemoveIcon} onClick={() => removeFile()} cursor={'pointer'} />

                        {pdf ? (
                          base64String != '' && (
                            <PDF pdfClasses={'previewPdf'} base64String={base64String} />)
                        ) : (
                          <img
                            width={'155px'}
                            height={'207px'}
                            src={URL.createObjectURL(previewFile)}
                            alt="PurchaseReceipt"
                          />
                        )}

                      </Box>
                    </Flex>
                  </Box>
                )}

                {(SecondImage || showSecondImageAsFirst) && (
                  <Box>

                    <Flex>
                      <Box
                        border={'1px solid #C8C8C8'}
                        borderRadius={'8px'}
                        width={'155px'}
                        height={'207px'}
                        overflow={'auto'}
                      >

                        <Image
                          src={RemoveIcon}
                          onClick={() => removeSecondFile()}
                          cursor={'pointer'}
                        />

                        {secondpdf ? (
                          base64StringSecond != '' && (
                            <PDF pdfClasses={'previewPdf'} base64String={base64StringSecond} />)
                        ) : (
                          <img
                            width={'155px'}
                            height={'207px'}
                            src={URL.createObjectURL(previewSecondFile)}
                            alt="PurchaseReceipt"
                          />
                        )}

                      </Box>
                    </Flex>
                  </Box>
                )}

                {(!SecondImage || showSecondImageAsFirst) && (
                  <Box
                    border={'2px solid #C8C8C8'}
                    borderRadius={'8px'}
                    borderStyle={'dotted'}
                    width={'155px'}
                    height={'207px'}
                  >

                    <Input
                      type="file"
                      display={'hidden'}
                      border={'none'}
                      width={'155px'}
                      height={'207px'}
                      cursor={'pointer'}
                      position={'absolute'}
                      accept=".png,.jpg,.jpeg,.bmp,.pdf"
                      //   placeholder={placeHolder}
                      onChange={
                        showSecondImageAsFirst
                          ? (e) => handleFileUpload(e)
                          : (e) => handleFileUploadSecond(e)
                      }
                      style={{
                        opacity: 0,
                        overflow: 'hidden',
                        zIndex: 999,
                      }}
                    />

                    <Box mt={'50px'} marginX={'16px'} textAlign={'center'}>

                      <Image
                        my={'2'}
                        marginX={'auto'}
                        src={PlusIcon}
                        alt="your model"
                      />

                      <Text mx={'auto'}>
                        <TextScript
                          text={UploadPurchaseReceiptScripts.SecondImageText}
                          translationKey={`${pageName}/${UploadPurchaseReceiptKeys.SecondImageText}`} />
                      </Text>

                    </Box>
                  </Box>

                )}
              </Flex>
            )}

            <Box width={{ base: 'full', lg: '50%' }} marginX={'auto'}>
              <Button
                mt={'5'}
                width={'full'}
                textAlign={'center'}
                colorScheme="primary"
                bg={isDisabled ? 'gray.200' : colors.primary}
                cursor={isDisabled ? 'not-allowed' : 'pointer'}
                disabled={isDisabled}
                onClick={() => (isDisabled ? undefined : handleSubmit(selectedFile, selectedSecondFile))}
              >
                <TextScript
                  text={Scripts.Global.Continue}
                  translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Continue}`}
                />
              </Button>
            </Box>
          </Box>
        </MainStackLayout>
        <FhotoToolTip
          closeCancelPopup={setOpenCancelPopup}
          openCancelPopup={openCancelPopup}
        />
      </Box>
    </Box>
  );
}

export default UploadPurchaseReceipt;
