import { Icon, IconProps } from '@chakra-ui/icons';
import * as React from 'react';
import { JSX } from 'react/jsx-runtime';

export const AlertIcon = (
  props: JSX.IntrinsicAttributes &
    Omit<React.SVGProps<SVGSVGElement>, 'as' | 'translate' | keyof IconProps> & {
      htmlTranslate?: 'yes' | 'no' | undefined;
    } & IconProps & { as?: 'svg' | undefined }
) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM11 15V6H13V15H11ZM12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
      fill="#DB1B1B"
    />
  </Icon>
);
export default AlertIcon;
