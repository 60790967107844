/* eslint-disable no-console */
import TextInput from '../component/TextInput';
import {
  SubHeader,
  TextScript,
  getTextScript,
  getTypeByDomain,
  useConfig,
  useTextScript,
} from '@MGPD/myasurion-shared';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import help from '../../icons/Help.svg';
import FindSerialNoModal from './FindSerialNoModal';
import Select from './Select';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import zhTW from 'date-fns/locale/zh-TW';
import './datepicker.css';
import { useCentralStore } from '../../store/appContext';
import { formatDate, getRedirectUrl } from '../../utils/utills';
import ActionTypes from '../../store/actionTypes';
import MainStackLayout from '../../layout/MainStackLayout';
import CalendarIcon from '../../icons/CalendarIcon';

function DeviceDetails() {
  const navigate = useNavigate();
  const globalState = useCentralStore();
  const { isTranslationsLoading, language } = useTextScript();

  const registerLocaleAssertion = registerLocale as (locale: string, localeData: any) => void;
  registerLocaleAssertion('zh-TW', zhTW);

  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;

  const [serialNumber, setSrNo] = useState<string>('');
  const [isSrNoError, setSrNoError] = useState(false);
  const [formFields, setFormFields] = useState({
    Brand: '',
    Model: '',
  });
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [date, setDate] = useState<string>('');

  const [brandLbl, setBrandLbl] = useState<string>('');
  const [modelLbl, setModelLbl] = useState<string>('');
  const [serialNumberLbl, setSerialNumberLbl] = useState<string>('');
  const [purchaseDateLbl, setPurchaseDateLbl] = useState<string>('');

  const AddNewDeviceScripts = Scripts.AddDevice.AddNewDevice;
  const AddNewDeviceKeys = translationsKeys.AddDevice.AddNewDevice;
  const registerDeviceList = globalState?.state?.registerDeviceResponse;
  const BrandNames = registerDeviceList?.map((val: any) => {
    return val.AssetMakeName;
  });
  const filterBrands = Array.from(new Set(BrandNames));
  const filterModelOnBrand = registerDeviceList?.filter((val: any) => {
    return val.AssetMakeName === formFields.Brand;
  });
  const filterUniqueModelNo = filterModelOnBrand?.map((val: any) => {
    return val.AssetCatalogName?.concat(val.Color); 
  });
  const finalFilteredModel = Array.from(new Set(filterUniqueModelNo));
  //const MobileDeviceNumber = anchorDevice && anchorDevice?.MobileDeviceNumber?.split('_')?.[0];
  // const AssetPurchaseDate = date && getDateAndDay(date, 'MMMM D, YYYY');
  const selectedCategoryDetails = globalState?.state?.selectedCategory?.selectedDeviceCategory[0];
  const selectedCategory = globalState?.state?.selectedCategory?.selectedDeviceCategory[0]?.categoryName;
  const pageName = AddNewDeviceKeys.PageName;
  // const futureDate = getDateAndDay(new Date(), 'YYYY-MM-DD');
  const pastDateLimit = new Date(new Date().setDate(new Date().getDate() - 30));
  // const pastDateFormat = getDateAndDay(pastDateLimit, 'YYYY-MM-DD');
  const disableFun = (date && serialNumber && formFields?.Brand && formFields?.Model) === "" ? true : false

  const selectedDeviceDetails = registerDeviceList?.filter((v: any) => {
    return v.AssetMakeName === formFields.Brand && v.AssetCatalogName?.concat(v.Color) === formFields.Model;
  });

  useEffect(() => {
    if (!isTranslationsLoading) {
      getBrandLbl();
      getModelLbl();
      getSerialNumberLbl();
      getPurchaseDateLbl();
    }
  }, [language, isTranslationsLoading])

  const getBrandLbl = async () => {
    const brandLBL = await getTextScript(
      `${AddNewDeviceKeys.PageNameAnchorDevice}/${AddNewDeviceKeys.brand}`,
      AddNewDeviceScripts.brand,
      language,
      []);
    
    setBrandLbl(brandLBL);
  }

  const getModelLbl = async () => {
    const modelLbl = await getTextScript(
      `${AddNewDeviceKeys.PageNameAnchorDevice}/${AddNewDeviceKeys.model}`,
      AddNewDeviceScripts.model,
      language,
      []);

    setModelLbl(modelLbl);
  }

  const getSerialNumberLbl = async () => {
    const serialNumberLbl = await getTextScript(
      `${AddNewDeviceKeys.PageNameAnchorDevice}/${AddNewDeviceKeys.serialNumber}`,
      AddNewDeviceScripts.serialNumber,
      language,
      []);

    setSerialNumberLbl(serialNumberLbl);
  }

  const getPurchaseDateLbl = async () => {
    const purchaseDateLbl = await getTextScript(
      `${AddNewDeviceKeys.PageNameAnchorDevice}/${AddNewDeviceKeys.purchaseDate}`,
      AddNewDeviceScripts.purchaseDate,
      language,
      []);

    setPurchaseDateLbl(purchaseDateLbl);
  }

  const onClickPopup = () => {
    setOpenCancelPopup(!openCancelPopup);
  };

  const onCancelClick = () => {
    window.location.href = getRedirectUrl(getTypeByDomain());
  };

  const handleSelectChange = (event: any, fieldName: any) => {
    const selectedValue = event.target.value;
    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      [fieldName]: selectedValue,
    }));
  };

  const handleChange = (date: any) => {
    setDate(date);
  };

  const handleSrNoChange = (e: any) => {
    const regex = /^[a-zA-Z0-9-_#]+$/;

    if (regex.test(e.value)) {
      setSrNoError(false)
    } else {
      setSrNoError(true);
    }

    setSrNo(e.value);
  };

  const handleSubmit = () => {

    // here convert date in to English only
    const purchaseDate = formatDate(date, 'YYYY-MM-DD', 'en-US', 'MMMM D, YYYY');

    globalState?.dispatch({
      type: ActionTypes.SET_ADDED_DEVICE_DETAILS,
      payload: {
        category: selectedCategory,
        PurchaseDate: purchaseDate,
        AssetMake: formFields.Brand,
        AssetModel: formFields.Model,
        SerialNumber: serialNumber,
        AssetCatalogId: selectedDeviceDetails?.[0]?.AssetCatalogId,
        ModelRRP: selectedDeviceDetails?.[0]?.ModelRrp,
      },
    });
    navigate('/uploadPurchaseReceipt', { replace: true });
  };

  // const inputRef = useRef<ReactDatePicker>(null);

  // const showPicker = () => {
  //   // inputRef?.current?.showPicker();
  //   if (inputRef.current) {
  //     inputRef.current.setOpen(true);
  //   }
  // }

  // const handleCloseDatePicker = () => {
  //   if (inputRef.current) {
  //     inputRef.current.setOpen(false);
  //   }
  // };

  const onBackClick = () => {
     navigate('/deviceCategoryList', { replace: true });
  };

  return (
    <Box>
      <Box w={{ base: 'full', lg: '80%' }} margin="auto" pt={'50px'}>
        <MainStackLayout gap={0} spacing={0}>
          <SubHeader
           onClick={onBackClick}
            backText={'Back'}
            allowBackButtonClick={false}
            showCancelButton={false}
            showBackButton={true}
            cancelText={Scripts.CancelSR.cancelBtnTextCancel}
            onCancelClick={onCancelClick}
          ></SubHeader>
          <Box
            id={'myasurion-profile-avatar'}
            w={{ base: 'full', lg: '80%' }}
            margin="0 auto"
            h={'auto'}
            p={'1rem'}
          >
            <Image
              my={'2'}
              marginX={'auto'}
              src={selectedCategoryDetails?.image || selectedCategoryDetails?.localimage}
              alt="your model"
            />

            <Text mt={'5'} fontSize={{ base: '3xl', lg: '4xl' }}>
              <TextScript
                text={AddNewDeviceScripts.header}
                translationKey={`${pageName}/${AddNewDeviceKeys.header}`}
              />
            </Text>

            <Box mt={'4'}>
              <Select
                size={'lg'}
                borderColor={'#A5AAAF'}
                placeholder={brandLbl || AddNewDeviceScripts.brand}
                value={formFields.Brand}
                onChange={(e: any) => handleSelectChange(e, 'Brand')}
                className={formFields.Brand ? `floatingPlaceholder` : ''}
              >
                {filterBrands?.map((val: any) => {
                  return (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  );
                })}
              </Select>

              <Select
                size={'lg'}
                borderColor={'#A5AAAF'}
                mt={'4'}
                placeholder={modelLbl || AddNewDeviceScripts.model}
                value={formFields.Model}
                onChange={(e: any) => handleSelectChange(e, 'Model')}
                className={formFields.Brand ? `floatingPlaceholder` : ''}
              >
                {finalFilteredModel?.map((val: any) => {
                  return (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  );
                })}
              </Select>

              <FormControl w={'full'} isRequired={true} mt={4}>
                <TextInput
                  size={'lg'}
                  showIcon={false}
                  type="text"
                  borderColor={'#A5AAAF'}
                  placeholder={serialNumberLbl || AddNewDeviceScripts.serialNumber}
                  onChange={(e: any) => handleSrNoChange(e)}
                  value={serialNumber}
                  maxLength={30}
                />
                {isSrNoError && (
                  <Text color={'red'}>
                    <TextScript
                      text={AddNewDeviceScripts.errorSerialNumber}
                      translationKey={`${pageName}/${AddNewDeviceKeys.errorSerialNumber}`}
                    />
                  </Text>
                )}
              </FormControl>
            </Box>

            <Flex flexDir={'row'} my="5" pt={2} onClick={() => {onClickPopup();}}>
              <Image src={help} cursor={'pointer'}></Image>
              <Link
                cursor={'pointer'}
                ml={'2'}
                textDecoration={'underline'}
                onClick={() => {
                  onClickPopup();
                }}
              >
                <TextScript
                  text={AddNewDeviceScripts.findSerialNumber}
                  translationKey={`${pageName}/${AddNewDeviceKeys.findSerialNumber}`}
                />
              </Link>
            </Flex>
            {/* <Box onClick={() => showPicker()} position={'relative'}>
              <Input
                size="md"
                borderColor={'#A5AAAF'}
                max={futureDate}
                min={pastDateFormat}
                type="date"
                // ref={inputRef}
                onChange={handleChange}
                display={'hidden'}
                position={'absolute'}
                cursor={'pointer'}
                style={{
                  opacity: 0,
                  overflow: 'hidden',
                  zIndex: 999,
                }}
              />

              <InputGroup position={'absolute'}>
                <InputRightElement pointerEvents="none" marginTop={{lg:'0px', base:'20px'}} marginRight={{lg:'0px',base:'7px'}}>
                  <CalendarIcon />
                </InputRightElement>
                <Input
                  size={'lg'}
                  borderColor={'#A5AAAF'}
                  // onFocus={(e) => e.target.showPicker()}
                  placeholder={purchaseDateLbl || AddNewDeviceScripts.purchaseDate}
                  type="text"
                  position={'relative'}
                  value={AssetPurchaseDate}
                />
              </InputGroup>
            </Box> */}

            <Box px={2}
              pt={date ? 0 : 2} 
              pb={2}
              width={'100%'}
              border={'2px solid #A5AAAF'}
              borderRadius="md"
              // onClick={() => showPicker()}
              display={'flex'}
              alignItems={'center'}
              >
              <Box flex={'1'}>
                { date &&
                  <Text color={'gray.500'} fontSize={'xs'} className='floating-label'>
                    <TextScript
                      text={AddNewDeviceScripts.purchaseDate}
                      translationKey={`${AddNewDeviceKeys.PageNameAnchorDevice}/${AddNewDeviceKeys.purchaseDate}`}
                    />
                  </Text> 
                }
                <ReactDatePicker
                  placeholderText={purchaseDateLbl || AddNewDeviceScripts.purchaseDate}
                  showIcon={false}
                  // ref={inputRef}
                  maxDate={new Date()}
                  minDate={pastDateLimit}
                  onChange={handleChange}
                  locale={'zh-TW'}
                  selected={date ? new Date(date) : undefined}
                  dateFormat={'MMMM d, YYYY'}
                  className="custom-date-picker"
                  // showPopperArrow={false}
                  popperPlacement="top-start"
                />
              </Box>

              <Box pointerEvents="none" >
                <CalendarIcon />
              </Box>
            </Box>

            <Box mt={'24'} width={{ base: 'full', lg: '50%' }} marginX={'auto'}>
              <Button
                mt={'5'}
                width={'full'}
                textAlign={'center'}
                colorScheme="primary"
                bg={disableFun || isSrNoError ? 'gray.200' : colors.primary}
                cursor={disableFun || isSrNoError ? 'not-allowed' : 'pointer'}
                disabled={disableFun || isSrNoError}
                onClick={() => (disableFun || isSrNoError ? undefined : handleSubmit())}
              >
                <TextScript
                  text={Scripts.Global.Continue}
                  translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Continue}`}
                />
              </Button>
            </Box>
          </Box>
        </MainStackLayout>
        <FindSerialNoModal
          closeCancelPopup={setOpenCancelPopup}
          openCancelPopup={openCancelPopup}
        />
      </Box>
    </Box>
  );
}

export default DeviceDetails;
