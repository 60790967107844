import PaymentScript from '../../Scripts/asurion-script.json';
import PaymentKeys from '../../Scripts/translationsKeys.json';
import { TextScript, useConfig } from '@MGPD/myasurion-shared';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  CloseButton,
  Flex,
} from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

interface popupProps {
  onYesClick: () => void;
  openPopup: boolean;
  closePopup: () => void;
}

const PaymentOptionPopup: React.FC<PropsWithChildren<popupProps>> = ({
  onYesClick,
  openPopup,
  closePopup,
}) => {
  const cancelRef = React.useRef<any>();
  //localization color theme changes
  const { data: configData } = useConfig();
  const { theme } = configData;
  const { colors } = theme;
  const { primary } = colors;
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => closePopup()}
        isOpen={openPopup}
        isCentered
        autoFocus={false}
      >
        <AlertDialogOverlay />
        <AlertDialogContent margin={'12px'}>
          <AlertDialogBody paddingX={'12px'} paddingTop={'12px'} as={'b'} fontSize={'18px'}>
            <Box fontSize={'lg'} lineHeight={'6'}>
              <Flex justifyContent={'flex-end'} wordBreak={'break-word'}>
                <CloseButton onClick={() => closePopup()} />
              </Flex>
              <Box my={'0.5rem'}>
              <Box fontWeight={'bold'}>
                <TextScript
                  text={PaymentScript.Payment.popupText1}
                  translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.popupText1}`}
                />
              </Box>
              <Box fontWeight={'normal'}>
                <TextScript
                  text={PaymentScript.Payment.popupText2}
                  translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.popupText2}`}
                />
              </Box>
              </Box>
            </Box>

            <Button
              bgColor={primary}
              color="white"
              variant="outline"
              width={'100%'}
              height={'3rem'}
              onClick={() => onYesClick()}
            >
              <Box fontWeight={'bold'} lineHeight={'shorter'}>
                <TextScript
                  text={PaymentScript.Payment.popupBtnText}
                  translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.popupBtnText}`}
                />
              </Box>
            </Button>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default PaymentOptionPopup;
