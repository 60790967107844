import MainStackLayout from '../layout/MainStackLayout';
import { ResumeFlowDataKey ,currentAgreementKey,processIncidentKey } from '../modules/config/constants';
import { logGTMEvent, setFulfillmentMethodApi } from '../services/api';
import { useSessionStorage, SubHeader, TextScript, getCarrierByDomain, getTypeByDomain } from '@MGPD/myasurion-shared';
import translationsKeys from '../Scripts/translationsKeys.json'
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ActionTypes from '../store/actionTypes';
import { useCentralStore } from '../store/appContext';
import Scripts from '../Scripts/asurion-script.json';
import CancelSrPopup from './myclaims/CancelSrPopup';
import { getGTMData, saveError, showErrorPage } from '../utils/utills';
import { getProgrammeName, getSwapLBL } from '../utils/localization-helper';
import features from '../utils/features.json';
import { getOEMWarranty } from '../utils/featuresUtills';
import { GTM_EVENT } from '../utils/constant';

function OEMWarranty() {
  const globalState = useCentralStore();
  const OemWarrantyKeys = translationsKeys.OEMWarranty
  const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
  const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
  const R_ServiceRequestId = R_ServiceRequests?.ServiceRequestId;

  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [acceptClick, setAcceptClick] = useState(false)
  const [SwapLBL, setSwapLBL] = useState('')
  const [programmeNameLBL, setProgrammeNameLBL] = useState('')
  const [processIncidentResponse, setProcessIncidentResponse] = useState<any>()
  // const [AssetData] = useSessionStorage<any>(AssetDataSessionKey, null)
  const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);

  const serviceRequestId =
    serviceRequestData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId

  // const [FulfillmentOption] = useSessionStorage<any>('FulfillmentOption', null);
  // const [FulfilmentOptionsLocal] = useState();
  const [interactionLineId] = useSessionStorage<any>('interactionLineId', null);
  const [agreementDatas] = useSessionStorage<any>(currentAgreementKey, null);
  
  // const [, setFulfillmentOption] = useSessionStorage<any>('FulfillmentOption', null);
  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };

  const interactionData = globalState?.state?.sessionResponse?.Interaction
  // const isSS4 = agreementDatas?.IsSmartSupport
  const isBYOD = agreementDatas?.AgreementType?.toUpperCase() === 'BYOD'
   
  //SetFulfillment APi
  // const {refetch: setfulFillmentMethod} = useQuery(
  //   ['setfulFillmentMethod'],
  //   async () => {
  //     // setLoading(true);
  //     return await setFulfillmentMethodApi(interactionLineId || interactionData?.InteractionLine.InteractionLineId, 'RPLCMNT', 'serviceRequestId');
  //   },
  //   {
  //     enabled: !!FulfilmentOptionsLocal,
  //     onSuccess: () => {        
  //     },
  //     onError: (error) => {        
  //       saveError(error, globalState);
  //       showErrorPage(navigate)
  //     },
  //   }
  // );

  const setfulFillmentMethod = () => {
    // setLoading(true);
    setFulfillmentMethodApi(interactionLineId || interactionData?.InteractionLine.InteractionLineId, 'RPLCMNT', serviceRequestId, globalState?.state?.findAgreementSessionData)
      .then(() => {
        // setLoading(false);         
        const GTMData = getGTMData(GTM_EVENT.SET_FULFILLMENT_EVENT, globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0], 
          getCarrierByDomain(), getTypeByDomain(), JSON.parse(processIncidentResponse)?.CustomerCaseNumber)
          logGTMEvent(GTMData)
      })
      .catch((error) => {
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  };

  const navigate = useNavigate();

  const Accept = () => {
    setAcceptClick(true)
    setLoading(true)
  };

  useEffect(() => {
    getSwapLbl();
    getProgrammeNameLbl();
    const GTMData = getGTMData(GTM_EVENT.OEM_SCREEN_EVENT, globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0], 
    getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)
    logGTMEvent(GTMData)
  }, [])

  useEffect(() => {
    const data = sessionStorage.getItem('processIncident') || ResumeFlowData;        
    setProcessIncidentResponse(data);
    if(acceptClick && processIncidentResponse){      
      setLoading(false);
      setAcceptClick(false);
      setfulFillmentMethod();
      navigate('/replacement', { replace: true });
    }
  }, [() => sessionStorage.getItem('processIncident')]);

  const getSwapLbl = async () => {
    const swapLBL = await getSwapLBL();
    setSwapLBL(swapLBL);
  }

  const getProgrammeNameLbl = async () => {
    const programmeName = await getProgrammeName(agreementDatas?.ClientOffer?.ClientOfferName);
    setProgrammeNameLBL(programmeName);
  }

  const onCancelClick = () => {
    setOpenCancelPopup(!openCancelPopup);
  }

  return (
    <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'} w="full">
      <MainStackLayout gap={0} spacing={0}>
        <Box w={{ base: 'full', lg: '80%' }} marginX={{ base: '', lg: 'auto' }} mb={{ base: '0', lg: '20px' }}>
          <SubHeader
            backText={'Back'}
            allowBackButtonClick={false}
            showCancelButton={true}
            showBackButton={false}
            cancelText={Scripts.CancelSR.cancelBtnText}
            onCancelClick={onCancelClick}>
          </SubHeader>
        </Box>
        <Box
          id={'myasurion-profile-avatar'}
          w={'full'}
          h={'auto'}
          p={'16px 16px'}
          maxWidth={{ base: 'full', lg: '60%' }}
          marginX={'auto'}
        >
            <Box>
              {!isBYOD && SwapLBL &&
              <Box>
              <Text as="h6" fontSize={"18px"} mb="20px">
              <TextScript
                  text={'Please note that your device is still covered under the 1 year Original Equipment Manufacturer warranty, you may refer to the OEM authorized dealer as well.'}
                  translationKey={`${OemWarrantyKeys.PageName}/${OemWarrantyKeys.TextScript1}`}/>
              </Text>
              <Text as="h6" fontSize={"18px"} mb="20px">
              <TextScript
                  text={'However, if you wish to proceed with the device {1}, please be informed that once you have completed the device exchange with us, the Original Equipment Manufacturer’s warranty of your device will be void.'}
                  translationKey={`${OemWarrantyKeys.PageName}/${OemWarrantyKeys.TextScript2}`}
                  dynamicValues={[SwapLBL?.toLowerCase() || 'swap']}/>
              </Text>
              </Box>}
              {isBYOD && SwapLBL &&
              <Text as="h6" fontSize={"18px"} mb="20px">
              <TextScript
                  text={'If you wish to proceed with the device {1}, please be informed that once you have completed the device exchange with us, the Original Equipment Manufacturer’s warranty of your device will be void.'}
                  translationKey={`${OemWarrantyKeys.PageName}/${OemWarrantyKeys.TextScriptByod}`}
                  dynamicValues={[SwapLBL?.toLowerCase() || 'swap']}/>
              </Text>
              }
              { programmeNameLBL && 
                <Text as="h6" fontSize={"18px"} mb="20px">
                  {/* {isSS4 || isBYOD? */}
                    <TextScript
                      text={'Your device will be covered with our {2} warranty under {1}.'}
                      translationKey={`${OemWarrantyKeys.PageName}/${OemWarrantyKeys.TextScript3}`}
                      dynamicValues={[programmeNameLBL, isBYOD ? '12 months' : getOEMWarranty(agreementDatas?.ClientOffer?.ClientOfferName, features)]}/>
                    {/* :
                    <TextScript
                      text={'Your device will be covered with our 6 months warranty under {1}.'}
                      translationKey={`${OemWarrantyKeys.PageName}/${OemWarrantyKeys.TextScript4}`}
                      dynamicValues={[programmeNameLBL]}/>
                  } */}
                </Text>
              }
              <Heading fontSize="18px" mb="20px">
              <TextScript
                  text={'Do you agree with these terms?'}
                  translationKey={`${OemWarrantyKeys.PageName}/${OemWarrantyKeys.TextAgreeQuestion}`}/>
              </Heading>
            </Box>
          <Flex minWidth="max-content" alignItems="center" gap={{ base: '2', lg: '0' }}>
            <Button
              colorScheme="#000000"
              variant="outline"
              width="auto"
              paddingX={'20px'}
              height="48px"
              borderColor={'black'}
              onClick={onCancelClick}
              mr={{ base: '0', lg: '5' }}
            >
              <TextScript
                  text={'Decline'}
                  translationKey={`${OemWarrantyKeys.PageName}/${OemWarrantyKeys.DeclineButton}`}/>
            </Button>
            <Spacer display={{ base: 'block', lg: 'none' }} />
            <Button
              colorScheme="#000000"
              variant="outline"
              width="auto"
              paddingX={'20px'}
              height="48px"
              borderColor={'black'}
              onClick={Accept}
            >
             <TextScript
                  text={'Accept'}
                  translationKey={`${OemWarrantyKeys.PageName}/${OemWarrantyKeys.AcceptButton}`}/>
            </Button>
          </Flex>
        </Box>
      </MainStackLayout>
      <CancelSrPopup
        closeCancelPopup={setOpenCancelPopup}
        openCancelPopup={openCancelPopup}
        />
    </Box>
  );
}

export default OEMWarranty;
