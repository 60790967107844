import { useConfig } from '@MGPD/myasurion-shared';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  CircularProgress,
} from '@chakra-ui/react';
import React, { useRef } from 'react';

interface PaymentStatusAlertProps {
  isOpen: boolean;
  onClose: () => void;
}

const ECPayStatusAlert: React.FC<PaymentStatusAlertProps> = ({ isOpen, onClose }) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { primary } = colors;


  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Payment Status
          </AlertDialogHeader>

          <AlertDialogBody>
            <CircularProgress isIndeterminate color={primary} />
            <span style={{ marginLeft: '1rem' }}>Processing your payment...</span>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button colorScheme="primary" ref={cancelRef} onClick={onClose}>
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ECPayStatusAlert;
