import React from 'react';
import { useSessionStorage, TextScript, useAccount, useConfig, getCarrierByDomain, getTypeByDomain } from '@MGPD/myasurion-shared';
import { Box, Flex, Text, Button, Link } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { assetDataKey, btChargeOrderKey, btProcessPaymentKey, btSubmitOrderKey, ResumeFlowDataKey, serviceFeeKey, storeIdKey, deliveryTypeKey, processIncidentKey } from '../../modules/config/constants';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuid } from "uuid";
import { useCentralStore } from '../../store/appContext';
import ActionTypes from '../../store/actionTypes';
import PaymentScript from '../../Scripts/asurion-script.json'
import PaymentKeys from '../../Scripts/translationsKeys.json'
import { BillingGatewayRegion, BillingGatewayService, Environment, Locale, PaymentUI, PaymentUIReference } from 'fast-payments-BGBT/microui';
import { headers } from '../../services/headers'
import { callLoggerApi, convertDateToUTC, getGTMData, isBatteryReplacement, isDeviceRefresh, isDeviceRepair, isEWSClaim, isInWarrantyOEM, isMalfunction, isPUR, isReplacement, isScreenRepair, isScreenRepairBatReplacement, isSwap, isWalkIn, saveError, showErrorPage } from '../../utils/utills';
import { chargeOrderApi, getPciToken, logGTMEvent, processOveragePayment, repairRequestSchedule, submitOrderApi, updateStatus } from '../../services/api';
import { getCurrencySymbol } from '../../utils/localization-helper';
import { hasMultiDeviceSupport } from '../../utils/featuresUtills';
import { GTM_EVENT } from '../../utils/constant';

function BGBTPaymentNew() {
    const globalState = useCentralStore();
    const [account] = useAccount({ firstname: 'firstName', lastname: 'lastName', email: 'email' });
    const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
    const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);

    const showLoader = (val:boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val })
    }
    const showPaymentMessage = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.SET_PAYMENT_MESSAGE, payload: val });
    }

    const _locationCoordinates = globalState?.state?.locationCoordinates
    const agreementData: any = globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0]
    const userAddress = agreementData?.Address
    const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
    const R_ServiceRequestId = R_ServiceRequests?.ServiceRequestId;
    const R_CustomerCaseId = R_ServiceRequests?.CustomerCaseId;
    const R_ServiceOrderId = R_ServiceRequests?.ServiceOrder?.ServiceOrderId;
    const R_ServiceOrderNumber = R_ServiceRequests?.ServiceOrder?.ServiceOrderNumber;
    const R_IncidentType = R_ServiceRequests?.IncidentType;
    const [ServiceFeeData] = useSessionStorage<any>(serviceFeeKey, null);
    const LFLSRFFees =
        ServiceFeeData &&
        ServiceFeeData?.filter((item: any) => item?.IncidentType === R_IncidentType?.toUpperCase())?.[0]
        ?.Fees?.TotalAmount;
    const [_paymentMethod] = useState<string>("");
    const [, setOpenPopup] = useState<boolean>(false);    
    const [, setClientToken] = useState<string>('');
    const [gettPciTokenResponse, setGetPciTokenResponse] = useState<string>('');
    const [chargeOrderResponse, setChargeOrderResponse] = useSessionStorage<any>(btChargeOrderKey, null);
    const [, setProcessPaymentResponse] = useSessionStorage<any>(btProcessPaymentKey, null);
    const [, setSubmitOrderResponse] = useSessionStorage<any>(btSubmitOrderKey, null);
    const [srDeliveryType] = useSessionStorage<any>(deliveryTypeKey, null);
    const [StoreId] = useSessionStorage<any>(storeIdKey, null);
    const isPickup = isPUR(srDeliveryType);
    const [walkingDate] = useSessionStorage<any>('storeDate', null);
    const [walkingTime] = useSessionStorage<any>('storeTime', null);
    const initializeData = globalState?.state?.sessionResponse?.InitializeResponse
    const interactionData = globalState?.state?.sessionResponse?.Interaction
    const [assetData] = useSessionStorage<any>(assetDataKey, null);
    const[paymentFail, setPaymentFail] = useState<boolean>(false);
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { primary, bodyTextColor } = colors;
    let assetData1 =  agreementData?.Assets?.Asset?.filter((asset: any) => asset?.AssetInstance === 'ENROLLED')?.[0];
    if(assetData1 === undefined) {
        assetData1 = agreementData?.Assets?.Asset?.filter(
            (asset: any) => asset?.AssetInstance === 'REPLACED'
        )?.[0];
    }

    const _ServiceOrderId = globalState?.state?.serviceOrderData?.CreateServiceOrderResults?.ServiceOrderId || R_ServiceOrderId
    const _ServiceOrderNumber = globalState?.state?.serviceOrderData?.CreateServiceOrderResults?.ServiceOrderNumber || R_ServiceOrderNumber
    const incType = globalState?.state?.processIncData?.ServiceRequest?.IncidentType || R_IncidentType
    const isRepair =
        isDeviceRefresh(incType) ||
        isDeviceRepair(incType) ||
        isBatteryReplacement(incType) ||
        isScreenRepair(incType) ||
        isScreenRepairBatReplacement(incType) ||
        (isMalfunction(incType) && isInWarrantyOEM(serviceRequestData)) ||
        isEWSClaim(serviceRequestData, incType);
    const processIncData = globalState?.state?.processIncData
    const R_SRF = ResumeFlowData?.ServiceFee?.ServiceFee && parseFloat(ResumeFlowData?.ServiceFee?.ServiceFee)?.toFixed(2);
    const [totalSRFee, setTotalSRFee] = useState<any>(globalState?.state?.srf || R_SRF)
    const [currencySymbol, setCurrencySymbol] = useState('$')
    useEffect(() => {
        if (globalState?.state?.chargeNightDeliveryFee) {
            setTotalSRFee(Number(globalState?.state?.nightDeliveryFee))
        } else {
            setTotalSRFee((Number(globalState?.state?.srf || R_SRF || LFLSRFFees) + Number(globalState?.state?.nightDeliveryFee) + Number(globalState?.state?.doorStepAddOnData.fee))?.toFixed(2))
        }
    }, [globalState?.state?.nightDeliveryFee, R_SRF, LFLSRFFees, globalState?.state?.doorStepAddOnData.fee])

    useEffect(() => {
        clearPaymentDetails();
        getCurrency();
    }, [])

    useEffect(() => {
        if(!globalState?.state?.selectedPaymentMethod){
            clearPaymentDetails();
        }
    }, [globalState?.state?.selectedPaymentMethod])

    const getCurrency = async () => {
        const currency = await getCurrencySymbol();
        setCurrencySymbol(currency);
    }
    const [] = useSessionStorage<any>(btChargeOrderKey, null);
    const hasDTAddOnAcquired = false;
    function generateUniqueId() {
        return uuid();
    }

    let _gpayPaymentData: any;

    const navigate = useNavigate();   
    const [, setCheckboxChecked] = useState(false);
    const [, setBldgValue] = useState<string>();
    const [, setAddress] = useState<string>();
    const [, setUnitNumber] = useState<string>();
    const [, setPostalCode] = useState<any>();
    const [, setIsInavlidBldgValue] = useState<boolean>(false);
    const [, setIsInavlidAddress] = useState<boolean>(false);
    const [, setIsInavlidUnitNumber] = useState<boolean>(false);
    const [, setIsInavlidPostalCode] = useState<boolean>(false);
    const [isOpenPayment,setOpenPayment] = useState<boolean>(false);

    const setPaymentError = (errmsg: string) => {
        globalState?.dispatch({ type: ActionTypes.SET_PAYMENT_ERROR, payload: errmsg });
    }

    const getLocale = (carrier: any) => {
        let locale;
        switch (carrier) {
            case 'samsung':
            case 'telstra':
                locale = Locale.enAU
                break;
            case 'm1':
            case 'singtel':
            case 'starhub':
            case 'sh_device_care':
                locale = Locale.enSG
                break;
            case 'ais':
            case 'true':
            case 'asurion_techcare':
                locale = Locale.enTH
                break;
            case 'celcom':
            case 'maxis':
                locale = Locale.msMY
                break;
            default:
                locale = Locale.enSG
        }
        return locale;
    };
    const client = import.meta.env.VITE_ASURION_CLIENT.toLowerCase()
    const locale = getLocale(client);
    const splitName = agreementData?.Customers?.Customer[0]?.FirstName.split(' ')

    const callSubmitOrder = async () => {
        showLoader(true);
        const SubmitServiceOrderParameters = {
              ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
              InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
              ServiceOrderId: _ServiceOrderId,
          };
          try {
            showLoader(false);
            navigate('/claimconfirmation', { replace: true })
            submitOrderApi({ SubmitServiceOrderParameters }, globalState?.state?.findAgreementSessionData)
            .then((response: any) => {
                setSubmitOrderResponse(response?.SubmitServiceOrderResults);
                showPaymentMessage(false)
                const GTMData = getGTMData(GTM_EVENT.SUBMIT_ORDER_EVENT, globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0], 
                    getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)
                    logGTMEvent(GTMData)
            }).catch(error => {
                console.error(error);
                showPaymentMessage(false)
                callLoggerApi(error, globalState);
            })
        } catch (error) {
            showLoader(false);
            showPaymentMessage(false)
            console.error(error);
            setPaymentError('Payment failed')
            saveError(error, globalState);
            showErrorPage(navigate)
        }
    }

    const callProcessPaymentApi = async (
        ProcessPaymentParameters: any,
        ServiceOrderId: any,
        ChargeOrderId: any,
      ) => {
        if(ChargeOrderId) {
            showLoader(true);
            try {
                const response = await axios.post(
                    `/swap/claim/api/v3/processpayment/${ServiceOrderId}/${ChargeOrderId}`,
                    { ProcessPaymentParameters  }, { headers: { ...headers, 'access-token' : globalState?.state?.findAgreementSessionData, 'Asurion-interactionlineid': interactionData?.InteractionLine.InteractionLineId }, }
                );
                setProcessPaymentResponse(response?.data?.ProcessPaymentResults);
                if (globalState?.state?.chargeNightDeliveryFee) {
                    showPaymentMessage(false);
                    callProcessOveragePaymentApi();
                }
                else if (
                    response?.data?.ProcessPaymentResults?.Result?.PaymentStatus?.toUpperCase() ===
                        'APPROVED' &&
                    response?.data?.ProcessPaymentResults?.ChargeOrder?.ChargeOrderStatus?.toUpperCase() ===
                        'CHARGED' &&
                    response?.data?.ProcessPaymentResults?.Result?.Code?.toUpperCase() === 'BG-0'
                ) {
                    setPaymentFail(false);
                    callSubmitOrder();
                } else {
                    showLoader(false);
                    setPaymentFail(true);
                    setGetPciTokenResponse('');
                    setUuidNew(generateUniqueId()?.replace(/-/g, '')?.toUpperCase());
                    showLoader(true);
                    getPciToken({
                        GetPCITokenRequest: {
                            IsSCRClaim: isRepair ? true : false,
                            ReferenceId: generateUniqueId()?.replace(/-/g, '')?.toUpperCase(),
                            AgreementId: agreementData?.AgreementId,
                            InteractionLineId:
                                interactionData?.InteractionLine.InteractionLineId,
                            FirstName: agreementData?.Customers?.Customer[0]?.FirstName || agreementData?.Customers?.Customer[0]?.FullName,
                            LastName: agreementData?.Customers?.Customer?.[0]?.LastName === '' ? splitName && splitName[1] : agreementData?.Customers?.Customer?.[0]?.LastName,
                            PhoneNumber:
                                assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
                            AuthorizationAmount: totalSRFee?.toString(),
                        },
                    },
                    globalState?.state?.findAgreementSessionData
                ).then((getPciTokenResponse) => {
                        setClientToken(getPciTokenResponse?.clientToken);
                        setGetPciTokenResponse(getPciTokenResponse?.GetPCITokenResponse?.token);
                        setReferenceId(getPciTokenResponse?.GetPCITokenResponse?.ReferenceId);
                        showLoader(false);
                    })
                    .catch(() => {
                        showLoader(false);
                        showPaymentMessage(false);
                    });
                }               
            } catch (error) {
                showLoader(false);
                setPaymentError('Payment failed')
                callLoggerApi(error, globalState);
                console.error(error);
                showPaymentMessage(false)
            }
        }
      };

    const callProcessPayment = (ChargeOrder: any, _payment_method: any, _chargOrderPayload: any) => {
        const paymentMethod = _payment_method || initializeData?.isBraintree?.paymentMethod
        const ProcessPaymentParameters: any = {
            ChargeOrderId: ChargeOrder?.ChargeOrderId,
            ServiceOrderId: _ServiceOrderId,
            ApplicationId: initializeData?.ApplicationId,
            ClientId: initializeData?.ClientId,
            InteractionLineId:interactionData?.InteractionLine?.InteractionLineId,
            ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
            ServiceOrderNumber: _ServiceOrderNumber?.toString(),
            AuthorizeAdditionalCharges: false,
            ValidateAddress: false,
            ValidateCardSecurityCode: false,
            IpAddressForFraud: interactionData?.SourceIpAddress,
            FulfillmentOption: "RPLCMNT",
            IsSCRClaim: isRepair ? true : false,
            PaymentInformation: {
                PaymentMethodType: paymentMethod,
                CardType: ChargeOrder?.CardType, // in future, CardType check for other payment method
                CardBrand: paymentMethod === "CRE" ? ChargeOrder?.CardBrand : paymentMethod === "PYPL" ? ChargeOrder?.CardBrand : "",
                CardCheckNumber: ChargeOrder?.CardCheckNumber?.toString(),
                AccountHolder: {
                    FirstName: paymentMethod === 'GGLP' ? _gpayPaymentData?.paymentMethodData?.info?.billingAddress?.name : paymentMethod === 'APLP' ? _chargOrderPayload?.CardHolderFirstName : _chargOrderPayload?.CardHolderFirstName,//agreementData?.Customers?.Customer[0]?.FirstName,
                    LastName: paymentMethod === 'GGLP' || paymentMethod === 'APLP' ? '' : _chargOrderPayload?.CardHolderLastName,//agreementData?.Customers?.Customer[0]?.LastName,
                    CompanyName: "",
                    IdentificationType: "NATIONALID",
                    IdentificationNumber: agreementData?.IdentificationNumber,
                }
            },
            Address: userAddress,
            ContactDetails: {
                phone: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
                workPhone: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
                cellPhone: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
                email: account?.email,
            },
            AutoCompleteId: ChargeOrder?.AutoCompleteId,
        };

        callProcessPaymentApi(
            ProcessPaymentParameters,
            _ServiceOrderId,
            ChargeOrder?.ChargeOrderId,
        );
      };

    const callCreateRepairRequest = async(_payment_method?:any, _chargOrderPayload?:any) => {
        showLoader(true)
        let CreateRepairRequestParams = {        
            InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,        
            CustomerCaseId: processIncData?.CustomerCaseId || R_CustomerCaseId,        
            ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,        
            ServiceOrderId: _ServiceOrderId,        
            RepairRequestType: isPickup ? 'PUR' : 'WALKIN',
            ServiceProviderId: isWalkIn(srDeliveryType) ? StoreId : undefined ,
            AssetMake: "APPLE",
            Longitude: _locationCoordinates?.lng,//103.840400,
            Lattitude: _locationCoordinates?.lat,//1.428850,
            AppointmentDate: isWalkIn(srDeliveryType)
            ? convertDateToUTC(`${walkingDate} ${walkingTime}`, 'YYYY-MM-DD HH:mm:ssa', initializeData?.TimeZone)
            : globalState?.state?.pickupDate,
        }
        try {
            await axios.post(
                `/swap/claim/api/repairrequest/create`,
                { CreateRepairRequestParams  }, { headers: { ...headers, 'access-token' : globalState?.state?.findAgreementSessionData, 'Asurion-interactionlineid': interactionData?.InteractionLine.InteractionLineId }, }
            );
            callSubmitOrder();
        }
        catch (error) {
            showPaymentMessage(false)
            showLoader(false)
            saveError(error, globalState);
            showErrorPage(navigate)
        }
    }

    const [uuidNew, setUuidNew] = useState(uuid()?.replace(/-/g, '')?.toUpperCase())
    
    const [, setReferenceId] = useState('');
    const callClientToken = () => {
        showLoader(true);
        chargeOrderCOD;
        getPciToken(
            { GetPCITokenRequest: {
                IsSCRClaim: isRepair ? true : false,
                ReferenceId: uuidNew,
                AgreementId: agreementData?.AgreementId,
                InteractionLineId: interactionData?.InteractionLine.InteractionLineId,
                FirstName: agreementData?.Customers?.Customer[0]?.FirstName || agreementData?.Customers?.Customer[0]?.FullName,
                LastName: agreementData?.Customers?.Customer?.[0]?.LastName === '' ? splitName && splitName[1] : agreementData?.Customers?.Customer?.[0]?.LastName,
                PhoneNumber: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
                AuthorizationAmount: totalSRFee?.toString(),
            }},
          globalState?.state?.findAgreementSessionData
        )
          .then((getPciTokenResponse) => {
            setClientToken(getPciTokenResponse?.clientToken);
            setGetPciTokenResponse(getPciTokenResponse?.GetPCITokenResponse?.token);
            setReferenceId(getPciTokenResponse?.GetPCITokenResponse?.ReferenceId);
            showLoader(false);
          })
          .catch(() => {
            showLoader(false);
            showPaymentMessage(false);
          });
    };

    const callChargeOrderApi = async (setOpenPopup: any, ChargeOrder: any) => {
        const _payment_method = ChargeOrder?.PaymentMethodType
        const _chargOrderPayload = ChargeOrder
        const CreateChargeOrderParameters: any = {
            InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
            ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
            CustomerCaseId: processIncData?.CustomerCaseId || R_CustomerCaseId,
            AssetCategoryId: hasMultiDeviceSupport(agreementData?.ClientOffer?.ClientOfferName) ? assetData?.AssetCategory?.AssetCategoryId : undefined,
            AgreementId: agreementData?.AgreementId,
            ClientId: interactionData?.ClientId,
            ClientChannelId: interactionData?.ClientChannelId,
            ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
            AssetMakeId: null,//fileClaimDetails?.selectedDevice?.Make?.MakeId,
            Transactionid: gettPciTokenResponse,
            IsSCRClaim: isRepair ? true : false,
            MobileNumber: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
            ChargeOrder: _ServiceOrderId ? ChargeOrder : {
                PaymentMethodType: "COD",
                ChargeOrderStatus: "PREAUTH",
                AddressId: agreementData?.billingAddress?.AddressId,
                AdditionalChargeAuth: "false",
                ServiceOrderId: _ServiceOrderId,
            },
            ServiceOrderId: _ServiceOrderId,
            isRecheduleNightDeliveryFlow: globalState?.state?.chargeNightDeliveryFee ? true :false
        };
        showLoader(true)

        chargeOrderApi(
            { CreateChargeOrderParameters },
            _ServiceOrderId,
            interactionData?.InteractionLine?.InteractionLineId,
            globalState?.state?.findAgreementSessionData
          )
            .then((response) => {
              showLoader(false);
              const ChargeOrder = response?.CreateChargeOrderResults?.ChargeOrder;
              setChargeOrderResponse(ChargeOrder);
              const chargeOrderHold = response?.CreateChargeOrderResults.Holds;
              const isHold = (holds: any) => {
                return holds.some((hold: any) => hold.HoldStatus === 'OPEN');
              };
              if (
                setOpenPopup &&
                chargeOrderHold?.length > 0 &&
                isHold(chargeOrderHold)) {
                setOpenPopup(true);
                showPaymentMessage(false);
                navigate('../reviewhold', { replace: true });
              } else if (isRepair) {
                callCreateRepairRequest(_payment_method, _chargOrderPayload);
              } else {
                callProcessPayment(ChargeOrder, _payment_method, _chargOrderPayload);
              }
            })
            .catch(() => {
                showLoader(false);
                showPaymentMessage(false);
                setPaymentError('Payment failed');
                setGetPciTokenResponse('');
                setUuidNew(generateUniqueId()?.replace(/-/g, "")?.toUpperCase());
                showLoader(true)
                getPciToken(
                    { GetPCITokenRequest: {
                        IsSCRClaim: isRepair ? true : false,
                        ReferenceId: generateUniqueId()?.replace(/-/g, "")?.toUpperCase(),
                        AgreementId: agreementData?.AgreementId,
                        InteractionLineId: interactionData?.InteractionLine.InteractionLineId,
                        FirstName: agreementData?.Customers?.Customer[0]?.FirstName || agreementData?.Customers?.Customer[0]?.FullName,
                        LastName: agreementData?.Customers?.Customer?.[0]?.LastName === '' ? splitName && splitName[1] : agreementData?.Customers?.Customer?.[0]?.LastName,
                        PhoneNumber: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
                        AuthorizationAmount: totalSRFee?.toString(),
                    }},
                  globalState?.state?.findAgreementSessionData
                )
                  .then((getPciTokenResponse) => {
                    setClientToken(getPciTokenResponse?.clientToken);
                    setGetPciTokenResponse(getPciTokenResponse?.GetPCITokenResponse?.token);
                    setReferenceId(getPciTokenResponse?.GetPCITokenResponse?.ReferenceId);
                    showLoader(false);
                  })
                  .catch(() => {
                    showLoader(false);
                    showPaymentMessage(false);
                  });
            });
    };

    const chargeOrderRequest = (
        response: any,
        paymentMethod: any,
        getCCDetails: any,
    ) => {
        let ChargeOrder = {
            PaymentMethodType: paymentMethod,
            ChargeOrderStatus: "PREAUTH",
            AddressId: agreementData?.billingAddress?.AddressId,
            AdditionalChargeAuth: "false",
            ChargeOrderCardBrand:
                paymentMethod === "CRE" ? response?.additionalData?.cardBrand : paymentMethod === "PYPL" ? 'PaypalAccount' : '',
            ChargeOrderCardType: paymentMethod === "PYPL" ? "PYPL" : "CREDIT",
            CardCheckNumber: parseInt("0"),
            CardHolderFirstName: (paymentMethod === "CRE" || paymentMethod === "APLP") ? (response?.billingInformation?.contact?.firstName || response?.billingInformation?.contact?.firstName) : (response?.billingInformation?.contact?.firstName?.split(" ")[0] || response?.billingInformation?.contact?.firstName ),
            CardHolderLastName: (paymentMethod === "CRE" || paymentMethod === "APLP") ? (response?.billingInformation?.contact?.lastName || '') : (response?.billingInformation?.contact?.lastName?.split(" ")[1] || response?.billingInformation?.contact?.lastName),
            OverageAmount: 1,
            ccDetails: paymentMethod === "CRE" ? getCCDetails() : undefined,
        };
        callChargeOrderApi(setOpenPopup, ChargeOrder)
    };

    const next = (response: any, paymentMethod: any) => {
        const getCCDetails = () => {
            let ccmask = "1111";
            const name = "abc";
            let cardType = "VISA";
            let cvv = "";
            return {
                ccmask,
                name,
                cardType,
                cvv,
            };
        };
        chargeOrderRequest(
            response,
            paymentMethod,
            getCCDetails,
        );
    };

    const submitPayment = async () => {
        paymentUI?.current?.continue();
    };

    const clearPaymentDetails = () => {
        setCheckboxChecked(false)
        globalState?.dispatch({ type: ActionTypes.SET_PAYMENT_METHOD, payload: '' })
        setOpenPayment(false)
        setIsInavlidBldgValue(false);
        setIsInavlidAddress(false);
        setIsInavlidUnitNumber(false);
        setIsInavlidPostalCode(false);
        setBldgValue('');
        setAddress('');
        setUnitNumber('');
        setPostalCode('');
    }

    useEffect(() => {        
        if(globalState?.state?.BTClientTokenData === '') {
            callClientToken()
        }
    }, [globalState?.state?.BTClientTokenData])

    const chargeOrderCOD = () => {
        showLoader(true);
        const ChargeOrder = {
          PaymentMethodType: 'COD',
          IncidentType: incType,
          ChargeOrderStatus: "PREAUTH",
          AddressId: agreementData?.billingAddress?.AddressId,
        };
        const CreateChargeOrderParameters: any = {
          InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
          ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
          CustomerCaseId: processIncData?.CustomerCaseId || R_CustomerCaseId,
          AgreementId: agreementData?.AgreementId,
          ClientId: interactionData?.ClientId,
          ClientChannelId: interactionData?.ClientChannelId,
          CustomerCaseNumber: processIncData?.CustomerCaseNumber || ResumeFlowData?.CustomerCaseNumber,
          ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
          MobileNumber: assetData?.MobileDeviceNumber,
          ChargeOrder: ChargeOrder,
          ServiceOrderId: _ServiceOrderId,
        };
        chargeOrderApi(
          { CreateChargeOrderParameters },
          _ServiceOrderId,
          interactionData?.InteractionLine?.InteractionLineId,
          globalState?.state?.findAgreementSessionData
        )
        .then((data: any) => {
            showLoader(false);
            const ChargeOrderResponse = data?.CreateChargeOrderResults?.ChargeOrder;
            setChargeOrderResponse(ChargeOrderResponse);
            const chargeOrderHold = data?.CreateChargeOrderResults.Holds;
            const isHold = (holds: any) => {
                return holds.some((hold: any) => hold.HoldStatus === "OPEN");
            };
            callProcessPaymentApi;
            if (
                setOpenPopup &&
                chargeOrderHold?.length > 0 &&
                isHold(chargeOrderHold)
            ) {
                setOpenPopup(true);
                showPaymentMessage(false)
                    navigate('../reviewhold', { replace: true });
            } else if(isRepair) {
                callCreateRepairRequest(ChargeOrder?.PaymentMethodType, ChargeOrder)
            } else {
                callProcessPayment(ChargeOrderResponse, ChargeOrder?.PaymentMethodType, ChargeOrder);
            }
        })
        .catch((err: any) => {
            showLoader(false);
            setPaymentError('Payment failed');
            saveError(err, globalState);
            showErrorPage(navigate);
        });
    };

    const callUpdateStatusAPI = () => {
        showLoader(true);
        updateStatus(globalState?.state?.updateSalesOrderParams, interactionData?.InteractionLine?.InteractionLineId, globalState?.state?.findAgreementSessionData)
            .then((data: any) => {
                showLoader(false);
                navigate('/myplansandservicerequests', { replace: true });
                console.log('updateStatus: ', data);
            })
            .catch((error: any) => {
                showLoader(false);
                console.log(`error is ${error}`);
            });
    };

    const callRepairReqScheduleAPI = () => {
        showLoader(true);
        repairRequestSchedule(
            globalState?.state?.repirScheduleParams,
            interactionData?.InteractionLine?.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((data: any) => {
                showLoader(false);
                callUpdateStatusAPI();
                console.log('callRepairReqScheduleAPI: ', data);
            })
            .catch((error: any) => {
                showLoader(false);
                console.log(`error is ${error}`);
            });
    };

    const callProcessOveragePaymentApi = () => {
        showLoader(true);
        const payloadParams = {
            ProcessOveragePaymentParameters: {
                CustomerDecision: 'NIGHT_DELIVERY_FEE',
                RepairRequestId: R_ServiceRequests?.RepairRequest?.RepairRequestId || generateUniqueId()?.replace(/-/g, "")?.toUpperCase(),
                CustomerCaseId: processIncData?.CustomerCaseId || R_CustomerCaseId,
                ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
                ServiceOrderId: _ServiceOrderId,
                AssetCatalogId: R_ServiceRequests?.EnrolledDevice?.AssetCatalog?.AssetCatalogId,
                UpdatedBy: R_ServiceRequests?.UpdatedBy,
                ServiceOrderLines: {
                    ServiceOrderLine: [
                        {
                        ServiceOrderLineType: 'PHN',
                        Quantity: 1,
                        AssetCatalogId: R_ServiceRequests?.EnrolledDevice?.AssetCatalog?.AssetCatalogId,
                        VendorItemId: '',
                        Priority: 'MNDTRY',
                        VenderItemType: 'PHN',
                        VendorData: {
                            IsLikeForLike: true,
                            IsSimCard: false,
                            IsInStock: true,
                        },
                        },
                    ],
                },
                ChargeOrderType: chargeOrderResponse?.ChargeOrderType,
                ReferenceNumber: ResumeFlowData?.CustomerCaseNumber?.toString(),
                TotalAmount: chargeOrderResponse?.TotalAmount,
                TaxAmount: chargeOrderResponse?.TaxAmount
            },
        };
        processOveragePayment(payloadParams, interactionData?.InteractionLine?.InteractionLineId, globalState?.state?.findAgreementSessionData)
            .then((data: any) => {
                showLoader(false);
                callRepairReqScheduleAPI();
                console.log('callProcessOveragePaymentApi: ', data);
            })
            .catch((error: any) => {
                showLoader(false);
                console.log(`error is ${error}`);
            });
    };

    const PaymentTotal = () => {
        return(
            <Box mt='10px' w={{ base: 'full', lg: '50%' }}>
                <hr />
                <Text my={4} fontWeight={700} fontSize={'18px'}>
                    <TextScript 
                        text={PaymentScript?.Payment?.paymentBreakdown}
                        translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.PaymentBreakdown}`}
                    />
                </Text>
                {!globalState?.state?.chargeNightDeliveryFee &&
                    <Flex mb={3} direction="row" justifyContent="space-between">
                        {(isSwap(incType) || isReplacement(incType) || isMalfunction(incType) || isDeviceRefresh(incType)) &&
                            <Text>
                                <TextScript
                                    text={PaymentScript.Global.SRFLBL}
                                    translationKey={`${PaymentKeys.Global.PageName}/${PaymentKeys.Global.SRFLBL}`}
                                />
                            </Text>
                        }
                        {(!isSwap(incType) && !isReplacement(incType) && !isMalfunction(incType) && !isDeviceRefresh(incType)) &&
                            <Text>
                                <TextScript
                                    text={PaymentScript.Global.RepairFeeLBL}
                                    translationKey={`${PaymentKeys.Global.PageName}/${PaymentKeys.Global.RepairFeeLBL}`}
                                />
                            </Text>
                        }
                        <Text>{currencySymbol}{globalState?.state?.srf || R_SRF || Number(LFLSRFFees)?.toFixed(2)}</Text>
                    </Flex>
                }
                {globalState?.state?.nightDeliveryFee &&
                    <Flex mb={3} direction="row" justifyContent="space-between">
                        <Text>
                            <TextScript 
                                text={PaymentScript?.Payment?.nightdelivery}
                                translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.nightdelivery}`}
                            />
                        </Text>
                        <Text>{currencySymbol}{globalState?.state?.nightDeliveryFee || 0}</Text>
                    </Flex>
                }
                { hasDTAddOnAcquired &&
                  <Flex mb={3} direction="row" justifyContent="space-between">
                    <Text>
                        <TextScript 
                            text={PaymentScript?.Payment?.dataTransfer}
                            translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.dataTransfer}`}
                        />
                    </Text>
                    <Text>{currencySymbol}{globalState?.state?.doorStepAddOnData.fee || 0}</Text>
                </Flex>
                }
                <hr style={{
                        border: 'none',
                        borderTop: '2px dotted #adaaaa',
                    }}/>
                <Flex my={2} direction="row" justifyContent="space-between">
                    <Text>
                        <TextScript 
                            text={PaymentScript?.Global?.Subtotal}
                            translationKey={`${PaymentKeys.Global.PageName}/${PaymentKeys.Global.Subtotal}`}
                        />
                    </Text>
                    <Text>{currencySymbol+totalSRFee}</Text>
                </Flex>
                <Flex mb={2} direction="row" justifyContent="space-between">
                    <Text fontWeight={700}>
                        <TextScript 
                            text={PaymentScript?.Global?.Total}
                            translationKey={`${PaymentKeys.Global.PageName}/${PaymentKeys.Global.Total}`}
                        />
                    </Text>
                    <Text fontWeight={700}>{currencySymbol+totalSRFee}</Text>
                </Flex>
            </Box>
        )
    }

    let paymentUI = React.useRef<PaymentUIReference | null>(null);
    let Maid = isRepair ? initializeData?.BillingProgramID?.scrMaid : initializeData?.BillingProgramID?.maid;
    const billingGatewayService = new BillingGatewayService({
        environment: import.meta.env.VITE_ENV === 'prod' ? Environment.PROD : Environment.QA,
        region: BillingGatewayRegion.APAC,
        applicationId: initializeData?.ClientName?.toLowerCase(),
        billingProgramId: Maid,
    });
    const handlePaymentSuccess = (response: any) => {
        console.log('Payment success:', response);
        let paymentMethod = response?.paymentMethod === "creditCard" ? "CRE" : "PYPL"
        next(response, paymentMethod);
    };
    
    const handlePaymentFailure = (error: any) => {
        console.error('Payment failure:', error);
        if (error?.status === 'error') {
            setGetPciTokenResponse('');
            setUuidNew(generateUniqueId()?.replace(/-/g, '')?.toUpperCase());
            showLoader(true);
            getPciToken(
                { GetPCITokenRequest: {
                    IsSCRClaim: isRepair ? true : false,
                    ReferenceId: generateUniqueId()?.replace(/-/g, "")?.toUpperCase(),
                    AgreementId: agreementData?.AgreementId,
                    InteractionLineId: interactionData?.InteractionLine.InteractionLineId,
                    FirstName: agreementData?.Customers?.Customer[0]?.FirstName || agreementData?.Customers?.Customer[0]?.FullName,
                    LastName: agreementData?.Customers?.Customer?.[0]?.LastName === '' ? splitName && splitName[1] : agreementData?.Customers?.Customer?.[0]?.LastName,
                    PhoneNumber: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
                    AuthorizationAmount: totalSRFee?.toString(),
                }},
              globalState?.state?.findAgreementSessionData
            )
              .then((getPciTokenResponse) => {
                setClientToken(getPciTokenResponse?.clientToken);
                setGetPciTokenResponse(getPciTokenResponse?.GetPCITokenResponse?.token);
                setReferenceId(getPciTokenResponse?.GetPCITokenResponse?.ReferenceId);
                showLoader(false);
              })
              .catch(() => {
                showLoader(false);
                showPaymentMessage(false);
              });
        }
    };

    let billingInformation = {
        address: {
            address1: userAddress?.Address1,
            address2: userAddress?.Address2,
            city: userAddress?.City,
            stateOrProvince: userAddress?.StateProvinceCode,
            postalCode: userAddress?.PostalCode,
        }
    };

    return (
        <Box pb={'100px'} mb={'5'}>
            <Text fontSize="32px" mb={'16px'} textAlign={{ base: 'start', lg: 'center' }}>
                <TextScript 
                    text={PaymentScript?.Payment?.paymentOptionHeading}
                    translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.PaymentOptionHeading}`}
                />
            </Text>
            {paymentFail && <Text align={'center'} fontSize={'sm'} px={4} pb={1} textColor={'red'}>Payment failed please retry</Text>}
            {gettPciTokenResponse && 
                <Box className="flex pt-8 pb- mb-20 flex-col md:flex-row">
                    <PaymentUI
                        applicationSession={gettPciTokenResponse}
                        billingGatewayService={billingGatewayService}
                        onPaymentSuccess={handlePaymentSuccess}
                        onPaymentFailure={handlePaymentFailure}
                        onLoading={() => console.log('Form loading...')}
                        onLoaded={() => console.log('Form loaded and ready to present...')}
                        ref={paymentUI}
                        hideContinueButton={true}
                        locale={locale}
                        billingInformation={billingInformation}
                    />
                </Box>
            }
            <Box mt={3} py={4} position='fixed' bottom='0' w={'100%'} bg={'#fff'} style={{ boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.08)', transform: 'translate(-50%, 0%)'}} left={'50%'}>
                <Flex direction="column" w={{ base: '100%', lg: '69%'}} paddingX={'10px'} marginX={{base: '0', lg: 'auto'}}>
                    <Flex direction='row' justifyContent="space-between">
                        <Box>
                            <Box display={'block'}>
                                <Text fontWeight={700} fontSize={'14px'}>
                                    <TextScript 
                                        text={`${PaymentScript?.Payment?.totalFee} ${PaymentScript?.Payment?.incGST}`}
                                        translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.TotalFeeincGST}`}
                                    />
                                </Text>
                            </Box>
                            <Flex>
                                <Text fontSize={'24px'} fontWeight={700}>{currencySymbol+totalSRFee}</Text>
                                {globalState?.state?.selectedPaymentMethod !== '' &&
                                    <Link marginY={'auto'} marginLeft={'10px'} fontSize={'14px'} color={bodyTextColor} textDecoration={"underline"} onClick={() => setOpenPayment(!isOpenPayment)}>
                                        {isOpenPayment && 
                                            <Text>
                                                <TextScript 
                                                    text={PaymentScript?.Payment?.lblBreakdown}
                                                    translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.breakdown}`}
                                                    dynamicValues={[PaymentScript?.Payment?.closeBreakdown]}
                                                />
                                            </Text>
                                        }
                                        {!isOpenPayment && 
                                            <Text>
                                                <TextScript 
                                                    text={PaymentScript?.Payment?.lblBreakdown}
                                                    translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.breakdown}`}
                                                    dynamicValues={[PaymentScript?.Payment?.viewBreakdown]}
                                                />
                                            </Text>
                                        }
                                    </Link>
                                }
                            </Flex>
                        </Box>
                        <Button
                            variant="outline"
                            h={'auto'}
                            paddingX={'30px'}
                            _hover={{ bg: 'green.400', color: 'white' }}
                            onClick={() => submitPayment()}
                            style={{cursor: '', background: primary, color: 'white'}}
                        >
                            <TextScript 
                                text={PaymentScript?.Global?.Submit}
                                translationKey={`${PaymentKeys.Global.PageName}/${PaymentKeys.Global.Submit}`}
                            />
                        </Button>
                    </Flex>
                    {isOpenPayment &&
                        <PaymentTotal />
                    }
                </Flex>
            </Box>
        </Box>
   );
}

export default BGBTPaymentNew;