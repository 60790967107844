import {
  TextScript,
  getCarrierByDomain,
  getCountryByCarrier,
  useAccount,
} from '@MGPD/myasurion-shared';
import { Button } from '@chakra-ui/button';
import { Input } from '@chakra-ui/input';
import { Box, HStack, Spacer, Text } from '@chakra-ui/layout';
import { useState } from 'react';

export interface ProfileFieldProps {
  fieldKey: string;
  label: string;
  value: string;
  editable: boolean;
  onSave?: (fieldKey: string, newValue: string) => void;
}

enum MODE {
  EDIT = 'edit',
  VIEW = 'view',
}

const ProfileField: React.FC<ProfileFieldProps> = ({
  fieldKey,
  label,
  value,
  editable,
  onSave,
}) => {
  const carrier = getCarrierByDomain();
  const cc = getCountryByCarrier(carrier);
  const countryCodeNumber = cc.countryCodeNumber;

  const [toggleMode, setToggleMode] = useState<string>(MODE.VIEW);
  const [currentValue, setCurrentValue] = useState<string>('');
  const [account] = useAccount({ profileId: '', userId: '', countryCallingCode: '' });

  return (
    <Box p={'16px'} w={'full'} fontSize={'18px'} fontWeight={700} minH="86px" maxH="180px">
      <TextScript text={label} translationKey={`DashboardDetails/${fieldKey}`} />
      {editable ? (
        <HStack w={'full'}>
          {toggleMode === MODE.VIEW && (
            <Text fontSize={'18px'} fontWeight={300}>
              {fieldKey === 'mdn'
                ? `(${account?.countryCallingCode || countryCodeNumber}) ${value}`
                : value}
            </Text>
          )}
          {toggleMode === MODE.EDIT && (
            <Input
              value={value || currentValue}
              size={'lg'}
              onChange={(event) => {
                setCurrentValue(event.target.value);
              }}
            />
          )}
          <Spacer />
          <Button
            variant={'link'}
            fontWeight={300}
            onClick={() => {
              if (toggleMode === MODE.EDIT) {
                onSave &&
                  currentValue &&
                  value !== currentValue &&
                  onSave(fieldKey, currentValue || '');
              }
              setToggleMode(toggleMode === MODE.VIEW ? MODE.EDIT : MODE.VIEW);
            }}
          >
            {toggleMode === MODE.EDIT ? 'Save' : 'Edit'}
          </Button>
        </HStack>
      ) : (
        <Text fontSize={'18px'} fontWeight={300}>
          {fieldKey === 'mdn'
            ? `(${account?.countryCallingCode || countryCodeNumber}) ${value}`
            : value}
        </Text>
      )}
    </Box>
  );
};
export default ProfileField;
