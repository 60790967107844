/* eslint-disable no-console */
import MainStackLayout from '../../layout/MainStackLayout';
import '../../services/api';
import { ImeiDetails, SubHeader, getTypeByDomain } from '@MGPD/myasurion-shared';
import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import CancelSrPopup from './../myclaims/CancelSrPopup.tsx';
import { getMakeModel, validateImei } from '../../services/api';
import { getRedirectUrl, saveError, showErrorPage } from '../../utils/utills.tsx';
import { apiCallGetMakeModel } from '../../utils/featuresUtills.tsx';

function ImeiDetail() {

const ImeiDetailsScrips = Scripts.UpdateDeviceDetails.ImeiDetails
  const globalState = useCentralStore();
  const navigate = useNavigate();
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [imeiVal, setImei] = useState<string>('');
  const [isImeiInvalid, setImeiInvalid] = useState(false);

  const interactionData = globalState?.state?.sessionResponse?.Interaction;
  const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;
  const agreementData1: any = globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  
  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };

  const onCancelClick = () => {
    // setOpenCancelPopup(!openCancelPopup);
    window.location.href = getRedirectUrl(getTypeByDomain());
  };

  
  const handleSubmit = () => {
    // validateImei(imeiVal, deviceType = undefined, interactionLineId)
    setLoading(true)
    if(apiCallGetMakeModel(agreementData1?.ClientOffer?.ClientOfferName)) {
      getMakeModel('', globalState?.state?.sessionResponse?.InitializeResponse?.ClientId, interactionLineId, globalState?.state?.findAgreementSessionData)
      .then((resp) => {
          setLoading(false);
          setImeiInvalid(false)
          globalState?.dispatch({ type: ActionTypes.UPDATEDEVICE_ASSET_DETAILS, payload: resp?.AssetDetials });
          if(resp?.AssetDetials?.length > 0) {
              navigate('/selectMakeModel')
              setImeiInvalid(false)
          } else {
              setImeiInvalid(true)
          }
          
      })
      .catch((err) => {
          setLoading(false);
          setImeiInvalid(true)
          console.log('validate imei:', err);
          saveError(err, globalState);
          showErrorPage(navigate);
      })
    } else {
      validateImei(imeiVal, undefined, interactionLineId, globalState?.state?.findAgreementSessionData)
        .then((resp) => {
            setLoading(false);
            setImeiInvalid(false)
            console.log('validateImei', resp?.GetDeviceByImeiResponse, resp?.GetDeviceByImeiResponse?.IMEIFound);
            globalState?.dispatch({ type: ActionTypes.IMEI_DEVICE_DETAILS, payload: resp?.GetDeviceByImeiResponse?.DeviceDetails });
            if(resp?.GetDeviceByImeiResponse?.ValidIMEI === true) {
                navigate('/updateCapacity')
                setImeiInvalid(false)
            } else {
                setImeiInvalid(true)
            }
            
        })
        .catch((err) => {
            setLoading(false);
            setImeiInvalid(true)
            console.log('validate imei:', err);
            // saveError(error, globalState);
            // showErrorPage(navigate);
        })
    }
  }

  const handleImeiChange = (e: any) => {
    setImeiInvalid(false)
    const regex = /^\d{15}/
    if(regex.test(e.target.value) && e.target.value?.length === 15){
      setImeiInvalid(false)
    }else {
      setImeiInvalid(true)
    }
    setImei(e.target.value)
    globalState?.dispatch({ type: ActionTypes.SET_UPDATE_DEVICE_IMEI, payload: e.target.value });
  }


  const isDisabled = imeiVal !== '' && !isImeiInvalid ? false : true
  const onBackClick = ()=>{
    navigate('/updateDevice')
 }

  return (
    <Box>
      <Box
        w={{ base: 'full', lg: '80%' }}
        margin="auto"
        pb={{ base: 0, lg: '150px' }}
        id={'myasurion-profile-dashboard'}
        paddingTop={'54px'}
      >
        <MainStackLayout gap={0} spacing={0}>
        <SubHeader
            backText={'Back'}
            allowBackButtonClick={false}
            showCancelButton={true}
            showBackButton={true}
            onClick={onBackClick}
            cancelText={Scripts.CancelSR.closeBtnTextClose}
            cancelTextKey='CloseBtn1'
            disableGlobalPageName= {true}
            onCancelClick={onCancelClick}
          ></SubHeader>
          <Box
            id={'myasurion-profile-avatar'}
            w={{ base: 'full', lg: '80%' }}
            margin="0 auto"
            h={'auto'}
            p={'10px'}
          >
            <ImeiDetails
                title={ImeiDetailsScrips.title}
                imeiPlaceholder={ImeiDetailsScrips.imeiPlaceholder}
                imeiValue={imeiVal}
                findImeiQue={ImeiDetailsScrips.findImeiQue}
                iosTitle={ImeiDetailsScrips.iosTitle}
                iosHeading={ImeiDetailsScrips.iosHeading}
                iosInstruciton={ImeiDetailsScrips.iosInstruciton}
                androidTitle={ImeiDetailsScrips.androidTitle}
                androidHeading={ImeiDetailsScrips.androidHeading}
                androidInstruciton={ImeiDetailsScrips.androidInstruciton}
                plan={'plan'}
                btnSubmitLBL={'Next'}
                handleImeiChange={(e) => handleImeiChange(e)}
                onClickSubmit={() => handleSubmit()}
                invalidImei={isImeiInvalid}
                isDisabled={isDisabled}
                // onClickCancel={}
             />
          </Box>
          
        </MainStackLayout>
        <CancelSrPopup closeCancelPopup={setOpenCancelPopup} openCancelPopup={openCancelPopup} />
      </Box>
    </Box>
  );
}

export default ImeiDetail;
