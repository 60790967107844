import { getOnlineSession, loggerApiCall } from '../services/api';
import { useCentralStore } from '../store/appContext';
import { getTypeByDomain, useConfig, useAccount, useAuthToken, getCarrierByDomain, AccountInfo, useSessionStorage } from '@MGPD/myasurion-shared';
import { Box, Link, Text, Button, Stack } from '@chakra-ui/react';
import { PropsWithChildren, useEffect } from 'react';

import Scripts from '../Scripts/asurion-script.json';
import { TextScript } from '@MGPD/myasurion-shared';
import translationsKeys from '../Scripts/translationsKeys.json'
import { getRedirectUrl } from '../utils/utills';
import { useNavigate } from 'react-router-dom';
import ActionTypes from '../store/actionTypes';
import { useMutation, useQuery } from '@tanstack/react-query';
import { findAgreementByMdn } from '../../services/api';
import { loadingWaitingMessages } from '../../modules/config/constants';
import Geocode from 'react-geocode';

const ErrorScreen: React.FC<PropsWithChildren> = () => {

  const globalState = useCentralStore();
  const ErrorKeys = translationsKeys.ServiceUnavailable;
  const navigate = useNavigate();
  //localization color theme changes
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { accent } = colors;
  const [account, setAccount] = useAccount<AccountInfo>({ profileId: '', userId: '' });
  const [, setAuthToken] = useAuthToken({});
  const [sessionData, ] = useSessionStorage<any>('data', '');

  const disableApplLoad = getCarrierByDomain()?.toLowerCase() === 'starhub'//useFeatureIsOn('disableApplLoad');

  useEffect(() => {
    if (globalState?.state?.errorData && import.meta.env.VITE_ENV != 'dev') {
      let errorData = globalState?.state?.errorData;
      callLoggerApi(errorData);
    }
  }, [globalState?.state?.errorData]);

  const callLoggerApi = (errorData: any) => {
    const errorMessage = JSON.stringify(errorData?.response?.data || errorData?.message);

    const agreement =
      globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement?.[0];

    let payload = {
      LoggerRequest: {
        message: JSON.stringify(errorData?.stack || errorData),
        level: 'error',
        category: '',
        agreementRequestId: agreement?.AgreementId,
        mdn: agreement?.ContactPoints?.ContactPoint?.filter((val: any) => val?.PhoneNumber)?.[0]
          ?.PhoneNumber,
        email: agreement?.ContactPoints?.ContactPoint?.filter((val: any) => val?.EmailAddress)?.[0]
          ?.EmailAddress,
        errorAPI: errorData?.config?.url,
        errorId: '',
        status: 'API Log',
        errorCode: errorData?.response?.status,
        errorMessage,
        customer:
          globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement?.[0]
            ?.Customers?.Customer?.[0]?.FullName,
        clientId: globalState?.state?.sessionResponse?.InitializeResponse?.ClientId,
        clientName: globalState?.state?.sessionResponse?.InitializeResponse?.ClientName,
      },
    };
    loggerApiCall(
      payload,
      globalState?.state?.sessionResponse?.Interaction?.InteractionLine?.InteractionLineId,
      globalState?.state?.findAgreementSessionData
    );
  };

  const doLogout = () => {
    // Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_DoLogout', {
    //     userId: account.userId,
    //     localStorage: localStorage,
    //     sessionStorage: sessionStorage,
    //     caller,
    // });
    localStorage.clear();
    const p = sessionStorage.getItem('p');
    sessionStorage.clear();
    sessionStorage.setItem('p', p ?? '');
    setAccount({ profileId: '', userId: '' });
    setAuthToken({});    
    window.open(getRedirectUrl(getTypeByDomain()), '_self');
};

const { mutate: fetchAgreementByMdn } = useMutation({
  mutationFn: findAgreementByMdn,
  retry: 5,
  retryDelay: (failureCount) => {
      if (failureCount === 3) {
          // setLoadingMessage(
              loadingWaitingMessages[
                  Math.floor(Math.random() * loadingWaitingMessages.length)
              ]
          // );
      }
      return Math.min(1000 * 2 ** failureCount, 30000);
  },
  onSuccess: (data) => {
      if(disableApplLoad) {
          // let deviceList: any = [];
          // deviceList = data?.deviceData?.filter((a: any) => a?.type?.toLowerCase() !== 'byod' && a?.assetInstance?.toUpperCase() === 'REPLACED')
    
          // if (!deviceList || deviceList === undefined || deviceList.length === 0) {
          // deviceList = data?.deviceData?.filter((a: any) => a?.type?.toLowerCase() !== 'byod' && a?.assetInstance?.toUpperCase() === 'ENROLLED')
          // }
          
          // deviceList = [...new Map(deviceList?.map((item: any) =>
          //   [item['mdn'], item])).values()]

          let deviceList1 = new Map()
                data?.deviceData?.forEach((v: any) => {
                    let prevValue = deviceList1.get(v.mdn)
                    if(!prevValue || prevValue.type === "ENROLLED"){
                        deviceList1.set(v.mdn, v)
                    } 
                })
             let deviceList = [...deviceList1.values()]

          globalState?.dispatch({
              type: ActionTypes.SET_DEVICES_LIST,
              payload: deviceList || data?.deviceData,
          });

          globalState?.dispatch({
              type: ActionTypes.SET_FINDAGREEMENT_BY_MDN_DATA,
              payload: data,
          });   
          globalState?.dispatch({
            type: ActionTypes.SET_FINDAGREEMENT_SESSION_DATA,
            payload: data?.data,
          });
      }
      globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
      navigate(getRedirectUrl(getTypeByDomain()))
  },
  onSettled: () => {
      fetchOnlineSession();
  },
  onError: (error) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
      console.error('Findagreement failed', (error as { message: string })?.message);
      // doLogout('findAgreementByMdn');
  },
});

const { refetch: fetchOnlineSession } = useQuery(
['sessionData'],
async () => {
    return await getOnlineSession();
},
{
    retry: 5,
    retryDelay: (failureCount, error) => {
      console.error('error', error);
        if (failureCount === 3) {
            // setLoadingMessage(
                loadingWaitingMessages[
                    Math.floor(Math.random() * loadingWaitingMessages.length)
                ]
            // );
        }
        // Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Error', {
        //     userId: account.userId,
        //     error: error,
        // });
        return Math.min(1000 * 2 ** failureCount, 30000);
    },
    onSuccess: (data) => {
      globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
        // Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Success', {
        //     userId: account.userId,
        // });

          globalState?.dispatch({
            type: ActionTypes.SET_SESSION_RESPONSE,
            payload: data,
          });
          const isMaxis = data.InitializeResponse?.ClientName?.toUpperCase() === "MAXIS"
          globalState?.dispatch({
            type: ActionTypes.SET_IS_MAXIS_FLAG,
            payload: isMaxis,
          });
          
          const btMethods = data?.InitializeResponse?.isBraintree?.method
          
          globalState?.dispatch({
            type: ActionTypes.SET_BRAINTREE_METHODS,
            payload: btMethods,
          });
          
    
          const agreementData = data?.FindAgreementsResults?.Agreements?.Agreement?.[0]
          const address1 = agreementData?.Address?.Address1;
          const address2 = agreementData?.Address?.Address2;
          const city = agreementData?.Address?.City;
          const _postalCode = agreementData?.Address?.PostalCode;
          const addressjoin = { address1, address2, city, _postalCode };
          const fulladdress = Object.values(addressjoin).join(', ');
    
          Geocode.setApiKey(import.meta.env.VITE_GOOGLE_API_KEY);    
          Geocode.setLanguage('en');
          Geocode.setRegion('SGP');
          Geocode.setLocationType('ROOFTOP');
    
          Geocode.fromAddress(fulladdress).then(
            (response) => {
              const { lat, lng } = response?.results?.[0]?.geometry?.location;
              globalState?.dispatch({
                type: ActionTypes.SET_LOCATION_COORDINATES,
                payload: { lat, lng },
              });
            },
            (error) => {
              console.error(error);
            }
          );
        // setAgreementData(data?.FindAgreementsResults?.Agreements?.Agreement?.[0])
    },
    onError: (error) => {
      globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
        console.error(error);
        // Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Error', {
        //     userId: account.userId,
        //     error,
        // });
        // setPageLoading(true);
        // setIsFailedApiInit(true);
        // doLogout('getOnlineSession');
    },
    enabled: false,
}
);

const callFindAgreement = () => {
  globalState?.dispatch({ type: ActionTypes.LOADING, payload: true });
  fetchAgreementByMdn({
    mdn: globalState?.state?.selectedEnrolledDevice?.mdn || account?.enrolledMdn || '',
    email: globalState?.state?.selectedEnrolledDevice?.emailAddress ?? account?.email ?? '',
    data: sessionData,
    imei: '',
    idx: 0,
    assetId: '',
    isDeviceData: true
})
}

  return (
    <Box w={{ base: 'full', lg: '60%' }} marginX={'auto'} paddingTop={'54px'}>
      {globalState?.state?.jwtExpired ? (
                <Stack
                    h="100vh"
                    w="full"
                    justifyContent="center"
                    alignItems="center"
                    px={4}
                    textAlign={'center'}
                >
                    <>
                        <Text>
                            <TextScript
                                text={Scripts.ServiceUnavailable.reloginMessage}
                                translationKey={`${ErrorKeys.PageName}/${ErrorKeys.reloginMessage}`}
                            />
                        </Text>
                        <Button
                            colorScheme="primary"
                            my={2}
                            onClick={() => {
                                doLogout();
                            }}
                        >
                            <TextScript
                                text={Scripts.ServiceUnavailable.reloginBtnText}
                                translationKey={`${ErrorKeys.PageName}/${ErrorKeys.TryLoggingInAgain}`}
                            />
                        </Button>
                    </>
                </Stack>
            ):
      <Box paddingTop={'56px'} textAlign={'center'} paddingX={'16px'}>
        {/* <Text fontSize={'32px'} mb="20px">
          {Scripts.ServiceUnavailable.PrimaryHeader}
        </Text> */}
        <Text fontSize={'24px'} textAlign={'center'} mb="20px">
          <TextScript
            text={Scripts.ServiceUnavailable.Content}
            translationKey={`${ErrorKeys.PageName}/${ErrorKeys.KeyContent}`}
          />
        </Text>
        <Text fontSize={'24px'} textAlign={'center'} mb="20px">
          <TextScript
            text={Scripts.ServiceUnavailable.Content1}
            translationKey={`${ErrorKeys.PageName}/${ErrorKeys.KeyContent1}`}
          />
        </Text>
        <Text fontSize={'24px'} textAlign={'center'} mb="20px">
          <TextScript
            text={Scripts.ServiceUnavailable.Content2}
            translationKey={`${ErrorKeys.PageName}/${ErrorKeys.KeyContent2}`}
          />
        </Text>
        <Text fontSize={'24px'} textAlign={'center'} mb="20px">
          <TextScript
            text={Scripts.ServiceUnavailable.Content3}
            translationKey={`${ErrorKeys.PageName}/${ErrorKeys.KeyContent3}`}
          />
        </Text>
        <Box display="flex" justifyContent="center" mt={'1.5rem'}>
          <Link
            // href={getRedirectUrl(getTypeByDomain())}
            onClick={() => callFindAgreement()}
            color={accent}
            fontSize={'1rem'}
            fontWeight={700}
            py={'12px'}
            textDecoration="underline"
          >
            <TextScript
              text={Scripts.Global.Close}
              translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Close}`}
            />
          </Link>
        </Box>
      </Box>}
    </Box>
  );
};

export default ErrorScreen;
