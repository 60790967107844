import { SubHeader, getTextScript, TextScript } from '@MGPD/myasurion-shared';
import translationsKeys from '../../Scripts/translationsKeys.json'
import {
  Box, Button, Checkbox, Flex, Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import Scripts from '../../Scripts/asurion-script.json';
import CancelSrPopup from './../myclaims/CancelSrPopup';
import { getProgrammeName } from '../../utils/localization-helper';
import { getInventoryData } from '../../services/api';
import { saveError, showErrorPage } from '../../utils/utills';
import MainStackLayout from '../../layout/MainStackLayout';

function ThingsToNote() {
  const globalState = useCentralStore();
  const ThingsToNoteKeys = translationsKeys.ThingsToNote

  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [laptopNotes, setLaptopNotes] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [programmeNameLBL, setProgrammeNameLBL] = useState('');
  const [, setInventoryResponse] = useState<any>();

  const agreementData: any =
    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  const clientOfferId = agreementData?.ClientOffer?.ClientOfferId;
  const interactionData = globalState?.state?.sessionResponse?.Interaction;

  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };

  const navigate = useNavigate();


  const getLaptopNotes = async () => {
    const returnedText = await getTextScript(
      `${ThingsToNoteKeys.PageName}/${ThingsToNoteKeys.LaptopNotes}`,
      Scripts.ThingsToNote.LaptopNotes,
      'en-US',
      [programmeNameLBL]
    );
    setLaptopNotes(returnedText);
  };

  useEffect(() => {
    if (agreementData?.ClientOffer?.ClientOfferName) {
      getProgrammeNameLbl();
    }
  }, [agreementData?.ClientOffer?.ClientOfferName])

  useEffect(() => {
    if (programmeNameLBL && programmeNameLBL?.length > 0) {
      getLaptopNotes()
    }
  }, [programmeNameLBL]);

  const getProgrammeNameLbl = async () => {
    const programmeName = await getProgrammeName(agreementData?.ClientOffer?.ClientOfferName);
    setProgrammeNameLBL(programmeName);
  }

  const callInventoryDataApi = () => {
    setLoading(true);
    getInventoryData(clientOfferId, interactionData, globalState?.state?.findAgreementSessionData)
    
    .then((data: any) => {
         globalState?.dispatch({
           type: ActionTypes.SET_REGISTER_DEVICE_RESPONSE,
           payload: data,
         });
         setLoading(false);
         setInventoryResponse(data);
         navigate('/deviceDetailsNew', { replace: true });
        })
        .catch((error:any) => {
         saveError(error, globalState);
         showErrorPage(navigate);
         setLoading(false);
        });
      };
    

  const onSubmit = () => {
    callInventoryDataApi()
    
  };

  const onCancelClick = () => {
    setOpenCancelPopup(!openCancelPopup);
  }

  const onBackClick = () => {
    navigate('/deviceCategoryList', { replace: true });
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'} w="full">
      <MainStackLayout gap={0} spacing={0}>
        <Box w={{ base: 'full', lg: '80%' }} marginX={{ base: '', lg: 'auto' }} mb={{ base: '0', lg: '20px' }}>
          <SubHeader
          onClick={onBackClick}
            backText={'Back'}
            allowBackButtonClick={false}
            showCancelButton={false}
            showBackButton={true}
            cancelText={Scripts.CancelSR.cancelBtnText}
            onCancelClick={onCancelClick}>
          </SubHeader>
        </Box>
        <Box
          id={'myasurion-profile-avatar'}
          w={'full'}
          h={'auto'}
          p={'16px 16px'}
          maxWidth={{ base: 'full', lg: '60%' }}
          marginX={{base: '13px' ,lg:'auto'}}
        >
          <Box w="full" m="0">
            <Box
              className={''}
              w={{ base: 'full', lg: '50%' }}
              margin={{ base: '0', lg: 'auto' }}
              pb={{ base: '0', lg: '0' }}
            >
              <Text mb={8} fontSize={'24px'} fontWeight={'400'}>
                <TextScript
                  text={Scripts.ThingsToNote.HeaderText}
                  translationKey={`${ThingsToNoteKeys.PageName}/${ThingsToNoteKeys.HeaderText}`}
                />
              </Text>
              <Box>
                {laptopNotes && (
                  <div dangerouslySetInnerHTML={{ __html: laptopNotes }} />
                )}
              </Box>
            </Box>
            <Flex
              pb={{ base: '0', lg: '20px' }}
              mb={'auto'}
              w={{ base: 'full', lg: '50%' }}
              marginLeft={'auto'}
              marginRight={'auto'}
              justifyContent={'start'}
            >
              <Checkbox
                marginBottom={'24px'}
                marginTop={'24px'}
                alignItems={'flex-start'}
                size="lg"
                onChange={handleCheckboxChange}
                colorScheme="primary"
              ></Checkbox>
              <Text marginBottom={'24px'} marginTop={'24px'} marginLeft={'8px'} size={'16px'}>
                My laptop meets the conditions
              </Text>
            </Flex>
            <Box display="flex" justifyContent="center">
              <Button
                isDisabled={!isChecked}
                color="white"
                colorScheme="primary"
                onClick={onSubmit}
                width="200px"
              >
                <TextScript
                  text={'Next'}
                  translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Next}`}
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </MainStackLayout>
      <CancelSrPopup
        closeCancelPopup={setOpenCancelPopup}
        openCancelPopup={openCancelPopup}
      />
    </Box>
  );
}

export default ThingsToNote;
