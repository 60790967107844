import { useCentralStore } from '../store/appContext';
import { camelize } from '../utils/helper';
import { SubHeader, useSessionStorage, TextScript, useConfig } from '@MGPD/myasurion-shared';
import { Box, Button, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import translationsKeys from '../Scripts/translationsKeys.json'
import { getTextScript } from '@MGPD/myasurion-shared/components/localization';
import Scripts from '../Scripts/asurion-script.json';
import CancelSrPopup from './myclaims/CancelSrPopup';
import { getPerilText } from '../utils/localization-helper';
import { ResumeFlowDataKey, processIncidentKey } from '../modules/config/constants';

const EConfirmation = () => {
  const navigate = useNavigate();
  const globalState = useCentralStore();
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [SubHeaderText, setSubHeaderText] = useState('');
  const [PerilType, setPerilType] = useState('');

  const [assetData] = useSessionStorage<any>('assetData', null);
  const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);
  const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
  const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
  const R_IncidentType = R_ServiceRequests?.IncidentType;

  const EConfirmationKeys = translationsKeys.EConfirmation
  const agreementData1: any =
    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

  // const assetData1 = agreementData1?.Assets?.Asset?.filter(
  //   (a: any) => a?.AssetInstance === 'ENROLLED'
  // )?.[0];

  let assetData1 =  agreementData1?.Assets?.Asset?.filter(
    (asset: any) => asset?.AssetInstance === 'ENROLLED'
  )?.[0];
    if(assetData1 === undefined) {
      assetData1 = agreementData1?.Assets?.Asset?.filter(
        (asset: any) => asset?.AssetInstance === 'REPLACED'
      )?.[0];
    }

  // const AssetMake = camelize(assetData?.Make?.Name?.toLowerCase()) ||camelize(assetData1?.Make?.Name?.toLowerCase())
  const AssetFamily = camelize(assetData?.AssetCatalog?.AssetFamily?.toLowerCase())?.replace(/iphone/gi,'iPhone') ||camelize(assetData1?.AssetCatalog?.AssetFamily?.toLowerCase())?.replace(/iphone/gi,'iPhone')
  const Imei = assetData?.IMEI || assetData1?.IMEI
  //localization color theme changes
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { primary } = colors;

  const incType = serviceRequestData?.ServiceRequest?.IncidentType || R_IncidentType;

  const handleNext = () => {
    navigate('/submiteconfirmation');
  };

  const onCancelClick = () => {
    setOpenCancelPopup(!openCancelPopup);
  };

useEffect(() => {
  const getSubHeaderText = async () => {
    const SubHeaderText = await getTextScript(
      `${EConfirmationKeys.PageName}/${EConfirmationKeys.SubHeaderText}`,
      `I understand that under the terms and conditions, as soon as my service request for a Replacement is accepted, my Registered Device will become the property of Starhub and I will no longer have any right to that device  <b>(“Previous Registered Device”).</b> <br />`
    );
    setSubHeaderText(SubHeaderText);
  };
  getSubHeaderText();
  getPerilLbl();
}, []);

  const getPerilLbl = async () => {
    const peril = await getPerilText(incType?.toUpperCase(), incType);
    setPerilType(peril);
  }

  return (
    <Box w={{ base: 'full', lg: '80%' }} margin="auto">
      <Box pt={12}>
        <SubHeader
          backText={'Back'}
          allowBackButtonClick={false}
          showCancelButton={true}
          showBackButton={false}
          cancelText={Scripts.CancelSR.cancelBtnText}
          onCancelClick={onCancelClick}
        ></SubHeader>
      </Box>
      <Box px={4} pt={6} w={{ base: 'full', lg: '80%' }} margin="0 auto">
        <Text pb={'0.5rem'} fontSize={'2rem'} fontWeight={300}>
        <TextScript
         text={'Reminder'}
         translationKey={`${EConfirmationKeys.PageName}/${EConfirmationKeys.ReminderLbl}`}/>
        </Text>
        {PerilType && PerilType?.length > 0 && 
          <Text fontSize={'18px'}>
            <TextScript
              translationKey={`${EConfirmationKeys.PageName}/${EConfirmationKeys.HeaderText}`}
              text ={'I am making a service request for {3} as I no longer have {1} {2} (the Registered Device) in my possession.'}
              dynamicValues = {[ AssetFamily, Imei, PerilType]}
            />
          
           {/* {`I am making a service request for Replacement as I no longer have ${
            camelize(assetData?.Make?.Name?.toLowerCase()) ||
            camelize(assetData1?.Make?.Name?.toLowerCase())
          }, ${
            camelize(assetData?.AssetCatalog?.AssetFamily?.toLowerCase())?.replace(
              /iphone/gi,
              'iPhone'
            ) ||
            camelize(assetData1?.AssetCatalog?.AssetFamily?.toLowerCase())?.replace(
              /iphone/gi,
              'iPhone'
            )
          } */}
          {/* ${assetData?.IMEI || assetData1?.IMEI} (the Registered Device) in my possession.`{' '}  */}
           </Text>
          }
           <br/>
           <Box fontSize={'18px'}>
           {SubHeaderText &&
          <div  dangerouslySetInnerHTML={{ __html: SubHeaderText }} />}
           </Box>
          <br/>
          <Text fontSize={'18px'}>
          <TextScript
             text={`I acknowledge that Starhub as the owner of Previous Registered Device will have the right to:`}
             translationKey={`${EConfirmationKeys.PageName}/${EConfirmationKeys.AcknowledgeText}`}/>
          </Text>
          <br />
          <Text fontSize={'18px'}>
          <TextScript
             text={`1. register the IMEI to the Singapore Police Force and any other relevant local
             authorities; and`}
             translationKey={`${EConfirmationKeys.PageName}/${EConfirmationKeys.Point1Text}`}/>
          </Text>
          <Text fontSize={'18px'}>
          <TextScript
             text={`2. take any other action consistent with ownership of the Previous Registered
             Device that is deemed necessary including `}
             translationKey={`${EConfirmationKeys.PageName}/${EConfirmationKeys.Point2Text}`}/>
          </Text>
           <Text fontSize={'18px'}>
          <TextScript
             text={`(i.) informing the Singapore Police Force and any other relevant local authorities to
             assist in the recovery of the Registered Device and (ii) disabling and locating the
             Previous Registered Device.`}
             translationKey={`${EConfirmationKeys.PageName}/${EConfirmationKeys.Point2SubText}`}/>
          </Text>
        <Box w={{ base: 'full', lg: '30%' }} margin={{ base: 0, lg: 'auto' }}>
          <Button
            my={4}
            py={3}
            px={5}
            bgColor={primary}
            color="white"
            w="full"
            onClick={() => {
              handleNext();
            }}
          >
          <TextScript
            text={Scripts.Global.Next}
            translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Next}`}/>
          </Button>
        </Box>
        <CancelSrPopup closeCancelPopup={setOpenCancelPopup} openCancelPopup={openCancelPopup} />
      </Box>
    </Box>
  );
};

export default EConfirmation;
