/* eslint-disable no-console */
import React, { useEffect } from 'react'
import MainStackLayout from '../../layout/MainStackLayout';
import '../../services/api';
import { BillingDetails, TextScript } from '@MGPD/myasurion-shared';
import { 
  Box, 
  AlertDialog, 
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Text
 } from '@chakra-ui/react';
 import { CloseIcon } from '@chakra-ui/icons';
import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import ActionTypes from '../../store/actionTypes.ts';
import { useCentralStore } from '../../store/appContext.tsx';
import CancelSrPopup from '../myclaims/CancelSrPopup.tsx';
import { AssetData } from '../../types/types.ts';
import { payENRfee } from '../../services/api';
import moment from 'moment';
import { getCurrencySymbol } from '../../utils/localization-helper.tsx';

export interface BillingDetailsCompProps {
  latestPaymnetHistory: any;
}

export const BillingDetailsComp: React.FC<BillingDetailsCompProps> = ({
// function BillingDetailsComp({
  latestPaymnetHistory
}) => {

const billingDetailsScrips = Scripts.MyPayments.BillingDetails
  const globalState = useCentralStore();
  // const navigate = useNavigate();
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [assetData, setAssetData] = useState<any>();
  const [billingHistory, setBillingHistoryData] = useState<any>();
  const [paynowError, setPaynowError] = useState<any>(false);
  const [isViewAll, setIsViewAll] = useState<any>(false);
  const [currencySymbol, setCurrencySymbol] = useState('$');

  const agreementData1: any = globalState?.state?.myPaymentsCurrentSession;
  const interactionData = globalState?.state?.sessionResponse?.Interaction;
  const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;

  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };

  useEffect(() => {
      getCurrency();
  }, [])

  const getCurrency = async () => {
    const currency = await getCurrencySymbol();
    setCurrencySymbol(currency);
  }

  useEffect(() => {
    let setBillingHistoryArr: any = []     
    globalState?.state?.myPaymentsResp?.PaymentHistory?.map((v: any) => {
        setBillingHistoryArr.push({
            plan: agreementData1?.ClientOffer?.ClientOfferName,
            paymentDate: moment((v?.CreatedDate)?.split('.')[0]).format('DD MMM YYYY'),
            amount: v?.TotalAmount,
            status: v?.TransactionStatus,
            BillingOrderId: v.BillingOrderId,
            AgreementId: v.AgreementId,
            ChargeOrderTypeCode: v?.ChargeOrderTypeCode,
            createdDate: (v?.CreatedDate)?.split('.')[0],
            paymentDuration: `${moment((v?.BillingStartDate)?.split('.')[0]).format('DD MMM YYYY')} - ${moment((v?.NextDueDate)?.split('.')[0]).format('DD MMM YYYY')}`,
            NextDueDate: moment(v?.NextDueDate?.split('.')[0]).format('DD MMM YYYY')
        })
    })

    let sortedBillingHistoryArr = setBillingHistoryArr?.sort(function(a: any,b: any){
      return new Date(b.paymentDate).valueOf() - new Date(a.paymentDate).valueOf();
    })
    
    setBillingHistoryData(isViewAll ? sortedBillingHistoryArr : sortedBillingHistoryArr?.slice(0,5))
    
//     let aa = [
//       {
//           "PolicyNo": "ATC7265188",
//           "ChargeOrderTypeCode": "ENRFEE",
//           "TotalAmount": 300,
//           "CreatedDate": "2023-06-16T05:03:58.000Z",
//           "ChargeOrderStatusCode": "CHARGED",
//           "SuccessfulFlag": 1,
//           "Duplicate": 0,
//           "TransactionStatus": "Failed",
//           "AgreementId": "1EDE5C010A82055489EAAFDE6A146967",
//           "BillingOrderId": "1EE0C033160B028381401718751E7AEA",
//           "ChargeOrderId": "1EE0C033160B028281401718751E7AEA",
//           "CustomerCaseId": "1EDF30F532180C8092AE93773A734CBE"
//       },
//       {
//           "PolicyNo": "ATC7265188",
//           "ClaimId": 420060829,
//           "ChargeOrderTypeCode": "SWAPFEE",
//           "TotalAmount": 1250.25,
//           "CreatedDate": "2023-05-15T10:58:19.000Z",
//           "ChargeOrderStatusCode": "CHARGED",
//           "SuccessfulFlag": 1,
//           "Duplicate": 0,
//           "TransactionStatus": "Paid",
//           "AgreementId": "1EDE5C010A82055489EAAFDE6A146967",
//           "ChargeOrderId": "FB3EE46E874A4770917754738DFF4839",
//           "CustomerCaseId": "1EDF30F532180C8092AE93773A734CBE"
//       },
//       {
//           "PolicyNo": "ATC7265188",
//           "ChargeOrderTypeCode": "ENRFEE",
//           "TotalAmount": 1180.07,
//           "CreatedDate": "2023-05-15T10:56:42.000Z",
//           "ChargeOrderStatusCode": "CHARGED",
//           "SuccessfulFlag": 1,
//           "Duplicate": 0,
//           "TransactionStatus": "Paid",
//           "AgreementId": "1EDE5C010A82055489EAAFDE6A146967",
//           "BillingOrderId": "1EDE5C010A82055389EAAFDE6A146967",
//           "ChargeOrderId": "1EDE5C010A82055289EAAFDE6A146967",
//           "CustomerCaseId": "1EDF30F532180C8092AE93773A734CBE"
//       }
//   ]

//   aa?.map((v: any) => {
//     setBillingHistoryArr.push({
//         plan: agreementData1?.ClientOffer?.ClientOfferName,
//         paymentDate: moment(v?.CreatedDate).format('MMM DD YYYY'),
//         amount: '$' + v?.TotalAmount,
//         status: v?.TransactionStatus,
//         BillingOrderId: v.BillingOrderId,
//         AgreementId: v.AgreementId,
//     })
// })

  }, [globalState?.state?.myPaymentsResp?.PaymentHistory, isViewAll, sessionStorage.getItem('al')])
  

  useEffect(() => {
    if (agreementData1) {
      // let assetData1: AssetData = agreementData1?.Assets.Asset.filter(
      //   (a: any) => a.AssetInstance === 'ENROLLED'
      // )[0];

      let assetData1: AssetData =  agreementData1?.Assets?.Asset?.filter(
        (asset: any) => asset?.AssetInstance === 'ENROLLED'
      )?.[0];
        if(assetData1 === undefined) {
          assetData1 = agreementData1?.Assets?.Asset?.filter(
            (asset: any) => asset?.AssetInstance === 'REPLACED'
          )?.[0];
        }

      let asset = []
      asset.push({
        plan: agreementData1?.ClientOffer?.ClientOfferName,
        mdn: `(${assetData1?.CountryCallingCode.split('+')?.[1]}) ${assetData1?.MobileDeviceNumber}`,
        model: assetData1?.AssetCatalog.AssetCatalogName,
        imei: assetData1?.IMEI
      })
      setAssetData(asset);
    }
  }, [agreementData1 || globalState?.state?.sessionResponse]);

// const onCancelClick = () => {
//   navigate('/myPayments')
// };

const payEnrFee = (params: any) => {
  setPaynowError(false)
  let payEnrFeeRequest = {
      "PayEnrFeeRequest": {
          "BillingOrderId": params.BillingOrderId,
          "AgreementId": params.AgreementId,
          "ClientId": globalState?.state?.sessionResponse?.InitializeResponse?.ClientId,
          "ClientAccountId": agreementData1?.ClientAccount?.ClientAccountId
      }
  }
  setLoading(true)
  payENRfee(payEnrFeeRequest, interactionLineId, globalState?.state?.findAgreementSessionData)
    .then(resp => {
      console.log('resp', resp);
      setLoading(false)
    })
    .catch(err => {
      console.log('err', err);
      setLoading(false)
      setPaynowError(true)
    })
  // actions.payEnrFee(payEnrFeeRequest)
}

const handleViewInvoices = (e: any) => {
  console.log(e);
  window.location.href = '/portal/documents'
}



const cancelRef = React.useRef<any>();

  return (
    <Box>
      <Box
        w={{ base: 'full', lg: '90%' }}
        margin="auto"
        pb={'150px'}
        id={'myasurion-profile-dashboard'}
        // paddingBottom={'154px'}
        // mb='20px'
      >
        <MainStackLayout gap={0} spacing={0}>
          {/* <SubHeader
            backText={''}
            allowBackButtonClick={false}
            showCancelButton={true}
            showBackButton={false}
            cancelText={Scripts.CancelSR.cancelBtnTextCancel}
            onCancelClick={onCancelClick}
          ></SubHeader> */}
          <Box
            id={'myasurion-profile-avatar'}
            w={{ base: 'full', lg: '80%' }}
            margin="0 auto"
            h={'auto'}
            // p={'24px 16px'}
          >
            <BillingDetails 
                pageTitle={billingDetailsScrips.pageTitle} 
                mdn={assetData?.[0]?.mdn} 
                plan={assetData?.[0]?.plan} 
                priceLBL={billingDetailsScrips.priceLBL} 
                priceValue={agreementData1?.ClientAccountBillingCycle !== "YEARLY" && latestPaymnetHistory?.TotalAmount && latestPaymnetHistory?.ChargeOrderTypeCode === 'ENRFEE' ? currencySymbol+latestPaymnetHistory?.TotalAmount : '-'} 
                nextBillingDateLBL={billingDetailsScrips.nextBillingDateLBL} 
                nextBillingDateValue={agreementData1?.ClientAccountBillingCycle !== "YEARLY" && latestPaymnetHistory?.NextDueDate && latestPaymnetHistory?.ChargeOrderTypeCode === 'ENRFEE' ? moment(latestPaymnetHistory?.NextDueDate?.split('.')[0]).format('DD MMM YYYY') : '-'} 
                headingBillingHistory={billingDetailsScrips.headingBillingHistory} 
                viewAllLBL={billingDetailsScrips.viewAllLBL} 
                viewInvoicesText={billingDetailsScrips.viewInvoicesLBL} 
                paymentDateLBL={billingDetailsScrips.paymentDateLBL} 
                amountLBL={billingDetailsScrips.amountLBL} 
                statusLBL={billingDetailsScrips.statusLBL} 
                billingHistoryData={billingHistory} // show 5 records, on click view all show all
                payNowBtnLBL={billingDetailsScrips.payNowBtnLBL} 
                handleChange={() => setIsViewAll(!isViewAll)} // view all click, show all history
                handlePaynow={(data: any) => payEnrFee(data)}
                handleMyDocuments={() => undefined}
                handleViewInvoices={(e: any) => handleViewInvoices(e)}
                billingDesc={billingDetailsScrips.billingDesc}
            />
          </Box>
          
        </MainStackLayout>
        <CancelSrPopup closeCancelPopup={setOpenCancelPopup} openCancelPopup={openCancelPopup} />
      </Box>
      {paynowError &&
        <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => undefined}//onClose()
        isOpen={paynowError}
        isCentered
        autoFocus={false}
      >
        <AlertDialogOverlay />
        <AlertDialogContent margin={'12px'} p='3'>
          <Box
              display={'flex'}
              justifyContent={'right'}
              width={'100%'}
          >
              <Text
                  variant={'link'}
                  cursor={'pointer'}
                  onClick={() => setPaynowError(false)}
              >
                <TextScript
                    text={'Close'}
                    translationKey={`myPayments/CancelButton}`}
                />
              </Text>
              <CloseIcon
                  onClick={() => setPaynowError(false)}
                  display={'block'}
                  cursor={'pointer'}
                  alignSelf={'center'}
                  marginX={'0.5rem'}
                  padding={'1px'}
              />
          </Box>
          <AlertDialogBody paddingX={'12px'} paddingTop={'12px'}>
            <Text>
                <TextScript
                    text={'Something went wrong, please retry.'}
                    translationKey={`myPayments/tokenError}`}
                />
              </Text>
          </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialog>
      }
    </Box>
  );
}

export default BillingDetailsComp;
