import { Stack, StackProps } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

const MainStackLayout: React.FC<PropsWithChildren & StackProps> = ({ children, ...props }) => {
  return (
    <Stack
      position={'relative'}
      w={'full'}
      h={'calc(100vh - 50px)'}
      direction={'column'}
      spacing={2}
      alignItems={'stretch'}
      {...props}
    >
      {children}
    </Stack>
  );
};
export default MainStackLayout;
