import { v4 as uuid } from 'uuid';

export const headers: any = {
  'asurion-channel': 'online',
  'asurion-client': import.meta.env.VITE_ASURION_CLIENT,
  'asurion-enduser': 'myasurion',
  'asurion-lineofbusiness': 'MOBILITY',
  'asurion-region': 'APAC-SEA',
  'asurion-application': 'myasurion',
  'asurion-correlationid': uuid()?.replace(/-/g, '')?.toUpperCase(),
  'x-api-key': import.meta.env.VITE_X_API_KEY,
  // 'Asurion-interactionlineid': JSON.parse(window.sessionStorage.interactionData).InteractionId
  // 'access-token': window.sessionStorage?.data ? JSON.parse(window.sessionStorage.data) : '',
  // 'access-token': window.sessionStorage?.claimSessionData ? JSON.parse(window.sessionStorage.claimSessionData) : ''
};
