import MainStackLayout from '../layout/MainStackLayout';
import { useSessionStorage, DeviceService, TextScript, getTextScript } from '@MGPD/myasurion-shared';
import {
  Box,
  Center,
  Text
} from '@chakra-ui/react';
import translationsKeys from '../Scripts/translationsKeys.json'
import Scripts from '../Scripts/asurion-script.json';
import { useEffect, useState } from 'react';
import { useCentralStore } from '../store/appContext';
import { getCurrencySymbol, getProgrammeName } from '../utils/localization-helper';
import { isSwap } from '../utils/utills';

function TriageFailure() {
  const globalState = useCentralStore();
  const [offeredPeril,] =useSessionStorage<any>('OfferedPeril', null);
  const [offerServiceFee,] =useSessionStorage<any>('OfferedFee', null);
  const TriageFailureKeys = translationsKeys.TriageFailure

  const [exploreOptions, setExploreOptions] = useState('');
  const [exploreSSOptions, setExploreSSOptions] = useState('');
  const [descriptionSwap, setDescriptionSwap] = useState('');
  const [SRFtext, setSRFtext] = useState('');
  const [repairFee, setRepairFee] = useState('');
  const [currencySymbol, setCurrencySymbol] = useState('$')
  const [programmeNameLBL, setProgrammeNameLBL] = useState('')

  const agreementData1: any =
    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

  const getCurrency = async () => {
    const currency = await getCurrencySymbol();
    setCurrencySymbol(currency);
  }

  const getExploreOptions = async () => {
    const PlaceHolderText = await getTextScript(
    `${translationsKeys.Global.PageName}/${translationsKeys.Global.ExploreYourOptions}`,
    `${Scripts.Global.ExploreYourOptions}`);
    setExploreOptions(PlaceHolderText);
    };

  const getExploreSSOptions = async (programmeName: string) => {
    const PlaceHolderText = await getTextScript(
    `${translationsKeys.Global.PageName}/${translationsKeys.Global.ExploreOptions}`,
    Scripts.Global.ExploreSmartSupportOptions,
    'en-US',
    [programmeName]);
    setExploreSSOptions(PlaceHolderText);
  };
  
  const getDescriptionSwap = async () => {
      const PlaceHolderText = await getTextScript(
      `${TriageFailureKeys.PageName}/${TriageFailureKeys.descriptionSwap}`,
      `${Scripts.TriageFailure.descriptionSwap}`);
      setDescriptionSwap(PlaceHolderText);
      };

  const getSRFtext = async () => {
    const PlaceHolderText = await getTextScript(
    `${translationsKeys.Global.PageName}/${translationsKeys.Global.SRFLBL}`,
    Scripts.Global.SRFLBL);
    setSRFtext(PlaceHolderText);
  };

  const getRepairFee = async () => {
    const PlaceHolderText = await getTextScript(
    `${translationsKeys.Global.PageName}/${translationsKeys.Global.RepairFeeLBL}`,
    Scripts.Global.RepairFeeLBL);
    setRepairFee(PlaceHolderText);
  };

  const getProgrammeNameLbl = async () => {
    const programmeName = await getProgrammeName(agreementData1?.ClientOffer?.ClientOfferName);
    setProgrammeNameLBL(programmeName);
  }

  useEffect(() => {
    getCurrency();
    getExploreOptions();
    getDescriptionSwap();
    getSRFtext();
    getRepairFee();
  }, [])

  useEffect(() => {
    if(agreementData1){
      getProgrammeNameLbl();
    }
  }, [agreementData1])

  useEffect(() => {
    if(programmeNameLBL && programmeNameLBL?.length > 0){
      getExploreSSOptions(programmeNameLBL);
    }
  }, [programmeNameLBL])

  return (
    <Center>
      <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'}>
        <MainStackLayout gap={0} spacing={0}>
          <Box
            id={'myasurion-profile-avatar'}
            mt={4}
            w={'full'}
            h={'auto'}
            p={'16px 16px 16px 16px'}
          >
            <Text fontSize={'32px'}>
            <TextScript
                text={Scripts.TriageFailure.HeaderText}
                translationKey={`${TriageFailureKeys.PageName}/${TriageFailureKeys.HeaderText}`}/>
            </Text>
            <Box pt={'24px'} pb={'16px'}>
                <DeviceService
                  tag={exploreOptions || Scripts.Global.ExploreYourOptions}
                  desc2=""
                  title={exploreSSOptions || Scripts.TriageFailure.descriptionSwap}                
                  desc={descriptionSwap}
                  feeText={
                    `${isSwap(offeredPeril)}`
                      ? `${SRFtext} ${currencySymbol}${offerServiceFee?.[0]?.fee}`
                      : `${repairFee} ${currencySymbol}${offerServiceFee?.[0].fee}`
                  }
                  deviceServiceButtonProps={{
                    label: 'Take me there',
                    onClick: () => window.history.back(),
                    disable: false,
                  }}
                ></DeviceService>
              </Box>
          </Box>
        </MainStackLayout>
      </Box>
    </Center>
  );
}

export default TriageFailure;
