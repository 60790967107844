import { useConfig } from '@MGPD/myasurion-shared';
import { TextScript, getTextScript } from '@MGPD/myasurion-shared';
import { Box, BoxProps, Button, Flex, Image, Square, Text } from '@chakra-ui/react';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import DeviceModelScript from '../Scripts/asurion-script.json';
import DeviceModelTranslations from '../Scripts/translationsKeys.json';
import AlertIcon from './AlertIcon';

export interface DeviceDetails {
  img: string;
  familyName: string;
  srf: string;
}

export interface DeviceModelProps {
  DeviceDetails: DeviceDetails;
  onClickCallback: (args: DeviceDetails) => void;
  inStock: boolean;
  incidentType: string;
}

const DeviceModel: React.FC<PropsWithChildren<BoxProps & DeviceModelProps>> = ({
  onClickCallback,
  children,
  DeviceDetails,
  inStock,
  incidentType,
  ...props
}) => {
  const [ServiceRequestFeeText, setServiceRequestFeeText] = useState('');

  useEffect(() => {
    getServiceRequestFeeText();
  }, [DeviceDetails]);

  const getServiceRequestFeeText = async () => {
    const returnText = await getTextScript(
      `${DeviceModelTranslations.DeviceModel.PageName}/${DeviceModelTranslations.DeviceModel.ServiceRequestFee}`,
      `Standard service request fee (SRF) <b>{1}</b>`,
      'en-US',
      [DeviceDetails.srf]
    );

    setServiceRequestFeeText(returnText);
  };

  const ButtonComponent = () => {
    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { primary, accent } = colors;
    return (
      <>
        {inStock ? (
          <Button
            variant="outline"
            onClick={() => {
              onClickCallback && onClickCallback(DeviceDetails);
            }}
            display={'flex'}
            justifyContent={'center'}
            marginX={'auto'}
            bg={primary}
            textColor={'white'}
            width={'full'}
            mt={{ base: '0.5rem', lg: '1rem' }}
          >
            <TextScript
              text={'Proceed'}
              translationKey={`${DeviceModelTranslations.DeviceModel.PageName}/${DeviceModelTranslations.DeviceModel.KeyProceedButton}`}
            />
          </Button>
        ) : (
          <Box>
            <Button
              variant="outline"
              onClick={() => {
                onClickCallback && onClickCallback(DeviceDetails);
              }}
              // display={'flex'}
              // justifyContent={'center'}
              // marginX={'auto'}
              colorScheme="primary"
              // width={'full'}
              mt={{ base: '0.5rem', lg: '1rem' }}
              // p={2}
              mb={'8px'}
            >
              <Text color={accent}>
                <TextScript
                  text={DeviceModelScript.DeviceModel.waitForDeviceToRestocked}
                  translationKey={`${DeviceModelTranslations.DeviceModel.PageName}/${DeviceModelTranslations.DeviceModel.waitForDeviceToRestocked}`}
                />
              </Text>
            </Button>
            <Flex>
              <AlertIcon height={'1.5rem'} width={'1.5rem'} />
              <span style={{ paddingLeft: '4px', color: '#DB1B1B' }}>
                <TextScript
                  text={'Do note that backorder takes approximately 2 weeks.'}
                  translationKey={`${DeviceModelTranslations.DeviceModel.PageName}/${DeviceModelTranslations.DeviceModel.KeyLblBackOrderInfo}`}
                />
              </span>
            </Flex>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <Box {...props} paddingX={'1rem'}>
        <Box w={{ base: 'full', lg: '60%' }} m={'auto'}>
          <Flex justify={{ base: 'center', lg: 'space-between' }}>
            <Flex direction={'column'}>
              <Box marginRight={{ base: '', lg: '2rem' }}>
                {inStock && (
                  <Text fontSize={'2rem'}>
                    <TextScript
                      text={'Your model is available for {1}'}
                      translationKey={`${DeviceModelTranslations.DeviceModel.PageName}/${DeviceModelTranslations.DeviceModel.scriptInStock}`}
                      dynamicValues={[incidentType]}
                    />
                  </Text>
                )}
                {!inStock && (
                  <Text fontSize={'2rem'}>
                    <TextScript
                      text={'Sorry, your model is not available for {1}'}
                      translationKey={`${DeviceModelTranslations.DeviceModel.PageName}/${DeviceModelTranslations.DeviceModel.scriptOutOfStock}`}
                      dynamicValues={[incidentType]}
                    />
                  </Text>
                )}
                <Text fontSize={'1.12rem'}>{DeviceDetails.familyName}</Text>
                <Box mt={'1rem'}>
                  {ServiceRequestFeeText && (
                    <div dangerouslySetInnerHTML={{ __html: ServiceRequestFeeText }} />
                  )}
                </Box>
              </Box>
              <Box w={{ base: 'full', lg: '60%' }} display={{ base: 'none', lg: 'block' }}>
                <ButtonComponent />
              </Box>
            </Flex>
            <Square maxWidth={'148px'} maxHeight={'148px'}>
              <Image src={DeviceDetails.img} alt="your model" />
            </Square>
          </Flex>
        </Box>
        <Box display={{ base: 'block', lg: 'none' }}>
          <ButtonComponent />
        </Box>
      </Box>
    </>
  );
};

export default DeviceModel;