/* eslint-disable no-console */
import {
    getCarrierByDomain,
    getEnvByDomain,
    getHomeLink,
    getTypeByDomain,
    getUrlByTypeAndEnv,
} from '@MGPD/myasurion-shared';
import { Flex } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { consumeSSOAuthToken, fetchAccountProfile } from '../services/api';
import ActionTypes from '../ClaimComponents/store/actionTypes';
import { useCentralStore } from '../ClaimComponents/store/appContext';
import Loader from '../ClaimComponents/utils/loader';
// import { useNavigate } from 'react-router-dom';

async function setSessionAndRedirect(data: any, globalState: any) {
    // const navigate = useNavigate();
    await new Promise((resolve) => {
        // console.log('account details before saving to session...', JSON.stringify(data.body));
        sessionStorage.setItem('account', JSON.stringify(data.body));
        // console.log('account details saved to session. Redirecting...');
        setTimeout(resolve, 100); // Minimal delay to allow event loop to proceed
    });
    // console.log('fetching user account before redirect...', sessionStorage.getItem('account'));
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
    window.location.href = getHomeLink();
    // window.open(getHomeLink(), '_self');
    // navigate(getHomeLink(), { replace: true })
}

function SSOConsumer() {
    const globalState = useCentralStore();
    const portalType = getTypeByDomain();
    const carrier = getCarrierByDomain();
    const sharedUrlLogin = getUrlByTypeAndEnv(
        carrier,
        'device_protection',
        import.meta.env.VITE_ENV?.toString().toLowerCase(),
        true
    );
    useQuery(
        ['SSOConsumer'],
        async () => {
            const params = new URLSearchParams(window.location.search);
            const c = params.get('c');
            // const accessToken = sessionStorage.getItem('at');
            // const accountInfo = sessionStorage.getItem('account');

            if (!c) {
                // if (!c) {
                console.log('token is missing or invalid ', c);
                if (portalType === 'shared') {
                    window.location.href = sharedUrlLogin + '/login?logoutRedirect=true';
                }
                window.location.href = '/login';
                return;
            }
            // if (accessToken || accountInfo) {
            //     console.log('access exists ', c);
            //     window.location.href = '/login';
            //     return;
            // }
            console.log('consuming SSO Token ', c);
            globalState?.dispatch({ type: ActionTypes.LOADING, payload: true });
            return await consumeSSOAuthToken(c);
        },
        {
            retry: 5,
            onSuccess: (data) => {
                console.log('SSO token consumed successfully. ', data.body);
                const params = new URLSearchParams(window.location.search);
                const p = params.get('p');
                if (data?.statusCode === 200) {
                    sessionStorage.setItem('at', JSON.stringify(data.body));
                    sessionStorage.setItem('data', JSON.stringify(data.body.data));
                    sessionStorage.setItem('p', p ?? 'device_protection');
                    console.log('at is saved to session. Fetching user account...', data.body);
                    refetchUserAccount();
                } else {
                    globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
                    if (portalType === 'shared') {
                        const sharedUrlLogin = getUrlByTypeAndEnv(
                            carrier,
                            (p ?? 'device_protection') as 'device_protection' | 'repair',
                            getEnvByDomain() as 'local' | 'dev' | 'sqa' | 'uat' | 'prod',
                            true
                        );
                        window.location.href = sharedUrlLogin + '/login?logoutRedirect=true';
                    }
                    window.location.href = window.location.origin + '/login';
                }
            },
            onError: (error) => {
                console.error(error);
                globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
            },
            enabled: true,
        }
    );

    const { refetch: refetchUserAccount } = useQuery(
        ['userAccount'],
        async () => {
            console.log('Fetching account profile');
            const authTokens = sessionStorage.getItem('at');
            const parsedAuthTokens = authTokens ? JSON.parse(authTokens) : null;
            return await fetchAccountProfile(parsedAuthTokens);
        },
        {
            retry: 5,
            onSuccess: (data) => {
                console.log('Account profile fetched. ', data);
                if (data?.statusCode === 200) {
                    setSessionAndRedirect(data, globalState);
                } else {
                    globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
                    if (portalType === 'shared') {
                        window.location.href = sharedUrlLogin + '/login?logoutRedirect=true';
                    }
                    window.location.href = window.location.origin + '/login';
                }
            },
            onError: (error) => {
                console.error(error);
                globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
            },
            enabled: false,
        }
    );

    return (
        <Flex
            w={'full'}
            color={'#000'}
            mt={'60px'}
            p={'16px 8px'}
            maxW={{ base: 'initial', lg: '768px', xl: '992px', '2xl': '1280px' }}
            mx={{ base: '0px', lg: 'auto' }}
            flex={1}
            style={{ height: 'calc(100vh - 56px)' }}
        >
            <Flex h="full" w="full" justifyContent="center" alignItems="center">
                {/* <Spinner /> */}
                <Loader />
            </Flex>
        </Flex>
    );
}

export default SSOConsumer;
