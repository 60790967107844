/* eslint-disable no-console */
import {
    getCarrierByDomain,
    getCountryByCarrier,
    iMyAsurionPortalType,
} from '@MGPD/myasurion-shared/modules/carrier';
import { generateAuthorization, getAuthTokens } from '@MGPD/myasurion-shared/utils';
import axios from 'axios';
import { SHA256 } from 'crypto-js';

import { DocumentDetails } from '../components/mydocuments/DocumentList';
import { APIVERSION, GetDevicesAPIReturnType, iGetDeviceResponseMap } from '../types/responses';

const onlineBaseUrl = import.meta.env.VITE_API_ONLINE_BASE_URL;
const carrier = getCarrierByDomain();
const cc = getCountryByCarrier(carrier);
const myAsurionBaseUrl = import.meta.env.VITE_CONFIG_API_BASE_URL;
const myAsurionBaseUrlV2 = import.meta.env.VITE_CONFIG_API_BASE_URL_V2;

const createHeaders = () => {
    const authtoken = getAuthTokens();
    const headers = {
        token: authtoken.token,
        'x-api-key': import.meta.env.VITE_API_KEY,
        'x-asurion-client': carrier,
        'x-asurion-cc': cc.code,
        authorization: authtoken.authorization,
        id: authtoken.userId,
    };
    return headers;
};

export const getDevices = async <T extends APIVERSION<iGetDeviceResponseMap>>(
    version: APIVERSION<iGetDeviceResponseMap> = 'v1'
): Promise<Awaited<GetDevicesAPIReturnType<T>>> => {
    const headers = createHeaders();

    const response = await axios.get(
        `${myAsurionBaseUrl}/account/devices`.replace('v1', `${version ?? 'v1'}`),
        {
            headers,
            validateStatus: (status) => {
                return status < 500 && status !== 401 && status !== 403;
            },
        }
    );
    if (response.data?.statusCode && response.data?.statusCode === 500) {
        throw response.data;
    }
    return response.data;
};

export const initializeOnlineApi = async () => {
    const headers = createHeaders();

    const response = await axios.get(`${myAsurionBaseUrl}/online/initialize`, {
        headers,
        validateStatus: (status) => {
            return status < 500 && status !== 401 && status !== 403;
        },
    });
    return response.data.body;
};

export const findAgreementByMdn = async ({
    mdn,
    email,
    data,
    imei,
    idx,
    assetId,
    isDeviceData,
    sessionId,
}: {
    mdn: string;
    email: string;
    data: any;
    imei: string;
    idx: number;
    assetId?: string;
    isDeviceData?: boolean;
    sessionId?: string;
}) => {
    const headers = createHeaders();

    if (!mdn) {
        return;
    }
    const carrier = getCarrierByDomain();
    const payload: {
        mdn: string;
        email: string;
        data: any;
        imei: string;
        idx: number;
        assetId?: string;
        isDeviceData?: boolean;
        sessionId?: string;
    } = { mdn, email, data, imei, idx, isDeviceData, sessionId };
    if (carrier === 'cht_home') {
        payload.assetId = assetId;
    }
    //myAsurionBaseUrlV2 for V2 findagreement API
    //myAsurionBaseUrl for V1 findagreement API
    const response = await axios.post(`${myAsurionBaseUrlV2}/online/findagreement`, payload, {
        headers,
    });
    // This will logout the user if this fails due to useQuery
    // if (response.data.body.message === 'ERR_SERVER_ERROR') {
    //     throw response.data.body;
    // }
    return response.data.body;
};


export const getOnlineSession = async () => {
    const headers = createHeaders();

    const response = await axios.get(`${myAsurionBaseUrl}/online/session`, { headers });
    return response.data.body;
};

export const getSmartScanToken = async () => {
    const headers = createHeaders();

    try {
        const response = await axios.get(`${myAsurionBaseUrl}/smartscan/token`, { headers });
        return response.data.body;
    } catch (error) {
        console.log(error);
    }
};

export const getPlanDetailsByAgreementId = async (agreementId: string) => {
    const headers = createHeaders();

    try {
        const response = await axios.get(
            `${onlineBaseUrl}/swap/claim/api/details/${agreementId}?agreementId=${agreementId}`,
            { headers }
        );
        return response.data.body;
    } catch (error) {
        console.log(error);
    }
};

export const getSecurityAdvisorData = async () => {
    const headers = createHeaders();

    const response = await axios.get(`${myAsurionBaseUrl}/secadv/token`, { headers });
    return response.data.body;
};

export const getClaimDetailsData = async ({
    mdn,
    email,
    data,
}: {
    mdn: string;
    email: string;
    data: any;
}) => {
    const headers = createHeaders();

    if (!mdn) {
        return;
    }
    const response = await axios.post(
        `${myAsurionBaseUrl}/online/claimdetails`,
        { mdn, email, data },
        { headers }
    );
    return response.data.body;
};

export const getTechTipsData = async () => {
    const headers = createHeaders();
    const response = await axios.get(
        `${myAsurionBaseUrl}/article/list?language=en-US`.replace('v1', 'v2'),
        { headers }
    );
    return response.data.body;
};

export const getAllArticles = async () => {
    const headers = createHeaders();
    const response = await axios.get(
        `${myAsurionBaseUrl}/article/list?language=en-US&items=3`.replace('v1', 'v2'),
        { headers }
    );
    return response.data.body;
};

export const getSecurityAdvisorV2Data = async ({ mdn }: { mdn: string }) => {
    const headers = createHeaders();

    const response = await axios.post(
        `${myAsurionBaseUrl}/secadv/token`.replace('v1', 'v2'),
        { mdn },
        {
            headers,
        }
    );
    return response.data.body;
};

export const getFAQQuestions = async ({
    query = '',
    portalType = 'device_protection',
}: {
    query?: string;
    portalType: iMyAsurionPortalType;
}) => {
    // const headers = createHeaders();
    const space = 'p98nobc8lq7v';
    const env =
        import.meta.env.VITE_ENV === 'dev' || import.meta.env.VITE_ENV === 'sqa' ? 'qa' : 'master';
    const access_token = import.meta.env.VITE_TOM_CONTENTFUL_ACCESS_KEY;
    // `https://cdn.contentful.com/spaces/${space}/environments/${env}/entries?${
    //     portalType === 'repair' ? 'fields.portalType=repair' : ''
    // }`,
    const response = await axios.get(
        `https://cdn.contentful.com/spaces/${space}/environments/${env}/entries?fields.portalType=${
            portalType === 'shared' ? 'device_protection' : portalType
        }`,
        {
            headers: {
                // ...headers,
                'Content-Type': 'application/json',
                authorization: `Bearer ${access_token}`,
            },
            params: {
                access_token,
                content_type: 'thDeviceDetailPage',
                query,
                include: 10,
            },
        }
    );
    return response.data;
};

export const getRequiredDocuments = async (policyNumber: string, agreementId: string) => {
    const headers = createHeaders();
    try {
        const response = await axios.get(`${myAsurionBaseUrl}/online/documents`, {
            headers,
            params: {
                p: policyNumber,
                aid: agreementId,
            },
        });
        return response.data.body?.GetTaskDetailResponse?.Documents as DocumentDetails[];
    } catch (error) {
        console.log(error);
        return [] as DocumentDetails[];
    }
};

export const getAuthToken = async (data: string) => {
    const headers = createHeaders();
    const response = await axios.post(
        `${myAsurionBaseUrl}/auth/token`,
        { data },
        {
            headers,
        }
    );
    return response.data.body;
};

export const consumeSSOAuthToken = async (token: string | null) => {
    try {
        const timestamp = +new Date();
        const tokenSHA = SHA256(timestamp.toString()).toString();

        const headers = {
            Authorization: generateAuthorization(
                {
                    httpPath: '/api/v1/auth/token',
                    method: 'GET',
                    token: tokenSHA,
                },
                import.meta.env.VITE_MYASURION_API_AUTH_HMAC_KEY
            ),
            token: tokenSHA,
            t: timestamp,
            'x-api-key': import.meta.env.VITE_API_KEY,
            'x-asurion-client': carrier,
            'x-asurion-cc': cc.code,
        };

        const { data } = await axios.get(`${myAsurionBaseUrl}/auth/token?c=${token}`, { headers });

        return data;
    } catch (error) {
        console.error('Error consuming SSO auth token:', error);
    }
};

export const fetchAccountProfile = async (authTokens: any) => {
    try {
        const headers = {
            token: authTokens.token,
            'x-api-key': import.meta.env.VITE_API_KEY,
            'x-asurion-client': carrier,
            'x-asurion-cc': cc.code,
            authorization: authTokens.authorization,
            id: authTokens.id,
        };
        const { data } = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/account/profile`, { headers });
        return data;
    } catch (error) {
        console.error('Error fetching account profile:', error);
        throw error;
    }
};

export const acceptTNC = async (d: string, i: number | undefined) => {
    try {
        const headers = createHeaders();
        const { data } = await axios.post(
            `${myAsurionBaseUrl}/account/tnc`,
            { d, i: i },
            { headers }
        );
        return data;
    } catch (error) {
        console.error('Error fetching accepting T&C:', error);
    }
};

export const RawFindAgreement = async ( mdn: String) => {
    try{
    const headers = createHeaders();
    const payload: {
        mdn: any;
    } = { mdn};

    const response = await axios.post(`${myAsurionBaseUrl}/online/rawfindagreement`, payload, {
        headers,
    });
    return response.data.body;
} catch (error) {
    console.error('Error fetching rawload', error);
}
}
