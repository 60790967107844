import { TextScript, useConfig, useTextScript } from '@MGPD/myasurion-shared';
import { Box, BoxProps, Flex, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import format from 'date-fns/format';
import { FC } from 'react';

export type iGetNotifications = {
    AgreementId: string;
    EventDate: string;
    IsResolved: 'N' | 'Y';
    NotificationBody: string;
    NotificationBodyTL: string;
    NotificationDate: string;
    ServiceRequestEventCode: string;
    ServiceRequestEventDesc: string;
    ServiceRequestEventDescTl: string;
    ServiceRequestEventDetailId: string;
};

export interface NotificationsDisplayProps {
    notificationsCount?: number;
    notifications: iGetNotifications[];
    isLoading: boolean;
}

export const NotificationsDisplay: FC<BoxProps & NotificationsDisplayProps> = ({
    notificationsCount,
    notifications,
    ...props
}) => {
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { language } = useTextScript();
    return (
        <Box
            id={'myasurion-notifications-display'}
            zIndex={999998}
            pos={'fixed'}
            top={0}
            left={0}
            bg={'#fff'}
            w={'full'}
            h={'100dvh'}
            m={0}
            p={0}
            border={'none'}
            color={'black'}
            overflowY={'auto'}
            {...props}
        >
            <VStack
                gap={0}
                spacing={0}
                w={'full'}
                alignItems={'baseline'}
                id={'myasurion-notifications-display-stack'}
                maxW={{ base: 'initial', lg: '768px', xl: '992px', '2xl': '1280px' }}
                mx="auto"
                pt={{ md: 8 }}
            >
                <Box w={'full'} px={'16px'} py={'16px'}>
                    <HStack gap={6}>
                        <Box>
                            <VStack alignItems={'baseline'} gap={1}>
                                <Text
                                    fontSize="2xl"
                                    fontWeight={400}
                                    lineHeight="132%"
                                    fontStyle="normal"
                                >
                                    <TextScript
                                        translationKey="Global/Notifications"
                                        text="notifications"
                                    />
                                </Text>
                                {notifications && Array.isArray(notifications) && (
                                    <Text
                                        fontSize="md"
                                        fontStyle="normal"
                                        fontWeight={400}
                                        lineHeight="124%"
                                        color="#6E767D"
                                    >
                                        {language === 'th-TH' ? 'ล่าสุดวันที่' : 'Last'}{' '}
                                        {new Date(
                                            notifications[0]?.NotificationDate
                                        ).toLocaleString('en-US', {
                                            month: 'short',
                                            day: 'numeric',
                                            year: 'numeric',
                                        })}
                                    </Text>
                                )}
                            </VStack>
                        </Box>
                        <Spacer />
                    </HStack>
                </Box>
                {notifications &&
                    Array.isArray(notifications) &&
                    notifications?.map((notif, i: number) => {
                        return (
                            <Box w="full" px={'16px'} key={`notifs-${notif?.AgreementId}-${i}`}>
                                <Flex
                                    alignSelf="stretch"
                                    padding={'12px 0px 20px'}
                                    direction="column"
                                    borderBottom="1px solid #D5D6DA"
                                    gap="8px"
                                >
                                    <Text
                                        fontSize={'18px'}
                                        fontWeight={700}
                                        lineHeight="132%"
                                        color={notif?.IsResolved === 'N' ? colors.primary : 'black'}
                                    >
                                        {language === 'th-TH'
                                            ? notif?.ServiceRequestEventDescTl ??
                                              notif?.ServiceRequestEventDesc
                                            : notif?.ServiceRequestEventDesc}
                                    </Text>
                                    <Text
                                        fontSize="md"
                                        fontWeight={notif?.IsResolved === 'N' ? 700 : 400}
                                        lineHeight="124%"
                                        fontStyle="normal"
                                    >
                                        {language === 'th-TH'
                                            ? notif?.NotificationBodyTL
                                            : notif?.NotificationBody}
                                    </Text>
                                    <Text
                                        fontSize="sm"
                                        fontStyle="normal"
                                        lineHeight="136%"
                                        fontWeight={400}
                                        color={'#6E767D'}
                                    >
                                        {format(new Date(notif?.EventDate), 'd MMMM yyyy h:mm aaa')}
                                    </Text>
                                </Flex>
                            </Box>
                        );
                    })}
                {(!notifications ||
                    !Array.isArray(notifications) ||
                    notifications?.length === 0) && (
                    <Box w="full" px={'16px'}>
                        <Flex
                            alignSelf="stretch"
                            padding={'12px 0px 20px'}
                            direction="column"
                            gap="8px"
                        >
                            <Text
                                fontSize={'18px'}
                                fontWeight={700}
                                lineHeight="132%"
                                color={colors.primary}
                            >
                                No Pending Notifications
                            </Text>
                        </Flex>
                    </Box>
                )}
            </VStack>
        </Box>
    );
};

export default NotificationsDisplay;
