import { CloseIcon } from '@chakra-ui/icons';
import {
    Box,
    BoxProps,
    Button,
    Flex,
    Image,
    Input,
    Select,
    Square,
    Stack,
    Text,
} from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

import FileUpload from '../../assets/fileUpload.svg';
import { TextScript, useConfig } from '@MGPD/myasurion-shared';

export interface UpdateDeviceProps {
    title: string;
    plan: string;
    mdn: string;
    model: string;
    imei: string;
    imageEl: any;
    updateDeviceText: string;
    updateDeviceQueLBL: string;
    updateDeviceOptions: Array<string>;
    uploadDocTextLBL: string;
    placeHolder: string;
    optionPlaceHolder: string;
    btnSubmitLBL: string;
    onClickSubmit: any;
    onClickCancel: any;
    handleFileUpload?: (e: any) => void;
    handleOptions?: (e: any) => void;
    isDisabled: boolean;
    moreThan5Mb: boolean;
    fileError: boolean;
    uploadFileText: string;
    hideUploadBtn: boolean;
    uploadedFileName: string;
    removeFile: any;
    serialNumber?: any;
    FileErrorText?: string;
}

export const UpdateDevice: React.FC<PropsWithChildren & BoxProps & UpdateDeviceProps> = (
    args: any
) => {
    const {
        title,
        updateDeviceText,
        updateDeviceQueLBL,
        updateDeviceOptions,
        uploadDocTextLBL,
        plan,
        mdn,
        model,
        imei,
        imageEl,
        placeHolder,
        optionPlaceHolder,
        onClickSubmit,
        isDisabled,
        handleFileUpload,
        btnSubmitLBL,
        handleOptions,
        moreThan5Mb,
        fileError,
        uploadFileText,
        hideUploadBtn,
        uploadedFileName,
        removeFile,
        serialNumber,
        fileErrorText,
        ...props
    } = args;
    const pageName = 'UpdateDevice';

    const fields: { field: string; label: string; weight?: number }[] = [
        { field: 'mdn', label: 'Mobile:', weight: 700 },
        { field: 'model', label: 'Model:' },
    ];

    if (serialNumber) {
        fields.push({ field: 'serialNumber', label: 'Serial Number:' });
    } else {
        fields.push({ field: 'imei', label: 'IMEI:' });
    }

    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;

    // const handleOptions = (e: any) => {
    //     console.log('handleOptions', e);

    // }

    return (
        <Box>
            <Box
                {...props}
                p={4}
                alignItems="center"
                w={{ base: 'full', lg: '70%' }}
                margin={{ base: '0', lg: 'auto' }}
                pb={{ base: '0', lg: '140px' }}
            >
                <Box p={2}>
                    {title && (
                        <Text fontSize={{ base: '2xl', lg: '3xl' }}>
                            <TextScript text={title} translationKey={`${pageName}/title`} />
                        </Text>
                    )}
                </Box>
                <Box
                    id={'myasurion-devicecard'}
                    borderRadius={'8px'}
                    minW={'335px'}
                    minH={'124px'}
                    borderWidth={'1px'}
                    borderStyle={'solid'}
                    borderColor={'gray.300'}
                    p={'16px'}
                    // w="80%"
                    {...props}
                >
                    <>
                        <Flex justify={'space-between'}>
                            <Box>
                                <Box m={'0'} mb={'15px'}>
                                    <Box
                                        m={0}
                                        p={'4px'}
                                        // w={'130px'}
                                        bg={'brand.neutral.bright'}
                                        borderRadius={'2px'}
                                    >
                                        <Text fontSize={'sm'}>{plan}</Text>
                                    </Box>
                                </Box>
                                {fields.map((f, i) => {
                                    return (
                                        <Stack
                                            direction={'row'}
                                            gap={0}
                                            spacing={0}
                                            w={'full'}
                                            fontSize={'md'}
                                            fontWeight={400}
                                            key={`devicecard-field-${f.field}-${i}`}
                                        >
                                            <Box w={'30%'} float={'left'} fontWeight={'bold'}>
                                                <TextScript
                                                    text={f.label}
                                                    translationKey={`${pageName}/${f.field}`}
                                                />
                                            </Box>
                                            <Box
                                                w={'70%'}
                                                float={'right'}
                                                fontWeight={f.weight || 400}
                                            >
                                                {args[`${f.field}`]}
                                            </Box>
                                        </Stack>
                                    );
                                })}
                            </Box>
                            <Square>
                                {imageEl ? (
                                    imageEl
                                ) : (
                                    <Box m={'30% auto'} fontSize={'xs'} color={'gray.300'}>
                                        <TextScript
                                            text={'No image'}
                                            translationKey={`${pageName}/lblNoImage`}
                                        />
                                    </Box>
                                )}
                            </Square>
                        </Flex>
                        {/* <Center mt="2">
                            <Box
                                w="80%"
                                m="auto"
                                border="1px"
                                borderColor={colors.primary}
                                py="2"
                                px="4"
                                borderRadius="4"
                            >
                                <Text align={'center'} color={colors.primary} fontWeight={'bold'}>
                                    <TextScript
                                        text={updateDeviceText}
                                        translationKey={`${pageName}/updateDeviceText`}
                                    />
                                </Text>
                            </Box>
                        </Center> */}
                    </>
                </Box>

                <Box w={{ base: 'full', lg: '80%' }} m="auto">
                    <Text fontWeight={'bold'} mt="5">
                        <TextScript
                            text={updateDeviceQueLBL}
                            translationKey={`${pageName}/updateDeviceQueLBL`}
                        />
                        <span style={{ color: 'red' }}>*</span>
                    </Text>
                    <Box mt="2">
                        <Select placeholder={updateDeviceOptions?.length === 1 ? undefined : optionPlaceHolder} onChange={(e) => handleOptions(e)}>
                            {updateDeviceOptions?.map((v: string, i: any) => {
                                return (
                                    <option key={v} value={v}>
                                        <Text fontSize={{ base: '2xl', lg: '3xl' }}>
                                            <TextScript
                                                text={v}
                                                translationKey={`${pageName}/${v}-${i}`}
                                            />
                                        </Text>
                                    </option>
                                );
                            })}
                        </Select>
                    </Box>
                    <Text fontWeight={'bold'} mt="5">
                        <TextScript
                            text={uploadDocTextLBL}
                            translationKey={`${pageName}/uploadDocTextLBL`}
                        />
                        <span style={{ color: 'red' }}>*</span>
                    </Text>
                    {!hideUploadBtn ? (
                        <>
                            <Flex
                                mt="2"
                                direction={'row'}
                                alignContent={'center'}
                                border={'1px solid #6E767D'}
                                borderRadius={'xl'}
                                p="2"
                                position={'relative'}
                            >
                                <Image src={FileUpload} my={'auto'} mx="2" />
                                <Input
                                    type="file"
                                    display={'hidden'}
                                    border={'none'}
                                    p="5px 10px"
                                    height={'auto'}
                                    placeholder={placeHolder}
                                    onChange={(e) => handleFileUpload(e)}
                                    style={{
                                        opacity: 0,
                                        overflow: 'hidden',
                                        zIndex: 999,
                                    }}
                                />
                                <label
                                    style={{
                                        position: 'absolute',
                                        top: '30%',
                                        left: '15%',
                                        zIndex: '0',
                                        color: '#6E767D',
                                    }}
                                >
                                    <span>
                                        <TextScript
                                            text={uploadFileText}
                                            translationKey={`${pageName}/uploadFileText`}
                                        />
                                    </span>
                                </label>
                                {/* </InputGroup> */}
                            </Flex>
                            {fileError && (
                                <Text color="red" fontSize={'14px'} mt="2px" align={'center'}>
                                    <TextScript
                                        text={fileErrorText || 'Invalid file format, please upload a PDF/JPEG/jpg/png/bmp file.'}
                                        translationKey={`${pageName}/updateDeviceInvalidFileFormatError`}
                                    />
                                </Text>
                            )}
                            {moreThan5Mb && (
                                <Text color="red" fontSize={'14px'} mt="2px" align={'center'}>
                                    <TextScript
                                        text={'You can upload files maximum of 5 MB per file'}
                                        translationKey={`${pageName}/updateDeviceFileSizeError`}
                                    />
                                </Text>
                            )}
                        </>
                    ) : (
                        <>
                            <Flex direction={'row'} justify={'space-between'}>
                                {uploadedFileName}
                                <CloseIcon
                                    onClick={() => removeFile()}
                                    cursor={'pointer'}
                                    alignSelf={'center'}
                                    marginX={'0.5rem'}
                                    padding={'1px'}
                                />
                            </Flex>
                        </>
                    )}
                    <Box w={{ base: 'full', lg: '50%' }} m="20px auto">
                        <Button
                            w="full"
                            // marginLeft={{ base: '0', lg: '20px' }}
                            color="white"
                            colorScheme="primary"
                            bg={isDisabled ? 'gray.200' : colors.primary}
                            cursor={isDisabled ? 'not-allowed' : 'pointer'}
                            disabled={isDisabled}
                            onClick={() =>
                                isDisabled ? undefined : onClickSubmit && onClickSubmit()
                            }
                        >
                            {btnSubmitLBL && (
                                <TextScript text={btnSubmitLBL} translationKey={`Global/Submit`} />
                            )}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default UpdateDevice;
