import { Icon, IconProps } from '@chakra-ui/icons';
import { Box, Circle, Text } from '@chakra-ui/react';
import * as React from 'react';
import { JSX } from 'react/jsx-runtime';

export const NotificationIcon = (
    props: JSX.IntrinsicAttributes &
        Omit<React.SVGProps<SVGSVGElement>, 'as' | 'translate' | keyof IconProps> & {
            htmlTranslate?: 'yes' | 'no' | undefined;
        } & IconProps & { as?: 'svg' | undefined } & { promptcount: number }
) => (
    <Box position="relative" p="4px">
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 12.279V15H18V12.279L17.316 12.052C15.931 11.589 15 10.298 15 8.838V6C15 2.691 12.309 0 9 0C5.691 0 3 2.691 3 6V8.838C3 10.298 2.069 11.589 0.684 12.052L0 12.279ZM14.966 13H3.035C4.255 11.998 5 10.486 5 8.838V6C5 3.794 6.794 2 9 2C11.206 2 13 3.794 13 6V8.838C13 10.486 13.745 11.998 14.966 13Z"
                fill={props?.fill?.toString() ?? 'black'}
            />
            <path
                d="M8.9997 18C7.5237 18 6.24769 17.19 5.55469 16H12.4447C11.7517 17.19 10.4757 18 8.9997 18Z"
                fill={props?.fill?.toString() ?? 'black'}
            />
        </Icon>
        {props?.promptcount > 0 && (
            <Circle
                position={'absolute'}
                top="0"
                right="0"
                minBlockSize="14px"
                minInlineSize="14px"
                minW="14px"
                minH="14px"
                // size="14px"
                padding={props.promptcount > 100 ? '4px' : 'unset'}
                verticalAlign="middle"
                background="#DB1B1B"
            >
                <Text
                    fontWeight={700}
                    size={props.promptcount > 100 ? '7px' : '8px'}
                    lineHeight="normal"
                    fontSize={props.promptcount > 100 ? '7px' : '8px'}
                    textAlign="center"
                    color="white"
                >
                    {props.promptcount}
                </Text>
            </Circle>
        )}
    </Box>
);

export default NotificationIcon;
