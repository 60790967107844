export default {
    DETRACTOR: [
        'Takes a long time to do what I want',
        'Portal was difficult to use',
        'SmartSupport was not able to address my issue',
        'Unsatisfied with service request processing',
        'Inflexible delivery arrangement',
        // 'Agent was not helpful in handling my request / query',
    ],

    PASSIVE: [
        'Time spent on getting what I want',
        'Ease of getting what I want',
        'Usefulness of SmartSupport to address my issue',
        'Satisfaction with service request processing',
        'Flexibility with delivery arrangement',
        'Satisfaction with customer service',
    ],

    PROMOTER: [
        'Minimal time spent to do what I want',
        'Portal was easy to use',
        'SmartSupport was able to address my concern',
        'Satisfied with service request processing',
        'Flexible delivery arrangement',
        // 'Agent was friendly and helpful in handling my request / query',
    ],
};

export const getContentByCarrier = (carrierId: string) => {
    switch (carrierId) {
        case 'starhub':
            return {
                DETRACTOR: [
                    'Takes a long time to do what I want',
                    'Portal was difficult to use',
                    'SmartSupport was not able to address my issue',
                    'Unsatisfied with service request processing',
                    'Inflexible delivery arrangement',
                ],

                PASSIVE: [
                    'Time spent on getting what I want',
                    'Ease of getting what I want',
                    'Usefulness of SmartSupport to address my issue',
                    'Satisfaction with service request processing',
                    'Flexibility with delivery arrangement',
                    'Satisfaction with customer service',
                ],

                PROMOTER: [
                    'Minimal time spent to do what I want',
                    'Portal was easy to use',
                    'SmartSupport was able to address my concern',
                    'Satisfied with service request processing',
                    'Flexible delivery arrangement',
                ],
            };
        case 'asurion_techcare':
            return {
                DETRACTOR: [
                    'Takes a long time to do what I want',
                    'Site was difficult to use',
                    'Service was not able to address my issue',
                    'Unsatisfied with service request processing',
                    'Inflexible delivery arrangement',
                ],

                PASSIVE: [
                    'Time spent on getting what I want',
                    'Ease of getting what I want',
                    'Usefulness of service to address my issue',
                    'Satisfaction with service request processing',
                    'Flexibility with delivery arrangement',
                    'Satisfaction with customer service',
                ],

                PROMOTER: [
                    'Minimal time spent to do what I want',
                    'Site is easy to use',
                    'Handle errors well and help available',
                    'Able to address my issues',
                    'Satisfied with service request processing',
                    'Flexible delivery arrangement',
                ],
            };
        default:
            return {
                DETRACTOR: [
                    'Takes a long time to do what I want',
                    'Site was difficult to use',
                    'Service was not able to address my issue',
                    'Unsatisfied with service request processing',
                    'Inflexible delivery arrangement',
                ],

                PASSIVE: [
                    'Time spent on getting what I want',
                    'Ease of getting what I want',
                    'Usefulness of service to address my issue',
                    'Satisfaction with service request processing',
                    'Flexibility with delivery arrangement',
                    'Satisfaction with customer service',
                ],

                PROMOTER: [
                    'Minimal time spent to do what I want',
                    'Site is easy to use',
                    'Handle errors well and help available',
                    'Able to address my issues',
                    'Satisfied with service request processing',
                    'Flexible delivery arrangement',
                ],
            };
    }
};
