import { TextScript } from '@MGPD/myasurion-shared';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  HStack,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
} from '@chakra-ui/react';
// import colornames from 'colornames';
import { useEffect, useState } from 'react';

interface Option {
  value: string;
  label: string;
  name: string;
  checked: boolean;
}

export interface Section {
  id: string;
  name: string;
  options: Option[];
}
interface FilterOptionsAccordionProps {
  selectedValues: { [sectionId: string]: { [value: string]: boolean } };
  setSelectedValues: React.Dispatch<
    React.SetStateAction<{ [sectionId: string]: { [value: string]: boolean } }>
  >;
  filterArray: Array<Section>;
  pageName: string
}

const FilterOptionsAccordion: React.FC<FilterOptionsAccordionProps> = ({
  selectedValues,
  setSelectedValues,
  filterArray,
  pageName
}) => {
  const [trueKeys, setTrueKeys] = useState<string[]>([]);

  useEffect(() => {
    let keys: string[] = [];
    for (const category in selectedValues) {
      const categoryKeys = Object.keys(selectedValues[category]).filter(
        (key) => selectedValues[category][key]
      );
      keys.push(...categoryKeys);
    }
    setTrueKeys(keys);
  }, [selectedValues]);

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    option: Option,
    sectionId: string
  ) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [sectionId]: { ...prevState[sectionId], [option.value]: e.target.checked },
    }));
  };

  const handleTagClose = (sectionId: string, value: string) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [sectionId]: { ...prevState[sectionId], [value]: false },
    }));
  };

  // const getColorHex = (colorName: string): string | undefined => {
  //   const colorMappings: Record<string, string> = {
  //     'light blue': '#ADD8E6',
  //     'jet black': '#0A0A0A',
  //     'rose gold': '#E0BFB8',
  //     'beige/cream': '#F5F5DC',
  //     glow: '#FCE29A',
  //   };
  //   const lowerCaseColorName = colorName.toLowerCase();
  //   if (lowerCaseColorName in colorMappings) {
  //     return colorMappings[lowerCaseColorName];
  //   }
  //   return '#FCE29A';
  // };

  return (
    <>
      <HStack
        marginY={'5px'}
        wrap={'wrap'}
        px={4}
        display={'flex'}
        justifyContent={'center'}
        pb={2}
      >
        {trueKeys?.map((val: string, index: number) => (
          <Tag
            size={'lg'}
            key={val + index}
            borderRadius="full"
            variant="solid"
            colorScheme="primary"
          >
            <TagLabel>{val}</TagLabel>
            {filterArray.map(
              (section) =>
                section.options.find((option) => option.value === val) && (
                  <TagCloseButton key={val} onClick={() => handleTagClose(section.id, val)} />
                )
            )}
          </Tag>
        ))}
      </HStack>
      <Accordion allowMultiple defaultIndex={filterArray.map((_, index) => index)}>
        {filterArray.map((section) => (
          <AccordionItem key={section.id}>
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight={'bold'}
                      fontSize={'1.125rem'}
                    >
                      <TextScript
                        text={section?.name}
                        translationKey={`${pageName}/${section?.name}`}/>

                    </Box>
                    {isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
                  </AccordionButton>
                </h2>
                {isExpanded && (
                  <AccordionPanel pb={4}>
                    <Stack spacing={5} direction="column">
                      {section.options.map((option) => (
                        <Checkbox
                          key={option.value}
                          colorScheme="primary"
                          isChecked={selectedValues[section.id]?.[option.value]}
                          onChange={(e) => handleCheckboxChange(e, option, section.id)}
                        >
                          <TextScript
                            text={option.label}
                            translationKey={`${pageName}/${option.label}`}/>
                          {/* {option.name === 'Color' && (
                            <Box
                              w="1rem"
                              h="1rem"
                              borderRadius="50%"
                              borderColor={'gray.500'}
                              bg={colornames(option.label) || getColorHex(option.label)}
                              display={'inline-block'}
                              borderWidth={option.label.toLowerCase() == 'white' ? '1px' : '0px'}
                              ml={'0.75rem'}
                            ></Box>
                          )} */}
                        </Checkbox>
                      ))}
                    </Stack>
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

export default FilterOptionsAccordion;
