/* eslint-disable no-console */
import { SubHeader, TextScript, getTypeByDomain, useConfig } from '@MGPD/myasurion-shared';
import { Box, Button, Image, Text } from '@chakra-ui/react';

import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import highfive from '../../icons/MiniHighFive.svg';
import { getRedirectUrl } from '../../utils/utills';
import MainStackLayout from '../../layout/MainStackLayout';
import { useCentralStore } from '../../store/appContext';

function ConfirmDeviceRegistration() {
  const { data } = useConfig();
  const globalState = useCentralStore();
  const { theme } = data;
  const { colors } = theme;

  const ConfirmDeviceRegistrationScrips = Scripts.AddDevice.ConfirmDeviceRegistration;
  const ConfirmDeviceRegistrationKeys = translationsKeys.AddDevice.ConfirmDeviceRegistration;

  const pageName = ConfirmDeviceRegistrationKeys.PageName;

  const onCancelClick = () => {
    window.location.href = getRedirectUrl(getTypeByDomain());
  };

  return (
    <Box>
      <Box
        w={{ base: 'full', lg: '80%' }}
        margin="auto"
        pt={'50px'}
      >
        <MainStackLayout gap={0} spacing={0}>
          <SubHeader
            backText={''}
            allowBackButtonClick={false}
            showCancelButton={false}
            showBackButton={false}
            cancelText={Scripts.CancelSR.cancelBtnTextCancel}
            onCancelClick={onCancelClick}
          ></SubHeader>
          <Box
            id={'myasurion-profile-avatar'}
            w={{ base: 'full', lg: '80%' }}
            margin="0 auto"
            h={'auto'}
            p={'1rem'}
            textAlign={'center'}
          >
            <Image marginX={'auto'} src={highfive} alt="your model" />
            <Text mt={'5'} fontSize={{ base: '2xl', lg: '3xl' }}>
              <TextScript
                text={ConfirmDeviceRegistrationScrips.header}
                translationKey={`${pageName}/${ConfirmDeviceRegistrationKeys.header}`}
              />
            </Text>
            {globalState?.state?.inquiryResponse &&
              <Text mt={'5'}>
                <TextScript
                  text={ConfirmDeviceRegistrationScrips.subHeader}
                  translationKey={`${pageName}/${ConfirmDeviceRegistrationKeys.subHeader}`}
                />
              </Text>
            }

             <Box width={{ base: 'full', lg: '50%' }} marginX={'auto'}>
              <Button
                 mt={'5'}
                 width={'full'}
                 textAlign={'center'}
                 colorScheme="primary"
                 bg={colors.primary}
                 cursor={'pointer'}
                onClick={onCancelClick}
              >
                <TextScript
                  text={ConfirmDeviceRegistrationScrips.goBackBtn}
                  translationKey={`${ConfirmDeviceRegistrationKeys.PageName}/${ConfirmDeviceRegistrationKeys.goBackBtn}`}
                />
              </Button>
            </Box>
          </Box>
        </MainStackLayout>
      </Box>
    </Box>
  );
}

export default ConfirmDeviceRegistration;
