/* eslint-disable no-console */
import { ArrowBackIcon } from '@chakra-ui/icons';
import ProfileField from '../components/EditableField';
import MenuSidebar from '../components/MenuSidebar';
import MainStackLayout from '../layout/MainStackLayout';
import {
  labels,
  dashboardFieldLabels,
  addressLine1PartsLabels,
  addressLine2PartsLabels,
} from '../modules/config/constants';
import { fetchAccountProfile } from '../modules/services/apiService';
import {
  AccountInfo,
  AvatarInitials,
  BackButton,
  DesktopView,
  MobileView,
  TextScript,
  TextString,
  TextTranslation,
  getCarrierByDomain,
  // getTypeByDomain,
  useAccount,
  useConfig,
} from '@MGPD/myasurion-shared';
import { Box, Center, Text, VStack, Stack, SimpleGrid, Button, Link } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface ProfileFieldItem {
  fieldKey: string;
  value?: string;
}

const DashboardDetails = () => {
  const { data } = useConfig();
  const [account, setAccountInfo] = useAccount<AccountInfo>({ profileId: '', userId: '' });
  const carrier = getCarrierByDomain();
  const { theme }: { theme: any } = data;
  const { profileBanner } = theme?.components || {};
  const { fieldLabels: defaultFieldLabels, avatarLabel } = labels && labels.dashboard;

  const fullname = account?.enrolledName || `${account.firstName} ${account.lastName}`;
  const addressLine1Parts: string[] = (
    account?.communicationPreferences?.address?.addressLine1 || ''
  ).split(',');
  const addressLine2Parts: string[] = (
    account?.communicationPreferences?.address?.addressLine2 || ''
  ).split(',');

  const carrierProfileFieldsMap: { [key: string]: ProfileFieldItem[] } = {
    default: [
      {
        fieldKey: 'name',
        value: fullname,
      },
      { fieldKey: 'email', value: account.email },
      {
        fieldKey: 'mdn',
        value: account.enrolledMdn,
      },
    ],
    asurion_techcare: [
      {
        fieldKey: 'firstName',
        value: account.firstName,
      },
      {
        fieldKey: 'middleName',
        value: account.middleName,
      },
      {
        fieldKey: 'lastName',
        value: account.lastName,
      },
      { fieldKey: 'email', value: account.email },
      {
        fieldKey: 'mdn',
        value: account.enrolledMdn,
      },
      {
        fieldKey: 'dateOfBirth',
        value: account?.dateOfBirth
          ? new Date(account?.dateOfBirth).toLocaleString('default', {
              month: 'long',
              year: 'numeric',
              day: '2-digit',
            })
          : '',
      },
      {
        fieldKey: 'identificationType',
        value: account.identificationType,
      },
      {
        fieldKey: 'identificationNbr',
        value: account.identificationNbr,
      },
      ...addressLine1PartsLabels.map((labelTxt, idx) => ({
        fieldKey: labelTxt,
        value: addressLine1Parts[idx] || '',
      })),
      ...addressLine2PartsLabels.map((labelTxt, idx) => ({
        fieldKey: labelTxt,
        value: addressLine2Parts[idx] || '',
      })),
      {
        fieldKey: 'stateProvince',
        value: account?.communicationPreferences?.address?.stateProvince,
      },
      {
        fieldKey: 'postalCode',
        value: account?.communicationPreferences?.address?.postalCode,
      },
    ],
    maxis: [
      {
        fieldKey: 'firstName',
        value: account.firstName,
      },
      {
        fieldKey: 'middleName',
        value: account.middleName,
      },
      {
        fieldKey: 'lastName',
        value: account.lastName,
      },
      { fieldKey: 'email', value: account.email },
      {
        fieldKey: 'mdn',
        value: account.enrolledMdn,
      },
      {
        fieldKey: 'dateOfBirth',
        value: account?.dateOfBirth
          ? new Date(account?.dateOfBirth).toLocaleString('default', {
              month: 'long',
              year: 'numeric',
              day: '2-digit',
            })
          : '',
      },
      {
        fieldKey: 'identificationType',
        value: account.identificationType,
      },
      {
        fieldKey: 'identificationNbr',
        value: account.identificationNbr,
      },
      ...addressLine1PartsLabels.map((labelTxt, idx) => ({
        fieldKey: labelTxt,
        value: addressLine1Parts[idx] || '',
      })),
      ...addressLine2PartsLabels.map((labelTxt, idx) => ({
        fieldKey: labelTxt,
        value: addressLine2Parts[idx] || '',
      })),
      {
        fieldKey: 'stateProvince',
        value: account?.communicationPreferences?.address?.stateProvince,
      },
      {
        fieldKey: 'postalCode',
        value: account?.communicationPreferences?.address?.postalCode,
      },
    ],
  };

  const profileFields = carrierProfileFieldsMap[carrier] || carrierProfileFieldsMap['default'];
  const fieldLabels: string[] = dashboardFieldLabels[carrier] ?? defaultFieldLabels;

  const onFieldSave = useCallback((fieldKey: string, newValue: string) => {
    console.log(`Updating [${fieldKey}] with value of ${newValue}`);
  }, []);

  useEffect(() => {
    const refreshProfileData = async () => {
      const response = await fetchAccountProfile();
      setAccountInfo(response);
    };
    refreshProfileData();
  }, []);

  return (
    <>
      <Box id={'myasurion-profile-avatar'} w={'full'} h={'auto'} p={'24px 16px'} {...profileBanner}>
        <VStack alignItems={'center'} w={'full'} gap={2} spacing={2}>
          <AvatarInitials
            firstname={account?.firstName?.toUpperCase() || ''}
            lastname={account?.lastName?.toUpperCase() || ''}
            size="80px"
            textMargin="20px"
            fontSize="32px"
          />
          <Box textAlign={'center'}>
            <Text
              fontSize={'12px'}
              fontWeight={900}
              lineHeight={'136%'}
              textTransform={'uppercase'}
              color={'#000'}
              textAlign={'center'}
            >
              <TextString text={avatarLabel} translationKey={`DashboardDetails/MainAccount`} />
              {/* <TextTranslation
                text={avatarLabel}
                translationKey={'avatarLabel'}
                module={'myAsurionProfile'}
                section={'dashboard'}
              ></TextTranslation> */}
            </Text>
            <Text fontSize={'18px'} fontWeight={300} color={'#000'}>
              {`${account.firstName} ${account.lastName}`}
            </Text>
          </Box>
        </VStack>
      </Box>

      <Box
        id={'myasurion-profile-details'}
        w={'full'}
        position={'relative'}
        borderBottom={`1px solid #ccc`}
        borderColor={'gray.100'}
      >
        <Stack
          direction={{ base: 'column', md: 'row' }}
          w={'full'}
          gap={2}
          spacing={2}
          alignItems={'baseline'}
          height={'100%'}
        >
          <SimpleGrid columns={{ base: 1, md: 3 }}>
            {profileFields.map((field, i) => {
              return (
                <ProfileField
                  fieldKey={field.fieldKey}
                  key={`profilefield-${i}`}
                  label={fieldLabels[i]}
                  value={field.value || ''}
                  editable={false}
                  onSave={onFieldSave}
                />
              );
            })}
          </SimpleGrid>
        </Stack>
      </Box>
    </>
  );
};

const DashboardPage = () => {
  const { title } = labels && labels.dashboard;
  const disableApplLoad = getCarrierByDomain()?.toLowerCase() === 'starhub'//useFeatureIsOn('disableApplLoad');
  const navigate = useNavigate();

  return (
    <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'}>
      <MobileView>
        <MainStackLayout gap={0} spacing={0}>
          <BackButton isProfile>
            <TextTranslation
              text={title}
              translationKey={`dashboardBack-${title}`}
              module={'myAsurionProfile'}
              section={'dashboard'}
              disabled={false}
            />
          </BackButton>
          <DashboardDetails />
        </MainStackLayout>
      </MobileView>

      <DesktopView>
        <Center>
          <MainStackLayout
            gap={'20px'}
            spacing={0}
            maxW={'1024px'}
            direction={'row'}
            mt={'10px'}
            h={'auto'}
          >
            <Box w={'full'} maxWidth={'280px'} p={0} m={0} mt={'20px'}>
                <Box ml={2} mb={5}>
                  <Button
                      variant={'link'}
                      as={Link}
                      colorScheme="black"
                      href={disableApplLoad ? '#' : '/'}
                      onClick={() => disableApplLoad ? navigate('/') : undefined}
                      leftIcon={<ArrowBackIcon />}
                      color={'#000'}
                      // display={{ base: 'block', md: 'none' }}
                  >
                      <Text>
                          {/* <TextScript
                              text={'Close'}
                              translationKey={`Subheader/${getTypeByDomain()}-Device care`}
                          /> */}
                          Back
                      </Text>
                  </Button>
                </Box>
              <MenuSidebar />
            </Box>
            <Box w={'full'} p={0} m={0}>
              <Box w={'full'} pl={'16px'} fontSize={'40px'} fontWeight={400}>
                <TextScript text={'My Account'} translationKey={`Dashboard/My Account`} />
              </Box>

              <DashboardDetails />
            </Box>
          </MainStackLayout>
        </Center>
      </DesktopView>
    </Box>
  );
};

export default DashboardPage;
