/* eslint-disable no-console */
import MainStackLayout from '../layout/MainStackLayout';
// import { useQuery } from '@tanstack/react-query';
import '../services/api';
import { SubHeader, TextScript, getTextScript, useConfig } from '@MGPD/myasurion-shared';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
// import { useQuery } from '@tanstack/react-query';
// import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../Scripts/asurion-script.json';
// import { useSessionStorage, } from '@MGPD/myasurion-shared';
import ActionTypes from '../store/actionTypes';
import { useCentralStore } from '../store/appContext';
import CancelSrPopup from './myclaims/CancelSrPopup.tsx';
import LostModeTranslations from '../Scripts/translationsKeys.json'

function LostMode() {
  const globalState = useCentralStore();
  const navigate = useNavigate();
  // const [FulfilmentOptionsLocal, setFulfillmentOptionsLocal] = useState();
  const [openCancelPopup, setOpenCancelPopup] = useState(false);

  // const [, setFulfillmentOption] = useSessionStorage<any>('FulfillmentOption', null);
  // const [serviceRequestData] = useSessionStorage<any>('processIncident', null);

  // const [, setFulfillmentOption] = useSessionStorage<any>('FulfillmentOption', null);
  // const [serviceRequestData] = useSessionStorage<any>('processIncident', null);
  // const serviceRequestId = serviceRequestData?.ServiceRequest?.ServiceRequestId;
  // const [interactionLineId] = useSessionStorage<any>('interactionLineId', null);
  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };
  console.log(setLoading);

  //getFulfillment APi
  // const { refetch: getFulfillmentMethod, data: getFulfillmentMethodData } = useQuery(
  //   ['getfulfillmentmethod'],
  //   async () => {
  //     setLoading(true);
  //     return await getFulfillmentMethodApi(serviceRequestId);
  //   },
  //   {
  //     enabled: false,
  //     onSuccess: (data: any) => {
  //       setLoading(true);
  //       console.log(getFulfillmentMethodData);
  //       const FulfillmentOptions = data[0].FulfillmentOption;
  //       setFulfillmentOption(FulfillmentOptions);
  //       setFulfillmentOptionsLocal(FulfillmentOptions);
  //     },
  //   }
  // );
  // const {} = useQuery(
  //   ['setfulFillmentMethod'],
  //   async () => {
  //     setLoading(true);
  //     return await setFulfillmentMethodApi(interactionLineId, 'RPLCMNT', serviceRequestId);
  //   },
  //   {
  //     enabled: !!FulfilmentOptionsLocal,
  //     onSuccess: (data) => {
  //       setLoading(false);
  //       navigate('/replacement');
  //       console.log('SetFulfillmentMethod data ===> ', data);
  //     },
  //     onError: (error) => {
  //       setLoading(false);
  //       console.log('SetFulfillmentMethod error ===> ', error);
  //     },
  //   }
  // );

  const skipClick = () => {
    navigate('/replacement', { replace: true });
    // getFulfillmentMethod();
    //  navigate('/replacement', { replace: true });
  };
  const haveTurnedOn = () => {
    navigate('/replacement', { replace: true });
    // getFulfillmentMethod();
    // navigate('/replacement', { replace: true });   //Video Declartion navigation on API check
  };

  const onCancelClick = () => {
    setOpenCancelPopup(!openCancelPopup);
  };

  const [Subheading2contentText, setSubheading2contentText] = useState<any>()
  const [Textheaderaccordian1Desc, setTextheaderaccordian1Desc] = useState<any>()
  useEffect(() => {
    callSubheading2content();
    callTextheaderaccordian1Desc()
  }, []);

  const callSubheading2content = async () => {
    const returnedText = await getTextScript(
        `${LostModeTranslations.LostMode.PageName}/${LostModeTranslations.LostMode.setSubheading2ContentText}`,
        `${Scripts.LostModeScript.Subheading2content}`
    );
    setSubheading2contentText(returnedText);
  };

  const callTextheaderaccordian1Desc = async () => {
    const returnedText = await getTextScript(
        `${LostModeTranslations.LostMode.PageName}/${LostModeTranslations.LostMode.textheaderaccordian1Desc}`,
        `${Scripts.LostModeScript.textheaderaccordian1Desc}`
    );
    setTextheaderaccordian1Desc(returnedText);
  };

  //localization color theme changes
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { primary } = colors;
  return (
    <Box>
      <Box
        w={{ base: 'full', lg: '80%' }}
        margin="auto"
        pb={{ base: 0, lg: '150px' }}
        id={'myasurion-profile-dashboard'}
        paddingTop={'54px'}
      >
        <MainStackLayout gap={0} spacing={0}>
          <SubHeader
            backText={''}
            allowBackButtonClick={false}
            showCancelButton={true}
            showBackButton={false}
            cancelText={Scripts.CancelSR.cancelBtnText}
            onCancelClick={onCancelClick}
          ></SubHeader>
          <Box
            id={'myasurion-profile-avatar'}
            w={{ base: 'full', lg: '80%' }}
            margin="0 auto"
            h={'auto'}
            p={'24px 16px'}
          >
            <Text fontWeight={'light'} fontSize={'32px'} mb="10px">
                <TextScript 
                  text={Scripts.LostModeScript.Header}
                  translationKey={`${LostModeTranslations.LostMode.PageName}/${LostModeTranslations.LostMode.Header}`}
                />
            </Text>
            <Text as={'b'} mb="10px">
              <TextScript 
                  text={Scripts.LostModeScript.Subheading1}
                  translationKey={`${LostModeTranslations.LostMode.PageName}/${LostModeTranslations.LostMode.Subheading1}`}
                />
            </Text>
            <Text mb="36px">
              <TextScript 
                text={Scripts.LostModeScript.Subheading1content}
                translationKey={`${LostModeTranslations.LostMode.PageName}/${LostModeTranslations.LostMode.Subheading1content}`}
              />
            </Text>
            <Text fontWeight={'light'} mb="4px" fontSize={'2xl'}>
              <TextScript 
                text={Scripts.LostModeScript.Subheading2}
                translationKey={`${LostModeTranslations.LostMode.PageName}/${LostModeTranslations.LostMode.Subheading2}`}
              />
            </Text>
            {/* <Text mb="32px"> */}
              <div dangerouslySetInnerHTML={{ __html: Subheading2contentText }} />
              {/* {Scripts.LostModeScript.Subheading2content}
              <br />
              {Scripts.LostModeScript.Subheading2content2} */}
            {/* </Text> */}
            <Accordion allowToggle width="100%">
              <AccordionItem key={`accordion-item-faq`} py="6" border={'none'}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      background={'inherit'}
                      _hover={{ background: 'inherit', border: 'none' }}
                      p={0}
                      alignItems={'baseline'}
                    >
                      <Box as="span" flex="1" textAlign="left">
                        <Text as={'b'} fontSize={'18px'} fontWeight={isExpanded ? 700 : 'normal'}>
                          <TextScript 
                            text={Scripts.LostModeScript.textheaderaccordian1}
                            translationKey={`${LostModeTranslations.LostMode.PageName}/${LostModeTranslations.LostMode.textheaderaccordian1}`}
                          />
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel px={0}>
                      {/* <ol style={{ listStyle: 'none' }}>
                        <li style={{ paddingTop: '8px' }}>
                          <Box display="inline-block" width="1em">
                            1:
                          </Box>
                          On an Apple device, open the <b>Find My</b> app.
                        </li>
                        <li style={{ paddingTop: '8px' }}>
                          <Box display="inline-block" width="1em">
                            2:
                          </Box>
                          In the app, tap <b>Devices</b>, and select the lost device from the list.
                        </li>
                        <li style={{ paddingTop: '8px' }}>
                          <Box display="inline-block" width="1em">
                            3:
                          </Box>
                          Look for <b>Mark as Lost</b>.
                        </li>
                        <li style={{ paddingTop: '8px' }}>
                          <Box display="inline-block" width="1em">
                            4:
                          </Box>
                          Tap <b>Activate</b> and continue.
                        </li>
                        <li style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                          <Box display="inline-block" width="1em">
                            5:
                          </Box>
                          Enter your <b>passcode</b>. Optional: Enter a <b>display message</b>.
                        </li>
                      </ol> */}
                      <div dangerouslySetInnerHTML={{ __html: Textheaderaccordian1Desc }} />
                      <Box
                        p={'12px'}
                        borderRadius={'8px'}
                        border={'1px solid var(--neutral-deep-use-black-text, #A5AAAF)'}
                      >
                        This device is the property of NEW Asurion Singapore Pte Ltd (Asurion). The
                        IMEI of this device has been reported to the Singapore Police Force. Please
                        email Asurion at <u>founddevice@asurion.com</u> to arrange return of this
                        device
                      </Box>
                      <ol style={{ listStyle: 'none' }}>
                        <li style={{ paddingTop: '8px' }}>
                          <Box display="inline-block" width="1em">
                            6:
                          </Box>
                          Tap <b>Activate.</b>
                        </li>
                      </ol>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem key={`accordion-item-faq`} py="6">
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      background={'inherit'}
                      _hover={{ background: 'inherit', border: 'none' }}
                      p={0}
                      alignItems={'baseline'}
                    >
                      <Box as="span" flex="1" textAlign="left">
                        <Text as={'b'} fontSize={'18px'} fontWeight={isExpanded ? 700 : 'normal'}>
                          <TextScript 
                            text={Scripts.LostModeScript.textheaderaccordian2}
                            translationKey={`${LostModeTranslations.LostMode.PageName}/${LostModeTranslations.LostMode.textheaderaccordian2}`}
                          />
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel px={0}>
                      <ol style={{ listStyle: 'none' }}>
                        <li style={{ paddingTop: '8px' }}>
                          <Box display="inline-block" width="1em">
                            1:
                          </Box>
                          In a web browser, go to{' '}
                          <a href="https://www.icloud.com/find" target='_blank' style={{ textDecoration: 'underline' }}>
                            icloud.com/find/
                          </a>
                          .
                        </li>
                        <li style={{ paddingTop: '8px' }}>
                          <Box display="inline-block" width="1em">
                            2:
                          </Box>
                          <b>Sign in</b> with your Apple ID.{' '}
                          <a href="" style={{ textDecoration: 'underline' }}>
                            Need help?
                          </a>
                        </li>
                        <li style={{ paddingTop: '8px' }}>
                          <Box display="inline-block" width="1em">
                            3:
                          </Box>
                          Click <b>All Devices</b>, and choose <b>your missing device.</b>
                        </li>
                        <li style={{ paddingTop: '8px' }}>
                          <Box display="inline-block" width="1em">
                            4:
                          </Box>
                          Look for <b>Lost Mode</b>.
                        </li>
                        <li style={{ paddingTop: '8px' }}>
                          <Box display="inline-block" width="1em">
                            5:
                          </Box>
                          Optional: Enter a <b>phone number</b>. Enter a <b>display message</b>.
                        </li>
                        <li style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                          <Box display="inline-block" width="1em">
                            6:
                          </Box>
                          Click <b>Done</b>.
                        </li>
                      </ol>
                      <Box
                        p={'12px'}
                        borderRadius={'8px'}
                        border={'1px solid var(--neutral-deep-use-black-text, #A5AAAF)'}
                      >
                        This device is the property of NEW Asurion Singapore Pte Ltd (Asurion). The
                        IMEI of this device has been reported to the Singapore Police Force. Please
                        email Asurion at <u>founddevice@asurion.com</u> to arrange return of this
                        device
                      </Box>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem key={`accordion-item-faq`} py="6">
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      background={'inherit'}
                      _hover={{ background: 'inherit', border: 'none' }}
                      p={0}
                      alignItems={'baseline'}
                    >
                      <Box as="span" flex="1" textAlign="left">
                        <Text as={'b'} fontSize={'18px'} fontWeight={isExpanded ? 700 : 'normal'}>
                          <TextScript 
                            text={Scripts.LostModeScript.textheaderaccordian3}
                            translationKey={`${LostModeTranslations.LostMode.PageName}/${LostModeTranslations.LostMode.textheaderaccordian3}`}
                          />
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel px={0}>
                      <Text>
                        <TextScript 
                          text={Scripts.LostModeScript.textaccordian3content1}
                          translationKey={`${LostModeTranslations.LostMode.PageName}/${LostModeTranslations.LostMode.textaccordian3content1}`}
                        />
                      </Text>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
            {/* <Accordion allowToggle width="100%">
              <AccordionItem key={`accordion-item-faq`} py="6">
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Text as={'b'} fontSize={'md'}>
                      {Scripts.LostModeScript.textheaderaccordian2}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <OrderedList>
                    <ListItem>
                      {' '}
                      In a web browser, go to <a href="">icloud.com/find/</a>.
                    </ListItem>
                    <ListItem>
                      <b>Sign in</b> with your Apple ID. <a href="">Need help?</a>{' '}
                    </ListItem>
                    <ListItem>
                      Click <b>All Devices</b>, and choose <b>your missing device.</b>
                    </ListItem>
                    <ListItem>
                      Look for <b>Lost Mode</b>.
                    </ListItem>
                    <ListItem>
                      Optional: Enter a <b>phone number</b>. Enter a <b>display message</b>.
                    </ListItem>
                    <ListItem>
                      Click <b>Done</b>.
                    </ListItem>
                  </OrderedList>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Accordion allowToggle width="100%">
              <AccordionItem key={`accordion-item-faq`} py="6">
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Text as={'b'} fontSize={'md'}>
                      {Scripts.LostModeScript.textheaderaccordian3}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <Text>{Scripts.LostModeScript.textaccordian3content1}</Text>
                  <Text>
                    {Scripts.LostModeScript.textaccordian3content2}
                    <a href="">
                      <b>go to www.myasurionurl.com</b>
                    </a>{' '}
                    to cancel your service request.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion> */}
          </Box>
          <Box
            display={{ base: 'block', lg: 'none' }}
            id={'myasurion-profile-avatar'}
            w={'full'}
            h={'auto'}
            p={'24px 16px'}
          >
            <Stack minWidth="max-content" alignItems="center" gap="2">
              <Button
                background={primary}
                color={'#FFFFFF'}
                height="40px"
                fontSize={'md'}
                fontWeight={'700'}
                onClick={haveTurnedOn}
                width={'full'}
              >
                <TextScript 
                  text='I’ve turned on Lost Mode'
                  translationKey={`${LostModeTranslations.LostMode.PageName}/${LostModeTranslations.LostMode.btnTurnedOnLostMode}`}
                />
              </Button>
              <Link
                colorScheme={primary}
                color={primary}
                height="40px"
                fontSize={'md'}
                fontWeight={'700'}
                onClick={skipClick}
                textDecoration={'underline'}
                pt={2}
              >
                <TextScript 
                  text='Skip this step'
                  translationKey={`${LostModeTranslations.LostMode.PageName}/${LostModeTranslations.LostMode.btnSkip}`}
                />
              </Link>
            </Stack>
          </Box>
        </MainStackLayout>
        <CancelSrPopup closeCancelPopup={setOpenCancelPopup} openCancelPopup={openCancelPopup} />
      </Box>
      <Flex
        display={{ base: 'none', lg: 'flex' }}
        direction={{ base: 'column', lg: 'row-reverse' }}
        position={{ base: 'relative', lg: 'fixed' }}
        justifyContent={{ base: 'start', lg: 'space-around' }}
        bottom={{ base: 'none', lg: '0' }}
        id={'myasurion-profile-avatar'}
        w={'full'}
        h={'auto'}
        p={'24px 16px'}
        bgColor={{ base: '', lg: '#faf9f5' }}
      >
        {/* <Stack minWidth="max-content" alignItems="center" gap="2"> */}
        <Button
          background={primary}
          color={'#FFFFFF'}
          height="40px"
          fontSize={'md'}
          fontWeight={'700'}
          onClick={haveTurnedOn}
        >
          <TextScript 
            text='I’ve turned on Lost Mode'
            translationKey={`${LostModeTranslations.LostMode.PageName}/${LostModeTranslations.LostMode.btnTurnedOnLostMode}`}
          />
        </Button>
        <Button
          colorScheme={primary}
          color={primary}
          variant="link"
          height="40px"
          fontSize={'md'}
          font-weight={'700'}
          onClick={skipClick}
        >
          <TextScript 
            text='Skip this step'
            translationKey={`${LostModeTranslations.LostMode.PageName}/${LostModeTranslations.LostMode.btnSkip}`}
          />
        </Button>
        {/* </Stack> */}
      </Flex>
    </Box>
  );
}

export default LostMode;
