import { Box, BoxProps, Flex, } from '@chakra-ui/layout';
import { FormControl, FormErrorMessage, } from '@chakra-ui/react';
import React, { ChangeEvent } from 'react';
import TextInput from '../component/TextInput';
import Selects from './Select';
import { DeviceSpecsFieldConfig } from '../../types/types';

export interface DeviceSpecsProps {
  fields: DeviceSpecsFieldConfig[];
  handleInputChange: (fieldName: DeviceSpecsFieldConfig, value: string) => void;
  handleSelectChange?: (e: ChangeEvent<HTMLSelectElement>, field: DeviceSpecsFieldConfig) => void;
  //Others: boolean,
  OtherBrand: boolean,
  OtherModel: boolean
}

export const DeviceSpecs: React.FC<DeviceSpecsProps & BoxProps> = ({
  fields,
  handleInputChange,
  handleSelectChange,
  OtherBrand,
  OtherModel
}) => {
  return (
    <>
      <Box mt={4} fontFamily={'Apercu Pro'}>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          wrap="wrap"
          justifyContent={{ base: 'start', lg: 'space-between' }}
        >
          {fields.map((field) => (
            <>
              {field?.type === 'Input' && (
                <>
                  {/* {field.label && (
                    <Text fontWeight={'bold'} pb={2}>
                      {field.label}
                    </Text>
                  )} */}
                  <FormControl
                    key={field.name}
                    isRequired={field.isRequired}
                    isInvalid={field.isInvalid}
                    w={{ base: 'full', lg: '48%' }}
                    mb={4}
                  >
                    <TextInput
                      cursor={field?.defaultDisable ? 'not-allowed' : ''}
                      type="text"
                      showIcon={false}
                      borderColor={'#A5AAAF'}
                      placeholder={field.placeholder}
                      value={field.value}
                      maxLength={40}
                      onChange={(e: any) => {
                        handleInputChange(field, e.value);
                      }}
                      isDisabled={field?.defaultDisable}
                    />
                    {field.isInvalid && <FormErrorMessage>{field.errorMessage}</FormErrorMessage>}
                  </FormControl>
                </>
              )}
              {field?.type === 'Select' &&
                // ((field.name === 'Brand' && OtherBrand) || (field?.Other !== 'Others')) &&
                (
                  <Box w={{ base: 'full', lg: '48%' }}>
                    {((field.name === 'Brand' && OtherBrand) || (field.name === 'Model' && OtherModel) || (field?.Other !== 'Others') || (field.showSelect)) &&
                      <Selects
                        mb={4}
                        placeholder={field.placeholder}
                        size="lg"
                        h={'60px'}
                        borderColor={'#A5AAAF'}
                        value={field.value}
                        onChange={(e: any) => handleSelectChange && handleSelectChange(e, field)}
                      >
                        {Array.isArray(field?.data) &&
                          [...new Set(field?.data)]?.map((val: any) => {
                            return (
                              <option key={val} value={val}>
                                {val}
                              </option>
                            );
                          })}
                      </Selects>}
                    {field?.type === 'Select' && field?.Other === 'Others' &&
                      (<FormControl
                        key={field.name}
                        isRequired={field.isRequired}
                        isInvalid={field.isInvalid}
                        mb={4}
                      >

                        <TextInput
                          cursor={field?.defaultDisable ? 'not-allowed' : ''}
                          type="text"
                          showIcon={false}
                          borderColor={'#A5AAAF'}
                          placeholder={field.placeholder}
                          value={field.value}
                          maxLength={40}
                          onChange={(e: any) => {
                            handleInputChange(field, e.value);
                          }}
                          isDisabled={field?.defaultDisable}
                        />

                      </FormControl>
                      )}
                  </Box>)}

            </>
          ))}
        </Flex>
      </Box>
    </>
  );
};

export default DeviceSpecs;
