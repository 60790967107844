import { Heading, Stack, Text } from '@chakra-ui/react';

import { TextScript } from '@MGPD/myasurion-shared';
// import SendMessageIcon from '../icons/SendMessage';
const PAGE_NAME = 'NPS';

export const Completed = () => {
    return (
        <Stack>
            {/* <SendMessageIcon width={'80x'} height={'80px'} /> */}
            <Heading
                fontSize="32px"
                fontWeight="light"
                lineHeight="39.68px"
                letterSpacing="-0.352px"
            >
                <TextScript
                    text="Thanks for your feedback"
                    translationKey={`${PAGE_NAME}/completedHeading`}
                />
            </Heading>
            <Text fontSize="lg" lineHeight="22.32px">
                <TextScript
                    text="Your feedback is a huge part of improving the experience."
                    translationKey={`${PAGE_NAME}/completedMessage`}
                />
            </Text>
        </Stack>
    );
};

export default Completed;
