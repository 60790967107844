/* eslint-disable no-console */
import MainStackLayout from '../../layout/MainStackLayout';
import '../../services/api';
import { SelectColor, SubHeader, getTypeByDomain } from '@MGPD/myasurion-shared';
import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import ActionTypes from '../../store/actionTypes.ts';
import { useCentralStore } from '../../store/appContext.tsx';
import CancelSrPopup from '../myclaims/CancelSrPopup.tsx';
import { getRedirectUrl } from '../../utils/utills.tsx';

function DeviceColorUpdates() {

  const globalState = useCentralStore();
  const navigate = useNavigate();
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [colorVal, setColorVal] = useState<string>('');

  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };
  console.log(setLoading);

  const onCancelClick = () => {
    // setOpenCancelPopup(!openCancelPopup);
    window.location.href = getRedirectUrl(getTypeByDomain());
  };

  let filterDevices = globalState?.state?.imeiDeviceDetails

  let deviceColors = filterDevices
        ?.map((val: any) => {
          if (
            (val?.Capacity) ==
            globalState?.state?.updateDeviceCapacity
          ) {
            return val?.ClientColor || val?.Color;
          } else {
            return null;
          }
        })
        ?.filter((value: any) => value !== null);
  
  console.log('deviceColors', deviceColors);
  


  const handleNext = () => {
    navigate('/reviewUpdateDevice')
  }

  const setColor = (e: any) => {
    console.log('setCapacity----', e);
    setColorVal(e)
    globalState?.dispatch({ type: ActionTypes.SET_UPDATE_DEVICE_COLOR, payload: e });
  }

  return (
    <Box>
      <Box
        w={{ base: 'full', lg: '80%' }}
        margin="auto"
        pb={{ base: 0, lg: '150px' }}
        id={'myasurion-profile-dashboard'}
        paddingTop={'54px'}
      >
        <MainStackLayout gap={0} spacing={0}>
          <SubHeader
            backText={''}
            allowBackButtonClick={false}
            showCancelButton={true}
            showBackButton={false}
            cancelText={Scripts.CancelSR.cancelBtnTextCancel}
            onCancelClick={onCancelClick}
          ></SubHeader>
          <Box
            id={'myasurion-profile-avatar'}
            w={{ base: 'full', lg: '80%' }}
            margin="0 auto"
            h={'auto'}
            p={'10px'}
          >
            <SelectColor 
                colorOptions={deviceColors}
                selectedColor={colorVal}
                modelName={filterDevices?.[0]?.Family}
                onClickCallback={(e) => setColor(e)}
                handleNext={() => handleNext()}
                isDisabled={colorVal !== '' ? false : true}
            />
          </Box>
          
        </MainStackLayout>
        <CancelSrPopup closeCancelPopup={setOpenCancelPopup} openCancelPopup={openCancelPopup} />
      </Box>
    </Box>
  );
}

export default DeviceColorUpdates;
