import { Icon, IconProps } from '@chakra-ui/icons';
import * as React from 'react';
import { JSX } from 'react/jsx-runtime';

export const MySRIcon = (
  props: JSX.IntrinsicAttributes &
    Omit<React.SVGProps<SVGSVGElement>, 'as' | 'translate' | keyof IconProps> & {
      htmlTranslate?: 'yes' | 'no' | undefined;
    } & IconProps & { as?: 'svg' | undefined }
) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M6 6.00098H17V9.00098H6V6.00098Z" fill="black" />
    <path d="M11 10.001H6V12.001H11V10.001Z" fill="black" />
    <path d="M6 13.001H11V15.001H6V13.001Z" fill="black" />
    <path d="M11 16.001H6V18.001H11V16.001Z" fill="black" />
    <path d="M12 10.001H17V12.001H12V10.001Z" fill="black" />
    <path d="M17 13.001H12V15.001H17V13.001Z" fill="black" />
    <path d="M12 16.001H17V18.001H12V16.001Z" fill="black" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 22.001H20C20.553 22.001 21 21.554 21 21.001V3.00098C21 2.44898 20.553 2.00098 20 2.00098H3C2.447 2.00098 2 2.44898 2 3.00098V21.001C2 21.553 2.447 22.001 3 22.001ZM19 20.001H4V4.00098H19V20.001Z"
      fill="black"
    />
  </Icon>
);
export default MySRIcon;
