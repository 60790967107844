/* eslint-disable no-console */
import '../../services/api';
import { Box, Text, Button, Flex } from '@chakra-ui/react';
import Scripts from '../../Scripts/asurion-script.json';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { TextScript } from '@MGPD/myasurion-shared';

export interface PendingPaymentProps {
    currentSessionResp: any;
    assetData: any;
    currencySymbol: any;
    callPCIToken: any
}

// function PendingPaymentSection({ currentSessionResp }) {
export const PendingPaymentSection: React.FC<PendingPaymentProps> = ({ currentSessionResp, currencySymbol, callPCIToken }) => {

    const PendingAgrScrips = Scripts.MyPayments.PendingAgr
    const globalState = useCentralStore();

    const handlePaynow = () => {
        globalState?.dispatch({ type: ActionTypes.SET_PENDING_PAYMENT_FLAG, payload: true });
        callPCIToken()
    }

    return (
        <Box px='3'>
            <Text mb='2' fontWeight={'700'} fontSize={'18px'}>
                <TextScript
                    text={PendingAgrScrips.Heading}
                    translationKey={`${PendingAgrScrips.PageName}/${PendingAgrScrips.Heading}`}
                />
            </Text>
            <Flex dir='row' justifyContent={'space-between'}>
                <Text>{currentSessionResp?.MDN}</Text>
                <Text>
                    <TextScript
                        text={PendingAgrScrips.TotalAmtLBL}
                        translationKey={`${PendingAgrScrips.PageName}/${PendingAgrScrips.TotalAmtLBL}`}
                    />
                </Text>
                <Text>{currencySymbol}{currentSessionResp?.TotalAmount}</Text>
                <Button
                    w="auto"
                    rounded={'2xl'}
                    color="white"
                    fontSize={'14px'}
                    colorScheme="primary"
                    h={'auto'}
                    p="2"
                    // bg={isDisabled ? 'gray.200' : colors.primary}
                    // cursor={isDisabled ? 'not-allowed' : 'pointer'}
                    // disabled={isDisabled}
                    onClick={() => handlePaynow()}
                >
                    <TextScript
                        text={PendingAgrScrips.PayNowLBL}
                        translationKey={`${PendingAgrScrips.PageName}/${PendingAgrScrips.PayNowLBL}`}
                    />
                </Button>
            </Flex>
        </Box>
    );
}

export default PendingPaymentSection;
