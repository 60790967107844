import * as React from 'react';
const { useState, useEffect } = React;

const qualtrics = ({ qualtricsDomain = "", qualtricsZid = ""}) => {
  const g: any = function (this: any, e:any, h:any, f: any, g: any) {
    this.get = function (a: string) { for (var a = a + "=", c = document.cookie.split(";"), b = 0, e = c.length; b < e; b++) { for (var d = c[b]; " " == d.charAt(0);)d = d.substring(1, d.length); if (0 == d.indexOf(a)) return d.substring(a.length, d.length) } return null };
    this.set = function (a: string, c: string) { var b: any = new Date; b.setTime(b.getTime() + 6048E5); b = "; expires=" + b.toGMTString(); document.cookie = a + "=" + c + b + "; path=/; " };
    this.check = function () { var a = this.get(f); if (a) a = a.split(":"); else if (100 != e) "v" == h && (e = Math.random() >= e / 100 ? 0 : 100), a = [h, e, 0], this.set(f, a.join(":")); else return !0; var c = a[1]; if (100 == c) return !0; switch (a[0]) { case "v": return !1; case "r": return c = a[2] % Math.floor(100 / c), a[2]++, this.set(f, a.join(":")), !c }return !0 };
    this.go = function () { if (this.check()) { var a = document.createElement("script"); a.type = "text/javascript"; a.src = g; document.body && document.body.appendChild(a) } };
    this.start = function () { var t = this; "complete" !== document.readyState ? window.addEventListener ? window.addEventListener("load", function () { t.go() }, !1) : window.attachEvent && window.attachEvent("onload", function () { t.go() }) : t.go() };
  };
  try { 
    return (new g(100, "r", `QSI_S_${qualtricsZid}`, `${qualtricsDomain}/SIE/?Q_ZID=${qualtricsZid}`))
  } catch (i) {
    console.log("Qualtrics survey initialization error:", i)
    return null;
  }
};

function QualtricsSurvey() {
  const qltrcsObject = qualtrics({
    qualtricsDomain: import.meta.env.VITE_QUALTRICS_BASE_URL,
    qualtricsZid: import.meta.env.VITE_QUALTRICS_ZONE_ID
  });
  const [qltrcsPayload, setQltrcsPayload] = useState<any>(null);

  useEffect(() => {
    let qString = window.location.href.split("?")[1];

    if (qString) {
      let searchParams = new URLSearchParams(qString);
      let payloadVal = searchParams.get('payload');

      if (payloadVal) {
        setQltrcsPayload(payloadVal);
      }
    }
  }, [])

  useEffect(() => {
    if (qltrcsPayload && qltrcsObject) {
      qltrcsObject.go();
    }
  }, [qltrcsPayload])

  return (
    <>
      <div id='smartsupport_qualtrics_survey_container' style={{ display: 'none' }}>{qltrcsPayload}</div>
    </>
  )
}

export default QualtricsSurvey;
