/* eslint-disable no-console */
import { useConfig } from '@MGPD/myasurion-shared';
import { Button, IconButton, Skeleton } from '@chakra-ui/react';

import NotificationIcon from './NotificationIcon';

export interface NotificationButtonProps {
    onToggle: () => void;
    isLoading: boolean;
    promptCount: number;
}
export type ComponentAttribute = Record<string, string | number>;

export const NotificationsButton: React.FC<NotificationButtonProps> = ({
    onToggle,
    isLoading,
    promptCount,
}) => {
    const { data } = useConfig();
    const { theme } = data;
    const { components } = theme;
    const { languageSelector } = components;

    const buttonColor =
        (languageSelector &&
            languageSelector.link &&
            ((languageSelector.link as ComponentAttribute).color as string)) ||
        '#fff';

    return (
        <>
            <Button
                id={'myasurion-menubutton'}
                as={IconButton}
                minW={'24px'}
                w={'26px'}
                p={0}
                aria-label="profile"
                icon={
                    <Skeleton isLoaded={!isLoading} p={isLoading ? '2px' : '0'}>
                        <NotificationIcon
                            fill={buttonColor ?? '#fff'}
                            promptcount={promptCount ?? 0}
                        />
                    </Skeleton>
                }
                variant={'outline'}
                border={'none'}
                bg={'none'}
                _hover={{
                    bg: 'none',
                }}
                _selected={{
                    bg: 'none',
                }}
                _active={{
                    bg: 'none',
                }}
                onClick={() => onToggle()}
                h="min-content"
            ></Button>
        </>
    );
};
