/* eslint-disable no-console */
import { Box } from '@chakra-ui/layout';
import DeviceDetails from './DeviceDetails';
import DeviceDetailsNew from './DeviceDetailsNew'
import { useCentralStore } from '../../store/appContext';

function AddNewDevice() {
  // const navigate = useNavigate();
  const globalState = useCentralStore();
  const agreementData: any =
  globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  const IsSmartSupport4Plus = agreementData?.IsSmartSupport4Plus ;

  return (
    <Box>{IsSmartSupport4Plus?
    <DeviceDetailsNew />:
    <DeviceDetails/>}
    </Box>
  )
 
}

export default AddNewDevice;
