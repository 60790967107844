import { Icon, IconProps } from '@chakra-ui/icons';
import * as React from 'react';
import { JSX } from 'react/jsx-runtime';

export const HomeIcon = (
  props: JSX.IntrinsicAttributes &
    Omit<React.SVGProps<SVGSVGElement>, 'as' | 'translate' | keyof IconProps> & {
      htmlTranslate?: 'yes' | 'no' | undefined;
    } & IconProps & { as?: 'svg' | undefined }
) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
      <path
        d="M12.001 15C13.1055 15 14.001 14.1046 14.001 13C14.001 11.8954 13.1055 11 12.001 11C10.8964 11 10.001 11.8954 10.001 13C10.001 14.1046 10.8964 15 12.001 15Z"
        fill="black"
      />
      <path
        d="M9.00098 19C9.00098 17.159 10.16 16 12.001 16C13.842 16 15.001 17.159 15.001 19H9.00098Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.00128 22H18.0013C18.5533 22 19.0013 21.551 19.0013 21V14H20.0013C20.8143 14 21.5403 13.515 21.8493 12.764C22.1593 12.015 21.9883 11.159 21.4143 10.585L13.4153 2.60602C13.0373 2.22802 12.5353 2.02002 12.0013 2.02002C11.4673 2.02002 10.9653 2.22802 10.5883 2.60502L2.58728 10.586C2.01428 11.16 1.84328 12.015 2.15428 12.766C2.46528 13.516 3.19028 14 4.00128 14H5.00128V21C5.00128 21.552 5.44928 22 6.00128 22ZM17.0013 20H7.00128V13C7.00128 12.448 6.55328 12 6.00128 12H4.00028L12.0023 4.02002L20.0013 12H18.0013C17.4493 12 17.0013 12.448 17.0013 13V20Z"
        fill="black"
      />
  </Icon>
);
export default HomeIcon;
