import Scripts from '../../Scripts/asurion-script.json';
import { CloseButton, Text } from '@chakra-ui/react'
import translationsKeys from '../../Scripts/translationsKeys.json';
import { TextScript} from '@MGPD/myasurion-shared';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  useDisclosure,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import { PropsWithChildren } from 'react';

interface popupProps {
  openCancelPopup: boolean;
  closeCancelPopup: (args: boolean) => void;
}

const FindSerialNoPopup: React.FC<PropsWithChildren<popupProps>> = ({
  openCancelPopup,
  closeCancelPopup,
}) => {
  const cancelRef = React.useRef<any>();
  let { isOpen } = useDisclosure();
  isOpen = openCancelPopup;
  const SerialNoPopupKeys = translationsKeys.SerialNoPopup;
  const GlobalTranslations = translationsKeys.Global;

  const onClose = () => {
    closeCancelPopup(false);
  };

  const onOk = () => {
    onClose();
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => onClose()}
        isOpen={isOpen}
        isCentered
        autoFocus={false}
      >
        <AlertDialogOverlay />
        <AlertDialogContent >
          <AlertDialogBody >
        <Flex justifyContent={'space-between'} >
        <Text fontSize={'32px'} fontFamily={'Apercu Pro'} fontWeight="400" lineHeight="38px" paddingTop={'12px'} >
          <TextScript
              text={Scripts.SerialNoPopup.HeaderText}
              translationKey={`${SerialNoPopupKeys.PageName}/${SerialNoPopupKeys.HeaderText }`}
            />
           </Text>
           {<Flex justifyContent={'flex-end'}><CloseButton onClick={() => onClose()} /></Flex>}
           </Flex>
           <Box paddingTop={'16px'}>
           <Text>
           <TextScript
              text={Scripts.SerialNoPopup.Text1}
              translationKey={`${SerialNoPopupKeys.PageName}/${SerialNoPopupKeys.Text1 }`}
            />
            </Text>
            <Box paddingLeft={'7px'}>
            <UnorderedList>
              <ListItem><TextScript
              text={Scripts.SerialNoPopup.List1}
              translationKey={`${SerialNoPopupKeys.PageName}/${SerialNoPopupKeys.List1 }`}
            /></ListItem>
              <ListItem><TextScript
              text={Scripts.SerialNoPopup.List2}
              translationKey={`${SerialNoPopupKeys.PageName}/${SerialNoPopupKeys.List2}`}/></ListItem>
              <ListItem><TextScript
              text={Scripts.SerialNoPopup.List3}
              translationKey={`${SerialNoPopupKeys.PageName}/${SerialNoPopupKeys.List3}`}/></ListItem>
              <ListItem><TextScript
              text={Scripts.SerialNoPopup.List4}
              translationKey={`${SerialNoPopupKeys.PageName}/${SerialNoPopupKeys.List4}`}/></ListItem>
            </UnorderedList>
            </Box>
            <Text marginTop={'16px'}>
            <TextScript
              text={Scripts.SerialNoPopup.Text3}
              translationKey={`${SerialNoPopupKeys.PageName}/${SerialNoPopupKeys.Text3 }`}
            />
            </Text>
            </Box>
          </AlertDialogBody>
          <Box display='block'>
            <Flex  justifyContent={'flex-end'} margin={"16px"}>
              <Button
                onClick={() => onOk()}
                width={'50%'}
                colorScheme="primary"
                variant="solid"
                borderRadius= "8px"
              >
                <TextScript
                  text={'Ok'}
                  translationKey={`${GlobalTranslations.PageName}/${GlobalTranslations.Yes}`}
                />
              </Button>
            </Flex>
          </Box>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FindSerialNoPopup;
