/* eslint-disable no-console */
import MainStackLayout from '../../layout/MainStackLayout';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { SubHeader, TextScript, getTextScript, useTextScript } from '@MGPD/myasurion-shared';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import Translationkeys from '../../Scripts/translationsKeys.json';
import TextInput from '../component/TextInput';
import { CLEANING_SERVICE_ACTION } from '../../utils/constant';
import { updateCleaningServices } from '../../services/api';
import { getMDNFromAgreement, saveError, showErrorPage } from '../../utils/utills';

function SetDeviceDetails() {
  const globalState = useCentralStore();
  const navigate = useNavigate();
  const { isTranslationsLoading, language } = useTextScript();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [brandLbl, setBrandLbl] = useState<string>('');
  const [modelLbl, setModelLbl] = useState<string>('');

  const [brand, setBrand] = useState<string>('');
  const [model, setModel] = useState<string>('');

  const interactionData = globalState?.state?.sessionResponse?.Interaction;

  const agreementData: any =
  globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

  useEffect(() => {
    if (!isTranslationsLoading) {
      getBrandLbl();
      getModelLbl();
    }
  }, [language, isTranslationsLoading]);

  useEffect(() => {
    if (model?.length && brand?.length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [model, brand]);

  useEffect(() => {
    if (globalState?.state?.selectedCleaningDeviceDetails) {
      setBrand(globalState?.state?.selectedCleaningDeviceDetails?.brand);
      setModel(globalState?.state?.selectedCleaningDeviceDetails?.model);
    }
  }, [globalState?.state?.selectedCleaningDeviceDetails]);

  const getBrandLbl = async () => {
    const brandLBL = await getTextScript(
      `${Translationkeys.AddDevice.AddNewDevice.PageNameAnchorDevice}/${Translationkeys.AddDevice.AddNewDevice.brand}`,
      Scripts.AddDevice.AddNewDevice.brand,
      language,
      []
    );

    setBrandLbl(brandLBL);
  };

  const getModelLbl = async () => {
    const modelLbl = await getTextScript(
      `${Translationkeys.AddDevice.AddNewDevice.PageNameAnchorDevice}/${Translationkeys.AddDevice.AddNewDevice.model}`,
      Scripts.AddDevice.AddNewDevice.model,
      language,
      []
    );

    setModelLbl(modelLbl);
  };

  const handleInputChange = (type: string, value: string) => {
    if (type === 'model') {
      setModel(value);
    } else if (type === 'brand') {
      setBrand(value);
    }
  };

  const onSubmit = () => {
    const cleaningDeviceDetails = {
      brand: brand,
      model: model,
    };

    callCreateCleaningServiceAPI(cleaningDeviceDetails)

  };

  const onBackClick = () => {
    navigate('/selectModel', { replace: true });
  };

  const onCancelClick = () => {
    window.history.back();
  };

  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };

  const callCreateCleaningServiceAPI = (cleaningDeviceDetails: any) => {

    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;

    const selectedModel = globalState?.state?.selectedDeviceModel;

    const UpdateCleaningServiceRequest = {
      UpdateCleaningServiceRequest: {
        Action: CLEANING_SERVICE_ACTION.ACTION_PROCESS_INCIDENT,
        InteractionLineId: interactionLineId,
        AgreementId: agreementData?.AgreementId,
        ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
        CustomerId: agreementData?.Customers?.Customer[0]?.CustomerId,
        ClientChannelId: agreementData?.ClientAccount?.ClientChannelId,
        MobileDeviceNumber: getMDNFromAgreement(agreementData),
        SubscriptionNumber: getMDNFromAgreement(agreementData),
        Brand: cleaningDeviceDetails?.brand,
        ModelNo: cleaningDeviceDetails?.model,
        DeviceCategory: selectedModel?.DeviceCategory,
        AssetCatalogId: selectedModel?.AssetCatalogId,
      },
    };

    setLoading(true);

    updateCleaningServices(UpdateCleaningServiceRequest, interactionLineId, globalState?.state?.findAgreementSessionData)
      .then((data) => {
        setLoading(false);

        globalState?.dispatch({
          type: ActionTypes.SET_CLEANING_INCIDENT_DATA,
          payload: data,
        });

        // set selected category state
        globalState?.dispatch({
          type: ActionTypes.SET_CLEANING_DEVICE_DETAILS,
          payload: cleaningDeviceDetails,
        });

        navigate('/cleaningLogistics', { replace: true });
      })
      .catch((error) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  };

  return (
    <Box paddingTop={'54px'} w="full">
      <MainStackLayout gap={0} spacing={0}>
        <Flex w={{ base: 'full', lg: '80%' }} margin="0 auto" h={'100%'} direction={'column'}>
          <SubHeader
            backText={'Back'}
            allowBackButtonClick={false}
            showCancelButton={false}
            showBackButton={true}
            onClick={onBackClick}
            cancelText={Scripts.CancelSR.cancelBtnText}
            onCancelClick={onCancelClick}
          ></SubHeader>

          <Box
            id={'myasurion-profile-avatar'}
            w={{ base: 'full', lg: '60%' }}
            margin="0 auto"
            h={'auto'}
            py={'1rem'}
            px={4}
          >
            <Text mt={'5'} fontSize={{ base: '3xl', lg: '4xl' }}>
              <TextScript
                text={Scripts.CleaningService.SelectDevice.HeaderDeviceDetails}
                translationKey={`${Translationkeys.CleaningService.SelectDevice.PageName}/${Translationkeys.CleaningService.SelectDevice.HeaderDeviceDetails}`}
              />
            </Text>

            <Box my={'1rem'}>
              <TextInput
                type="text"
                showIcon={false}
                borderColor={'#A5AAAF'}
                placeholder={brandLbl || Scripts.AddDevice.AddNewDevice.brand}
                value={brand}
                maxLength={40}
                onChange={(e: any) => {
                  handleInputChange('brand', e.value);
                }}
              />
            </Box>

            <TextInput
              type="text"
              showIcon={false}
              borderColor={'#A5AAAF'}
              placeholder={modelLbl || Scripts.AddDevice.AddNewDevice.model}
              value={model}
              maxLength={40}
              onChange={(e: any) => {
                handleInputChange('model', e.value);
              }}
            />
          </Box>
          <Box
            width={{ base: 'full', lg: '30%' }}
            marginX={'auto'}
            marginTop={'auto'}
            height={'100%'}
            alignContent={'flex-end'}
            mb={{ base: '25%', lg: '2rem' }}
            px={4}
          >
            <Button
              isDisabled={isDisabled}
              width={'full'}
              textAlign={'center'}
              color="white"
              colorScheme="primary"
              disabled={isDisabled}
              onClick={() => onSubmit()}
            >
              <TextScript
                text={Scripts.Global.Continue}
                translationKey={`${Translationkeys.Global.PageName}/${Translationkeys.Global.Continue}`}
              />
            </Button>
          </Box>
        </Flex>
      </MainStackLayout>
    </Box>
  );
}

export default SetDeviceDetails;
