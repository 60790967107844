import Axios from 'axios';

const space = 'rcqun2fh2hqb';
const env = 'master';
const access_token = import.meta.env.VITE_CONTENTFUL_ACCESS_TOKEN;

interface DeviceImage {
  title: string;
  url: string;
}

interface SeoPageLayoutItem {
  title: string;
  brandName: string;
  horizonId: string;
  deviceImagesMyAsurionCollection: {
    items: DeviceImage[];
  };
}

interface SeoPageLayoutCollection {
  items: SeoPageLayoutItem[];
}

interface GraphQLResponse {
  data: {
    seoPageLayoutCollection: SeoPageLayoutCollection;
  };
}

export default class SEOAPIServices {
  static fetchDeviceImage(): Promise<GraphQLResponse> {
    const query = `
      {
        seoPageLayoutCollection {
          items {
            title
            brandName
            horizonId
            deviceImagesMyAsurionCollection {
              items {
                title
                url
              }
            }
          }
        }
      }
    `;

    return Axios({
      method: 'post',
      url: `https://graphql.contentful.com/content/v1/spaces/${space}/environments/${env}`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${access_token}`,
      },
      data: JSON.stringify({
        query,
      }),
    }).then((res) => res.data);
  }
}
