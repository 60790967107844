import momentTZ from 'moment-timezone';
import { TRIAGE_QUESTION_TYPE } from './constant';
import { QueAns } from '../pages/TriageQuestion';
import { isReplacement, isSwap } from './utills';

export const camelize = function (str: string) {
  return str?.replace(/\w+/g, function (match) {
    return match?.charAt(0)?.toUpperCase() + match?.slice(1).toLowerCase();
  });
};

export const Regex = /^[a-zA-Z0-9,/@# \u0E00-\u0E7F \u4E00-\u9FFF-]*$/;

export const getCurrencySymbol = function (str: any) {
  const currencyCodeSymbols: { [index: string]: string; } = {
    "SGD": "$",
    "THB": "฿",
    "MYR": "RM"
  };
  return currencyCodeSymbols[str]
}

export const getFeeInclusiveTaxText = function (str: any) {
  if(str){
  const currencyCodeSymbols: { [index: string]: string; } = {
    "SGD": "(inc. GST)",
    "THB": "(inc. VAT)",
    "MYR": ""
  };
  return currencyCodeSymbols[str]
} else return "";
}

export const getCountryCode = function (str: any, section: any = undefined) {
  const currencyCodeSymbols: { [index: string]: string; } = {
    "SGD": section === 'payment' ? "SG" : "SGP",
    "MYR": "MY",
    "THB": "TH",
    "NTD": "TW"
  };
  return currencyCodeSymbols[str]
}

export const getCountryName = function (str: any) {
  const currencyCodeSymbols: { [index: string]: string; } = {
    "SGD": "Singapore",
    "MYR": "Malaysia",
    "THB": "Thailand",
    "NTD": "Taiwan"
  };
  return currencyCodeSymbols[str]
}

export const getPickupSlot = () => ["17", "20"];

export const getReturnSlot = () => ["20"];

export const convertToUTCDate = (date: any): string => {
  return new Date(Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  )).toString();
};


export const getDateFromUtcTimeZone = (offset: number, date:any) => {
  const offsetMultiplier = 3600000;
  const utcMs = date.getTime();
  const returnDate = new Date(utcMs + offsetMultiplier * offset);
  return returnDate;
};

export const isBefore2PM = (date: any) => {
  const today = getDateFromUtcTimeZone(7, date); // for Thailand => time zone is UTC+7
  const hour = today.getHours();
  return hour < 14;
};

export const isClaimWithinCutoff = (timeZone = "Asia/Bangkok") => {
  let hours = momentTZ(new Date()).tz(timeZone).format("HH");  
  return Number(hours) < 14
}

export const getMappedClientNameVal = function (str: any) {
  const clientNameVal: { [index: string]: string; } = {
    "asurion_techcare": "tom",
    "Maxis": "maxis",
    "starhub": "sg-sh",
    "cht_home": "cht"
  };
  return clientNameVal[str]
}

export const getTriageQuestions = function (
  peril: string,
  questionList: any,
  deviceMake: string,
  questionData: Array<QueAns>
) {
  const TriageQuestionsFilter = questionList?.filter((a: any) => {
    return (
      a?.AssetmakeName?.toUpperCase() === deviceMake &&
      a?.FaultDetection?.toUpperCase() === peril?.toUpperCase() &&
      a.MultiSelect === 0 &&
      a?.QuestionType !== TRIAGE_QUESTION_TYPE.IN_WARRANTY &&
      a?.QuestionType !== TRIAGE_QUESTION_TYPE.IN_WARRANTY_OTHER
    );
  });
  // map questions with user answers
  let answerdQuestions = questionData.reduce((acc: any, d: any) => {
    acc[d.Que] = d.ans;
    return acc;
  }, {});

  const Questions =
    Array.from(new Set(TriageQuestionsFilter?.map((val: any) => val.Question))).map(
      (uniqueQuestion: any) => {
        const questionData = TriageQuestionsFilter.find(
          (val: any) => val.Question === uniqueQuestion
        );
        return {
          Que: questionData?.Question,
          ans: answerdQuestions[questionData?.Question],
          expAns: questionData?.Answer,
          type: questionData?.QuestionType,
          displayOrder: questionData?.DisplayOrder,
        };
      }
    ) ?? [];
  // sort questions as per display order
  Questions?.sort((a: any, b: any) => {
    return a?.displayOrder - b?.displayOrder;
  });
  return Questions;
};

export const getAssetCapacity = (LFLDevice: any) => {
  let capacity = LFLDevice?.HorizonItemInformation?.AssetCapacity;
  return capacity ? (capacity == '1' ? `${capacity}TB` : `${capacity}GB`) : ''  
}


 export const getMaid = (IncidentType: any, Braintree: any) => {
  let maid = isSwap(IncidentType) || isReplacement(IncidentType) ? Braintree?.maid : Braintree?.repair_maid ;
  return maid;
 }
