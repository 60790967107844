import { Nullable } from '../../../utils/utills';
import { assign, createMachine } from 'xstate';

import { CustomerCategory } from '../types';

const npsMachine = createMachine(
    {
        /** @xstate-layout N4IgpgJg5mDOIC5QDsAOsB0BLCAbMAxAGICiAKgMIASA2gAwC6ioqA9rFgC5avLMgAPRAEYAbMIwBmaZICssuqIBMADnGyAnABoQATxFKA7BgAshpUroLZS4So0BfBzrSYAZmE4BjABZZkUADKAK4ATgBuYLoEELxg2MjhrADW8a4YHt5+ASERUQj+SV4Ahty89AwV-GwcZXxIgogmoipSdLZKspImJnQmGmI6+gjCPaYaExqSwhoqsqJ0hpJOLugZnr7+QWGR0WChoayhGKi4pW5HALYY6ZmbOTv5hawldRVVDTVcPPWgQgjNVqSdrCTrdXr9QZ6RAqEwYQwKOh0OSiQx0YR0WYrEDpWBgfBeTiBLxHQiBEgAGRIFDIAH1AhQAPIAJRIHxY7G+vH4-zUEjkJlsshMNgmnSGIhMElEMtEwsFJkkSimSiczhxrAgcH4rmqnLqPMQAFpRBKECaMEirdbraNsekcPg9bUfoaAUozaDjBpzJYNIqlNJhHZ7Ws7tltnlhhyXdyGv8jJIMBoWoZRBpZMJJCplRnPX0MKJs2purNRWnQ5hYI9dABVZDFcLFLBnABGTs++td8cQRe9Zg0qlR2dUkjNKgkYhlaLT4lhshUlYweIJRJJoTAzq5v0aCFRomT-pUwPasjRUs9gowU9RMx69hUiyXK7AhOZYGKsDjMe3boxkg0UwxBnVQrDBS8lGvWVDDvEwHzVBwgA */
        id: 'nps',
        predictableActionArguments: true,
        tsTypes: {} as import('./nps.typegen').Typegen0,
        schema: {
            context: {} as {
                surveyId: string;
                clientId: string;
                mdn: string;
                reasons: {
                    [K in CustomerCategory]: {
                        questionId: string;
                        description: string;
                        reasons: Array<string>;
                    };
                };
                selectedReasons: Array<string>;
                scoreQuestionId: string;
                score: Nullable<number>;
                customerCategory: Nullable<CustomerCategory>;
                surveyResult: Nullable<object>;
                errors: Nullable<object>;
            },
            events: {} as
                | { type: 'FETCH' }
                | { type: 'REJECT' }
                | { type: 'SET_SURVEY_ID'; id: string }
                | { type: 'SET_CLIENT_ID'; id: string }
                | { type: 'SET_SCORE_QUESTION_ID'; id: string }
                | { type: 'SET_MDN'; mdn: string }
                | { type: 'SET_SCORE'; score: number }
                | { type: 'SUBMIT_SCORE'; reasons: Array<any> }
                | { type: 'UPDATE_REASONS'; selectedReasons: Array<string> }
                | { type: 'SUBMIT_NPS' },
            services: {} as {
                getSurvey: {
                    data: {
                        [K in CustomerCategory]: {
                            questionId: string;
                            description: string;
                            reasons: Array<string>;
                        };
                    };
                };
                submitSurvey: {
                    data: any;
                };
            },
        },
        on: {
            SET_SURVEY_ID: {
                actions: ['setSurveyId'],
            },
            SET_CLIENT_ID: {
                actions: ['setClientId'],
            },
            SET_MDN: {
                actions: ['setMdn'],
            },
            SET_SCORE_QUESTION_ID: {
                actions: ['setScoreQuestionId'],
            },
        },
        context: {
            surveyId: '',
            clientId: '',
            mdn: '',
            reasons: {
                PROMOTER: {
                    questionId: '',
                    description: '',
                    reasons: [],
                },
                PASSIVE: {
                    questionId: '',
                    description: '',
                    reasons: [],
                },
                DETRACTOR: {
                    questionId: '',
                    description: '',
                    reasons: [],
                },
            },
            scoreQuestionId: '',
            score: null,
            customerCategory: null,
            selectedReasons: [],
            surveyResult: null,
            errors: null,
        },
        initial: 'idle',
        states: {
            idle: {
                on: {
                    FETCH: { target: 'fetchingSurvey' },
                },
            },
            fetchingSurvey: {
                tags: ['loading'],
                invoke: {
                    src: 'getSurvey',
                    onDone: { target: 'selectScore', actions: ['setReasonsPerCategory'] },
                    onError: {
                        target: 'surveyUnavailable',
                    },
                },
            },
            surveyUnavailable: {
                type: 'final',
            },
            selectScore: {
                on: {
                    SET_SCORE: {
                        actions: ['setScore', 'setCustomerCategory'],
                    },
                    SUBMIT_SCORE: {
                        target: 'submittingSurvey',
                    },
                },
            },
            selectReason: {
                on: {
                    UPDATE_REASONS: {
                        actions: ['setReasons'],
                    },
                    SUBMIT_NPS: 'submittingSurvey',
                },
            },
            submittingSurvey: {
                tags: ['loading'],
                invoke: {
                    src: 'submitSurvey',
                    onDone: {
                        target: 'surveySubmitted',
                        actions: 'setSurveyResult',
                    },
                    onError: {
                        target: 'selectReason',
                        actions: 'setError',
                    },
                },
            },
            surveySubmitted: {
                type: 'final',
            },
        },
    },
    {
        actions: {
            setSurveyId: assign({
                surveyId: (_, event) => event.id,
            }),
            setClientId: assign({
                clientId: (_, event) => event.id,
            }),
            setMdn: assign({
                mdn: (_, event) => event.mdn,
            }),
            setScoreQuestionId: assign({
                scoreQuestionId: (_, event) => event.id,
            }),
            setReasonsPerCategory: assign({
                reasons: (_, event: any) => event.data,
            }),
            setCustomerCategory: assign({
                customerCategory: (_, { score }) => {
                    // These are industry standards for NPS.
                    if (score > 8) return 'PROMOTER';
                    if (score > 6) return 'PASSIVE';
                    if (score !== null) return 'DETRACTOR';
                    return null;
                },
            }),
            setScore: assign({
                score: (_, event) => event.score,
            }),

            setReasons: assign({
                selectedReasons: (_, event) => event.selectedReasons,
            }),
            setSurveyResult: assign({
                surveyResult: (_, event: any) => event.data,
            }),
            setError: assign({
                errors: (_, event: any) =>
                    event.data.message || event.data.response.data || 'Unknown error occured',
            }),
        },
    }
);

export default npsMachine;
