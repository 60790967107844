/* eslint-disable no-console */
import CongratsSvg from '../assets/CongratsSvg';
import MenuSidebar from '../components/MenuSidebar';
import MainStackLayout from '../layout/MainStackLayout';
import { defaultToastProps } from '../modules/config/constants';
import { changePassword } from '../modules/services/apiService';
import {
  BackButton,
  DesktopView,
  MobileView,
  PASSWORDFORM,
  PasswordReset,
  PasswordResetForm,
  TextScript,
} from '@MGPD/myasurion-shared';
import { Box, Center, Heading } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

const ChangePasswordForm = ({
  changeSuccess,
  apiErrorMessage,
  isUpdating,
  setApiErrorMessage,
  updatePassword,
}: {
  changeSuccess: boolean | undefined;
  apiErrorMessage: PasswordReset;
  isUpdating: boolean | undefined;
  setApiErrorMessage: ({ oldPassword, newPassword, confirmNewPassword }: PasswordReset) => void;
  updatePassword: ({ op, np }: { op: string; np: string }) => void;
}) => {
  return (
    <>
      {changeSuccess !== true && (
        <PasswordResetForm
          mode={PASSWORDFORM.CHANGE}
          apiErrors={apiErrorMessage}
          hashingFnParams={[import.meta.env.VITE_MYASURION_API_AUTH_SALT]}
          title="Change Password"
          isLoading={isUpdating}
          onSubmit={(data: any) => {
            const { oldPassword, newPassword } = data;
            setApiErrorMessage({});
            if (oldPassword && newPassword) {
              updatePassword({ op: oldPassword, np: newPassword });
            }
          }}
        />
      )}
      {changeSuccess === true && (
        <Box w={'full'} mt={'12px'}>
          <DesktopView>
            <Heading
              w={'full'}
              letterSpacing={-0.352}
              fontWeight={'light'}
              lineHeight={'shorter'}
              fontSize={'40px'}
              textAlign={'center'}
            >
              <TextScript
                text={'Change password AAAAAAAA'}
                translationKey={`ChangePassword/titleDesktop`}
              />
            </Heading>
          </DesktopView>
          <Center>
            <CongratsSvg />
          </Center>
          <Center>
            {/* <Text fontSize={'32px'} fontWeight={300} lineHeight={'124%'} letterSpacing={'-0.352px'}>
              <DesktopView>Password saved</DesktopView>
              <MobileView>Password changed</MobileView>
            </Text> */}
            <Box fontSize={'32px'} fontWeight={300} lineHeight={'124%'} letterSpacing={'-0.352px'}>
              <DesktopView>
                <TextScript
                  text={'Password saved'}
                  translationKey={`ChangePassword/passwordChangedMessageDesktop`}
                />
              </DesktopView>
              <MobileView>
                <TextScript
                  text={'Password saved'}
                  translationKey={`ChangePassword/passwordChangedMessageMobile`}
                />
              </MobileView>
            </Box>
            {/* <TextScript text={'Change password'} translationKey={`ChangePassword/titleDesktop`} /> */}
          </Center>
        </Box>
      )}
    </>
  );
};

export const ChangePasswordPage = () => {
  const toast = useToast();
  const [changeSuccess, setChangeSuccess] = useState<boolean | undefined>();
  const [apiErrorMessage, setApiErrorMessage] = useState<PasswordReset>({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: updatePassword, isLoading: isUpdating } = useMutation({
    mutationFn: changePassword,
    onSuccess: (data) => {
      if (data && data.statusCode === 200) {
        // toast({
        //   ...defaultToastProps,
        //   title: 'Change Password!',
        //   description: 'You have successfully changed your password',
        // });
        setChangeSuccess(true);
      } else if (data && data.statusCode === 405) {
        setApiErrorMessage({ oldPassword: 'Please kindly use the password you have registered' });
        setChangeSuccess(false);
      } else {
        toast({
          ...defaultToastProps,
          title: 'Change Password!',
          description: 'There was an error while updating your password',
        });
        setChangeSuccess(false);
      }
    },
    onError: (error) => {
      console.log('onError', error);
      toast({
        ...defaultToastProps,
        title: 'Change Password!',
        description: 'There was an error while updating your password',
      });
      setApiErrorMessage({});
      setChangeSuccess(false);
    },
  });

  return (
    <Box
      id={`myasurion-profile-changepass`}
      p={{ base: '75px 16px 16px 16px', md: '54px 0 0 0' }}
      h={{ base: '100vh', md: 'auto' }}
    >
      <MobileView>
        <BackButton isProfile={true}>
          <TextScript
            text={'Change Password'}
            translationKey={`DashboardBack/${'Change Password'}`}
          />
        </BackButton>
        <ChangePasswordForm
          changeSuccess={changeSuccess || false}
          isUpdating={isUpdating || false}
          apiErrorMessage={apiErrorMessage}
          setApiErrorMessage={setApiErrorMessage}
          updatePassword={updatePassword}
        />
      </MobileView>
      <DesktopView>
        <Center>
          <MainStackLayout
            gap={'20px'}
            spacing={0}
            maxW={'1024px'}
            direction={'row'}
            mt={'60px'}
            h={'auto'}
          >
            <Box w={'full'} maxWidth={'280px'} p={0} m={0}>
              <MenuSidebar activePage="changepass" />
            </Box>
            <Box w={'350px'} p={'0 0 0 16px'} m={0}>
              <ChangePasswordForm
                changeSuccess={changeSuccess || false}
                isUpdating={isUpdating || false}
                apiErrorMessage={apiErrorMessage}
                setApiErrorMessage={setApiErrorMessage}
                updatePassword={updatePassword}
              />
            </Box>
          </MainStackLayout>
        </Center>
      </DesktopView>
    </Box>
  );
};
export default ChangePasswordPage;
