/* eslint-disable no-console */
import { Box, BoxProps } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

const FooterLayout: React.FC<PropsWithChildren & BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      id={'myasurion-profile-footer'}
      p={'16px'}
      w={'full'}
      bg={'#fff'}
      position={'sticky'}
      bottom={0}
      borderTop={'1px solid #EBEBEB'}
      {...props}
    >
      {children}
    </Box>
  );
};
export default FooterLayout;
